import React from 'react'
import { Stack, Box, Paper, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AuditLoggedActionFragmentFragment } from 'api/generated'
import { getRowValue } from 'utils/audit'

type AuditDetailPanelProps = {
  data: AuditLoggedActionFragmentFragment | null | undefined
}

const AuditDetailPanel: React.FC<AuditDetailPanelProps> = ({
  data
}: AuditDetailPanelProps) => {
  const { t } = useTranslation()
  const isUpdate = data?.action === 'U'
  const rowData = data?.row_data || {}
  const changedFields = data?.changed_fields || {}

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack
          direction='row'
          spacing={2}
          p={2}
          height='100%'
          divider={<Divider orientation='vertical' flexItem />}
        >
          <Stack spacing={2} flex={1}>
            <Typography variant='h6'>{t('initial_fields')}</Typography>
            <Stack spacing={1}>
              {Object.keys(rowData)
                ?.sort((a, b) =>
                  !!Object.hasOwn(changedFields, a) ===
                  !!Object.hasOwn(changedFields, b)
                    ? 0
                    : a
                    ? -1
                    : 1
                )
                ?.map(rowKey => {
                  if (rowKey === 'metadata') {
                    return null
                  }

                  const rowValue = rowData?.[rowKey]
                  const formattedValue = getRowValue(rowKey, rowValue, t)

                  return (
                    <Stack key={rowKey} direction='row' spacing={1}>
                      <Typography variant='body2'>{`${t(rowKey)}:`}</Typography>
                      <Typography
                        variant='subtitle2'
                        fontWeight='bold'
                        color={
                          isUpdate
                            ? Object.hasOwn(changedFields || {}, rowKey)
                              ? 'primary.main'
                              : ''
                            : ''
                        }
                      >{`${formattedValue}`}</Typography>
                    </Stack>
                  )
                })}
            </Stack>
          </Stack>

          {isUpdate ? (
            <Stack spacing={2} flex={1}>
              <Typography variant='h6'>{t('changed_fields')}</Typography>
              <Stack spacing={1}>
                {Object.keys(changedFields || {})?.map(rowKey => {
                  if (rowKey === 'metadata') {
                    return null
                  }

                  const rowValue = changedFields?.[rowKey]
                  const formattedValue = getRowValue(rowKey, rowValue, t)

                  return (
                    <Stack key={rowKey} direction='row' spacing={1}>
                      <Typography variant='body2'>{`${t(
                        rowKey
                      )}: `}</Typography>
                      <Typography
                        variant='subtitle2'
                        fontWeight='bold'
                        color='primary.main'
                      >{`${formattedValue}`}</Typography>
                    </Stack>
                  )
                })}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Paper>
    </Box>
  )
}

export default AuditDetailPanel
