import React, { lazy, Suspense } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import {
  esES as esMuiPickerLocale,
  enUS as enMuiPickerLocale
} from '@mui/x-date-pickers-pro/locales'
import { es as esDateFnsLocale, enUS as enDateFnsLocale } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { APIProvider } from '@vis.gl/react-google-maps'

import { FullPageLoading } from 'components'
import { useThemeType } from 'context/theme'
import { themeConfig, firebaseConfig } from 'config'
import { useUser } from 'context/auth/user'
import { useAuth } from 'context/auth/auth'

const Authenticated = lazy(() => import('./screens/Authenticated'))
const Unauthenticated = lazy(() => import('./screens/Unauthenticated'))

const App = () => {
  const { firstLoading, loadingDbUser } = useAuth()
  const { firebaseUser, dbUser } = useUser()
  const {
    i18n: { language }
  } = useTranslation()
  const { themeType } = useThemeType()
  const theme = themeConfig(themeType, language)
  const pickerLocale = language === 'es' ? esMuiPickerLocale : enMuiPickerLocale
  const dateFnsLocale = language === 'es' ? esDateFnsLocale : enDateFnsLocale

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<FullPageLoading />}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={dateFnsLocale}
          localeText={
            pickerLocale.components.MuiLocalizationProvider.defaultProps
              .localeText
          }
        >
          <APIProvider apiKey={firebaseConfig.apiKey} language={language}>
            {firstLoading || loadingDbUser ? (
              <FullPageLoading />
            ) : firebaseUser &&
              firebaseUser?.emailVerified &&
              dbUser?.deleted_at === null ? (
              <Authenticated />
            ) : (
              <Unauthenticated />
            )}
          </APIProvider>
        </LocalizationProvider>
      </Suspense>
    </ThemeProvider>
  )
}

export default App
