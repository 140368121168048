import React from 'react'

import AdminLayout from './Admin'
import CollectorLayout from './Collector'
import FullPageLoading from 'components/FullPageLoading'

type LayoutProps = {
  children: JSX.Element
  showAdminUI?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  children,
  showAdminUI
}: LayoutProps) => {
  return showAdminUI === undefined ? (
    <FullPageLoading />
  ) : showAdminUI ? (
    <AdminLayout>{children}</AdminLayout>
  ) : (
    <CollectorLayout>{children}</CollectorLayout>
  )
}

export default Layout
