import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  DialogContentText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Check as CheckIcon, Receipt as ReceiptIcon } from '@mui/icons-material'
import { ApolloError } from '@apollo/client'
import { DefaultTFuncReturn } from 'i18next'

import MuiButton from '../MuiButton'
import { getPaymentReceiptUrl } from 'utils/firebase'

type PaymentConfirmationDialogProps = {
  openDialog: boolean
  onAccept(): void
  onCancel?(): void
  loading?: boolean
  error?: ApolloError
  title: DefaultTFuncReturn | string
  description: DefaultTFuncReturn | string
  paymentId?: string
}

const PaymentConfirmationDialog: React.FC<PaymentConfirmationDialogProps> = ({
  openDialog,
  onAccept,
  onCancel,
  loading,
  error,
  title,
  description,
  paymentId
}: PaymentConfirmationDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={openDialog} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {error?.message ? (
          <Alert severity='error'>
            <AlertTitle>{t('error')}</AlertTitle>
            {t(error?.message)}
          </Alert>
        ) : null}
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {paymentId && paymentId !== 'successfully' ? (
          <MuiButton
            onClick={() => {
              const urlFile = getPaymentReceiptUrl(paymentId)
              window?.open(urlFile, '_black')
            }}
            color='secondary'
            disabled={loading}
            startIcon={<ReceiptIcon />}
          >
            {t('showReceipt')}
          </MuiButton>
        ) : null}

        <MuiButton
          onClick={onAccept}
          color='primary'
          disabled={loading}
          startIcon={<CheckIcon />}
        >
          {t('confirm')}
        </MuiButton>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentConfirmationDialog
