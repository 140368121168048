import React from 'react'
import { Clear as ClearIcon, Check as CheckIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

export type MuiDataGridBooleanCellProps = {
  value?: boolean
}

const MuiDataGridBooleanCell: React.FC<MuiDataGridBooleanCellProps> = ({
  value
}: MuiDataGridBooleanCellProps) => {
  const theme = useTheme()

  switch (value) {
    case true:
      return <CheckIcon style={{ color: theme.palette.success.main }} />

    case false:
      return <ClearIcon style={{ color: theme.palette.error.main }} />

    default:
      return null
  }
}

export default MuiDataGridBooleanCell
