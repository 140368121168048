import React from 'react'

import Header from './Header'
import Main from './Main'
import Footer from './Footer'

type UnauthenticatedLayoutProps = {
  children: JSX.Element
}

const UnauthenticatedLayout: React.FC<UnauthenticatedLayoutProps> = ({
  children
}: UnauthenticatedLayoutProps) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

export default UnauthenticatedLayout
