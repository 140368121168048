import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  CardHeader,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Box
} from '@mui/material'
import { Payment as PaymentIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { PaymentsList } from 'components'
import { Order_By, useGetPaymentsWithAggregateQuery } from 'api/generated'
import { formatCurrency } from 'utils/currency'
import { getPaymentsAggregateWhere, getPaymentsFilterPath } from 'utils/chart'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'
import { PaginationModelType, defaultPaginationModel } from 'utils/dataGrid'
import { sum } from 'utils/number'

const PaymentsChart: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery,
  locationId,
  userId,
  dateRange
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [paginationModel, setPaginationModel] = useState<PaginationModelType>(
    defaultPaginationModel
  )
  const { page, pageSize } = paginationModel
  const { data, loading, error, refetch } = useGetPaymentsWithAggregateQuery({
    variables: {
      where: getPaymentsAggregateWhere(dateRange, userId, locationId),
      limit: pageSize,
      offset: pageSize * page,
      order_by: { created_at: Order_By.Desc }
    }
  })
  const dictionariesData = dictionariesQuery?.data
  const aggregate = data?.paymentsAggregate?.aggregate
  const aggregateSum = aggregate?.sum
  const totalCount = aggregate?.totalCount

  const handleShowPaymentsInDetailTable = useCallback(() => {
    const selectedUser = dictionariesData?.users?.find(
      user => user?.id === userId
    )
    const selectedLocation = dictionariesData?.locations?.find(
      location => location?.id === locationId
    )
    const paymentsFilterPath = getPaymentsFilterPath(
      dateRange,
      selectedUser?.full_name,
      selectedLocation?.name
    )

    navigate(paymentsFilterPath)
  }, [dateRange, dictionariesData, userId, locationId, navigate])

  const totalAmount = sum([aggregateSum?.amount, aggregateSum?.penalty_amount])

  return (
    <>
      <CardHeader
        title={t('payments')}
        subheader={
          <Stack
            direction='row'
            spacing={1}
            divider={<Divider orientation='vertical' flexItem />}
          >
            <Tooltip title={t('totalPayments')}>
              <Typography variant='body1'>{totalCount || 0}</Typography>
            </Tooltip>

            <Tooltip title={t('totalAmount')}>
              <Typography variant='body1'>
                {aggregateSum
                  ? formatCurrency({
                      amount: totalAmount
                    })
                  : ''}
              </Typography>
            </Tooltip>
          </Stack>
        }
        action={
          <Tooltip title={t('showInDetail')}>
            <IconButton onClick={handleShowPaymentsInDetailTable}>
              <PaymentIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <Box flex={1}>
        <PaymentsList
          loading={loading}
          refetch={refetch}
          error={error}
          data={data}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>
    </>
  )
}

export default PaymentsChart
