import React from 'react'
import {
  CardContent,
  Typography,
  CardProps,
  Tooltip,
  Skeleton,
  Stack,
  Chip,
  Box,
  useTheme
} from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import { SparkLineChart } from '@mui/x-charts/SparkLineChart'
import { areaElementClasses } from '@mui/x-charts/LineChart'

import MuiCard from '../MuiCard'
import { AreaGradient, getDaysInMonth } from 'components/StatCard'

export type SummaryCardChartProps = {
  cardProps?: CardProps
  value: string | number | undefined
  valueTooltipTitle?: string | DefaultTFuncReturn
  loading?: boolean
  totalCount?: number
  totalCountTooltip?: string | DefaultTFuncReturn
  title?: string | DefaultTFuncReturn
  action?: JSX.Element
  data?: number[]
  color?: string
}

const SummaryCardChart: React.FC<SummaryCardChartProps> = ({
  cardProps,
  title,
  action,
  value,
  valueTooltipTitle,
  totalCount,
  totalCountTooltip,
  loading,
  data,
  color
}: SummaryCardChartProps) => {
  const theme = useTheme()
  const chartColor = color || theme.palette.grey[400]

  return loading && !value ? (
    <Skeleton variant='rectangular' height='100%' width='100%' />
  ) : (
    <MuiCard variant='outlined' sx={{ flex: 1 }} {...cardProps}>
      <CardContent>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography component='h2' variant='subtitle2' gutterBottom>
            {title}
          </Typography>
          {action}
        </Stack>

        <Stack
          direction='column'
          justifyContent='space-between'
          flexGrow={1}
          gap={1}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Tooltip title={valueTooltipTitle}>
              <Typography variant='h4' component='p'>
                {value}
              </Typography>
            </Tooltip>

            {typeof totalCount === 'number' ? (
              <Tooltip title={totalCountTooltip}>
                <Chip size='small' label={totalCount} />
              </Tooltip>
            ) : null}
          </Stack>

          {data ? (
            <Box sx={{ width: '100%', height: 50 }}>
              <SparkLineChart
                colors={[chartColor]}
                data={data}
                area
                showHighlight
                showTooltip
                xAxis={{
                  scaleType: 'band',
                  data: getDaysInMonth(4, 2024)
                }}
                sx={{
                  [`& .${areaElementClasses.root}`]: {
                    fill: `url(#area-gradient-${value})`
                  }
                }}
              >
                <AreaGradient
                  color={chartColor}
                  id={`area-gradient-${value}`}
                />
              </SparkLineChart>
            </Box>
          ) : null}
        </Stack>
      </CardContent>
    </MuiCard>
  )
}

export default SummaryCardChart
