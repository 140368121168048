import React from 'react'
import { Tabs, Tab } from '@mui/material'
import {
  Home as HomeIcon,
  People as PeopleIcon,
  AccountBalance as AccountBalanceIcon,
  Payments as PaymentsIcon
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: '100%',
  zIndex: 50,
  backgroundColor: theme.palette.background.paper
}))

export const PATHS_WITH_BOTTOM_TABS = ['/', '/clients', '/loans', '/payments']

type Tab = {
  path: string
  icon: JSX.Element
  label: string
}

type Tabs = {
  '/': Tab
  '/clients': Tab
  '/loans': Tab
  '/payments': Tab
}

const TABS: Tabs = {
  '/': {
    path: '/',
    icon: <HomeIcon />,
    label: 'home'
  },
  '/clients': {
    path: '/clients',
    icon: <PeopleIcon />,
    label: 'clients'
  },
  '/loans': {
    path: '/loans',
    icon: <AccountBalanceIcon />,
    label: 'loans'
  },
  '/payments': {
    path: '/payments',
    icon: <PaymentsIcon />,
    label: 'payments'
  }
}

const BottomTabs: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const pathname = location?.pathname as keyof Tabs
  const currentTab = TABS?.[pathname]

  return PATHS_WITH_BOTTOM_TABS?.includes(pathname) ? (
    <StyledTabs value={currentTab?.path || '/'}>
      {Object.keys(TABS)?.map(tabKey => {
        const tabValues = TABS[tabKey as keyof Tabs]

        return (
          <Tab
            key={tabKey}
            value={tabKey}
            sx={{ flex: 1, maxWidth: '100%' }}
            icon={tabValues?.icon}
            label={t(tabValues?.label)}
            component={Link}
            to={tabValues?.path}
          />
        )
      })}
    </StyledTabs>
  ) : null
}

export default BottomTabs
