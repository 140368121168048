import React from 'react'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro'
import { DefaultTFuncReturn } from 'i18next'

type MuiDateTimePickerProps = DateTimePickerProps<Date> & {
  helperText?: string | DefaultTFuncReturn
  error?: boolean
  fullWidth?: boolean
}

const MuiDateTimePicker: React.FC<MuiDateTimePickerProps> = ({
  helperText,
  error = false,
  fullWidth = true,
  ...props
}: MuiDateTimePickerProps) => {
  return (
    <DateTimePicker
      slotProps={{
        textField: {
          helperText,
          error,
          fullWidth
        }
      }}
      {...props}
    />
  )
}

export default MuiDateTimePicker
