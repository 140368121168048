import React, { useCallback } from 'react'
import { MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Language as LanguageIcon } from '@mui/icons-material'

import MuiTextField from '../MuiTextField'

type LanguageItem = {
  value: string
  label: string
}

type Languages = {
  en: LanguageItem
  es: LanguageItem
}

const languages: Languages = {
  es: {
    value: 'en',
    label: 'English'
  },
  en: {
    value: 'es',
    label: 'Español'
  }
}

const LanguageSelect: React.FC = () => {
  const { t, i18n } = useTranslation()

  const handleOnChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      i18n.changeLanguage(ev.target.value)
    },
    [i18n]
  )

  return (
    <MuiTextField
      select
      fullWidth={false}
      label={t('language')}
      onChange={handleOnChange}
      value={i18n.language.split('-')[0]}
      InputProps={{
        startAdornment: <LanguageIcon />
      }}
    >
      {Object.keys(languages).map(key => {
        const { value, label } = languages[key as keyof Languages]

        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        )
      })}
    </MuiTextField>
  )
}

export default LanguageSelect
