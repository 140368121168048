import { TFunction, DefaultTFuncReturn } from 'i18next'
import { format, parseISO } from 'date-fns'
import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro'

import { formatCurrency } from 'utils/currency'
import { generatePath } from '../dataGrid'
import { AUDIT_TABLE_KEYS } from 'config/constants'

export enum ROW_TYPE_ENUM {
  TIMES_STAMP_WITH_TIME = 'TIMES_STAMP_WITH_TIME',
  DATE = 'DATE',
  AMOUNT = 'AMOUNT',
  WITH_T = 'WITH_T',
  STRING = 'STRING'
}

export enum TIMES_STAMP_WITH_TIME_ZONE_ROW_ENUM {
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  DELETED_AT = 'deleted_at'
}

export enum DATE_ROW_ENUM {
  PAYMENT_DATE = 'payment_date',
  NEXT_SUBSCRIPTION_PAYMENT_DATE = 'next_subscription_payment_date',
  EXPECTED_PAYMENT_DATE = 'expected_payment_date',
  NEXT_PAYMENT_DATE = 'new_payment_date'
}

export enum AMOUNT_ROW_ENUM {
  AMOUNT = 'amount',
  BALANCE = 'balance',
  INTEREST_ARREARS_AMOUNT = 'interest_arrears_amount',
  COMMISSION_ARREARS_AMOUNT = 'commission_arrears_amount',
  CAPITAL_AMOUNT = 'capital_amount',
  CURRENT_BALANCE_AMOUNT = 'current_balance_amount',
  INTEREST_INTEREST_ARREARS_AMOUNT = 'current_interest_arrears_amount',
  INTEREST_COMMISSION_INTEREST_ARREARS_AMOUNT = 'current_commission_interest_arrears_amount',
  EXPECTED_AMOUNT = 'expected_amount',
  INTEREST_AMOUNT = 'interest_amount',
  NEW_BALANCE_AMOUNT = 'new_balance_amount',
  NEW_INTEREST_ARREARS_AMOUNT = 'new_interest_arrears_amount',
  NEW_COMMISSION_INTEREST_ARREARS_AMOUNT = 'new_commission_interest_arrears_amount',
  PENALTY_AMOUNT = 'penalty_amount',
  COMMISSION_AMOUNT = 'commission_amount',
  EXPECTED_COMMISSION_AMOUNT = 'expected_commission_amount',
  EXPECTED_CAPITAL_AMOUNT = 'expected_capital_amount',
  EXPECTED_INTEREST_AMOUNT = 'expected_interest_amount',
  COMMISSION_INTEREST_ARREARS_AMOUNT = 'commission_interest_arrears_amount'
}

export enum WITH_T_ROW_ENUM {
  TYPE = 'type',
  STATUS = 'status',
  PERIODICITY = 'periodicity',
  SUBSCRIPTION_TYPE = 'subscription_type',
  METHOD = 'method',
  ROLE = 'role'
}

export type GetAuditFilterPath = {
  tableName?: string
  rowId?: string
  user?: string | null
}

export const TIMES_STAMP_WITH_TIME_ZONE_ROW_ENUM_ARRAY = Object.values(
  TIMES_STAMP_WITH_TIME_ZONE_ROW_ENUM
)
export const DATE_ROW_ENUM_ARRAY = Object.values(DATE_ROW_ENUM)
export const AMOUNT_ROW_ENUM_ARRAY = Object.values(AMOUNT_ROW_ENUM)
export const WITH_T_ROW_ENUM_ARRAY = Object.values(WITH_T_ROW_ENUM)
export const SPECIAL_ROW_ENUM_ARRAY = [
  ...TIMES_STAMP_WITH_TIME_ZONE_ROW_ENUM_ARRAY,
  ...DATE_ROW_ENUM_ARRAY,
  ...AMOUNT_ROW_ENUM_ARRAY,
  ...WITH_T_ROW_ENUM_ARRAY
]

export const getFieldNamesEnumIncludesFieldName = (
  fieldNames: string[],
  fieldName: string
): boolean => !!fieldNames?.includes(fieldName)

export const getIsTimesStampWithTimeZoneField = (fieldName: string) =>
  getFieldNamesEnumIncludesFieldName(
    TIMES_STAMP_WITH_TIME_ZONE_ROW_ENUM_ARRAY,
    fieldName
  )

export const getIsDateField = (fieldName: string) =>
  getFieldNamesEnumIncludesFieldName(DATE_ROW_ENUM_ARRAY, fieldName)

export const getIsAmountField = (fieldName: string) =>
  getFieldNamesEnumIncludesFieldName(AMOUNT_ROW_ENUM_ARRAY, fieldName)

export const getIsWithTField = (fieldName: string) =>
  getFieldNamesEnumIncludesFieldName(WITH_T_ROW_ENUM_ARRAY, fieldName)

export const getIsSpecialField = (fieldName: string) =>
  getFieldNamesEnumIncludesFieldName(SPECIAL_ROW_ENUM_ARRAY, fieldName)

export const getRowValue = (
  fieldName: string,
  value: string | number | Date | null | undefined,
  t: TFunction
): string | number | Date | null | undefined | DefaultTFuncReturn => {
  try {
    if (value === null || value === undefined) {
      return value
    }

    const isSpecialRow = getIsSpecialField(fieldName)

    if (isSpecialRow) {
      const isTimesStampWithTimeZoneField =
        getIsTimesStampWithTimeZoneField(fieldName)

      if (isTimesStampWithTimeZoneField) {
        return format(new Date(value as string), 'dd/MM/yyyy HH:mm:ss')
      }

      const isDateField = getIsDateField(fieldName)
      if (isDateField) {
        return format(parseISO(value as string), 'dd/MM/yyyy')
      }

      const isAmountField = getIsAmountField(fieldName)
      if (isAmountField) {
        return `${formatCurrency({
          amount: Number(value || 0),
          withDefaultOptions: false
        })}`
      }

      const isWithTField = getIsWithTField(fieldName)
      if (isWithTField) {
        return t((value as string) || '')
      }
    }

    return ['string', 'number']?.includes(typeof value) ||
      value === null ||
      value === undefined
      ? value
      : typeof value === 'object'
      ? JSON.stringify(value || {}, null, 2)
      : ''
  } catch {
    return t('somethingWentWrong')
  }
}

export const getTablePathName = (tableName: string): string | null => {
  switch (tableName) {
    case AUDIT_TABLE_KEYS.CLIENT:
    case AUDIT_TABLE_KEYS.USER:
    case AUDIT_TABLE_KEYS.PAYMENT:
    case AUDIT_TABLE_KEYS.LOAN:
    case AUDIT_TABLE_KEYS.LOCATION:
      return `/${tableName}s`

    case AUDIT_TABLE_KEYS.LOCATION_PAYMENT_METHOD:
      return '/payment-methods'

    default:
      return null
  }
}

export const getAuditFilterPath = ({
  tableName,
  rowId,
  user
}: GetAuditFilterPath): string => {
  const filterModel: GridFilterModel = {
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: []
  }

  if (tableName) {
    filterModel.items?.push({
      id: 1,
      field: 'table_name',
      operator: 'is',
      value: tableName
    })
  }

  if (rowId) {
    filterModel.items?.push({
      id: 1,
      field: 'row_id',
      operator: 'equals',
      value: rowId
    })
  }

  if (user) {
    filterModel.items?.push({
      id: 3,
      field: 'user.full_name',
      operator: 'equals',
      value: user
    })
  }

  const path = generatePath({
    filterModel
  })

  return `/audits${path}`
}

export default {
  getRowValue,
  getTablePathName
}
