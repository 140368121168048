import React from 'react'
import { Grid, GridProps } from '@mui/material'

import SkeletonTextField from '../SkeletonTextField'

export type FormLoadingProps = GridProps & {
  numberOfFields?: number
}

const FormLoading: React.FC<FormLoadingProps> = ({
  numberOfFields = 10,
  ...props
}: FormLoadingProps) => {
  return (
    <Grid container spacing={2}>
      {[...Array(numberOfFields)].map((v, i) => {
        return (
          <Grid key={i} item xs={12} sm={3} {...props}>
            <SkeletonTextField />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default FormLoading
