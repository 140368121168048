export enum LOCAL_STORAGE_KEYS {
  THEME_TYPE = 'THEME_TYPE'
}

export enum TABLE_PREFERENCES_LOCAL_STORAGE_KEYS {
  TABLE_PREFERENCES = 'TABLE_PREFERENCES',
  USER_TABLE_PREFERENCES = 'USER_TABLE_PREFERENCES',
  CLIENT_TABLE_PREFERENCES = 'CLIENT_TABLE_PREFERENCES',
  LOCATION_TABLE_PREFERENCES = 'LOCATION_TABLE_PREFERENCES',
  TENANT_TABLE_PREFERENCES = 'TENANT_TABLE_PREFERENCES',
  LOAN_TABLE_PREFERENCES = 'LOAN_TABLE_PREFERENCES',
  PAYMENT_TABLE_PREFERENCES = 'PAYMENT_TABLE_PREFERENCES',
  PAYMENT_METHOD_TABLE_PREFERENCES = 'PAYMENT_METHOD_TABLE_PREFERENCES',
  AUDIT_METHOD_TABLE_PREFERENCES = 'AUDIT_METHOD_TABLE_PREFERENCES'
}

export const AGGREGATION_ROW_ID = 'AGGREGATION_ROW_ID'

export enum AUDIT_ACTIONS_KEYS {
  INSERT = 'I',
  UPDATE = 'U'
}

export enum AUDIT_TABLE_KEYS {
  PAYMENT = 'payment',
  LOAN = 'loan',
  USER = 'user',
  CLIENT = 'client',
  LOCATION = 'location',
  LOCATION_PAYMENT_METHOD = 'location_payment_method',
  CLIENT_ADDRESS = 'client_address',
  CLIENT_FILE = 'client_file',
  CLIENT_PHONE_NUMBER = 'client_phone_number',
  USER_LOCATION = 'user_location',
  USER_TENANT = 'user_tenant'
}

export default {
  LOCAL_STORAGE_KEYS,
  TABLE_PREFERENCES_LOCAL_STORAGE_KEYS,
  AGGREGATION_ROW_ID,
  AUDIT_ACTIONS_KEYS,
  AUDIT_TABLE_KEYS
}
