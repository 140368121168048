import { Resource } from 'i18next'

import es from './es'
import en from './en'

const resources: Resource = {
  es,
  en
}

export default resources
