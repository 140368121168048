import React, { forwardRef } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

type NumberFieldProps = NumericFormatProps & {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberField: React.FC<NumberFieldProps> = forwardRef<
  HTMLInputElement,
  NumberFieldProps
>(function NumberField(
  { onChange, ...props }: NumberFieldProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <NumericFormat
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator
      valueIsNumericString
      prefix={`₡ `}
      {...props}
    />
  )
})

export default NumberField
