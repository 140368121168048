import { getDownloadURL, ref } from 'firebase/storage'

import firebaseLib from 'lib/firebase'
import { firebaseConfig } from 'config'
import { Document_Template_Type_Enum } from 'api/generated'

const { storage } = firebaseLib

const getUrlFile = (filePath: string): Promise<string> | undefined =>
  filePath ? getDownloadURL(ref(storage, filePath)) : undefined

export const getPaymentReceiptUrl = (id: string): string =>
  `${firebaseConfig.documentsFunctionUrl}/paymentReport?type=${Document_Template_Type_Enum.PaymentReceipt}&id=${id}`

export default {
  getUrlFile,
  getPaymentReceiptUrl
}
