import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertTitle,
  DialogContentText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'
import { ApolloError } from '@apollo/client'
import { DefaultTFuncReturn } from 'i18next'

import MuiButton from '../MuiButton'

type ConfirmationDialogProps = {
  openDialog: boolean
  onAccept(): void
  onCancel?(): void
  loading?: boolean
  error?: ApolloError
  title: DefaultTFuncReturn | string
  description: DefaultTFuncReturn | string
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  openDialog,
  onAccept,
  onCancel,
  loading,
  error,
  title,
  description
}: ConfirmationDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={openDialog} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {error?.message ? (
          <Alert severity='error'>
            <AlertTitle>{t('error')}</AlertTitle>
            {t(error?.message)}
          </Alert>
        ) : null}
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel ? (
          <MuiButton
            onClick={onCancel}
            color='secondary'
            disabled={loading}
            startIcon={<CloseIcon />}
          >
            {t('cancel')}
          </MuiButton>
        ) : null}
        <MuiButton
          onClick={onAccept}
          color='primary'
          disabled={loading}
          startIcon={<CheckIcon />}
        >
          {t('confirm')}
        </MuiButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
