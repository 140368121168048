import { GridFilterModel } from '@mui/x-data-grid-pro'

import { DELETED_FILTER_MODEL, EMPTY_FILTER_MODEL } from '../dataGrid'

export const ALL_USERS_FILTER = EMPTY_FILTER_MODEL

export const DELETED_USERS_FILTER = DELETED_FILTER_MODEL

export enum USER_FILTERS_ENUM {
  ALL = 'ALL',
  DELETED = 'DELETED'
}

export type UserFiltersTypeValues = {
  index: number
  title: string
  filter: GridFilterModel
  collectorMode: boolean
}

export type UserFiltersType = {
  [key in USER_FILTERS_ENUM]: UserFiltersTypeValues
}

export const USER_FILTERS_OBJECT: UserFiltersType = {
  [USER_FILTERS_ENUM.ALL]: {
    index: 0,
    title: 'all',
    filter: ALL_USERS_FILTER,
    collectorMode: false
  },
  [USER_FILTERS_ENUM.DELETED]: {
    index: 1,
    title: 'deleted',
    filter: DELETED_USERS_FILTER,
    collectorMode: false
  }
}

export const USER_FILTERS_KEYS_ARRAY = Object.keys(
  USER_FILTERS_OBJECT
) as USER_FILTERS_ENUM[]

export const COLLECTOR_MODE_USER_FILTERS_KEYS_ARRAY = (
  Object.keys(USER_FILTERS_OBJECT) as USER_FILTERS_ENUM[]
)?.filter(filterKey => USER_FILTERS_OBJECT?.[filterKey]?.collectorMode)

export const USER_FILTERS_ARRAY: UserFiltersTypeValues[] =
  USER_FILTERS_KEYS_ARRAY?.map(filterKey => USER_FILTERS_OBJECT?.[filterKey])

export const getUserFilterByIndex = (
  index: number
): UserFiltersTypeValues | undefined =>
  USER_FILTERS_ARRAY?.find(userFilter => userFilter?.index === index)

export const getInitialUserFilter = () => {
  return USER_FILTERS_OBJECT.ALL
}

export default {
  USER_FILTERS_OBJECT,
  USER_FILTERS_KEYS_ARRAY,
  USER_FILTERS_ARRAY,
  getUserFilterByIndex,
  getInitialUserFilter
}
