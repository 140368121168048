import { GridFilterModel } from '@mui/x-data-grid-pro'
import isEqual from 'lodash.isequal'
import { format, startOfDay, startOfISOWeek, startOfMonth } from 'date-fns'

import { DELETED_FILTER_MODEL, EMPTY_FILTER_MODEL } from '../dataGrid'

export const TODAY_PAYMENTS_FILTER: GridFilterModel = {
  ...EMPTY_FILTER_MODEL,
  items: [
    {
      field: 'created_at',
      operator: 'onOrAfter',
      value: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm")
    }
  ]
}

export const WEEKlY_PAYMENT_FILTER: GridFilterModel = {
  ...EMPTY_FILTER_MODEL,
  items: [
    {
      field: 'created_at',
      operator: 'onOrAfter',
      value: format(startOfISOWeek(new Date()), "yyyy-MM-dd'T'HH:mm")
    }
  ]
}

export const MONTHLY_PAYMENTS_FILTER: GridFilterModel = {
  ...EMPTY_FILTER_MODEL,
  items: [
    {
      field: 'created_at',
      operator: 'onOrAfter',
      value: format(startOfMonth(new Date()), "yyyy-MM-dd'T'HH:mm")
    }
  ]
}

export const ALL_PAYMENTS_FILTER = EMPTY_FILTER_MODEL

export const DELETED_PAYMENTS_FILTER = DELETED_FILTER_MODEL

export enum PAYMENTS_FILTERS_ENUM {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ALL = 'ALL',
  DELETED = 'DELETED'
}

export type PaymentFiltersTypeValues = {
  index: number
  title: string
  filter: GridFilterModel
  collectorMode: boolean
}

export type PaymentFiltersType = {
  [key in PAYMENTS_FILTERS_ENUM]: PaymentFiltersTypeValues
}

export const PAYMENT_FILTERS_OBJECT: PaymentFiltersType = {
  [PAYMENTS_FILTERS_ENUM.DAILY]: {
    index: 0,
    title: 'daily',
    filter: TODAY_PAYMENTS_FILTER,
    collectorMode: true
  },
  [PAYMENTS_FILTERS_ENUM.WEEKLY]: {
    index: 1,
    title: 'weekly',
    filter: WEEKlY_PAYMENT_FILTER,
    collectorMode: true
  },
  [PAYMENTS_FILTERS_ENUM.MONTHLY]: {
    index: 2,
    title: 'monthly',
    filter: MONTHLY_PAYMENTS_FILTER,
    collectorMode: true
  },
  [PAYMENTS_FILTERS_ENUM.ALL]: {
    index: 3,
    title: 'all',
    filter: ALL_PAYMENTS_FILTER,
    collectorMode: true
  },
  [PAYMENTS_FILTERS_ENUM.DELETED]: {
    index: 4,
    title: 'deleted',
    filter: DELETED_PAYMENTS_FILTER,
    collectorMode: false
  }
}

export const PAYMENT_FILTERS_KEYS_ARRAY = Object.keys(
  PAYMENT_FILTERS_OBJECT
) as PAYMENTS_FILTERS_ENUM[]

export const COLLECTOR_MODE_PAYMENT_FILTERS_KEYS_ARRAY = (
  Object.keys(PAYMENT_FILTERS_OBJECT) as PAYMENTS_FILTERS_ENUM[]
)?.filter(filterKey => PAYMENT_FILTERS_OBJECT?.[filterKey]?.collectorMode)

export const PAYMENT_FILTERS_ARRAY: PaymentFiltersTypeValues[] =
  PAYMENT_FILTERS_KEYS_ARRAY?.map(
    filterKey => PAYMENT_FILTERS_OBJECT?.[filterKey]
  )

export const getPaymentFilterByIndex = (
  index: number
): PaymentFiltersTypeValues | undefined =>
  PAYMENT_FILTERS_ARRAY?.find(paymentFilter => paymentFilter?.index === index)

export const getInitialPaymentFilter = (
  filterModel: GridFilterModel | undefined
) => {
  if (isEqual(filterModel, PAYMENT_FILTERS_OBJECT.DAILY.filter)) {
    return PAYMENT_FILTERS_OBJECT.DAILY
  }

  if (isEqual(filterModel, PAYMENT_FILTERS_OBJECT.WEEKLY.filter)) {
    return PAYMENT_FILTERS_OBJECT.WEEKLY
  }

  if (isEqual(filterModel, PAYMENT_FILTERS_OBJECT.MONTHLY.filter)) {
    return PAYMENT_FILTERS_OBJECT.MONTHLY
  }

  return PAYMENT_FILTERS_OBJECT.ALL
}

export default {
  TODAY_PAYMENTS_FILTER,
  WEEKlY_PAYMENT_FILTER,
  MONTHLY_PAYMENTS_FILTER,
  PAYMENT_FILTERS_OBJECT,
  PAYMENT_FILTERS_KEYS_ARRAY,
  PAYMENT_FILTERS_ARRAY,
  getPaymentFilterByIndex,
  getInitialPaymentFilter
}
