import React from 'react'
import {
  Map as GoogleMap,
  MapProps as GoogleMapProps
} from '@vis.gl/react-google-maps'
import { useTheme } from '@mui/material'

export const DEFAULT_CENTER = {
  lat: 9.9117288,
  lng: -84.5235848
}
export const DEFAULT_GOOGLE_MAP_ID = 'G-MAP'
export const DEFAULT_GOOGLE_MAP_MAP_ID = '49ec708a6db23808'
export const DEFAULT_ZOOM = 15

type MapProps = GoogleMapProps & {
  children: JSX.Element | JSX.Element[]
}

const Map: React.FC<MapProps> = ({
  id,
  mapId,
  defaultCenter,
  defaultZoom,
  ...props
}: MapProps) => {
  const theme = useTheme()

  return (
    <GoogleMap
      id={id || DEFAULT_GOOGLE_MAP_ID}
      mapId={mapId || DEFAULT_GOOGLE_MAP_MAP_ID}
      defaultCenter={defaultCenter || DEFAULT_CENTER}
      defaultZoom={defaultZoom || DEFAULT_ZOOM}
      backgroundColor={theme.palette.background.paper}
      {...props}
    />
  )
}

export default Map
