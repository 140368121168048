import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
  LinearProgress,
  linearProgressClasses,
  Grid,
  CardHeader,
  Divider,
  IconButton
} from '@mui/material'
import { Payment as PaymentIcon } from '@mui/icons-material'
import { PieChart, PieValueType } from '@mui/x-charts-pro'
import { mangoFusionPalette } from '@mui/x-charts/colorPalettes'
import { useNavigate } from 'react-router-dom'

import { QueryError } from 'components'
import { useGetPaymentsAggregateQuery } from 'api/generated'
import { formatCurrency } from 'utils/currency'
import { getPaymentsAggregateWhere, getPaymentsFilterPath } from 'utils/chart'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'
import { PieCenterLabel } from 'components/MuiPieChart'
import { getPercentage, numOrZero, sum } from 'utils/number'

const PaymentsPieChart: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery,
  locationId,
  userId,
  dateRange
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { data, loading, error, refetch } = useGetPaymentsAggregateQuery({
    variables: {
      where: getPaymentsAggregateWhere(dateRange, userId, locationId)
    }
  })

  const dictionariesData = dictionariesQuery?.data
  const aggregate = data?.paymentsAggregate?.aggregate
  const aggregateSum = aggregate?.sum
  const totalCount = aggregate?.totalCount

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  const totalAmount = sum([aggregateSum?.amount, aggregateSum?.penalty_amount])
  const colorPalettes = mangoFusionPalette(theme.palette.mode)
  const seriesData: PieValueType[] = [
    {
      id: 0,
      value: numOrZero(aggregateSum?.capital_amount),
      label: `${t('capital')}`,
      color: colorPalettes?.[0]
    },
    {
      id: 1,
      value: numOrZero(aggregateSum?.commission_amount),
      label: `${t('commission')}`,
      color: colorPalettes?.[1]
    },
    {
      id: 2,
      value: numOrZero(aggregateSum?.interest_amount),
      label: `${t('interest')}`,
      color: colorPalettes?.[2]
    },
    {
      id: 3,
      value: numOrZero(aggregateSum?.commission_interest_arrears_amount),
      label: `${t('commission_arrears')}`,
      color: colorPalettes?.[3]
    },
    {
      id: 4,
      value: numOrZero(aggregateSum?.interest_arrears_amount),
      label: `${t('interest_arrears')}`,
      color: colorPalettes?.[4]
    },
    {
      id: 5,
      value: numOrZero(aggregateSum?.penalty_amount),
      label: `${t('penalty')}`,
      color: colorPalettes?.[5]
    }
  ]

  const handleShowPaymentsInDetailTable = useCallback(() => {
    const selectedUser = dictionariesData?.users?.find(
      user => user?.id === userId
    )
    const selectedLocation = dictionariesData?.locations?.find(
      location => location?.id === locationId
    )
    const paymentsFilterPath = getPaymentsFilterPath(
      dateRange,
      selectedUser?.full_name,
      selectedLocation?.name
    )

    navigate(paymentsFilterPath)
  }, [dateRange, dictionariesData, userId, locationId, navigate])

  return (
    <>
      <CardHeader
        title={t('payments')}
        subheader={
          <Stack
            direction='row'
            spacing={1}
            divider={<Divider orientation='vertical' flexItem />}
          >
            <Tooltip title={t('totalPayments')}>
              <Typography variant='body1'>{totalCount || 0}</Typography>
            </Tooltip>

            <Tooltip title={t('totalAmount')}>
              <Typography variant='body1'>
                {aggregateSum
                  ? formatCurrency({
                      amount: totalAmount
                    })
                  : ''}
              </Typography>
            </Tooltip>
          </Stack>
        }
        action={
          <Tooltip title={t('showInDetail')}>
            <IconButton onClick={handleShowPaymentsInDetailTable}>
              <PaymentIcon />
            </IconButton>
          </Tooltip>
        }
      />

      {error ? (
        <QueryError
          error={error}
          loading={loading}
          refetch={handleRefetchQuery}
        />
      ) : loading && !data ? (
        <Skeleton
          variant='rectangular'
          width='100%'
          sx={{ minWidth: 320 }}
          height='50vh'
        />
      ) : (
        <>
          <PieChart
            colors={mangoFusionPalette}
            series={[
              {
                valueFormatter: value =>
                  formatCurrency({ amount: value?.value }),
                highlightScope: {
                  faded: 'global',
                  highlighted: 'item'
                },
                innerRadius: 75,
                outerRadius: 100,
                paddingAngle: 0,
                data: seriesData
              }
            ]}
            height={300}
            margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
            slotProps={{
              legend: {
                hidden: true
              }
            }}
          >
            <PieCenterLabel
              primaryText={formatCurrency({
                amount: totalAmount,
                options: { notation: 'compact', maximumFractionDigits: 2 }
              })}
              secondaryText={t('total')}
            />
          </PieChart>

          <Grid container rowSpacing={4} columnSpacing={2}>
            {seriesData.map(series => {
              const percentage = getPercentage(series?.value, totalAmount)
              const value = formatCurrency({
                amount: series?.value
              })

              return (
                <Grid key={series?.id} item xs={12} md={6}>
                  <Stack direction='row'>
                    <Stack flex={1} gap={1}>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Typography variant='body2' fontWeight={500}>
                          {series?.label as string}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {value}
                        </Typography>
                      </Stack>
                      <Tooltip title={`${percentage}%`}>
                        <LinearProgress
                          variant='determinate'
                          value={percentage}
                          sx={{
                            [`& .${linearProgressClasses.bar}`]: {
                              backgroundColor: series.color
                            }
                          }}
                        />
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

export default PaymentsPieChart
