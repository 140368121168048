import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  from,
  ApolloLink
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'

import { hasuraConfig } from 'config'
import firebaseLib from 'lib/firebase'

const { auth } = firebaseLib

const cache = new InMemoryCache({
  typePolicies: {
    user_device_setting: {
      keyFields: ['user_id', 'device_id']
    },
    Query: {
      fields: {
        user_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'user', id: args?.id })
        },
        client_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'client', id: args?.id })
        },
        loan_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'loan', id: args?.id })
        },
        payment_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'payment', id: args?.id })
        },
        location_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'location', id: args?.id })
        },
        payment_method_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'payment_method', id: args?.id })
        },
        audit_logged_actions_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'audit_logged_actions', id: args?.id })
        },
        tenant_by_pk(existing, { canRead, args, toReference }) {
          return canRead(existing)
            ? existing
            : toReference({ __typename: 'tenant', id: args?.id })
        }
      }
    }
  }
})

const getLink = (): ApolloLink => {
  const httpLink = new HttpLink({
    uri: hasuraConfig.httpLinkUrl,
    credentials: 'same-origin'
  })

  const wsLink = new GraphQLWsLink(
    createClient({
      url: hasuraConfig.wsLinkUrl,
      connectionParams: async () => {
        const token = await auth.currentUser?.getIdToken()

        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined
          }
        }
      }
    })
  )

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const authLink = setContext(async (_, { headers }) => {
    const token = await auth.currentUser?.getIdToken()

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined
      }
    }
  })

  return from([authLink, link])
}

const client = new ApolloClient({
  link: getLink(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  cache
})

client.onClearStore(async () => {
  client.setLink(getLink())
})

export default client
