import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from 'firebase/auth'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogActions
} from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'

import MuiButton from '../MuiButton'

type EmailVerifiedDialogProps = DialogProps & {
  loading: boolean
  onSendEmail(): void
  user: null | User
}

const EmailVerifiedDialog: React.FC<EmailVerifiedDialogProps> = ({
  loading,
  onSendEmail,
  user,
  ...modalProps
}: EmailVerifiedDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog {...modalProps}>
      <DialogTitle>{`${t('hi')} ${user?.email}, `}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('emailVerificationAlert', { email: user?.email })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiButton
          onClick={onSendEmail}
          disabled={loading}
          startIcon={<SendIcon />}
        >
          {t('resendConfirmation')}
        </MuiButton>
      </DialogActions>
    </Dialog>
  )
}

export default EmailVerifiedDialog
