export const dynamicData = {
  client: {
    key: 'client',
    data: [
      {
        label: 'id',
        key: 'id'
      },
      {
        label: 'first_name',
        key: 'first_name'
      },
      {
        label: 'last_name',
        key: 'last_name'
      },
      {
        label: 'full_name',
        key: 'full_name'
      },
      {
        label: 'id_number',
        key: 'id_number'
      },
      {
        label: 'email',
        key: 'email'
      },
      {
        label: 'profession',
        key: 'profession'
      },
      {
        label: 'workplace',
        key: 'workplace'
      },
      {
        label: 'workplace',
        key: 'workplace'
      },
      {
        label: 'phone_numbers',
        key: 'phone_numbers'
      },
      {
        label: 'addresses',
        key: 'addresses'
      },
      {
        label: 'created_at',
        key: 'created_at'
      }
    ]
  },
  user: {
    key: 'user',
    data: [
      {
        label: 'id',
        key: 'id'
      },
      {
        label: 'first_name',
        key: 'first_name'
      },
      {
        label: 'last_name',
        key: 'last_name'
      },
      {
        label: 'full_name',
        key: 'full_name'
      },
      {
        label: 'role',
        key: 'role'
      },
      {
        label: 'status',
        key: 'status'
      },
      {
        label: 'id_number',
        key: 'id_number'
      },
      {
        label: 'phone_number',
        key: 'phone_number'
      },
      {
        label: 'created_at',
        key: 'created_at'
      }
    ]
  },
  loan: {
    key: 'loan',
    data: [
      {
        label: 'id',
        key: 'id'
      },
      {
        label: 'amount',
        key: 'amount'
      },
      {
        label: 'balance',
        key: 'balance'
      },
      {
        label: 'interestArrearsAmount',
        key: 'interest_arrears_amount'
      },
      {
        label: 'commissionInterestArrearsAmount',
        key: 'commission_interest_arrears_amount'
      },
      {
        label: 'payment_date',
        key: 'payment_date'
      },
      {
        label: 'interestPercentage',
        key: 'interest_percentage'
      },
      {
        label: 'capitalPercentage',
        key: 'capital_percentage'
      },
      {
        label: 'commissionPercentage',
        key: 'commission_percentage'
      },
      {
        label: 'periodicity',
        key: 'periodicity'
      },
      {
        label: 'type',
        key: 'type'
      },
      {
        label: 'currency',
        key: 'currency'
      },
      {
        label: 'status',
        key: 'status'
      },
      {
        label: 'created_at',
        key: 'created_at'
      }
    ]
  },
  location: {
    key: 'location',
    data: [
      {
        label: 'id',
        key: 'id'
      },
      {
        label: 'name',
        key: 'name'
      },
      {
        label: 'phone_number',
        key: 'phone_number'
      },
      {
        label: 'email',
        key: 'email'
      },
      {
        label: 'id_number',
        key: 'id_number'
      },
      {
        label: 'address',
        key: 'address'
      },
      {
        label: 'description',
        key: 'description'
      },
      {
        label: 'created_at',
        key: 'created_at'
      }
    ]
  }
}

export const dynamicDataKeysArray = Object.keys(dynamicData) as Array<
  keyof typeof dynamicData
>
export const dynamicDataArray = dynamicDataKeysArray?.map(
  dynamicDataKey => dynamicData?.[dynamicDataKey]
)

export default {
  dynamicData,
  dynamicDataKeysArray,
  dynamicDataArray
}
