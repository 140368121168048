import React from 'react'
import { useLocation } from 'react-router-dom'

import Header from './Header'
import Main from '../Shared/Main'
import Footer from './Footer'
import { PATHS_WITH_FAB_BUTTON } from '../Shared/Main/FabButton'

type CollectorLayoutProps = {
  children: JSX.Element
}

const CollectorLayout: React.FC<CollectorLayoutProps> = ({
  children
}: CollectorLayoutProps) => {
  const { pathname } = useLocation()

  return (
    <>
      <Header />
      <Main
        paddingBottom={PATHS_WITH_FAB_BUTTON?.includes(pathname) ? 18 : 10}
        px={2}
        paddingTop={2}
      >
        {children}
      </Main>
      <Footer />
    </>
  )
}

export default CollectorLayout
