import React, { useState, useCallback } from 'react'
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import MuiLink from 'components/MuiLink'
import ProtectedComponent from 'components/ProtectedComponent'
import { User_Role_Enum } from 'api/generated'
import { useUser } from 'context/auth/user'
import { useAuth } from 'context/auth/auth'
import { useThemeType } from 'context/theme'

const Header: React.FC = () => {
  const { t } = useTranslation()
  const { toggleLayoutType } = useUser()
  const { logout } = useAuth()
  const { toggleThemeType } = useThemeType()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <MuiLink to={`/`}>
            <img src='/logo.png' alt='image' width={200} />
          </MuiLink>

          <Box>
            <IconButton
              aria-controls='menu-appbar'
              onClick={handleMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
            >
              <ProtectedComponent
                allowedRoles={[
                  User_Role_Enum.Anonymous,
                  User_Role_Enum.Collector
                ]}
                inclusive={false}
              >
                <MenuItem onClick={toggleLayoutType}>{t('adminMode')}</MenuItem>
              </ProtectedComponent>

              <MenuItem onClick={toggleThemeType}>{t('toggleTheme')}</MenuItem>

              <MuiLink to='/profile' color='inherit'>
                <MenuItem onClick={handleClose}>{t('profile')}</MenuItem>
              </MuiLink>

              <MenuItem onClick={logout}>{t('logOut')}</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default Header
