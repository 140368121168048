import React from 'react'
import { Box, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import {
  PersonAdd as PersonAddIcon,
  AddCard as AddCardIcon,
  PersonAddAlt as PersonAddAltIcon,
  PostAdd as PostAddIcon
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { User_Role_Enum } from 'api/generated'
import { useUser } from 'context/auth/user'

export const PATHS_WITH_FAB_BUTTON = ['/']

type Action = {
  label: string
  icon: JSX.Element
  pathname: string
  allowedRoles: User_Role_Enum[]
}

type Actions = {
  addClient: Action
  addLoan: Action
  addUser: Action
  addPaymentMethod: Action
}

const ACTIONS: Actions = {
  addClient: {
    label: 'addClient',
    icon: <PersonAddIcon />,
    pathname: 'clients/add',
    allowedRoles: [
      User_Role_Enum.Collector,
      User_Role_Enum.TenantAdmin,
      User_Role_Enum.LocationAdmin,
      User_Role_Enum.SuperAdmin
    ]
  },
  addLoan: {
    label: 'addLoan',
    icon: <AddCardIcon />,
    pathname: 'loans/add',
    allowedRoles: [
      User_Role_Enum.TenantAdmin,
      User_Role_Enum.LocationAdmin,
      User_Role_Enum.SuperAdmin
    ]
  },
  addUser: {
    label: 'addUser',
    icon: <PersonAddAltIcon />,
    pathname: 'users/add',
    allowedRoles: [
      User_Role_Enum.TenantAdmin,
      User_Role_Enum.LocationAdmin,
      User_Role_Enum.SuperAdmin
    ]
  },
  addPaymentMethod: {
    label: 'addPaymentMethod',
    icon: <PostAddIcon />,
    pathname: 'payment-methods/add',
    allowedRoles: [
      User_Role_Enum.TenantAdmin,
      User_Role_Enum.LocationAdmin,
      User_Role_Enum.SuperAdmin
    ]
  }
}

const FabButton: React.FC = () => {
  const { t } = useTranslation()
  const { dbUser } = useUser()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return PATHS_WITH_FAB_BUTTON?.includes(pathname) ? (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      position='fixed'
      bottom={40}
      right={15}
      flexGrow={1}
      sx={{ transform: 'translateZ(0px)' }}
    >
      <SpeedDial
        ariaLabel='SpeedDial basic example'
        sx={{ position: 'fixed', bottom: 40, right: 15 }}
        icon={<SpeedDialIcon />}
      >
        {Object.keys(ACTIONS)?.map(actionKey => {
          const action = ACTIONS?.[actionKey as keyof Actions]

          return dbUser && action?.allowedRoles?.includes(dbUser?.role) ? (
            <SpeedDialAction
              key={actionKey}
              icon={action.icon}
              tooltipTitle={t(action.label)}
              onClick={() => navigate(action.pathname)}
            />
          ) : null
        })}
      </SpeedDial>
    </Box>
  ) : null
}

export default FabButton
