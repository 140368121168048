import { useTheme, useMediaQuery } from '@mui/material'

export type UseMediaQueryHook = {
  lgDown: boolean
  mdDown: boolean
  smDown: boolean
}

const useMediaQueryHook = (): UseMediaQueryHook => {
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return {
    lgDown,
    mdDown,
    smDown
  }
}

export default useMediaQueryHook
