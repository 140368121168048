import React from 'react'
import { useDrawingArea } from '@mui/x-charts-pro'
import { styled } from '@mui/material'

export type StyledTextProps = {
  variant: 'primary' | 'secondary'
}

export const StyledText = styled('text', {
  shouldForwardProp: prop => prop !== 'variant'
})<StyledTextProps>(({ theme }) => ({
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fill: theme?.palette.text.secondary,
  variants: [
    {
      props: {
        variant: 'primary'
      },
      style: {
        fontSize: theme.typography.h5.fontSize
      }
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontSize: theme.typography.body2.fontSize
      }
    },
    {
      props: {
        variant: 'primary'
      },
      style: {
        fontWeight: theme.typography.h5.fontWeight
      }
    },
    {
      props: ({ variant }) => variant !== 'primary',
      style: {
        fontWeight: theme.typography.body2.fontWeight
      }
    }
  ]
}))

export type PieCenterLabelProps = {
  primaryText: string
  secondaryText: string
}

export const PieCenterLabel: React.FC<PieCenterLabelProps> = ({
  primaryText,
  secondaryText
}: PieCenterLabelProps) => {
  const { width, height, left, top } = useDrawingArea()
  const primaryY = top + height / 2 - 10
  const secondaryY = primaryY + 24

  return (
    <React.Fragment>
      <StyledText variant='primary' x={left + width / 2} y={primaryY}>
        {primaryText}
      </StyledText>
      <StyledText variant='secondary' x={left + width / 2} y={secondaryY}>
        {secondaryText}
      </StyledText>
    </React.Fragment>
  )
}
