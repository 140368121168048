import React from 'react'
import { Box, BoxProps } from '@mui/material'

import FabButton from './FabButton'

type MainProps = BoxProps

const Main: React.FC<MainProps> = ({ children, ...props }: MainProps) => {
  return (
    <Box component='main' {...props}>
      {children}
      <FabButton />
    </Box>
  )
}

export default Main
