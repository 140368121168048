import React, { ReactNode, useState } from 'react'
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
  AdvancedMarkerProps,
  InfoWindowProps
} from '@vis.gl/react-google-maps'
import { useTheme } from '@mui/material'

type MapMarkerProps = AdvancedMarkerProps & {
  infoWindowProps?: InfoWindowProps
  children?: ReactNode | undefined
}

const MapMarker: React.FC<MapMarkerProps> = ({
  children,
  infoWindowProps = {},
  ...props
}) => {
  const theme = useTheme()
  const [infoWindowOpen, setInfoWindowOpen] = useState(true)
  const [markerRef, marker] = useAdvancedMarkerRef()

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        clickable={!!children}
        onClick={() => setInfoWindowOpen(true)}
        {...props}
      />

      {children && infoWindowOpen ? (
        <InfoWindow
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => setInfoWindowOpen(false)}
          style={{
            color: theme.palette.common.black
          }}
          {...infoWindowProps}
        >
          {children}
        </InfoWindow>
      ) : null}
    </>
  )
}

export default MapMarker
