import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { LicenseInfo } from '@mui/x-license'
import { inject } from '@vercel/analytics'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'react-medium-image-zoom/dist/styles.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeTypeProvider } from 'context/theme'
import AuthProviders from 'context/auth'
import apolloClient from 'lib/apolloClient'
import { muiConfig } from 'config'
import 'lib/i18n'

LicenseInfo.setLicenseKey(muiConfig.license)
inject()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AuthProviders>
        <ThemeTypeProvider>
          <App />
        </ThemeTypeProvider>
      </AuthProviders>
    </ApolloProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
