import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, IconButton, Tooltip, useTheme } from '@mui/material'
import {
  People as PeopleIcon,
  Payments as PaymentsIcon,
  Payment as PaymentIcon
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { SummaryCardChart, QueryError } from 'components'
import {
  getClientsSummaryFilterPath,
  getLoansSummaryFilterPath,
  getPaymentsSummaryFilterPath,
  getSummaryAggregateWhere
} from 'utils/chart'
import { formatCurrency } from 'utils/currency'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'
import { useGetChartsSummaryDataQuery } from 'api/generated'
import { LOAN_PAYMENTS_FILTERS_ENUM } from 'utils/loanFilters'
import { PAYMENTS_FILTERS_ENUM } from 'utils/paymentFilters'

// const MOCK_DATA = [
//   200, 24, 220, 260, 240, 380, 100, 240, 280, 240, 300, 340, 320, 360, 340, 380,
//   360, 400, 380, 420, 400, 640, 340, 460, 440, 480, 460, 600, 880, 920
// ]

const SummaryCharts: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery,
  locationId,
  userId
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data, loading, error, refetch } = useGetChartsSummaryDataQuery({
    variables: getSummaryAggregateWhere(locationId, userId)
  })
  const locations = dictionariesQuery?.data?.locations
  const users = dictionariesQuery?.data?.users
  const clientsAggregate = data?.clientsAggregate?.aggregate
  const loansAggregate = data?.loansAggregate?.aggregate
  const upToDateLoansAggregate = data?.upToDateLoansAggregate?.aggregate
  const todayLoansAggregate = data?.todayLoansAggregate?.aggregate
  const lateLoansAggregate = data?.lateLoansAggregate?.aggregate
  const paymentsAggregate = data?.paymentsAggregate?.aggregate
  const todayPaymentsAggregate = data?.todayPaymentsAggregate?.aggregate
  const weeklyPaymentsAggregate = data?.weeklyPaymentsAggregate?.aggregate
  const monthlyPaymentsAggregate = data?.monthlyPaymentsAggregate?.aggregate

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  const handleOnLoanNavigate = useCallback(
    (loanPaymentFilter?: LOAN_PAYMENTS_FILTERS_ENUM) => {
      const selectedLocation = locations?.find(
        location => location?.id === locationId
      )
      const selectedUser = users?.find(user => user?.id === userId)

      const path = getLoansSummaryFilterPath(
        selectedLocation?.name,
        selectedUser?.full_name,
        loanPaymentFilter
      )

      navigate(path)
    },
    [locations, locationId, users, userId, navigate]
  )

  const handleOnPaymentNavigate = useCallback(
    (paymentFilter?: PAYMENTS_FILTERS_ENUM) => {
      const selectedLocation = locations?.find(
        location => location?.id === locationId
      )
      const selectedUser = users?.find(user => user?.id === userId)

      const path = getPaymentsSummaryFilterPath(
        selectedLocation?.name,
        selectedUser?.full_name,
        paymentFilter
      )

      navigate(path)
    },
    [locations, locationId, users, userId, navigate]
  )

  const handleOnClientNavigate = useCallback(() => {
    const selectedLocation = locations?.find(
      location => location?.id === locationId
    )

    const path = getClientsSummaryFilterPath(selectedLocation?.name)

    navigate(path)
  }, [locations, locationId, navigate])

  return (
    <>
      {error ? (
        <QueryError
          error={error}
          loading={loading}
          refetch={handleRefetchQuery}
        />
      ) : (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          marginTop={1}
          overflow={{ sm: 'auto' }}
          whiteSpace={{ sm: 'nowrap' }}
          py={1}
          alignItems='stretch'
        >
          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('loans')}
              totalCount={loansAggregate?.totalCount}
              totalCountTooltip={t('totalLoans')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton onClick={() => handleOnLoanNavigate()}>
                    <PaymentsIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount: loansAggregate?.sum?.balance,
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('capital')}
              loading={loading}
              // data={MOCK_DATA}
              color={theme.palette.primary.main}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('upToDateLoans')}
              totalCount={upToDateLoansAggregate?.totalCount}
              totalCountTooltip={t('totalLoans')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton
                    onClick={() =>
                      handleOnLoanNavigate(
                        LOAN_PAYMENTS_FILTERS_ENUM.UP_TO_DATE
                      )
                    }
                  >
                    <PaymentsIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount: upToDateLoansAggregate?.sum?.balance,
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('capital')}
              loading={loading}
              // data={MOCK_DATA}
              color={theme.palette.success.main}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('todayLoans')}
              totalCount={todayLoansAggregate?.totalCount}
              totalCountTooltip={t('totalLoans')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton
                    onClick={() =>
                      handleOnLoanNavigate(LOAN_PAYMENTS_FILTERS_ENUM.TODAY)
                    }
                  >
                    <PaymentsIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount: todayLoansAggregate?.sum?.balance,
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('capital')}
              loading={loading}
              // data={MOCK_DATA}
              color={theme.palette.info.main}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('lateLoans')}
              totalCount={lateLoansAggregate?.totalCount}
              totalCountTooltip={t('totalLoans')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton
                    onClick={() =>
                      handleOnLoanNavigate(LOAN_PAYMENTS_FILTERS_ENUM.LATE)
                    }
                  >
                    <PaymentsIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount: lateLoansAggregate?.sum?.balance,
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('capital')}
              loading={loading}
              // data={MOCK_DATA}
              color={theme.palette.error.main}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('payments')}
              totalCount={paymentsAggregate?.totalCount}
              totalCountTooltip={t('totalPayments')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton onClick={() => handleOnPaymentNavigate()}>
                    <PaymentsIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount:
                  (paymentsAggregate?.sum?.amount || 0) +
                  (paymentsAggregate?.sum?.penalty_amount || 0),
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('totalAmount')}
              loading={loading}
              // data={MOCK_DATA}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('todayPayments')}
              totalCount={todayPaymentsAggregate?.totalCount}
              totalCountTooltip={t('totalPayments')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton
                    onClick={() =>
                      handleOnPaymentNavigate(PAYMENTS_FILTERS_ENUM.DAILY)
                    }
                  >
                    <PaymentIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount:
                  (todayPaymentsAggregate?.sum?.amount || 0) +
                  (todayPaymentsAggregate?.sum?.penalty_amount || 0),
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('totalAmount')}
              loading={loading}
              // data={MOCK_DATA}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('weeklyPayments')}
              totalCount={weeklyPaymentsAggregate?.totalCount}
              totalCountTooltip={t('totalPayments')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton
                    onClick={() =>
                      handleOnPaymentNavigate(PAYMENTS_FILTERS_ENUM.WEEKLY)
                    }
                  >
                    <PaymentIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount:
                  (weeklyPaymentsAggregate?.sum?.amount || 0) +
                  (weeklyPaymentsAggregate?.sum?.penalty_amount || 0),
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('totalAmount')}
              loading={loading}
              // data={MOCK_DATA}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('monthlyPayments')}
              totalCount={monthlyPaymentsAggregate?.totalCount}
              totalCountTooltip={t('totalPayments')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton
                    onClick={() =>
                      handleOnPaymentNavigate(PAYMENTS_FILTERS_ENUM.MONTHLY)
                    }
                  >
                    <PaymentIcon />
                  </IconButton>
                </Tooltip>
              }
              value={formatCurrency({
                amount:
                  (monthlyPaymentsAggregate?.sum?.amount || 0) +
                  (monthlyPaymentsAggregate?.sum?.penalty_amount || 0),
                options: {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }
              })}
              valueTooltipTitle={t('totalAmount')}
              loading={loading}
              // data={MOCK_DATA}
            />
          </Stack>

          <Stack minWidth={{ sm: 275 }}>
            <SummaryCardChart
              title={t('clients')}
              action={
                <Tooltip title={t('showInDetail')}>
                  <IconButton onClick={() => handleOnClientNavigate()}>
                    <PeopleIcon />
                  </IconButton>
                </Tooltip>
              }
              value={clientsAggregate?.totalCount || 0}
              valueTooltipTitle={t('totalClients')}
              loading={loading}
            />
          </Stack>
        </Stack>
      )}
    </>
  )
}

export default SummaryCharts
