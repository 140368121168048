import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import isEqual from 'lodash.isequal'

import { DELETED_FILTER_MODEL, EMPTY_FILTER_MODEL } from '../dataGrid'

export const TODAY_DATE_STRING_FORMAT = format(new Date(), 'yyyy-MM-dd')

export const LOANS_WITH_TODAY_PAYMENT_FILTER: GridFilterModel = {
  ...EMPTY_FILTER_MODEL,
  items: [
    {
      field: 'payment_date',
      operator: 'is',
      value: TODAY_DATE_STRING_FORMAT
    }
  ]
}

export const LOANS_WITH_LATE_PAYMENT_FILTER: GridFilterModel = {
  ...EMPTY_FILTER_MODEL,
  items: [
    {
      field: 'payment_date',
      operator: 'before',
      value: TODAY_DATE_STRING_FORMAT
    }
  ]
}

export const LOANS_WITH_UP_TO_DATE_PAYMENT_FILTER: GridFilterModel = {
  ...EMPTY_FILTER_MODEL,
  items: [
    {
      field: 'payment_date',
      operator: 'after',
      value: TODAY_DATE_STRING_FORMAT
    }
  ]
}

export const ALL_LOANS_FILTER = EMPTY_FILTER_MODEL
export const DELETED_LOANS_FILTER = DELETED_FILTER_MODEL

export enum LOAN_PAYMENTS_FILTERS_ENUM {
  LATE = 'LATE',
  TODAY = 'TODAY',
  UP_TO_DATE = 'UP_TO_DATE',
  ALL = 'ALL',
  DELETED = 'DELETED'
}

export type LoanPaymentFiltersTypeValues = {
  index: number
  title: string
  filter: GridFilterModel
  collectorMode: boolean
  sort: GridSortModel
}

export type LoanPaymentFiltersType = {
  [key in LOAN_PAYMENTS_FILTERS_ENUM]: LoanPaymentFiltersTypeValues
}

export const LOAN_SORT_BY_PAYMENT_DATE: GridSortModel = [
  { field: 'payment_date', sort: 'desc' }
]

export const LOAN_DEFAULT_SORT: GridSortModel = [
  { field: 'created_at', sort: 'desc' }
]

export const LOAN_PAYMENT_FILTERS_OBJECT: LoanPaymentFiltersType = {
  [LOAN_PAYMENTS_FILTERS_ENUM.LATE]: {
    index: 0,
    title: 'late',
    filter: LOANS_WITH_LATE_PAYMENT_FILTER,
    collectorMode: true,
    sort: LOAN_SORT_BY_PAYMENT_DATE
  },
  [LOAN_PAYMENTS_FILTERS_ENUM.TODAY]: {
    index: 1,
    title: 'today',
    filter: LOANS_WITH_TODAY_PAYMENT_FILTER,
    collectorMode: true,
    sort: LOAN_SORT_BY_PAYMENT_DATE
  },
  [LOAN_PAYMENTS_FILTERS_ENUM.UP_TO_DATE]: {
    index: 2,
    title: 'upToDate',
    filter: LOANS_WITH_UP_TO_DATE_PAYMENT_FILTER,
    collectorMode: true,
    sort: LOAN_SORT_BY_PAYMENT_DATE
  },
  [LOAN_PAYMENTS_FILTERS_ENUM.ALL]: {
    index: 3,
    title: 'all',
    filter: ALL_LOANS_FILTER,
    collectorMode: true,
    sort: LOAN_DEFAULT_SORT
  },
  [LOAN_PAYMENTS_FILTERS_ENUM.DELETED]: {
    index: 4,
    title: 'deleted',
    filter: DELETED_LOANS_FILTER,
    collectorMode: false,
    sort: LOAN_DEFAULT_SORT
  }
}

export const LOAN_PAYMENT_FILTERS_KEYS_ARRAY = Object.keys(
  LOAN_PAYMENT_FILTERS_OBJECT
) as LOAN_PAYMENTS_FILTERS_ENUM[]

export const COLLECTOR_MODE_LOAN_PAYMENT_FILTERS_KEYS_ARRAY = (
  Object.keys(LOAN_PAYMENT_FILTERS_OBJECT) as LOAN_PAYMENTS_FILTERS_ENUM[]
)?.filter(filterKey => LOAN_PAYMENT_FILTERS_OBJECT?.[filterKey]?.collectorMode)

export const LOAN_PAYMENT_FILTERS_ARRAY: LoanPaymentFiltersTypeValues[] =
  LOAN_PAYMENT_FILTERS_KEYS_ARRAY?.map(
    filterKey => LOAN_PAYMENT_FILTERS_OBJECT?.[filterKey]
  )

export const getPaymentFilterByIndex = (
  index: number
): LoanPaymentFiltersTypeValues | undefined =>
  LOAN_PAYMENT_FILTERS_ARRAY?.find(
    paymentFilter => paymentFilter?.index === index
  )

export const getInitialPaymentFilter = (
  filterModel: GridFilterModel | undefined
) => {
  if (isEqual(filterModel, LOAN_PAYMENT_FILTERS_OBJECT.LATE.filter)) {
    return LOAN_PAYMENT_FILTERS_OBJECT.LATE
  }

  if (isEqual(filterModel, LOAN_PAYMENT_FILTERS_OBJECT.TODAY.filter)) {
    return LOAN_PAYMENT_FILTERS_OBJECT.TODAY
  }

  return LOAN_PAYMENT_FILTERS_OBJECT.ALL
}

export default {
  ALL_LOANS_FILTER,
  LOANS_WITH_TODAY_PAYMENT_FILTER,
  LOANS_WITH_LATE_PAYMENT_FILTER,
  TODAY_DATE_STRING_FORMAT,
  LOAN_PAYMENT_FILTERS_OBJECT,
  LOAN_PAYMENT_FILTERS_KEYS_ARRAY,
  LOAN_PAYMENT_FILTERS_ARRAY,
  getPaymentFilterByIndex,
  getInitialPaymentFilter
}
