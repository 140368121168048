import React from 'react'

import { AuthProvider } from './auth'
import { UserProvider } from './user'
import { ProviderProps } from './types'

const AuthProviders: React.FC<ProviderProps> = ({
  children
}: ProviderProps) => {
  return (
    <AuthProvider>
      <UserProvider>{children}</UserProvider>
    </AuthProvider>
  )
}

export default AuthProviders
