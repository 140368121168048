import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  bigint: { input: any; output: any }
  date: { input: any; output: any }
  geography: { input: any; output: any }
  geometry: { input: any; output: any }
  inet: { input: any; output: any }
  jsonb: { input: any; output: any }
  oid: { input: any; output: any }
  timestamptz: { input: any; output: any }
  uuid: { input: any; output: any }
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>
  _gt?: InputMaybe<Scalars['Boolean']['input']>
  _gte?: InputMaybe<Scalars['Boolean']['input']>
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Boolean']['input']>
  _lte?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Scalars['Boolean']['input']>
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>
}

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']['input']>
  _gt?: InputMaybe<Scalars['Float']['input']>
  _gte?: InputMaybe<Scalars['Float']['input']>
  _in?: InputMaybe<Array<Scalars['Float']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Float']['input']>
  _lte?: InputMaybe<Scalars['Float']['input']>
  _neq?: InputMaybe<Scalars['Float']['input']>
  _nin?: InputMaybe<Array<Scalars['Float']['input']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>
  _gt?: InputMaybe<Scalars['Int']['input']>
  _gte?: InputMaybe<Scalars['Int']['input']>
  _in?: InputMaybe<Array<Scalars['Int']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['Int']['input']>
  _lte?: InputMaybe<Scalars['Int']['input']>
  _neq?: InputMaybe<Scalars['Int']['input']>
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type LinkUserInput = {
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']['input']>>>
  role: Scalars['String']['input']
  userId: Scalars['uuid']['input']
}

export type LinkUserOutput = {
  __typename?: 'LinkUserOutput'
  status: Scalars['String']['output']
}

export type PayLoanInput = {
  loanId: Scalars['uuid']['input']
  paymentAmount: Scalars['Float']['input']
  paymentMethodId: Scalars['uuid']['input']
  paymentType: Scalars['String']['input']
  penaltyAmount?: InputMaybe<Scalars['Float']['input']>
}

export type PayLoanOutput = {
  __typename?: 'PayLoanOutput'
  status: Scalars['String']['output']
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>
  _gt?: InputMaybe<Scalars['String']['input']>
  _gte?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>
  _in?: InputMaybe<Array<Scalars['String']['input']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>
  _lt?: InputMaybe<Scalars['String']['input']>
  _lte?: InputMaybe<Scalars['String']['input']>
  _neq?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>
  _nin?: InputMaybe<Array<Scalars['String']['input']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>
}

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>
  idNumber?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['uuid']['input']>>>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  role: Scalars['String']['input']
  status: Scalars['String']['input']
  userId: Scalars['uuid']['input']
}

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput'
  status: Scalars['String']['output']
}

/** columns and relationships of "address_type" */
export type Address_Type = {
  __typename?: 'address_type'
  /** An array relationship */
  client_addresses: Array<Client_Address>
  /** An aggregate relationship */
  client_addresses_aggregate: Client_Address_Aggregate
  comment: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** columns and relationships of "address_type" */
export type Address_TypeClient_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

/** columns and relationships of "address_type" */
export type Address_TypeClient_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

/** aggregated selection of "address_type" */
export type Address_Type_Aggregate = {
  __typename?: 'address_type_aggregate'
  aggregate?: Maybe<Address_Type_Aggregate_Fields>
  nodes: Array<Address_Type>
}

/** aggregate fields of "address_type" */
export type Address_Type_Aggregate_Fields = {
  __typename?: 'address_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Address_Type_Max_Fields>
  min?: Maybe<Address_Type_Min_Fields>
}

/** aggregate fields of "address_type" */
export type Address_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "address_type". All fields are combined with a logical 'AND'. */
export type Address_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Type_Bool_Exp>>
  _not?: InputMaybe<Address_Type_Bool_Exp>
  _or?: InputMaybe<Array<Address_Type_Bool_Exp>>
  client_addresses?: InputMaybe<Client_Address_Bool_Exp>
  client_addresses_aggregate?: InputMaybe<Client_Address_Aggregate_Bool_Exp>
  comment?: InputMaybe<String_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "address_type" */
export enum Address_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserAddressTypePkey = 'user_address_type_pkey'
}

export enum Address_Type_Enum {
  /** CONTACT */
  Contact = 'CONTACT',
  /** HOME */
  Home = 'HOME',
  /** WORK */
  Work = 'WORK'
}

/** Boolean expression to compare columns of type "address_type_enum". All fields are combined with logical 'AND'. */
export type Address_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Address_Type_Enum>
  _in?: InputMaybe<Array<Address_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Address_Type_Enum>
  _nin?: InputMaybe<Array<Address_Type_Enum>>
}

/** input type for inserting data into table "address_type" */
export type Address_Type_Insert_Input = {
  client_addresses?: InputMaybe<Client_Address_Arr_Rel_Insert_Input>
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Address_Type_Max_Fields = {
  __typename?: 'address_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Address_Type_Min_Fields = {
  __typename?: 'address_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "address_type" */
export type Address_Type_Mutation_Response = {
  __typename?: 'address_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Address_Type>
}

/** input type for inserting object relation for remote table "address_type" */
export type Address_Type_Obj_Rel_Insert_Input = {
  data: Address_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Address_Type_On_Conflict>
}

/** on_conflict condition type for table "address_type" */
export type Address_Type_On_Conflict = {
  constraint: Address_Type_Constraint
  update_columns?: Array<Address_Type_Update_Column>
  where?: InputMaybe<Address_Type_Bool_Exp>
}

/** Ordering options when selecting data from "address_type". */
export type Address_Type_Order_By = {
  client_addresses_aggregate?: InputMaybe<Client_Address_Aggregate_Order_By>
  comment?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: address_type */
export type Address_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "address_type" */
export enum Address_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "address_type" */
export type Address_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "address_type" */
export type Address_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Address_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "address_type" */
export enum Address_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Address_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Address_Type_Bool_Exp
}

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_Actions = {
  __typename?: 'audit_logged_actions'
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Scalars['String']['output']
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk: Scalars['timestamptz']['output']
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Scalars['timestamptz']['output']
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx: Scalars['timestamptz']['output']
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']['output']>
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: Maybe<Scalars['inet']['output']>
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id: Scalars['bigint']['output']
  hasura_user?: Maybe<Scalars['jsonb']['output']>
  /** Unique identifier for each auditable event */
  id: Scalars['uuid']['output']
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid: Scalars['oid']['output']
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']['output']>
  row_id?: Maybe<Scalars['uuid']['output']>
  /** Database schema audited table for this event is in */
  schema_name: Scalars['String']['output']
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only: Scalars['Boolean']['output']
  /** Non-schema-qualified table name of table event occured in */
  table_name: Scalars['String']['output']
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate = {
  __typename?: 'audit_logged_actions_aggregate'
  aggregate?: Maybe<Audit_Logged_Actions_Aggregate_Fields>
  nodes: Array<Audit_Logged_Actions>
}

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Fields = {
  __typename?: 'audit_logged_actions_aggregate_fields'
  avg?: Maybe<Audit_Logged_Actions_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Audit_Logged_Actions_Max_Fields>
  min?: Maybe<Audit_Logged_Actions_Min_Fields>
  stddev?: Maybe<Audit_Logged_Actions_Stddev_Fields>
  stddev_pop?: Maybe<Audit_Logged_Actions_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Audit_Logged_Actions_Stddev_Samp_Fields>
  sum?: Maybe<Audit_Logged_Actions_Sum_Fields>
  var_pop?: Maybe<Audit_Logged_Actions_Var_Pop_Fields>
  var_samp?: Maybe<Audit_Logged_Actions_Var_Samp_Fields>
  variance?: Maybe<Audit_Logged_Actions_Variance_Fields>
}

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>
}

/** aggregate avg on columns */
export type Audit_Logged_Actions_Avg_Fields = {
  __typename?: 'audit_logged_actions_avg_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>
  _not?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
  _or?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>
  action?: InputMaybe<String_Comparison_Exp>
  action_tstamp_clk?: InputMaybe<Timestamptz_Comparison_Exp>
  action_tstamp_stm?: InputMaybe<Timestamptz_Comparison_Exp>
  action_tstamp_tx?: InputMaybe<Timestamptz_Comparison_Exp>
  application_name?: InputMaybe<String_Comparison_Exp>
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>
  client_addr?: InputMaybe<Inet_Comparison_Exp>
  client_port?: InputMaybe<Int_Comparison_Exp>
  client_query?: InputMaybe<String_Comparison_Exp>
  event_id?: InputMaybe<Bigint_Comparison_Exp>
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  relid?: InputMaybe<Oid_Comparison_Exp>
  row_data?: InputMaybe<Jsonb_Comparison_Exp>
  row_id?: InputMaybe<Uuid_Comparison_Exp>
  schema_name?: InputMaybe<String_Comparison_Exp>
  session_user_name?: InputMaybe<String_Comparison_Exp>
  statement_only?: InputMaybe<Boolean_Comparison_Exp>
  table_name?: InputMaybe<String_Comparison_Exp>
  transaction_id?: InputMaybe<Bigint_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint {
  /** unique or primary key constraint on columns "id" */
  LoggedActionsPkey = 'logged_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Array<Scalars['String']['input']>>
  hasura_user?: InputMaybe<Array<Scalars['String']['input']>>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['Int']['input']>
  hasura_user?: InputMaybe<Scalars['Int']['input']>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['String']['input']>
  hasura_user?: InputMaybe<Scalars['String']['input']>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>
  /** Unique sequential identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>
}

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>
  /** Unique sequential identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>
  /** Unique identifier for each auditable event */
  id?: InputMaybe<Scalars['uuid']['input']>
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>
  row_id?: InputMaybe<Scalars['uuid']['input']>
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type Audit_Logged_Actions_Max_Fields = {
  __typename?: 'audit_logged_actions_max_fields'
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']['output']>
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']['output']>
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']['output']>
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']['output']>
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>
  /** Unique identifier for each auditable event */
  id?: Maybe<Scalars['uuid']['output']>
  row_id?: Maybe<Scalars['uuid']['output']>
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']['output']>
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** aggregate min on columns */
export type Audit_Logged_Actions_Min_Fields = {
  __typename?: 'audit_logged_actions_min_fields'
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']['output']>
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']['output']>
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']['output']>
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']['output']>
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>
  /** Unique identifier for each auditable event */
  id?: Maybe<Scalars['uuid']['output']>
  row_id?: Maybe<Scalars['uuid']['output']>
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']['output']>
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** response of any mutation on the table "audit.logged_actions" */
export type Audit_Logged_Actions_Mutation_Response = {
  __typename?: 'audit_logged_actions_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Logged_Actions>
}

/** on_conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint: Audit_Logged_Actions_Constraint
  update_columns?: Array<Audit_Logged_Actions_Update_Column>
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
}

/** Ordering options when selecting data from "audit.logged_actions". */
export type Audit_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>
  action_tstamp_clk?: InputMaybe<Order_By>
  action_tstamp_stm?: InputMaybe<Order_By>
  action_tstamp_tx?: InputMaybe<Order_By>
  application_name?: InputMaybe<Order_By>
  changed_fields?: InputMaybe<Order_By>
  client_addr?: InputMaybe<Order_By>
  client_port?: InputMaybe<Order_By>
  client_query?: InputMaybe<Order_By>
  event_id?: InputMaybe<Order_By>
  hasura_user?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  relid?: InputMaybe<Order_By>
  row_data?: InputMaybe<Order_By>
  row_id?: InputMaybe<Order_By>
  schema_name?: InputMaybe<Order_By>
  session_user_name?: InputMaybe<Order_By>
  statement_only?: InputMaybe<Order_By>
  table_name?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: audit.logged_actions */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  /** Unique identifier for each auditable event */
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Id = 'id',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  RowId = 'row_id',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>
  /** Unique sequential identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>
  /** Unique identifier for each auditable event */
  id?: InputMaybe<Scalars['uuid']['input']>
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>
  row_id?: InputMaybe<Scalars['uuid']['input']>
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate stddev on columns */
export type Audit_Logged_Actions_Stddev_Fields = {
  __typename?: 'audit_logged_actions_stddev_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type Audit_Logged_Actions_Stddev_Pop_Fields = {
  __typename?: 'audit_logged_actions_stddev_pop_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type Audit_Logged_Actions_Stddev_Samp_Fields = {
  __typename?: 'audit_logged_actions_stddev_samp_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "audit_logged_actions" */
export type Audit_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Logged_Actions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Audit_Logged_Actions_Stream_Cursor_Value_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>
  /** Unique sequential identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>
  /** Unique identifier for each auditable event */
  id?: InputMaybe<Scalars['uuid']['input']>
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>
  row_id?: InputMaybe<Scalars['uuid']['input']>
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type Audit_Logged_Actions_Sum_Fields = {
  __typename?: 'audit_logged_actions_sum_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>
}

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Id = 'id',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  RowId = 'row_id',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

export type Audit_Logged_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>
  /** filter the rows which have to be updated */
  where: Audit_Logged_Actions_Bool_Exp
}

/** aggregate var_pop on columns */
export type Audit_Logged_Actions_Var_Pop_Fields = {
  __typename?: 'audit_logged_actions_var_pop_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type Audit_Logged_Actions_Var_Samp_Fields = {
  __typename?: 'audit_logged_actions_var_samp_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type Audit_Logged_Actions_Variance_Fields = {
  __typename?: 'audit_logged_actions_variance_fields'
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>
  /** Unique sequential identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>
  _gt?: InputMaybe<Scalars['bigint']['input']>
  _gte?: InputMaybe<Scalars['bigint']['input']>
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['bigint']['input']>
  _lte?: InputMaybe<Scalars['bigint']['input']>
  _neq?: InputMaybe<Scalars['bigint']['input']>
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>
}

/** columns and relationships of "client" */
export type Client = {
  __typename?: 'client'
  /** An array relationship */
  addresses: Array<Client_Address>
  /** An aggregate relationship */
  addresses_aggregate: Client_Address_Aggregate
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  email: Scalars['String']['output']
  /** An array relationship */
  files: Array<Client_File>
  /** An aggregate relationship */
  files_aggregate: Client_File_Aggregate
  first_name: Scalars['String']['output']
  /** A computed field, executes function "client_full_name" */
  full_name?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  id_number: Scalars['String']['output']
  last_name: Scalars['String']['output']
  /** An array relationship */
  loans: Array<Loan>
  /** An aggregate relationship */
  loans_aggregate: Loan_Aggregate
  /** An object relationship */
  location: Location
  location_id: Scalars['uuid']['output']
  /** An array relationship */
  payments: Array<Payment>
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate
  /** An array relationship */
  phone_numbers: Array<Client_Phone_Number>
  /** An aggregate relationship */
  phone_numbers_aggregate: Client_Phone_Number_Aggregate
  profession: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  workplace: Scalars['String']['output']
}

/** columns and relationships of "client" */
export type ClientAddressesArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientFilesArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientLoansArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientLoans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientPhone_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

/** columns and relationships of "client" */
export type ClientPhone_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

/** columns and relationships of "client_address" */
export type Client_Address = {
  __typename?: 'client_address'
  /** An object relationship */
  address_type: Address_Type
  /** An object relationship */
  client: Client
  client_id: Scalars['uuid']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  geolocation?: Maybe<Scalars['geography']['output']>
  id: Scalars['uuid']['output']
  photo_url?: Maybe<Scalars['String']['output']>
  type: Address_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['String']['output']
}

/** aggregated selection of "client_address" */
export type Client_Address_Aggregate = {
  __typename?: 'client_address_aggregate'
  aggregate?: Maybe<Client_Address_Aggregate_Fields>
  nodes: Array<Client_Address>
}

export type Client_Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Address_Aggregate_Bool_Exp_Count>
}

export type Client_Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Address_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Client_Address_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "client_address" */
export type Client_Address_Aggregate_Fields = {
  __typename?: 'client_address_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Client_Address_Max_Fields>
  min?: Maybe<Client_Address_Min_Fields>
}

/** aggregate fields of "client_address" */
export type Client_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Address_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "client_address" */
export type Client_Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Client_Address_Max_Order_By>
  min?: InputMaybe<Client_Address_Min_Order_By>
}

/** input type for inserting array relation for remote table "client_address" */
export type Client_Address_Arr_Rel_Insert_Input = {
  data: Array<Client_Address_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Address_On_Conflict>
}

/** Boolean expression to filter rows from the table "client_address". All fields are combined with a logical 'AND'. */
export type Client_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Address_Bool_Exp>>
  _not?: InputMaybe<Client_Address_Bool_Exp>
  _or?: InputMaybe<Array<Client_Address_Bool_Exp>>
  address_type?: InputMaybe<Address_Type_Bool_Exp>
  client?: InputMaybe<Client_Bool_Exp>
  client_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  geolocation?: InputMaybe<Geography_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  photo_url?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<Address_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "client_address" */
export enum Client_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAddressPkey = 'user_address_pkey'
}

/** input type for inserting data into table "client_address" */
export type Client_Address_Insert_Input = {
  address_type?: InputMaybe<Address_Type_Obj_Rel_Insert_Input>
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  photo_url?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Address_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Client_Address_Max_Fields = {
  __typename?: 'client_address_max_fields'
  client_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  photo_url?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "client_address" */
export type Client_Address_Max_Order_By = {
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  photo_url?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Client_Address_Min_Fields = {
  __typename?: 'client_address_min_fields'
  client_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  photo_url?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "client_address" */
export type Client_Address_Min_Order_By = {
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  photo_url?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** response of any mutation on the table "client_address" */
export type Client_Address_Mutation_Response = {
  __typename?: 'client_address_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Client_Address>
}

/** on_conflict condition type for table "client_address" */
export type Client_Address_On_Conflict = {
  constraint: Client_Address_Constraint
  update_columns?: Array<Client_Address_Update_Column>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

/** Ordering options when selecting data from "client_address". */
export type Client_Address_Order_By = {
  address_type?: InputMaybe<Address_Type_Order_By>
  client?: InputMaybe<Client_Order_By>
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  geolocation?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  photo_url?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: client_address */
export type Client_Address_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "client_address" */
export enum Client_Address_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "client_address" */
export type Client_Address_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  photo_url?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Address_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "client_address" */
export type Client_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Address_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Client_Address_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  photo_url?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Address_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "client_address" */
export enum Client_Address_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Id = 'id',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Client_Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Address_Set_Input>
  /** filter the rows which have to be updated */
  where: Client_Address_Bool_Exp
}

/** aggregated selection of "client" */
export type Client_Aggregate = {
  __typename?: 'client_aggregate'
  aggregate?: Maybe<Client_Aggregate_Fields>
  nodes: Array<Client>
}

export type Client_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Aggregate_Bool_Exp_Count>
}

export type Client_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Client_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "client" */
export type Client_Aggregate_Fields = {
  __typename?: 'client_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Client_Max_Fields>
  min?: Maybe<Client_Min_Fields>
}

/** aggregate fields of "client" */
export type Client_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "client" */
export type Client_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Client_Max_Order_By>
  min?: InputMaybe<Client_Min_Order_By>
}

/** input type for inserting array relation for remote table "client" */
export type Client_Arr_Rel_Insert_Input = {
  data: Array<Client_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>
}

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Bool_Exp>>
  _not?: InputMaybe<Client_Bool_Exp>
  _or?: InputMaybe<Array<Client_Bool_Exp>>
  addresses?: InputMaybe<Client_Address_Bool_Exp>
  addresses_aggregate?: InputMaybe<Client_Address_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  files?: InputMaybe<Client_File_Bool_Exp>
  files_aggregate?: InputMaybe<Client_File_Aggregate_Bool_Exp>
  first_name?: InputMaybe<String_Comparison_Exp>
  full_name?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  id_number?: InputMaybe<String_Comparison_Exp>
  last_name?: InputMaybe<String_Comparison_Exp>
  loans?: InputMaybe<Loan_Bool_Exp>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Bool_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  payments?: InputMaybe<Payment_Bool_Exp>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>
  phone_numbers?: InputMaybe<Client_Phone_Number_Bool_Exp>
  phone_numbers_aggregate?: InputMaybe<Client_Phone_Number_Aggregate_Bool_Exp>
  profession?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  workplace?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "client" */
export enum Client_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientPkey = 'client_pkey'
}

/** columns and relationships of "client_file" */
export type Client_File = {
  __typename?: 'client_file'
  /** An object relationship */
  client: Client
  /** An object relationship */
  client_file_type: Client_File_Type
  client_id: Scalars['uuid']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  type: Client_File_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['String']['output']
}

/** aggregated selection of "client_file" */
export type Client_File_Aggregate = {
  __typename?: 'client_file_aggregate'
  aggregate?: Maybe<Client_File_Aggregate_Fields>
  nodes: Array<Client_File>
}

export type Client_File_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_File_Aggregate_Bool_Exp_Count>
}

export type Client_File_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_File_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Client_File_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "client_file" */
export type Client_File_Aggregate_Fields = {
  __typename?: 'client_file_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Client_File_Max_Fields>
  min?: Maybe<Client_File_Min_Fields>
}

/** aggregate fields of "client_file" */
export type Client_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_File_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "client_file" */
export type Client_File_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Client_File_Max_Order_By>
  min?: InputMaybe<Client_File_Min_Order_By>
}

/** input type for inserting array relation for remote table "client_file" */
export type Client_File_Arr_Rel_Insert_Input = {
  data: Array<Client_File_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Client_File_On_Conflict>
}

/** Boolean expression to filter rows from the table "client_file". All fields are combined with a logical 'AND'. */
export type Client_File_Bool_Exp = {
  _and?: InputMaybe<Array<Client_File_Bool_Exp>>
  _not?: InputMaybe<Client_File_Bool_Exp>
  _or?: InputMaybe<Array<Client_File_Bool_Exp>>
  client?: InputMaybe<Client_Bool_Exp>
  client_file_type?: InputMaybe<Client_File_Type_Bool_Exp>
  client_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  type?: InputMaybe<Client_File_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "client_file" */
export enum Client_File_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientFilePkey = 'client_file_pkey'
}

/** input type for inserting data into table "client_file" */
export type Client_File_Insert_Input = {
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>
  client_file_type?: InputMaybe<Client_File_Type_Obj_Rel_Insert_Input>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Client_File_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Client_File_Max_Fields = {
  __typename?: 'client_file_max_fields'
  client_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "client_file" */
export type Client_File_Max_Order_By = {
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Client_File_Min_Fields = {
  __typename?: 'client_file_min_fields'
  client_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "client_file" */
export type Client_File_Min_Order_By = {
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** response of any mutation on the table "client_file" */
export type Client_File_Mutation_Response = {
  __typename?: 'client_file_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Client_File>
}

/** on_conflict condition type for table "client_file" */
export type Client_File_On_Conflict = {
  constraint: Client_File_Constraint
  update_columns?: Array<Client_File_Update_Column>
  where?: InputMaybe<Client_File_Bool_Exp>
}

/** Ordering options when selecting data from "client_file". */
export type Client_File_Order_By = {
  client?: InputMaybe<Client_Order_By>
  client_file_type?: InputMaybe<Client_File_Type_Order_By>
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: client_file */
export type Client_File_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "client_file" */
export enum Client_File_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "client_file" */
export type Client_File_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Client_File_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "client_file" */
export type Client_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_File_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Client_File_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Client_File_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "client_file_type" */
export type Client_File_Type = {
  __typename?: 'client_file_type'
  /** An array relationship */
  client_files: Array<Client_File>
  /** An aggregate relationship */
  client_files_aggregate: Client_File_Aggregate
  comment: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** columns and relationships of "client_file_type" */
export type Client_File_TypeClient_FilesArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

/** columns and relationships of "client_file_type" */
export type Client_File_TypeClient_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

/** aggregated selection of "client_file_type" */
export type Client_File_Type_Aggregate = {
  __typename?: 'client_file_type_aggregate'
  aggregate?: Maybe<Client_File_Type_Aggregate_Fields>
  nodes: Array<Client_File_Type>
}

/** aggregate fields of "client_file_type" */
export type Client_File_Type_Aggregate_Fields = {
  __typename?: 'client_file_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Client_File_Type_Max_Fields>
  min?: Maybe<Client_File_Type_Min_Fields>
}

/** aggregate fields of "client_file_type" */
export type Client_File_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_File_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "client_file_type". All fields are combined with a logical 'AND'. */
export type Client_File_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Client_File_Type_Bool_Exp>>
  _not?: InputMaybe<Client_File_Type_Bool_Exp>
  _or?: InputMaybe<Array<Client_File_Type_Bool_Exp>>
  client_files?: InputMaybe<Client_File_Bool_Exp>
  client_files_aggregate?: InputMaybe<Client_File_Aggregate_Bool_Exp>
  comment?: InputMaybe<String_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "client_file_type" */
export enum Client_File_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ClientFileTypePkey = 'client_file_type_pkey'
}

export enum Client_File_Type_Enum {
  /** FACE_PHOTO */
  FacePhoto = 'FACE_PHOTO',
  /** ID_BACK_PHOTO */
  IdBackPhoto = 'ID_BACK_PHOTO',
  /** ID_FRONT_PHOTO */
  IdFrontPhoto = 'ID_FRONT_PHOTO'
}

/** Boolean expression to compare columns of type "client_file_type_enum". All fields are combined with logical 'AND'. */
export type Client_File_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Client_File_Type_Enum>
  _in?: InputMaybe<Array<Client_File_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Client_File_Type_Enum>
  _nin?: InputMaybe<Array<Client_File_Type_Enum>>
}

/** input type for inserting data into table "client_file_type" */
export type Client_File_Type_Insert_Input = {
  client_files?: InputMaybe<Client_File_Arr_Rel_Insert_Input>
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Client_File_Type_Max_Fields = {
  __typename?: 'client_file_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Client_File_Type_Min_Fields = {
  __typename?: 'client_file_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "client_file_type" */
export type Client_File_Type_Mutation_Response = {
  __typename?: 'client_file_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Client_File_Type>
}

/** input type for inserting object relation for remote table "client_file_type" */
export type Client_File_Type_Obj_Rel_Insert_Input = {
  data: Client_File_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Client_File_Type_On_Conflict>
}

/** on_conflict condition type for table "client_file_type" */
export type Client_File_Type_On_Conflict = {
  constraint: Client_File_Type_Constraint
  update_columns?: Array<Client_File_Type_Update_Column>
  where?: InputMaybe<Client_File_Type_Bool_Exp>
}

/** Ordering options when selecting data from "client_file_type". */
export type Client_File_Type_Order_By = {
  client_files_aggregate?: InputMaybe<Client_File_Aggregate_Order_By>
  comment?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: client_file_type */
export type Client_File_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "client_file_type" */
export enum Client_File_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "client_file_type" */
export type Client_File_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "client_file_type" */
export type Client_File_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_File_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Client_File_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "client_file_type" */
export enum Client_File_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Client_File_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_File_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Client_File_Type_Bool_Exp
}

/** update columns of table "client_file" */
export enum Client_File_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Client_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_File_Set_Input>
  /** filter the rows which have to be updated */
  where: Client_File_Bool_Exp
}

/** input type for inserting data into table "client" */
export type Client_Insert_Input = {
  addresses?: InputMaybe<Client_Address_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  files?: InputMaybe<Client_File_Arr_Rel_Insert_Input>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  loans?: InputMaybe<Loan_Arr_Rel_Insert_Input>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>
  phone_numbers?: InputMaybe<Client_Phone_Number_Arr_Rel_Insert_Input>
  profession?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  workplace?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Client_Max_Fields = {
  __typename?: 'client_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  email?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  /** A computed field, executes function "client_full_name" */
  full_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  profession?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  workplace?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "client" */
export type Client_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  profession?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  workplace?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Client_Min_Fields = {
  __typename?: 'client_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  email?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  /** A computed field, executes function "client_full_name" */
  full_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  profession?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  workplace?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "client" */
export type Client_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  profession?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  workplace?: InputMaybe<Order_By>
}

/** response of any mutation on the table "client" */
export type Client_Mutation_Response = {
  __typename?: 'client_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Client>
}

/** input type for inserting object relation for remote table "client" */
export type Client_Obj_Rel_Insert_Input = {
  data: Client_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Client_On_Conflict>
}

/** on_conflict condition type for table "client" */
export type Client_On_Conflict = {
  constraint: Client_Constraint
  update_columns?: Array<Client_Update_Column>
  where?: InputMaybe<Client_Bool_Exp>
}

/** Ordering options when selecting data from "client". */
export type Client_Order_By = {
  addresses_aggregate?: InputMaybe<Client_Address_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  files_aggregate?: InputMaybe<Client_File_Aggregate_Order_By>
  first_name?: InputMaybe<Order_By>
  full_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>
  phone_numbers_aggregate?: InputMaybe<Client_Phone_Number_Aggregate_Order_By>
  profession?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  workplace?: InputMaybe<Order_By>
}

/** columns and relationships of "client_phone_number" */
export type Client_Phone_Number = {
  __typename?: 'client_phone_number'
  /** An object relationship */
  client: Client
  client_id: Scalars['uuid']['output']
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  /** An object relationship */
  phone_number_type: Phone_Number_Type
  type: Phone_Number_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['String']['output']
}

/** aggregated selection of "client_phone_number" */
export type Client_Phone_Number_Aggregate = {
  __typename?: 'client_phone_number_aggregate'
  aggregate?: Maybe<Client_Phone_Number_Aggregate_Fields>
  nodes: Array<Client_Phone_Number>
}

export type Client_Phone_Number_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Phone_Number_Aggregate_Bool_Exp_Count>
}

export type Client_Phone_Number_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Client_Phone_Number_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "client_phone_number" */
export type Client_Phone_Number_Aggregate_Fields = {
  __typename?: 'client_phone_number_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Client_Phone_Number_Max_Fields>
  min?: Maybe<Client_Phone_Number_Min_Fields>
}

/** aggregate fields of "client_phone_number" */
export type Client_Phone_Number_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "client_phone_number" */
export type Client_Phone_Number_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Client_Phone_Number_Max_Order_By>
  min?: InputMaybe<Client_Phone_Number_Min_Order_By>
}

/** input type for inserting array relation for remote table "client_phone_number" */
export type Client_Phone_Number_Arr_Rel_Insert_Input = {
  data: Array<Client_Phone_Number_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Phone_Number_On_Conflict>
}

/** Boolean expression to filter rows from the table "client_phone_number". All fields are combined with a logical 'AND'. */
export type Client_Phone_Number_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Phone_Number_Bool_Exp>>
  _not?: InputMaybe<Client_Phone_Number_Bool_Exp>
  _or?: InputMaybe<Array<Client_Phone_Number_Bool_Exp>>
  client?: InputMaybe<Client_Bool_Exp>
  client_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  phone_number_type?: InputMaybe<Phone_Number_Type_Bool_Exp>
  type?: InputMaybe<Phone_Number_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "client_phone_number" */
export enum Client_Phone_Number_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientPhoneNumberPkey = 'client_phone_number_pkey'
}

/** input type for inserting data into table "client_phone_number" */
export type Client_Phone_Number_Insert_Input = {
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  phone_number_type?: InputMaybe<Phone_Number_Type_Obj_Rel_Insert_Input>
  type?: InputMaybe<Phone_Number_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Client_Phone_Number_Max_Fields = {
  __typename?: 'client_phone_number_max_fields'
  client_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "client_phone_number" */
export type Client_Phone_Number_Max_Order_By = {
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Client_Phone_Number_Min_Fields = {
  __typename?: 'client_phone_number_min_fields'
  client_id?: Maybe<Scalars['uuid']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "client_phone_number" */
export type Client_Phone_Number_Min_Order_By = {
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** response of any mutation on the table "client_phone_number" */
export type Client_Phone_Number_Mutation_Response = {
  __typename?: 'client_phone_number_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Client_Phone_Number>
}

/** on_conflict condition type for table "client_phone_number" */
export type Client_Phone_Number_On_Conflict = {
  constraint: Client_Phone_Number_Constraint
  update_columns?: Array<Client_Phone_Number_Update_Column>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

/** Ordering options when selecting data from "client_phone_number". */
export type Client_Phone_Number_Order_By = {
  client?: InputMaybe<Client_Order_By>
  client_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  phone_number_type?: InputMaybe<Phone_Number_Type_Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: client_phone_number */
export type Client_Phone_Number_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "client_phone_number" */
export enum Client_Phone_Number_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "client_phone_number" */
export type Client_Phone_Number_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Phone_Number_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "client_phone_number" */
export type Client_Phone_Number_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Phone_Number_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Client_Phone_Number_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  type?: InputMaybe<Phone_Number_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "client_phone_number" */
export enum Client_Phone_Number_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Client_Phone_Number_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Phone_Number_Set_Input>
  /** filter the rows which have to be updated */
  where: Client_Phone_Number_Bool_Exp
}

/** primary key columns input for table: client */
export type Client_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "client" */
export enum Client_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Profession = 'profession',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Workplace = 'workplace'
}

/** input type for updating data in table "client" */
export type Client_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  profession?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  workplace?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "client" */
export type Client_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Client_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  profession?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  workplace?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "client" */
export enum Client_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Profession = 'profession',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Workplace = 'workplace'
}

export type Client_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Set_Input>
  /** filter the rows which have to be updated */
  where: Client_Bool_Exp
}

/** currency */
export type Currency = {
  __typename?: 'currency'
  comment: Scalars['String']['output']
  /** An array relationship */
  loans: Array<Loan>
  /** An aggregate relationship */
  loans_aggregate: Loan_Aggregate
  value: Scalars['String']['output']
}

/** currency */
export type CurrencyLoansArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** currency */
export type CurrencyLoans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** aggregated selection of "currency" */
export type Currency_Aggregate = {
  __typename?: 'currency_aggregate'
  aggregate?: Maybe<Currency_Aggregate_Fields>
  nodes: Array<Currency>
}

/** aggregate fields of "currency" */
export type Currency_Aggregate_Fields = {
  __typename?: 'currency_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Currency_Max_Fields>
  min?: Maybe<Currency_Min_Fields>
}

/** aggregate fields of "currency" */
export type Currency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Currency_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "currency". All fields are combined with a logical 'AND'. */
export type Currency_Bool_Exp = {
  _and?: InputMaybe<Array<Currency_Bool_Exp>>
  _not?: InputMaybe<Currency_Bool_Exp>
  _or?: InputMaybe<Array<Currency_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  loans?: InputMaybe<Loan_Bool_Exp>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "currency" */
export enum Currency_Constraint {
  /** unique or primary key constraint on columns "value" */
  CurrencyPkey = 'currency_pkey'
}

export enum Currency_Enum {
  /** ₡ */
  Crc = 'CRC',
  /** $ */
  Usd = 'USD'
}

/** Boolean expression to compare columns of type "currency_enum". All fields are combined with logical 'AND'. */
export type Currency_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Currency_Enum>
  _in?: InputMaybe<Array<Currency_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Currency_Enum>
  _nin?: InputMaybe<Array<Currency_Enum>>
}

/** input type for inserting data into table "currency" */
export type Currency_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  loans?: InputMaybe<Loan_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Currency_Max_Fields = {
  __typename?: 'currency_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Currency_Min_Fields = {
  __typename?: 'currency_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "currency" */
export type Currency_Mutation_Response = {
  __typename?: 'currency_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Currency>
}

/** input type for inserting object relation for remote table "currency" */
export type Currency_Obj_Rel_Insert_Input = {
  data: Currency_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Currency_On_Conflict>
}

/** on_conflict condition type for table "currency" */
export type Currency_On_Conflict = {
  constraint: Currency_Constraint
  update_columns?: Array<Currency_Update_Column>
  where?: InputMaybe<Currency_Bool_Exp>
}

/** Ordering options when selecting data from "currency". */
export type Currency_Order_By = {
  comment?: InputMaybe<Order_By>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: currency */
export type Currency_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "currency" */
export enum Currency_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "currency" */
export type Currency_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "currency" */
export type Currency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Currency_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Currency_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "currency" */
export enum Currency_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Currency_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Currency_Set_Input>
  /** filter the rows which have to be updated */
  where: Currency_Bool_Exp
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>
  _gt?: InputMaybe<Scalars['date']['input']>
  _gte?: InputMaybe<Scalars['date']['input']>
  _in?: InputMaybe<Array<Scalars['date']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['date']['input']>
  _lte?: InputMaybe<Scalars['date']['input']>
  _neq?: InputMaybe<Scalars['date']['input']>
  _nin?: InputMaybe<Array<Scalars['date']['input']>>
}

/** columns and relationships of "document_template" */
export type Document_Template = {
  __typename?: 'document_template'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  document_template_type: Document_Template_Type
  id: Scalars['uuid']['output']
  is_active: Scalars['Boolean']['output']
  /** An object relationship */
  location: Location
  location_id: Scalars['uuid']['output']
  name: Scalars['String']['output']
  type: Document_Template_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['String']['output']
}

/** aggregated selection of "document_template" */
export type Document_Template_Aggregate = {
  __typename?: 'document_template_aggregate'
  aggregate?: Maybe<Document_Template_Aggregate_Fields>
  nodes: Array<Document_Template>
}

export type Document_Template_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Document_Template_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Document_Template_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Document_Template_Aggregate_Bool_Exp_Count>
}

export type Document_Template_Aggregate_Bool_Exp_Bool_And = {
  arguments: Document_Template_Select_Column_Document_Template_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Document_Template_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Document_Template_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Document_Template_Select_Column_Document_Template_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Document_Template_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Document_Template_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Template_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Document_Template_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "document_template" */
export type Document_Template_Aggregate_Fields = {
  __typename?: 'document_template_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Document_Template_Max_Fields>
  min?: Maybe<Document_Template_Min_Fields>
}

/** aggregate fields of "document_template" */
export type Document_Template_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Template_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "document_template" */
export type Document_Template_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Document_Template_Max_Order_By>
  min?: InputMaybe<Document_Template_Min_Order_By>
}

/** input type for inserting array relation for remote table "document_template" */
export type Document_Template_Arr_Rel_Insert_Input = {
  data: Array<Document_Template_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Template_On_Conflict>
}

/** Boolean expression to filter rows from the table "document_template". All fields are combined with a logical 'AND'. */
export type Document_Template_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Template_Bool_Exp>>
  _not?: InputMaybe<Document_Template_Bool_Exp>
  _or?: InputMaybe<Array<Document_Template_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  document_template_type?: InputMaybe<Document_Template_Type_Bool_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_active?: InputMaybe<Boolean_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<Document_Template_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "document_template" */
export enum Document_Template_Constraint {
  /** unique or primary key constraint on columns "id" */
  DocumentTemplatePkey = 'document_template_pkey'
}

/** input type for inserting data into table "document_template" */
export type Document_Template_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  document_template_type?: InputMaybe<Document_Template_Type_Obj_Rel_Insert_Input>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_active?: InputMaybe<Scalars['Boolean']['input']>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Document_Template_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Document_Template_Max_Fields = {
  __typename?: 'document_template_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "document_template" */
export type Document_Template_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Document_Template_Min_Fields = {
  __typename?: 'document_template_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "document_template" */
export type Document_Template_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** response of any mutation on the table "document_template" */
export type Document_Template_Mutation_Response = {
  __typename?: 'document_template_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Document_Template>
}

/** on_conflict condition type for table "document_template" */
export type Document_Template_On_Conflict = {
  constraint: Document_Template_Constraint
  update_columns?: Array<Document_Template_Update_Column>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

/** Ordering options when selecting data from "document_template". */
export type Document_Template_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  document_template_type?: InputMaybe<Document_Template_Type_Order_By>
  id?: InputMaybe<Order_By>
  is_active?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: document_template */
export type Document_Template_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "document_template" */
export enum Document_Template_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** select "document_template_aggregate_bool_exp_bool_and_arguments_columns" columns of table "document_template" */
export enum Document_Template_Select_Column_Document_Template_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** select "document_template_aggregate_bool_exp_bool_or_arguments_columns" columns of table "document_template" */
export enum Document_Template_Select_Column_Document_Template_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** input type for updating data in table "document_template" */
export type Document_Template_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_active?: InputMaybe<Scalars['Boolean']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Document_Template_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "document_template" */
export type Document_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Template_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Document_Template_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  is_active?: InputMaybe<Scalars['Boolean']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Document_Template_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** columns and relationships of "document_template_type" */
export type Document_Template_Type = {
  __typename?: 'document_template_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  document_templates: Array<Document_Template>
  /** An aggregate relationship */
  document_templates_aggregate: Document_Template_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "document_template_type" */
export type Document_Template_TypeDocument_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

/** columns and relationships of "document_template_type" */
export type Document_Template_TypeDocument_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

/** aggregated selection of "document_template_type" */
export type Document_Template_Type_Aggregate = {
  __typename?: 'document_template_type_aggregate'
  aggregate?: Maybe<Document_Template_Type_Aggregate_Fields>
  nodes: Array<Document_Template_Type>
}

/** aggregate fields of "document_template_type" */
export type Document_Template_Type_Aggregate_Fields = {
  __typename?: 'document_template_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Document_Template_Type_Max_Fields>
  min?: Maybe<Document_Template_Type_Min_Fields>
}

/** aggregate fields of "document_template_type" */
export type Document_Template_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Template_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "document_template_type". All fields are combined with a logical 'AND'. */
export type Document_Template_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Template_Type_Bool_Exp>>
  _not?: InputMaybe<Document_Template_Type_Bool_Exp>
  _or?: InputMaybe<Array<Document_Template_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  document_templates?: InputMaybe<Document_Template_Bool_Exp>
  document_templates_aggregate?: InputMaybe<Document_Template_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "document_template_type" */
export enum Document_Template_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DocumentTemplateTypePkey = 'document_template_type_pkey'
}

export enum Document_Template_Type_Enum {
  /** LOAN_AGREEMENT */
  LoanAgreement = 'LOAN_AGREEMENT',
  /** LOAN_APPLICATION */
  LoanApplication = 'LOAN_APPLICATION',
  /** PAYMENT_RECEIPT */
  PaymentReceipt = 'PAYMENT_RECEIPT'
}

/** Boolean expression to compare columns of type "document_template_type_enum". All fields are combined with logical 'AND'. */
export type Document_Template_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Document_Template_Type_Enum>
  _in?: InputMaybe<Array<Document_Template_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Document_Template_Type_Enum>
  _nin?: InputMaybe<Array<Document_Template_Type_Enum>>
}

/** input type for inserting data into table "document_template_type" */
export type Document_Template_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  document_templates?: InputMaybe<Document_Template_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Document_Template_Type_Max_Fields = {
  __typename?: 'document_template_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Document_Template_Type_Min_Fields = {
  __typename?: 'document_template_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "document_template_type" */
export type Document_Template_Type_Mutation_Response = {
  __typename?: 'document_template_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Document_Template_Type>
}

/** input type for inserting object relation for remote table "document_template_type" */
export type Document_Template_Type_Obj_Rel_Insert_Input = {
  data: Document_Template_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Document_Template_Type_On_Conflict>
}

/** on_conflict condition type for table "document_template_type" */
export type Document_Template_Type_On_Conflict = {
  constraint: Document_Template_Type_Constraint
  update_columns?: Array<Document_Template_Type_Update_Column>
  where?: InputMaybe<Document_Template_Type_Bool_Exp>
}

/** Ordering options when selecting data from "document_template_type". */
export type Document_Template_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  document_templates_aggregate?: InputMaybe<Document_Template_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: document_template_type */
export type Document_Template_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "document_template_type" */
export enum Document_Template_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "document_template_type" */
export type Document_Template_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "document_template_type" */
export type Document_Template_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Template_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Document_Template_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "document_template_type" */
export enum Document_Template_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Document_Template_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Template_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Document_Template_Type_Bool_Exp
}

/** update columns of table "document_template" */
export enum Document_Template_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Document_Template_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Template_Set_Input>
  /** filter the rows which have to be updated */
  where: Document_Template_Bool_Exp
}

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>
}

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>
  _eq?: InputMaybe<Scalars['geography']['input']>
  _gt?: InputMaybe<Scalars['geography']['input']>
  _gte?: InputMaybe<Scalars['geography']['input']>
  _in?: InputMaybe<Array<Scalars['geography']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['geography']['input']>
  _lte?: InputMaybe<Scalars['geography']['input']>
  _neq?: InputMaybe<Scalars['geography']['input']>
  _nin?: InputMaybe<Array<Scalars['geography']['input']>>
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']['input']>
}

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>
}

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>
  _eq?: InputMaybe<Scalars['geometry']['input']>
  _gt?: InputMaybe<Scalars['geometry']['input']>
  _gte?: InputMaybe<Scalars['geometry']['input']>
  _in?: InputMaybe<Array<Scalars['geometry']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['geometry']['input']>
  _lte?: InputMaybe<Scalars['geometry']['input']>
  _neq?: InputMaybe<Scalars['geometry']['input']>
  _nin?: InputMaybe<Array<Scalars['geometry']['input']>>
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']['input']>
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']['input']>
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']['input']>
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']['input']>
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']['input']>
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']['input']>
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']['input']>
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']['input']>
}

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['inet']['input']>
  _gt?: InputMaybe<Scalars['inet']['input']>
  _gte?: InputMaybe<Scalars['inet']['input']>
  _in?: InputMaybe<Array<Scalars['inet']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['inet']['input']>
  _lte?: InputMaybe<Scalars['inet']['input']>
  _neq?: InputMaybe<Scalars['inet']['input']>
  _nin?: InputMaybe<Array<Scalars['inet']['input']>>
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>
  _eq?: InputMaybe<Scalars['jsonb']['input']>
  _gt?: InputMaybe<Scalars['jsonb']['input']>
  _gte?: InputMaybe<Scalars['jsonb']['input']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['jsonb']['input']>
  _lte?: InputMaybe<Scalars['jsonb']['input']>
  _neq?: InputMaybe<Scalars['jsonb']['input']>
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>
}

/** columns and relationships of "loan" */
export type Loan = {
  __typename?: 'loan'
  amount: Scalars['Float']['output']
  balance: Scalars['Float']['output']
  capital_percentage: Scalars['Float']['output']
  /** An object relationship */
  client: Client
  client_id: Scalars['uuid']['output']
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  created_at: Scalars['timestamptz']['output']
  currency: Currency_Enum
  /** An object relationship */
  currency_currency: Currency
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage: Scalars['Float']['output']
  /** An object relationship */
  loan_periodicity: Loan_Periodicity
  /** An object relationship */
  loan_status: Loan_Status
  /** An object relationship */
  loan_type: Loan_Type
  /** An object relationship */
  location: Location
  location_id: Scalars['uuid']['output']
  payment_date: Scalars['date']['output']
  /** An array relationship */
  payments: Array<Payment>
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate
  periodicity: Loan_Periodicity_Enum
  periodicity_metadata?: Maybe<Scalars['jsonb']['output']>
  status: Loan_Status_Enum
  type: Loan_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** columns and relationships of "loan" */
export type LoanPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "loan" */
export type LoanPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "loan" */
export type LoanPeriodicity_MetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "loan" */
export type Loan_Aggregate = {
  __typename?: 'loan_aggregate'
  aggregate?: Maybe<Loan_Aggregate_Fields>
  nodes: Array<Loan>
}

export type Loan_Aggregate_Bool_Exp = {
  count?: InputMaybe<Loan_Aggregate_Bool_Exp_Count>
}

export type Loan_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Loan_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Loan_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "loan" */
export type Loan_Aggregate_Fields = {
  __typename?: 'loan_aggregate_fields'
  avg?: Maybe<Loan_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Loan_Max_Fields>
  min?: Maybe<Loan_Min_Fields>
  stddev?: Maybe<Loan_Stddev_Fields>
  stddev_pop?: Maybe<Loan_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Loan_Stddev_Samp_Fields>
  sum?: Maybe<Loan_Sum_Fields>
  var_pop?: Maybe<Loan_Var_Pop_Fields>
  var_samp?: Maybe<Loan_Var_Samp_Fields>
  variance?: Maybe<Loan_Variance_Fields>
}

/** aggregate fields of "loan" */
export type Loan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Loan_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "loan" */
export type Loan_Aggregate_Order_By = {
  avg?: InputMaybe<Loan_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Loan_Max_Order_By>
  min?: InputMaybe<Loan_Min_Order_By>
  stddev?: InputMaybe<Loan_Stddev_Order_By>
  stddev_pop?: InputMaybe<Loan_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Loan_Stddev_Samp_Order_By>
  sum?: InputMaybe<Loan_Sum_Order_By>
  var_pop?: InputMaybe<Loan_Var_Pop_Order_By>
  var_samp?: InputMaybe<Loan_Var_Samp_Order_By>
  variance?: InputMaybe<Loan_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Loan_Append_Input = {
  periodicity_metadata?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "loan" */
export type Loan_Arr_Rel_Insert_Input = {
  data: Array<Loan_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Loan_On_Conflict>
}

/** aggregate avg on columns */
export type Loan_Avg_Fields = {
  __typename?: 'loan_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "loan" */
export type Loan_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "loan". All fields are combined with a logical 'AND'. */
export type Loan_Bool_Exp = {
  _and?: InputMaybe<Array<Loan_Bool_Exp>>
  _not?: InputMaybe<Loan_Bool_Exp>
  _or?: InputMaybe<Array<Loan_Bool_Exp>>
  amount?: InputMaybe<Float_Comparison_Exp>
  balance?: InputMaybe<Float_Comparison_Exp>
  capital_percentage?: InputMaybe<Float_Comparison_Exp>
  client?: InputMaybe<Client_Bool_Exp>
  client_id?: InputMaybe<Uuid_Comparison_Exp>
  commission_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  commission_percentage?: InputMaybe<Float_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  currency?: InputMaybe<Currency_Enum_Comparison_Exp>
  currency_currency?: InputMaybe<Currency_Bool_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  interest_percentage?: InputMaybe<Float_Comparison_Exp>
  loan_periodicity?: InputMaybe<Loan_Periodicity_Bool_Exp>
  loan_status?: InputMaybe<Loan_Status_Bool_Exp>
  loan_type?: InputMaybe<Loan_Type_Bool_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  payment_date?: InputMaybe<Date_Comparison_Exp>
  payments?: InputMaybe<Payment_Bool_Exp>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>
  periodicity?: InputMaybe<Loan_Periodicity_Enum_Comparison_Exp>
  periodicity_metadata?: InputMaybe<Jsonb_Comparison_Exp>
  status?: InputMaybe<Loan_Status_Enum_Comparison_Exp>
  type?: InputMaybe<Loan_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "loan" */
export enum Loan_Constraint {
  /** unique or primary key constraint on columns "id" */
  LoanPkey = 'loan_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Loan_Delete_At_Path_Input = {
  periodicity_metadata?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Loan_Delete_Elem_Input = {
  periodicity_metadata?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Loan_Delete_Key_Input = {
  periodicity_metadata?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "loan" */
export type Loan_Inc_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  balance?: InputMaybe<Scalars['Float']['input']>
  capital_percentage?: InputMaybe<Scalars['Float']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  commission_percentage?: InputMaybe<Scalars['Float']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  interest_percentage?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "loan" */
export type Loan_Insert_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  balance?: InputMaybe<Scalars['Float']['input']>
  capital_percentage?: InputMaybe<Scalars['Float']['input']>
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  commission_percentage?: InputMaybe<Scalars['Float']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Currency_Enum>
  currency_currency?: InputMaybe<Currency_Obj_Rel_Insert_Input>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  interest_percentage?: InputMaybe<Scalars['Float']['input']>
  loan_periodicity?: InputMaybe<Loan_Periodicity_Obj_Rel_Insert_Input>
  loan_status?: InputMaybe<Loan_Status_Obj_Rel_Insert_Input>
  loan_type?: InputMaybe<Loan_Type_Obj_Rel_Insert_Input>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  payment_date?: InputMaybe<Scalars['date']['input']>
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>
  periodicity?: InputMaybe<Loan_Periodicity_Enum>
  periodicity_metadata?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Loan_Status_Enum>
  type?: InputMaybe<Loan_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type Loan_Max_Fields = {
  __typename?: 'loan_max_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  client_id?: Maybe<Scalars['uuid']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  payment_date?: Maybe<Scalars['date']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "loan" */
export type Loan_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  client_id?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  payment_date?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Loan_Min_Fields = {
  __typename?: 'loan_min_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  client_id?: Maybe<Scalars['uuid']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  payment_date?: Maybe<Scalars['date']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "loan" */
export type Loan_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  client_id?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  payment_date?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "loan" */
export type Loan_Mutation_Response = {
  __typename?: 'loan_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Loan>
}

/** input type for inserting object relation for remote table "loan" */
export type Loan_Obj_Rel_Insert_Input = {
  data: Loan_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Loan_On_Conflict>
}

/** on_conflict condition type for table "loan" */
export type Loan_On_Conflict = {
  constraint: Loan_Constraint
  update_columns?: Array<Loan_Update_Column>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** Ordering options when selecting data from "loan". */
export type Loan_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  client?: InputMaybe<Client_Order_By>
  client_id?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  currency?: InputMaybe<Order_By>
  currency_currency?: InputMaybe<Currency_Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
  loan_periodicity?: InputMaybe<Loan_Periodicity_Order_By>
  loan_status?: InputMaybe<Loan_Status_Order_By>
  loan_type?: InputMaybe<Loan_Type_Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  payment_date?: InputMaybe<Order_By>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>
  periodicity?: InputMaybe<Order_By>
  periodicity_metadata?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** columns and relationships of "loan_periodicity" */
export type Loan_Periodicity = {
  __typename?: 'loan_periodicity'
  comment: Scalars['String']['output']
  /** An array relationship */
  loans: Array<Loan>
  /** An aggregate relationship */
  loans_aggregate: Loan_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "loan_periodicity" */
export type Loan_PeriodicityLoansArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "loan_periodicity" */
export type Loan_PeriodicityLoans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** aggregated selection of "loan_periodicity" */
export type Loan_Periodicity_Aggregate = {
  __typename?: 'loan_periodicity_aggregate'
  aggregate?: Maybe<Loan_Periodicity_Aggregate_Fields>
  nodes: Array<Loan_Periodicity>
}

/** aggregate fields of "loan_periodicity" */
export type Loan_Periodicity_Aggregate_Fields = {
  __typename?: 'loan_periodicity_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Loan_Periodicity_Max_Fields>
  min?: Maybe<Loan_Periodicity_Min_Fields>
}

/** aggregate fields of "loan_periodicity" */
export type Loan_Periodicity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Loan_Periodicity_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "loan_periodicity". All fields are combined with a logical 'AND'. */
export type Loan_Periodicity_Bool_Exp = {
  _and?: InputMaybe<Array<Loan_Periodicity_Bool_Exp>>
  _not?: InputMaybe<Loan_Periodicity_Bool_Exp>
  _or?: InputMaybe<Array<Loan_Periodicity_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  loans?: InputMaybe<Loan_Bool_Exp>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "loan_periodicity" */
export enum Loan_Periodicity_Constraint {
  /** unique or primary key constraint on columns "value" */
  LoanPeriodicityPkey = 'loan_periodicity_pkey'
}

export enum Loan_Periodicity_Enum {
  /** BIWEEKLY */
  Biweekly = 'BIWEEKLY',
  /** MONTHLY */
  Monthly = 'MONTHLY',
  /** WEEKLY */
  Weekly = 'WEEKLY'
}

/** Boolean expression to compare columns of type "loan_periodicity_enum". All fields are combined with logical 'AND'. */
export type Loan_Periodicity_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Loan_Periodicity_Enum>
  _in?: InputMaybe<Array<Loan_Periodicity_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Loan_Periodicity_Enum>
  _nin?: InputMaybe<Array<Loan_Periodicity_Enum>>
}

/** input type for inserting data into table "loan_periodicity" */
export type Loan_Periodicity_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  loans?: InputMaybe<Loan_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Loan_Periodicity_Max_Fields = {
  __typename?: 'loan_periodicity_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Loan_Periodicity_Min_Fields = {
  __typename?: 'loan_periodicity_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "loan_periodicity" */
export type Loan_Periodicity_Mutation_Response = {
  __typename?: 'loan_periodicity_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Loan_Periodicity>
}

/** input type for inserting object relation for remote table "loan_periodicity" */
export type Loan_Periodicity_Obj_Rel_Insert_Input = {
  data: Loan_Periodicity_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Loan_Periodicity_On_Conflict>
}

/** on_conflict condition type for table "loan_periodicity" */
export type Loan_Periodicity_On_Conflict = {
  constraint: Loan_Periodicity_Constraint
  update_columns?: Array<Loan_Periodicity_Update_Column>
  where?: InputMaybe<Loan_Periodicity_Bool_Exp>
}

/** Ordering options when selecting data from "loan_periodicity". */
export type Loan_Periodicity_Order_By = {
  comment?: InputMaybe<Order_By>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: loan_periodicity */
export type Loan_Periodicity_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "loan_periodicity" */
export enum Loan_Periodicity_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan_periodicity" */
export type Loan_Periodicity_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "loan_periodicity" */
export type Loan_Periodicity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Loan_Periodicity_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Loan_Periodicity_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "loan_periodicity" */
export enum Loan_Periodicity_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Loan_Periodicity_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Loan_Periodicity_Set_Input>
  /** filter the rows which have to be updated */
  where: Loan_Periodicity_Bool_Exp
}

/** primary key columns input for table: loan */
export type Loan_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Loan_Prepend_Input = {
  periodicity_metadata?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "loan" */
export enum Loan_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CapitalPercentage = 'capital_percentage',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommissionInterestArrearsAmount = 'commission_interest_arrears_amount',
  /** column name */
  CommissionPercentage = 'commission_percentage',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestArrearsAmount = 'interest_arrears_amount',
  /** column name */
  InterestPercentage = 'interest_percentage',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PaymentDate = 'payment_date',
  /** column name */
  Periodicity = 'periodicity',
  /** column name */
  PeriodicityMetadata = 'periodicity_metadata',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "loan" */
export type Loan_Set_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  balance?: InputMaybe<Scalars['Float']['input']>
  capital_percentage?: InputMaybe<Scalars['Float']['input']>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  commission_percentage?: InputMaybe<Scalars['Float']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Currency_Enum>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  interest_percentage?: InputMaybe<Scalars['Float']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  payment_date?: InputMaybe<Scalars['date']['input']>
  periodicity?: InputMaybe<Loan_Periodicity_Enum>
  periodicity_metadata?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Loan_Status_Enum>
  type?: InputMaybe<Loan_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** columns and relationships of "loan_status" */
export type Loan_Status = {
  __typename?: 'loan_status'
  comment: Scalars['String']['output']
  /** An array relationship */
  loans: Array<Loan>
  /** An aggregate relationship */
  loans_aggregate: Loan_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "loan_status" */
export type Loan_StatusLoansArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "loan_status" */
export type Loan_StatusLoans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** aggregated selection of "loan_status" */
export type Loan_Status_Aggregate = {
  __typename?: 'loan_status_aggregate'
  aggregate?: Maybe<Loan_Status_Aggregate_Fields>
  nodes: Array<Loan_Status>
}

/** aggregate fields of "loan_status" */
export type Loan_Status_Aggregate_Fields = {
  __typename?: 'loan_status_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Loan_Status_Max_Fields>
  min?: Maybe<Loan_Status_Min_Fields>
}

/** aggregate fields of "loan_status" */
export type Loan_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Loan_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "loan_status". All fields are combined with a logical 'AND'. */
export type Loan_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Loan_Status_Bool_Exp>>
  _not?: InputMaybe<Loan_Status_Bool_Exp>
  _or?: InputMaybe<Array<Loan_Status_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  loans?: InputMaybe<Loan_Bool_Exp>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "loan_status" */
export enum Loan_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  LoanStatusPkey = 'loan_status_pkey'
}

export enum Loan_Status_Enum {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** CANCELED */
  Canceled = 'CANCELED',
  /** DEFAULTER */
  Defaulter = 'DEFAULTER',
  /** FINISHED */
  Finished = 'FINISHED',
  /** PAYMENT_ARRANGEMENT */
  PaymentArrangement = 'PAYMENT_ARRANGEMENT'
}

/** Boolean expression to compare columns of type "loan_status_enum". All fields are combined with logical 'AND'. */
export type Loan_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Loan_Status_Enum>
  _in?: InputMaybe<Array<Loan_Status_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Loan_Status_Enum>
  _nin?: InputMaybe<Array<Loan_Status_Enum>>
}

/** input type for inserting data into table "loan_status" */
export type Loan_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  loans?: InputMaybe<Loan_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Loan_Status_Max_Fields = {
  __typename?: 'loan_status_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Loan_Status_Min_Fields = {
  __typename?: 'loan_status_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "loan_status" */
export type Loan_Status_Mutation_Response = {
  __typename?: 'loan_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Loan_Status>
}

/** input type for inserting object relation for remote table "loan_status" */
export type Loan_Status_Obj_Rel_Insert_Input = {
  data: Loan_Status_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Loan_Status_On_Conflict>
}

/** on_conflict condition type for table "loan_status" */
export type Loan_Status_On_Conflict = {
  constraint: Loan_Status_Constraint
  update_columns?: Array<Loan_Status_Update_Column>
  where?: InputMaybe<Loan_Status_Bool_Exp>
}

/** Ordering options when selecting data from "loan_status". */
export type Loan_Status_Order_By = {
  comment?: InputMaybe<Order_By>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: loan_status */
export type Loan_Status_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "loan_status" */
export enum Loan_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan_status" */
export type Loan_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "loan_status" */
export type Loan_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Loan_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Loan_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "loan_status" */
export enum Loan_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Loan_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Loan_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Loan_Status_Bool_Exp
}

/** aggregate stddev on columns */
export type Loan_Stddev_Fields = {
  __typename?: 'loan_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "loan" */
export type Loan_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Loan_Stddev_Pop_Fields = {
  __typename?: 'loan_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "loan" */
export type Loan_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Loan_Stddev_Samp_Fields = {
  __typename?: 'loan_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "loan" */
export type Loan_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "loan" */
export type Loan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Loan_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Loan_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  balance?: InputMaybe<Scalars['Float']['input']>
  capital_percentage?: InputMaybe<Scalars['Float']['input']>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  commission_percentage?: InputMaybe<Scalars['Float']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  currency?: InputMaybe<Currency_Enum>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  interest_percentage?: InputMaybe<Scalars['Float']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  payment_date?: InputMaybe<Scalars['date']['input']>
  periodicity?: InputMaybe<Loan_Periodicity_Enum>
  periodicity_metadata?: InputMaybe<Scalars['jsonb']['input']>
  status?: InputMaybe<Loan_Status_Enum>
  type?: InputMaybe<Loan_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type Loan_Sum_Fields = {
  __typename?: 'loan_sum_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "loan" */
export type Loan_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** columns and relationships of "loan_type" */
export type Loan_Type = {
  __typename?: 'loan_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  loans: Array<Loan>
  /** An aggregate relationship */
  loans_aggregate: Loan_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "loan_type" */
export type Loan_TypeLoansArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "loan_type" */
export type Loan_TypeLoans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** aggregated selection of "loan_type" */
export type Loan_Type_Aggregate = {
  __typename?: 'loan_type_aggregate'
  aggregate?: Maybe<Loan_Type_Aggregate_Fields>
  nodes: Array<Loan_Type>
}

/** aggregate fields of "loan_type" */
export type Loan_Type_Aggregate_Fields = {
  __typename?: 'loan_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Loan_Type_Max_Fields>
  min?: Maybe<Loan_Type_Min_Fields>
}

/** aggregate fields of "loan_type" */
export type Loan_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Loan_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "loan_type". All fields are combined with a logical 'AND'. */
export type Loan_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Loan_Type_Bool_Exp>>
  _not?: InputMaybe<Loan_Type_Bool_Exp>
  _or?: InputMaybe<Array<Loan_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  loans?: InputMaybe<Loan_Bool_Exp>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "loan_type" */
export enum Loan_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  LoanTypePkey = 'loan_type_pkey'
}

export enum Loan_Type_Enum {
  /** FIXED */
  Fixed = 'FIXED',
  /** FIXED_CAPITAL */
  FixedCapital = 'FIXED_CAPITAL',
  /** LOWERING */
  Lowering = 'LOWERING'
}

/** Boolean expression to compare columns of type "loan_type_enum". All fields are combined with logical 'AND'. */
export type Loan_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Loan_Type_Enum>
  _in?: InputMaybe<Array<Loan_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Loan_Type_Enum>
  _nin?: InputMaybe<Array<Loan_Type_Enum>>
}

/** input type for inserting data into table "loan_type" */
export type Loan_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  loans?: InputMaybe<Loan_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Loan_Type_Max_Fields = {
  __typename?: 'loan_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Loan_Type_Min_Fields = {
  __typename?: 'loan_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "loan_type" */
export type Loan_Type_Mutation_Response = {
  __typename?: 'loan_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Loan_Type>
}

/** input type for inserting object relation for remote table "loan_type" */
export type Loan_Type_Obj_Rel_Insert_Input = {
  data: Loan_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Loan_Type_On_Conflict>
}

/** on_conflict condition type for table "loan_type" */
export type Loan_Type_On_Conflict = {
  constraint: Loan_Type_Constraint
  update_columns?: Array<Loan_Type_Update_Column>
  where?: InputMaybe<Loan_Type_Bool_Exp>
}

/** Ordering options when selecting data from "loan_type". */
export type Loan_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: loan_type */
export type Loan_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "loan_type" */
export enum Loan_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "loan_type" */
export type Loan_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "loan_type" */
export type Loan_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Loan_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Loan_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "loan_type" */
export enum Loan_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Loan_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Loan_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Loan_Type_Bool_Exp
}

/** update columns of table "loan" */
export enum Loan_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CapitalPercentage = 'capital_percentage',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommissionInterestArrearsAmount = 'commission_interest_arrears_amount',
  /** column name */
  CommissionPercentage = 'commission_percentage',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestArrearsAmount = 'interest_arrears_amount',
  /** column name */
  InterestPercentage = 'interest_percentage',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PaymentDate = 'payment_date',
  /** column name */
  Periodicity = 'periodicity',
  /** column name */
  PeriodicityMetadata = 'periodicity_metadata',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Loan_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Loan_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Loan_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Loan_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Loan_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Loan_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Loan_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Loan_Set_Input>
  /** filter the rows which have to be updated */
  where: Loan_Bool_Exp
}

/** aggregate var_pop on columns */
export type Loan_Var_Pop_Fields = {
  __typename?: 'loan_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "loan" */
export type Loan_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Loan_Var_Samp_Fields = {
  __typename?: 'loan_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "loan" */
export type Loan_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Loan_Variance_Fields = {
  __typename?: 'loan_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  capital_percentage?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  commission_percentage?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  interest_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "loan" */
export type Loan_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  capital_percentage?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  commission_percentage?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  interest_percentage?: InputMaybe<Order_By>
}

/** columns and relationships of "location" */
export type Location = {
  __typename?: 'location'
  address: Scalars['String']['output']
  /** An array relationship */
  clients: Array<Client>
  /** An aggregate relationship */
  clients_aggregate: Client_Aggregate
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description: Scalars['String']['output']
  /** An array relationship */
  document_templates: Array<Document_Template>
  /** An aggregate relationship */
  document_templates_aggregate: Document_Template_Aggregate
  email: Scalars['String']['output']
  geolocation?: Maybe<Scalars['geography']['output']>
  id: Scalars['uuid']['output']
  id_number: Scalars['String']['output']
  /** An array relationship */
  loans: Array<Loan>
  /** An aggregate relationship */
  loans_aggregate: Loan_Aggregate
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  next_subscription_payment_date?: Maybe<Scalars['date']['output']>
  /** An array relationship */
  payment_methods: Array<Location_Payment_Method>
  /** An aggregate relationship */
  payment_methods_aggregate: Location_Payment_Method_Aggregate
  /** An array relationship */
  payments: Array<Payment>
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate
  phone_number: Scalars['String']['output']
  /** An object relationship */
  subscriptionTypeBySubscriptionType?: Maybe<Subscription_Type>
  subscription_type?: Maybe<Subscription_Type_Enum>
  /** An object relationship */
  tenant: Tenant
  tenant_id: Scalars['uuid']['output']
  time_zone?: Maybe<Scalars['String']['output']>
  updated_at: Scalars['timestamptz']['output']
  /** An array relationship */
  user_locations: Array<User_Location>
  /** An aggregate relationship */
  user_locations_aggregate: User_Location_Aggregate
}

/** columns and relationships of "location" */
export type LocationClientsArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Order_By>>
  where?: InputMaybe<Client_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationClients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Order_By>>
  where?: InputMaybe<Client_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationDocument_TemplatesArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationDocument_Templates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationLoansArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationLoans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationPayment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationPayment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationUser_LocationsArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** columns and relationships of "location" */
export type LocationUser_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** aggregated selection of "location" */
export type Location_Aggregate = {
  __typename?: 'location_aggregate'
  aggregate?: Maybe<Location_Aggregate_Fields>
  nodes: Array<Location>
}

export type Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<Location_Aggregate_Bool_Exp_Count>
}

export type Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Location_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "location" */
export type Location_Aggregate_Fields = {
  __typename?: 'location_aggregate_fields'
  avg?: Maybe<Location_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Location_Max_Fields>
  min?: Maybe<Location_Min_Fields>
  stddev?: Maybe<Location_Stddev_Fields>
  stddev_pop?: Maybe<Location_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Location_Stddev_Samp_Fields>
  sum?: Maybe<Location_Sum_Fields>
  var_pop?: Maybe<Location_Var_Pop_Fields>
  var_samp?: Maybe<Location_Var_Samp_Fields>
  variance?: Maybe<Location_Variance_Fields>
}

/** aggregate fields of "location" */
export type Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "location" */
export type Location_Aggregate_Order_By = {
  avg?: InputMaybe<Location_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Location_Max_Order_By>
  min?: InputMaybe<Location_Min_Order_By>
  stddev?: InputMaybe<Location_Stddev_Order_By>
  stddev_pop?: InputMaybe<Location_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Location_Stddev_Samp_Order_By>
  sum?: InputMaybe<Location_Sum_Order_By>
  var_pop?: InputMaybe<Location_Var_Pop_Order_By>
  var_samp?: InputMaybe<Location_Var_Samp_Order_By>
  variance?: InputMaybe<Location_Variance_Order_By>
}

/** input type for inserting array relation for remote table "location" */
export type Location_Arr_Rel_Insert_Input = {
  data: Array<Location_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** aggregate avg on columns */
export type Location_Avg_Fields = {
  __typename?: 'location_avg_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "location" */
export type Location_Avg_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: InputMaybe<Array<Location_Bool_Exp>>
  _not?: InputMaybe<Location_Bool_Exp>
  _or?: InputMaybe<Array<Location_Bool_Exp>>
  address?: InputMaybe<String_Comparison_Exp>
  clients?: InputMaybe<Client_Bool_Exp>
  clients_aggregate?: InputMaybe<Client_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  document_templates?: InputMaybe<Document_Template_Bool_Exp>
  document_templates_aggregate?: InputMaybe<Document_Template_Aggregate_Bool_Exp>
  email?: InputMaybe<String_Comparison_Exp>
  geolocation?: InputMaybe<Geography_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  id_number?: InputMaybe<String_Comparison_Exp>
  loans?: InputMaybe<Loan_Bool_Exp>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Bool_Exp>
  minimum_payment_percentage?: InputMaybe<Float_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  next_subscription_payment_date?: InputMaybe<Date_Comparison_Exp>
  payment_methods?: InputMaybe<Location_Payment_Method_Bool_Exp>
  payment_methods_aggregate?: InputMaybe<Location_Payment_Method_Aggregate_Bool_Exp>
  payments?: InputMaybe<Payment_Bool_Exp>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>
  phone_number?: InputMaybe<String_Comparison_Exp>
  subscriptionTypeBySubscriptionType?: InputMaybe<Subscription_Type_Bool_Exp>
  subscription_type?: InputMaybe<Subscription_Type_Enum_Comparison_Exp>
  tenant?: InputMaybe<Tenant_Bool_Exp>
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>
  time_zone?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_locations?: InputMaybe<User_Location_Bool_Exp>
  user_locations_aggregate?: InputMaybe<User_Location_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = 'location_pkey'
}

/** input type for incrementing numeric columns in table "location" */
export type Location_Inc_Input = {
  minimum_payment_percentage?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  clients?: InputMaybe<Client_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  document_templates?: InputMaybe<Document_Template_Arr_Rel_Insert_Input>
  email?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  loans?: InputMaybe<Loan_Arr_Rel_Insert_Input>
  minimum_payment_percentage?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  next_subscription_payment_date?: InputMaybe<Scalars['date']['input']>
  payment_methods?: InputMaybe<Location_Payment_Method_Arr_Rel_Insert_Input>
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>
  phone_number?: InputMaybe<Scalars['String']['input']>
  subscriptionTypeBySubscriptionType?: InputMaybe<Subscription_Type_Obj_Rel_Insert_Input>
  subscription_type?: InputMaybe<Subscription_Type_Enum>
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>
  tenant_id?: InputMaybe<Scalars['uuid']['input']>
  time_zone?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_locations?: InputMaybe<User_Location_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Location_Max_Fields = {
  __typename?: 'location_max_fields'
  address?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  next_subscription_payment_date?: Maybe<Scalars['date']['output']>
  phone_number?: Maybe<Scalars['String']['output']>
  tenant_id?: Maybe<Scalars['uuid']['output']>
  time_zone?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "location" */
export type Location_Max_Order_By = {
  address?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  minimum_payment_percentage?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  next_subscription_payment_date?: InputMaybe<Order_By>
  phone_number?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  time_zone?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Location_Min_Fields = {
  __typename?: 'location_min_fields'
  address?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  next_subscription_payment_date?: Maybe<Scalars['date']['output']>
  phone_number?: Maybe<Scalars['String']['output']>
  tenant_id?: Maybe<Scalars['uuid']['output']>
  time_zone?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "location" */
export type Location_Min_Order_By = {
  address?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  email?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  minimum_payment_percentage?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  next_subscription_payment_date?: InputMaybe<Order_By>
  phone_number?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  time_zone?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "location" */
export type Location_Mutation_Response = {
  __typename?: 'location_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Location>
}

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** on_conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint
  update_columns?: Array<Location_Update_Column>
  where?: InputMaybe<Location_Bool_Exp>
}

/** Ordering options when selecting data from "location". */
export type Location_Order_By = {
  address?: InputMaybe<Order_By>
  clients_aggregate?: InputMaybe<Client_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  document_templates_aggregate?: InputMaybe<Document_Template_Aggregate_Order_By>
  email?: InputMaybe<Order_By>
  geolocation?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Order_By>
  minimum_payment_percentage?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  next_subscription_payment_date?: InputMaybe<Order_By>
  payment_methods_aggregate?: InputMaybe<Location_Payment_Method_Aggregate_Order_By>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>
  phone_number?: InputMaybe<Order_By>
  subscriptionTypeBySubscriptionType?: InputMaybe<Subscription_Type_Order_By>
  subscription_type?: InputMaybe<Order_By>
  tenant?: InputMaybe<Tenant_Order_By>
  tenant_id?: InputMaybe<Order_By>
  time_zone?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_locations_aggregate?: InputMaybe<User_Location_Aggregate_Order_By>
}

/** columns and relationships of "location_payment_method" */
export type Location_Payment_Method = {
  __typename?: 'location_payment_method'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  /** An object relationship */
  location: Location
  location_id: Scalars['uuid']['output']
  method: Payment_Method_Enum
  name: Scalars['String']['output']
  /** An object relationship */
  payment_method: Payment_Method
  /** An array relationship */
  payments: Array<Payment>
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate
  updated_at: Scalars['timestamptz']['output']
  value: Scalars['String']['output']
}

/** columns and relationships of "location_payment_method" */
export type Location_Payment_MethodPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "location_payment_method" */
export type Location_Payment_MethodPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** aggregated selection of "location_payment_method" */
export type Location_Payment_Method_Aggregate = {
  __typename?: 'location_payment_method_aggregate'
  aggregate?: Maybe<Location_Payment_Method_Aggregate_Fields>
  nodes: Array<Location_Payment_Method>
}

export type Location_Payment_Method_Aggregate_Bool_Exp = {
  count?: InputMaybe<Location_Payment_Method_Aggregate_Bool_Exp_Count>
}

export type Location_Payment_Method_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Location_Payment_Method_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "location_payment_method" */
export type Location_Payment_Method_Aggregate_Fields = {
  __typename?: 'location_payment_method_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Location_Payment_Method_Max_Fields>
  min?: Maybe<Location_Payment_Method_Min_Fields>
}

/** aggregate fields of "location_payment_method" */
export type Location_Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "location_payment_method" */
export type Location_Payment_Method_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Location_Payment_Method_Max_Order_By>
  min?: InputMaybe<Location_Payment_Method_Min_Order_By>
}

/** input type for inserting array relation for remote table "location_payment_method" */
export type Location_Payment_Method_Arr_Rel_Insert_Input = {
  data: Array<Location_Payment_Method_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Location_Payment_Method_On_Conflict>
}

/** Boolean expression to filter rows from the table "location_payment_method". All fields are combined with a logical 'AND'. */
export type Location_Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Location_Payment_Method_Bool_Exp>>
  _not?: InputMaybe<Location_Payment_Method_Bool_Exp>
  _or?: InputMaybe<Array<Location_Payment_Method_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  method?: InputMaybe<Payment_Method_Enum_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  payment_method?: InputMaybe<Payment_Method_Bool_Exp>
  payments?: InputMaybe<Payment_Bool_Exp>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "location_payment_method" */
export enum Location_Payment_Method_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPaymentMethodPkey = 'location_payment_method_pkey'
}

/** input type for inserting data into table "location_payment_method" */
export type Location_Payment_Method_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  method?: InputMaybe<Payment_Method_Enum>
  name?: InputMaybe<Scalars['String']['input']>
  payment_method?: InputMaybe<Payment_Method_Obj_Rel_Insert_Input>
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Location_Payment_Method_Max_Fields = {
  __typename?: 'location_payment_method_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by max() on columns of table "location_payment_method" */
export type Location_Payment_Method_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Location_Payment_Method_Min_Fields = {
  __typename?: 'location_payment_method_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** order by min() on columns of table "location_payment_method" */
export type Location_Payment_Method_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** response of any mutation on the table "location_payment_method" */
export type Location_Payment_Method_Mutation_Response = {
  __typename?: 'location_payment_method_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Location_Payment_Method>
}

/** input type for inserting object relation for remote table "location_payment_method" */
export type Location_Payment_Method_Obj_Rel_Insert_Input = {
  data: Location_Payment_Method_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Location_Payment_Method_On_Conflict>
}

/** on_conflict condition type for table "location_payment_method" */
export type Location_Payment_Method_On_Conflict = {
  constraint: Location_Payment_Method_Constraint
  update_columns?: Array<Location_Payment_Method_Update_Column>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

/** Ordering options when selecting data from "location_payment_method". */
export type Location_Payment_Method_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  method?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  payment_method?: InputMaybe<Payment_Method_Order_By>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: location_payment_method */
export type Location_Payment_Method_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "location_payment_method" */
export enum Location_Payment_Method_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "location_payment_method" */
export type Location_Payment_Method_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  method?: InputMaybe<Payment_Method_Enum>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "location_payment_method" */
export type Location_Payment_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Payment_Method_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Location_Payment_Method_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  method?: InputMaybe<Payment_Method_Enum>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "location_payment_method" */
export enum Location_Payment_Method_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Method = 'method',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Location_Payment_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Location_Payment_Method_Set_Input>
  /** filter the rows which have to be updated */
  where: Location_Payment_Method_Bool_Exp
}

/** primary key columns input for table: location */
export type Location_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  MinimumPaymentPercentage = 'minimum_payment_percentage',
  /** column name */
  Name = 'name',
  /** column name */
  NextSubscriptionPaymentDate = 'next_subscription_payment_date',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SubscriptionType = 'subscription_type',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  minimum_payment_percentage?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  next_subscription_payment_date?: InputMaybe<Scalars['date']['input']>
  phone_number?: InputMaybe<Scalars['String']['input']>
  subscription_type?: InputMaybe<Subscription_Type_Enum>
  tenant_id?: InputMaybe<Scalars['uuid']['input']>
  time_zone?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate stddev on columns */
export type Location_Stddev_Fields = {
  __typename?: 'location_stddev_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "location" */
export type Location_Stddev_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Location_Stddev_Pop_Fields = {
  __typename?: 'location_stddev_pop_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "location" */
export type Location_Stddev_Pop_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Location_Stddev_Samp_Fields = {
  __typename?: 'location_stddev_samp_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "location" */
export type Location_Stddev_Samp_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "location" */
export type Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Location_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  geolocation?: InputMaybe<Scalars['geography']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  minimum_payment_percentage?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  next_subscription_payment_date?: InputMaybe<Scalars['date']['input']>
  phone_number?: InputMaybe<Scalars['String']['input']>
  subscription_type?: InputMaybe<Subscription_Type_Enum>
  tenant_id?: InputMaybe<Scalars['uuid']['input']>
  time_zone?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** aggregate sum on columns */
export type Location_Sum_Fields = {
  __typename?: 'location_sum_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "location" */
export type Location_Sum_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  Geolocation = 'geolocation',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  MinimumPaymentPercentage = 'minimum_payment_percentage',
  /** column name */
  Name = 'name',
  /** column name */
  NextSubscriptionPaymentDate = 'next_subscription_payment_date',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SubscriptionType = 'subscription_type',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  TimeZone = 'time_zone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Location_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Location_Set_Input>
  /** filter the rows which have to be updated */
  where: Location_Bool_Exp
}

/** aggregate var_pop on columns */
export type Location_Var_Pop_Fields = {
  __typename?: 'location_var_pop_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "location" */
export type Location_Var_Pop_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Location_Var_Samp_Fields = {
  __typename?: 'location_var_samp_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "location" */
export type Location_Var_Samp_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Location_Variance_Fields = {
  __typename?: 'location_variance_fields'
  minimum_payment_percentage?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "location" */
export type Location_Variance_Order_By = {
  minimum_payment_percentage?: InputMaybe<Order_By>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** delete data from the table: "address_type" */
  delete_address_type?: Maybe<Address_Type_Mutation_Response>
  /** delete single row from the table: "address_type" */
  delete_address_type_by_pk?: Maybe<Address_Type>
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>
  /** delete data from the table: "client" */
  delete_client?: Maybe<Client_Mutation_Response>
  /** delete data from the table: "client_address" */
  delete_client_address?: Maybe<Client_Address_Mutation_Response>
  /** delete single row from the table: "client_address" */
  delete_client_address_by_pk?: Maybe<Client_Address>
  /** delete single row from the table: "client" */
  delete_client_by_pk?: Maybe<Client>
  /** delete data from the table: "client_file" */
  delete_client_file?: Maybe<Client_File_Mutation_Response>
  /** delete single row from the table: "client_file" */
  delete_client_file_by_pk?: Maybe<Client_File>
  /** delete data from the table: "client_file_type" */
  delete_client_file_type?: Maybe<Client_File_Type_Mutation_Response>
  /** delete single row from the table: "client_file_type" */
  delete_client_file_type_by_pk?: Maybe<Client_File_Type>
  /** delete data from the table: "client_phone_number" */
  delete_client_phone_number?: Maybe<Client_Phone_Number_Mutation_Response>
  /** delete single row from the table: "client_phone_number" */
  delete_client_phone_number_by_pk?: Maybe<Client_Phone_Number>
  /** delete data from the table: "currency" */
  delete_currency?: Maybe<Currency_Mutation_Response>
  /** delete single row from the table: "currency" */
  delete_currency_by_pk?: Maybe<Currency>
  /** delete data from the table: "document_template" */
  delete_document_template?: Maybe<Document_Template_Mutation_Response>
  /** delete single row from the table: "document_template" */
  delete_document_template_by_pk?: Maybe<Document_Template>
  /** delete data from the table: "document_template_type" */
  delete_document_template_type?: Maybe<Document_Template_Type_Mutation_Response>
  /** delete single row from the table: "document_template_type" */
  delete_document_template_type_by_pk?: Maybe<Document_Template_Type>
  /** delete data from the table: "loan" */
  delete_loan?: Maybe<Loan_Mutation_Response>
  /** delete single row from the table: "loan" */
  delete_loan_by_pk?: Maybe<Loan>
  /** delete data from the table: "loan_periodicity" */
  delete_loan_periodicity?: Maybe<Loan_Periodicity_Mutation_Response>
  /** delete single row from the table: "loan_periodicity" */
  delete_loan_periodicity_by_pk?: Maybe<Loan_Periodicity>
  /** delete data from the table: "loan_status" */
  delete_loan_status?: Maybe<Loan_Status_Mutation_Response>
  /** delete single row from the table: "loan_status" */
  delete_loan_status_by_pk?: Maybe<Loan_Status>
  /** delete data from the table: "loan_type" */
  delete_loan_type?: Maybe<Loan_Type_Mutation_Response>
  /** delete single row from the table: "loan_type" */
  delete_loan_type_by_pk?: Maybe<Loan_Type>
  /** delete data from the table: "location" */
  delete_location?: Maybe<Location_Mutation_Response>
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>
  /** delete data from the table: "location_payment_method" */
  delete_location_payment_method?: Maybe<Location_Payment_Method_Mutation_Response>
  /** delete single row from the table: "location_payment_method" */
  delete_location_payment_method_by_pk?: Maybe<Location_Payment_Method>
  /** delete data from the table: "payment" */
  delete_payment?: Maybe<Payment_Mutation_Response>
  /** delete single row from the table: "payment" */
  delete_payment_by_pk?: Maybe<Payment>
  /** delete data from the table: "payment_method" */
  delete_payment_method?: Maybe<Payment_Method_Mutation_Response>
  /** delete single row from the table: "payment_method" */
  delete_payment_method_by_pk?: Maybe<Payment_Method>
  /** delete data from the table: "payment_type" */
  delete_payment_type?: Maybe<Payment_Type_Mutation_Response>
  /** delete single row from the table: "payment_type" */
  delete_payment_type_by_pk?: Maybe<Payment_Type>
  /** delete data from the table: "phone_number_type" */
  delete_phone_number_type?: Maybe<Phone_Number_Type_Mutation_Response>
  /** delete single row from the table: "phone_number_type" */
  delete_phone_number_type_by_pk?: Maybe<Phone_Number_Type>
  /** delete data from the table: "subscription_type" */
  delete_subscription_type?: Maybe<Subscription_Type_Mutation_Response>
  /** delete single row from the table: "subscription_type" */
  delete_subscription_type_by_pk?: Maybe<Subscription_Type>
  /** delete data from the table: "tenant" */
  delete_tenant?: Maybe<Tenant_Mutation_Response>
  /** delete single row from the table: "tenant" */
  delete_tenant_by_pk?: Maybe<Tenant>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** delete data from the table: "user_location" */
  delete_user_location?: Maybe<User_Location_Mutation_Response>
  /** delete single row from the table: "user_location" */
  delete_user_location_by_pk?: Maybe<User_Location>
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>
  /** delete data from the table: "user_status" */
  delete_user_status?: Maybe<User_Status_Mutation_Response>
  /** delete single row from the table: "user_status" */
  delete_user_status_by_pk?: Maybe<User_Status>
  /** delete data from the table: "user_tenant" */
  delete_user_tenant?: Maybe<User_Tenant_Mutation_Response>
  /** delete single row from the table: "user_tenant" */
  delete_user_tenant_by_pk?: Maybe<User_Tenant>
  /** insert data into the table: "address_type" */
  insert_address_type?: Maybe<Address_Type_Mutation_Response>
  /** insert a single row into the table: "address_type" */
  insert_address_type_one?: Maybe<Address_Type>
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one?: Maybe<Audit_Logged_Actions>
  /** insert data into the table: "client" */
  insert_client?: Maybe<Client_Mutation_Response>
  /** insert data into the table: "client_address" */
  insert_client_address?: Maybe<Client_Address_Mutation_Response>
  /** insert a single row into the table: "client_address" */
  insert_client_address_one?: Maybe<Client_Address>
  /** insert data into the table: "client_file" */
  insert_client_file?: Maybe<Client_File_Mutation_Response>
  /** insert a single row into the table: "client_file" */
  insert_client_file_one?: Maybe<Client_File>
  /** insert data into the table: "client_file_type" */
  insert_client_file_type?: Maybe<Client_File_Type_Mutation_Response>
  /** insert a single row into the table: "client_file_type" */
  insert_client_file_type_one?: Maybe<Client_File_Type>
  /** insert a single row into the table: "client" */
  insert_client_one?: Maybe<Client>
  /** insert data into the table: "client_phone_number" */
  insert_client_phone_number?: Maybe<Client_Phone_Number_Mutation_Response>
  /** insert a single row into the table: "client_phone_number" */
  insert_client_phone_number_one?: Maybe<Client_Phone_Number>
  /** insert data into the table: "currency" */
  insert_currency?: Maybe<Currency_Mutation_Response>
  /** insert a single row into the table: "currency" */
  insert_currency_one?: Maybe<Currency>
  /** insert data into the table: "document_template" */
  insert_document_template?: Maybe<Document_Template_Mutation_Response>
  /** insert a single row into the table: "document_template" */
  insert_document_template_one?: Maybe<Document_Template>
  /** insert data into the table: "document_template_type" */
  insert_document_template_type?: Maybe<Document_Template_Type_Mutation_Response>
  /** insert a single row into the table: "document_template_type" */
  insert_document_template_type_one?: Maybe<Document_Template_Type>
  /** insert data into the table: "loan" */
  insert_loan?: Maybe<Loan_Mutation_Response>
  /** insert a single row into the table: "loan" */
  insert_loan_one?: Maybe<Loan>
  /** insert data into the table: "loan_periodicity" */
  insert_loan_periodicity?: Maybe<Loan_Periodicity_Mutation_Response>
  /** insert a single row into the table: "loan_periodicity" */
  insert_loan_periodicity_one?: Maybe<Loan_Periodicity>
  /** insert data into the table: "loan_status" */
  insert_loan_status?: Maybe<Loan_Status_Mutation_Response>
  /** insert a single row into the table: "loan_status" */
  insert_loan_status_one?: Maybe<Loan_Status>
  /** insert data into the table: "loan_type" */
  insert_loan_type?: Maybe<Loan_Type_Mutation_Response>
  /** insert a single row into the table: "loan_type" */
  insert_loan_type_one?: Maybe<Loan_Type>
  /** insert data into the table: "location" */
  insert_location?: Maybe<Location_Mutation_Response>
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>
  /** insert data into the table: "location_payment_method" */
  insert_location_payment_method?: Maybe<Location_Payment_Method_Mutation_Response>
  /** insert a single row into the table: "location_payment_method" */
  insert_location_payment_method_one?: Maybe<Location_Payment_Method>
  /** insert data into the table: "payment" */
  insert_payment?: Maybe<Payment_Mutation_Response>
  /** insert data into the table: "payment_method" */
  insert_payment_method?: Maybe<Payment_Method_Mutation_Response>
  /** insert a single row into the table: "payment_method" */
  insert_payment_method_one?: Maybe<Payment_Method>
  /** insert a single row into the table: "payment" */
  insert_payment_one?: Maybe<Payment>
  /** insert data into the table: "payment_type" */
  insert_payment_type?: Maybe<Payment_Type_Mutation_Response>
  /** insert a single row into the table: "payment_type" */
  insert_payment_type_one?: Maybe<Payment_Type>
  /** insert data into the table: "phone_number_type" */
  insert_phone_number_type?: Maybe<Phone_Number_Type_Mutation_Response>
  /** insert a single row into the table: "phone_number_type" */
  insert_phone_number_type_one?: Maybe<Phone_Number_Type>
  /** insert data into the table: "subscription_type" */
  insert_subscription_type?: Maybe<Subscription_Type_Mutation_Response>
  /** insert a single row into the table: "subscription_type" */
  insert_subscription_type_one?: Maybe<Subscription_Type>
  /** insert data into the table: "tenant" */
  insert_tenant?: Maybe<Tenant_Mutation_Response>
  /** insert a single row into the table: "tenant" */
  insert_tenant_one?: Maybe<Tenant>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert data into the table: "user_location" */
  insert_user_location?: Maybe<User_Location_Mutation_Response>
  /** insert a single row into the table: "user_location" */
  insert_user_location_one?: Maybe<User_Location>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>
  /** insert data into the table: "user_status" */
  insert_user_status?: Maybe<User_Status_Mutation_Response>
  /** insert a single row into the table: "user_status" */
  insert_user_status_one?: Maybe<User_Status>
  /** insert data into the table: "user_tenant" */
  insert_user_tenant?: Maybe<User_Tenant_Mutation_Response>
  /** insert a single row into the table: "user_tenant" */
  insert_user_tenant_one?: Maybe<User_Tenant>
  /** Link User */
  linkUser?: Maybe<LinkUserOutput>
  /** Pay loan action */
  payLoan?: Maybe<PayLoanOutput>
  /** Update user */
  updateUser?: Maybe<UpdateUserOutput>
  /** update data of the table: "address_type" */
  update_address_type?: Maybe<Address_Type_Mutation_Response>
  /** update single row of the table: "address_type" */
  update_address_type_by_pk?: Maybe<Address_Type>
  /** update multiples rows of table: "address_type" */
  update_address_type_many?: Maybe<Array<Maybe<Address_Type_Mutation_Response>>>
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>
  /** update multiples rows of table: "audit.logged_actions" */
  update_audit_logged_actions_many?: Maybe<
    Array<Maybe<Audit_Logged_Actions_Mutation_Response>>
  >
  /** update data of the table: "client" */
  update_client?: Maybe<Client_Mutation_Response>
  /** update data of the table: "client_address" */
  update_client_address?: Maybe<Client_Address_Mutation_Response>
  /** update single row of the table: "client_address" */
  update_client_address_by_pk?: Maybe<Client_Address>
  /** update multiples rows of table: "client_address" */
  update_client_address_many?: Maybe<
    Array<Maybe<Client_Address_Mutation_Response>>
  >
  /** update single row of the table: "client" */
  update_client_by_pk?: Maybe<Client>
  /** update data of the table: "client_file" */
  update_client_file?: Maybe<Client_File_Mutation_Response>
  /** update single row of the table: "client_file" */
  update_client_file_by_pk?: Maybe<Client_File>
  /** update multiples rows of table: "client_file" */
  update_client_file_many?: Maybe<Array<Maybe<Client_File_Mutation_Response>>>
  /** update data of the table: "client_file_type" */
  update_client_file_type?: Maybe<Client_File_Type_Mutation_Response>
  /** update single row of the table: "client_file_type" */
  update_client_file_type_by_pk?: Maybe<Client_File_Type>
  /** update multiples rows of table: "client_file_type" */
  update_client_file_type_many?: Maybe<
    Array<Maybe<Client_File_Type_Mutation_Response>>
  >
  /** update multiples rows of table: "client" */
  update_client_many?: Maybe<Array<Maybe<Client_Mutation_Response>>>
  /** update data of the table: "client_phone_number" */
  update_client_phone_number?: Maybe<Client_Phone_Number_Mutation_Response>
  /** update single row of the table: "client_phone_number" */
  update_client_phone_number_by_pk?: Maybe<Client_Phone_Number>
  /** update multiples rows of table: "client_phone_number" */
  update_client_phone_number_many?: Maybe<
    Array<Maybe<Client_Phone_Number_Mutation_Response>>
  >
  /** update data of the table: "currency" */
  update_currency?: Maybe<Currency_Mutation_Response>
  /** update single row of the table: "currency" */
  update_currency_by_pk?: Maybe<Currency>
  /** update multiples rows of table: "currency" */
  update_currency_many?: Maybe<Array<Maybe<Currency_Mutation_Response>>>
  /** update data of the table: "document_template" */
  update_document_template?: Maybe<Document_Template_Mutation_Response>
  /** update single row of the table: "document_template" */
  update_document_template_by_pk?: Maybe<Document_Template>
  /** update multiples rows of table: "document_template" */
  update_document_template_many?: Maybe<
    Array<Maybe<Document_Template_Mutation_Response>>
  >
  /** update data of the table: "document_template_type" */
  update_document_template_type?: Maybe<Document_Template_Type_Mutation_Response>
  /** update single row of the table: "document_template_type" */
  update_document_template_type_by_pk?: Maybe<Document_Template_Type>
  /** update multiples rows of table: "document_template_type" */
  update_document_template_type_many?: Maybe<
    Array<Maybe<Document_Template_Type_Mutation_Response>>
  >
  /** update data of the table: "loan" */
  update_loan?: Maybe<Loan_Mutation_Response>
  /** update single row of the table: "loan" */
  update_loan_by_pk?: Maybe<Loan>
  /** update multiples rows of table: "loan" */
  update_loan_many?: Maybe<Array<Maybe<Loan_Mutation_Response>>>
  /** update data of the table: "loan_periodicity" */
  update_loan_periodicity?: Maybe<Loan_Periodicity_Mutation_Response>
  /** update single row of the table: "loan_periodicity" */
  update_loan_periodicity_by_pk?: Maybe<Loan_Periodicity>
  /** update multiples rows of table: "loan_periodicity" */
  update_loan_periodicity_many?: Maybe<
    Array<Maybe<Loan_Periodicity_Mutation_Response>>
  >
  /** update data of the table: "loan_status" */
  update_loan_status?: Maybe<Loan_Status_Mutation_Response>
  /** update single row of the table: "loan_status" */
  update_loan_status_by_pk?: Maybe<Loan_Status>
  /** update multiples rows of table: "loan_status" */
  update_loan_status_many?: Maybe<Array<Maybe<Loan_Status_Mutation_Response>>>
  /** update data of the table: "loan_type" */
  update_loan_type?: Maybe<Loan_Type_Mutation_Response>
  /** update single row of the table: "loan_type" */
  update_loan_type_by_pk?: Maybe<Loan_Type>
  /** update multiples rows of table: "loan_type" */
  update_loan_type_many?: Maybe<Array<Maybe<Loan_Type_Mutation_Response>>>
  /** update data of the table: "location" */
  update_location?: Maybe<Location_Mutation_Response>
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<Location_Mutation_Response>>>
  /** update data of the table: "location_payment_method" */
  update_location_payment_method?: Maybe<Location_Payment_Method_Mutation_Response>
  /** update single row of the table: "location_payment_method" */
  update_location_payment_method_by_pk?: Maybe<Location_Payment_Method>
  /** update multiples rows of table: "location_payment_method" */
  update_location_payment_method_many?: Maybe<
    Array<Maybe<Location_Payment_Method_Mutation_Response>>
  >
  /** update data of the table: "payment" */
  update_payment?: Maybe<Payment_Mutation_Response>
  /** update single row of the table: "payment" */
  update_payment_by_pk?: Maybe<Payment>
  /** update multiples rows of table: "payment" */
  update_payment_many?: Maybe<Array<Maybe<Payment_Mutation_Response>>>
  /** update data of the table: "payment_method" */
  update_payment_method?: Maybe<Payment_Method_Mutation_Response>
  /** update single row of the table: "payment_method" */
  update_payment_method_by_pk?: Maybe<Payment_Method>
  /** update multiples rows of table: "payment_method" */
  update_payment_method_many?: Maybe<
    Array<Maybe<Payment_Method_Mutation_Response>>
  >
  /** update data of the table: "payment_type" */
  update_payment_type?: Maybe<Payment_Type_Mutation_Response>
  /** update single row of the table: "payment_type" */
  update_payment_type_by_pk?: Maybe<Payment_Type>
  /** update multiples rows of table: "payment_type" */
  update_payment_type_many?: Maybe<Array<Maybe<Payment_Type_Mutation_Response>>>
  /** update data of the table: "phone_number_type" */
  update_phone_number_type?: Maybe<Phone_Number_Type_Mutation_Response>
  /** update single row of the table: "phone_number_type" */
  update_phone_number_type_by_pk?: Maybe<Phone_Number_Type>
  /** update multiples rows of table: "phone_number_type" */
  update_phone_number_type_many?: Maybe<
    Array<Maybe<Phone_Number_Type_Mutation_Response>>
  >
  /** update data of the table: "subscription_type" */
  update_subscription_type?: Maybe<Subscription_Type_Mutation_Response>
  /** update single row of the table: "subscription_type" */
  update_subscription_type_by_pk?: Maybe<Subscription_Type>
  /** update multiples rows of table: "subscription_type" */
  update_subscription_type_many?: Maybe<
    Array<Maybe<Subscription_Type_Mutation_Response>>
  >
  /** update data of the table: "tenant" */
  update_tenant?: Maybe<Tenant_Mutation_Response>
  /** update single row of the table: "tenant" */
  update_tenant_by_pk?: Maybe<Tenant>
  /** update multiples rows of table: "tenant" */
  update_tenant_many?: Maybe<Array<Maybe<Tenant_Mutation_Response>>>
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update data of the table: "user_location" */
  update_user_location?: Maybe<User_Location_Mutation_Response>
  /** update single row of the table: "user_location" */
  update_user_location_by_pk?: Maybe<User_Location>
  /** update multiples rows of table: "user_location" */
  update_user_location_many?: Maybe<
    Array<Maybe<User_Location_Mutation_Response>>
  >
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<User_Role_Mutation_Response>>>
  /** update data of the table: "user_status" */
  update_user_status?: Maybe<User_Status_Mutation_Response>
  /** update single row of the table: "user_status" */
  update_user_status_by_pk?: Maybe<User_Status>
  /** update multiples rows of table: "user_status" */
  update_user_status_many?: Maybe<Array<Maybe<User_Status_Mutation_Response>>>
  /** update data of the table: "user_tenant" */
  update_user_tenant?: Maybe<User_Tenant_Mutation_Response>
  /** update single row of the table: "user_tenant" */
  update_user_tenant_by_pk?: Maybe<User_Tenant>
  /** update multiples rows of table: "user_tenant" */
  update_user_tenant_many?: Maybe<Array<Maybe<User_Tenant_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootDelete_Address_TypeArgs = {
  where: Address_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Address_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Audit_Logged_ActionsArgs = {
  where: Audit_Logged_Actions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Audit_Logged_Actions_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_ClientArgs = {
  where: Client_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Client_AddressArgs = {
  where: Client_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Client_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Client_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Client_FileArgs = {
  where: Client_File_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Client_File_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Client_File_TypeArgs = {
  where: Client_File_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Client_File_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Client_Phone_NumberArgs = {
  where: Client_Phone_Number_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Client_Phone_Number_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_CurrencyArgs = {
  where: Currency_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Currency_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Document_TemplateArgs = {
  where: Document_Template_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Document_Template_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Document_Template_TypeArgs = {
  where: Document_Template_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Document_Template_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_LoanArgs = {
  where: Loan_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Loan_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Loan_PeriodicityArgs = {
  where: Loan_Periodicity_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Loan_Periodicity_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Loan_StatusArgs = {
  where: Loan_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Loan_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Loan_TypeArgs = {
  where: Loan_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Loan_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_LocationArgs = {
  where: Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Location_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Location_Payment_MethodArgs = {
  where: Location_Payment_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Location_Payment_Method_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_Payment_MethodArgs = {
  where: Payment_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Payment_Method_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Payment_TypeArgs = {
  where: Payment_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Payment_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Phone_Number_TypeArgs = {
  where: Phone_Number_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Phone_Number_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_Subscription_TypeArgs = {
  where: Subscription_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Subscription_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_TenantArgs = {
  where: Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Tenant_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_LocationArgs = {
  where: User_Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Location_By_PkArgs = {
  location_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_StatusArgs = {
  where: User_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

/** mutation root */
export type Mutation_RootDelete_User_TenantArgs = {
  where: User_Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Tenant_By_PkArgs = {
  tenant_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

/** mutation root */
export type Mutation_RootInsert_Address_TypeArgs = {
  objects: Array<Address_Type_Insert_Input>
  on_conflict?: InputMaybe<Address_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Address_Type_OneArgs = {
  object: Address_Type_Insert_Input
  on_conflict?: InputMaybe<Address_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Audit_Logged_ActionsArgs = {
  objects: Array<Audit_Logged_Actions_Insert_Input>
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Audit_Logged_Actions_OneArgs = {
  object: Audit_Logged_Actions_Insert_Input
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ClientArgs = {
  objects: Array<Client_Insert_Input>
  on_conflict?: InputMaybe<Client_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_AddressArgs = {
  objects: Array<Client_Address_Insert_Input>
  on_conflict?: InputMaybe<Client_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_Address_OneArgs = {
  object: Client_Address_Insert_Input
  on_conflict?: InputMaybe<Client_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_FileArgs = {
  objects: Array<Client_File_Insert_Input>
  on_conflict?: InputMaybe<Client_File_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_File_OneArgs = {
  object: Client_File_Insert_Input
  on_conflict?: InputMaybe<Client_File_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_File_TypeArgs = {
  objects: Array<Client_File_Type_Insert_Input>
  on_conflict?: InputMaybe<Client_File_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_File_Type_OneArgs = {
  object: Client_File_Type_Insert_Input
  on_conflict?: InputMaybe<Client_File_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_OneArgs = {
  object: Client_Insert_Input
  on_conflict?: InputMaybe<Client_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_Phone_NumberArgs = {
  objects: Array<Client_Phone_Number_Insert_Input>
  on_conflict?: InputMaybe<Client_Phone_Number_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Client_Phone_Number_OneArgs = {
  object: Client_Phone_Number_Insert_Input
  on_conflict?: InputMaybe<Client_Phone_Number_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CurrencyArgs = {
  objects: Array<Currency_Insert_Input>
  on_conflict?: InputMaybe<Currency_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Currency_OneArgs = {
  object: Currency_Insert_Input
  on_conflict?: InputMaybe<Currency_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Document_TemplateArgs = {
  objects: Array<Document_Template_Insert_Input>
  on_conflict?: InputMaybe<Document_Template_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Document_Template_OneArgs = {
  object: Document_Template_Insert_Input
  on_conflict?: InputMaybe<Document_Template_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Document_Template_TypeArgs = {
  objects: Array<Document_Template_Type_Insert_Input>
  on_conflict?: InputMaybe<Document_Template_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Document_Template_Type_OneArgs = {
  object: Document_Template_Type_Insert_Input
  on_conflict?: InputMaybe<Document_Template_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_LoanArgs = {
  objects: Array<Loan_Insert_Input>
  on_conflict?: InputMaybe<Loan_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Loan_OneArgs = {
  object: Loan_Insert_Input
  on_conflict?: InputMaybe<Loan_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Loan_PeriodicityArgs = {
  objects: Array<Loan_Periodicity_Insert_Input>
  on_conflict?: InputMaybe<Loan_Periodicity_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Loan_Periodicity_OneArgs = {
  object: Loan_Periodicity_Insert_Input
  on_conflict?: InputMaybe<Loan_Periodicity_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Loan_StatusArgs = {
  objects: Array<Loan_Status_Insert_Input>
  on_conflict?: InputMaybe<Loan_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Loan_Status_OneArgs = {
  object: Loan_Status_Insert_Input
  on_conflict?: InputMaybe<Loan_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Loan_TypeArgs = {
  objects: Array<Loan_Type_Insert_Input>
  on_conflict?: InputMaybe<Loan_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Loan_Type_OneArgs = {
  object: Loan_Type_Insert_Input
  on_conflict?: InputMaybe<Loan_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_LocationArgs = {
  objects: Array<Location_Insert_Input>
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Location_OneArgs = {
  object: Location_Insert_Input
  on_conflict?: InputMaybe<Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Location_Payment_MethodArgs = {
  objects: Array<Location_Payment_Method_Insert_Input>
  on_conflict?: InputMaybe<Location_Payment_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Location_Payment_Method_OneArgs = {
  object: Location_Payment_Method_Insert_Input
  on_conflict?: InputMaybe<Location_Payment_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>
  on_conflict?: InputMaybe<Payment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_MethodArgs = {
  objects: Array<Payment_Method_Insert_Input>
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_Method_OneArgs = {
  object: Payment_Method_Insert_Input
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input
  on_conflict?: InputMaybe<Payment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_TypeArgs = {
  objects: Array<Payment_Type_Insert_Input>
  on_conflict?: InputMaybe<Payment_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Payment_Type_OneArgs = {
  object: Payment_Type_Insert_Input
  on_conflict?: InputMaybe<Payment_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Phone_Number_TypeArgs = {
  objects: Array<Phone_Number_Type_Insert_Input>
  on_conflict?: InputMaybe<Phone_Number_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Phone_Number_Type_OneArgs = {
  object: Phone_Number_Type_Insert_Input
  on_conflict?: InputMaybe<Phone_Number_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Subscription_TypeArgs = {
  objects: Array<Subscription_Type_Insert_Input>
  on_conflict?: InputMaybe<Subscription_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Subscription_Type_OneArgs = {
  object: Subscription_Type_Insert_Input
  on_conflict?: InputMaybe<Subscription_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_TenantArgs = {
  objects: Array<Tenant_Insert_Input>
  on_conflict?: InputMaybe<Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tenant_OneArgs = {
  object: Tenant_Insert_Input
  on_conflict?: InputMaybe<Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_LocationArgs = {
  objects: Array<User_Location_Insert_Input>
  on_conflict?: InputMaybe<User_Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Location_OneArgs = {
  object: User_Location_Insert_Input
  on_conflict?: InputMaybe<User_Location_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_StatusArgs = {
  objects: Array<User_Status_Insert_Input>
  on_conflict?: InputMaybe<User_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Status_OneArgs = {
  object: User_Status_Insert_Input
  on_conflict?: InputMaybe<User_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_TenantArgs = {
  objects: Array<User_Tenant_Insert_Input>
  on_conflict?: InputMaybe<User_Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Tenant_OneArgs = {
  object: User_Tenant_Insert_Input
  on_conflict?: InputMaybe<User_Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootLinkUserArgs = {
  input: LinkUserInput
}

/** mutation root */
export type Mutation_RootPayLoanArgs = {
  input: PayLoanInput
}

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  input: UpdateUserInput
}

/** mutation root */
export type Mutation_RootUpdate_Address_TypeArgs = {
  _set?: InputMaybe<Address_Type_Set_Input>
  where: Address_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Address_Type_By_PkArgs = {
  _set?: InputMaybe<Address_Type_Set_Input>
  pk_columns: Address_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Address_Type_ManyArgs = {
  updates: Array<Address_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_ActionsArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>
  where: Audit_Logged_Actions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_By_PkArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>
  pk_columns: Audit_Logged_Actions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_ManyArgs = {
  updates: Array<Audit_Logged_Actions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ClientArgs = {
  _set?: InputMaybe<Client_Set_Input>
  where: Client_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Client_AddressArgs = {
  _set?: InputMaybe<Client_Address_Set_Input>
  where: Client_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Client_Address_By_PkArgs = {
  _set?: InputMaybe<Client_Address_Set_Input>
  pk_columns: Client_Address_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Client_Address_ManyArgs = {
  updates: Array<Client_Address_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Client_By_PkArgs = {
  _set?: InputMaybe<Client_Set_Input>
  pk_columns: Client_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Client_FileArgs = {
  _set?: InputMaybe<Client_File_Set_Input>
  where: Client_File_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Client_File_By_PkArgs = {
  _set?: InputMaybe<Client_File_Set_Input>
  pk_columns: Client_File_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Client_File_ManyArgs = {
  updates: Array<Client_File_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Client_File_TypeArgs = {
  _set?: InputMaybe<Client_File_Type_Set_Input>
  where: Client_File_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Client_File_Type_By_PkArgs = {
  _set?: InputMaybe<Client_File_Type_Set_Input>
  pk_columns: Client_File_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Client_File_Type_ManyArgs = {
  updates: Array<Client_File_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Client_ManyArgs = {
  updates: Array<Client_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Client_Phone_NumberArgs = {
  _set?: InputMaybe<Client_Phone_Number_Set_Input>
  where: Client_Phone_Number_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Client_Phone_Number_By_PkArgs = {
  _set?: InputMaybe<Client_Phone_Number_Set_Input>
  pk_columns: Client_Phone_Number_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Client_Phone_Number_ManyArgs = {
  updates: Array<Client_Phone_Number_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CurrencyArgs = {
  _set?: InputMaybe<Currency_Set_Input>
  where: Currency_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Currency_By_PkArgs = {
  _set?: InputMaybe<Currency_Set_Input>
  pk_columns: Currency_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Currency_ManyArgs = {
  updates: Array<Currency_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Document_TemplateArgs = {
  _set?: InputMaybe<Document_Template_Set_Input>
  where: Document_Template_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Document_Template_By_PkArgs = {
  _set?: InputMaybe<Document_Template_Set_Input>
  pk_columns: Document_Template_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Document_Template_ManyArgs = {
  updates: Array<Document_Template_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Document_Template_TypeArgs = {
  _set?: InputMaybe<Document_Template_Type_Set_Input>
  where: Document_Template_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Document_Template_Type_By_PkArgs = {
  _set?: InputMaybe<Document_Template_Type_Set_Input>
  pk_columns: Document_Template_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Document_Template_Type_ManyArgs = {
  updates: Array<Document_Template_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_LoanArgs = {
  _append?: InputMaybe<Loan_Append_Input>
  _delete_at_path?: InputMaybe<Loan_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Loan_Delete_Elem_Input>
  _delete_key?: InputMaybe<Loan_Delete_Key_Input>
  _inc?: InputMaybe<Loan_Inc_Input>
  _prepend?: InputMaybe<Loan_Prepend_Input>
  _set?: InputMaybe<Loan_Set_Input>
  where: Loan_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Loan_By_PkArgs = {
  _append?: InputMaybe<Loan_Append_Input>
  _delete_at_path?: InputMaybe<Loan_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Loan_Delete_Elem_Input>
  _delete_key?: InputMaybe<Loan_Delete_Key_Input>
  _inc?: InputMaybe<Loan_Inc_Input>
  _prepend?: InputMaybe<Loan_Prepend_Input>
  _set?: InputMaybe<Loan_Set_Input>
  pk_columns: Loan_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Loan_ManyArgs = {
  updates: Array<Loan_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Loan_PeriodicityArgs = {
  _set?: InputMaybe<Loan_Periodicity_Set_Input>
  where: Loan_Periodicity_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Loan_Periodicity_By_PkArgs = {
  _set?: InputMaybe<Loan_Periodicity_Set_Input>
  pk_columns: Loan_Periodicity_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Loan_Periodicity_ManyArgs = {
  updates: Array<Loan_Periodicity_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Loan_StatusArgs = {
  _set?: InputMaybe<Loan_Status_Set_Input>
  where: Loan_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Loan_Status_By_PkArgs = {
  _set?: InputMaybe<Loan_Status_Set_Input>
  pk_columns: Loan_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Loan_Status_ManyArgs = {
  updates: Array<Loan_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Loan_TypeArgs = {
  _set?: InputMaybe<Loan_Type_Set_Input>
  where: Loan_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Loan_Type_By_PkArgs = {
  _set?: InputMaybe<Loan_Type_Set_Input>
  pk_columns: Loan_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Loan_Type_ManyArgs = {
  updates: Array<Loan_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_LocationArgs = {
  _inc?: InputMaybe<Location_Inc_Input>
  _set?: InputMaybe<Location_Set_Input>
  where: Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Location_By_PkArgs = {
  _inc?: InputMaybe<Location_Inc_Input>
  _set?: InputMaybe<Location_Set_Input>
  pk_columns: Location_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Location_ManyArgs = {
  updates: Array<Location_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Location_Payment_MethodArgs = {
  _set?: InputMaybe<Location_Payment_Method_Set_Input>
  where: Location_Payment_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Location_Payment_Method_By_PkArgs = {
  _set?: InputMaybe<Location_Payment_Method_Set_Input>
  pk_columns: Location_Payment_Method_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Location_Payment_Method_ManyArgs = {
  updates: Array<Location_Payment_Method_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _append?: InputMaybe<Payment_Append_Input>
  _delete_at_path?: InputMaybe<Payment_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Payment_Delete_Elem_Input>
  _delete_key?: InputMaybe<Payment_Delete_Key_Input>
  _inc?: InputMaybe<Payment_Inc_Input>
  _prepend?: InputMaybe<Payment_Prepend_Input>
  _set?: InputMaybe<Payment_Set_Input>
  where: Payment_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _append?: InputMaybe<Payment_Append_Input>
  _delete_at_path?: InputMaybe<Payment_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Payment_Delete_Elem_Input>
  _delete_key?: InputMaybe<Payment_Delete_Key_Input>
  _inc?: InputMaybe<Payment_Inc_Input>
  _prepend?: InputMaybe<Payment_Prepend_Input>
  _set?: InputMaybe<Payment_Set_Input>
  pk_columns: Payment_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Payment_ManyArgs = {
  updates: Array<Payment_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Payment_MethodArgs = {
  _set?: InputMaybe<Payment_Method_Set_Input>
  where: Payment_Method_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Method_By_PkArgs = {
  _set?: InputMaybe<Payment_Method_Set_Input>
  pk_columns: Payment_Method_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Method_ManyArgs = {
  updates: Array<Payment_Method_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Payment_TypeArgs = {
  _set?: InputMaybe<Payment_Type_Set_Input>
  where: Payment_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Type_By_PkArgs = {
  _set?: InputMaybe<Payment_Type_Set_Input>
  pk_columns: Payment_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Payment_Type_ManyArgs = {
  updates: Array<Payment_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Phone_Number_TypeArgs = {
  _set?: InputMaybe<Phone_Number_Type_Set_Input>
  where: Phone_Number_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Phone_Number_Type_By_PkArgs = {
  _set?: InputMaybe<Phone_Number_Type_Set_Input>
  pk_columns: Phone_Number_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Phone_Number_Type_ManyArgs = {
  updates: Array<Phone_Number_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Subscription_TypeArgs = {
  _set?: InputMaybe<Subscription_Type_Set_Input>
  where: Subscription_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Subscription_Type_By_PkArgs = {
  _set?: InputMaybe<Subscription_Type_Set_Input>
  pk_columns: Subscription_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Subscription_Type_ManyArgs = {
  updates: Array<Subscription_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_TenantArgs = {
  _set?: InputMaybe<Tenant_Set_Input>
  where: Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Tenant_By_PkArgs = {
  _set?: InputMaybe<Tenant_Set_Input>
  pk_columns: Tenant_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Tenant_ManyArgs = {
  updates: Array<Tenant_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_LocationArgs = {
  _set?: InputMaybe<User_Location_Set_Input>
  where: User_Location_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Location_By_PkArgs = {
  _set?: InputMaybe<User_Location_Set_Input>
  pk_columns: User_Location_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Location_ManyArgs = {
  updates: Array<User_Location_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _set?: InputMaybe<User_Role_Set_Input>
  where: User_Role_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _set?: InputMaybe<User_Role_Set_Input>
  pk_columns: User_Role_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Role_ManyArgs = {
  updates: Array<User_Role_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_StatusArgs = {
  _set?: InputMaybe<User_Status_Set_Input>
  where: User_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Status_By_PkArgs = {
  _set?: InputMaybe<User_Status_Set_Input>
  pk_columns: User_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Status_ManyArgs = {
  updates: Array<User_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_TenantArgs = {
  _set?: InputMaybe<User_Tenant_Set_Input>
  where: User_Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Tenant_By_PkArgs = {
  _set?: InputMaybe<User_Tenant_Set_Input>
  pk_columns: User_Tenant_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Tenant_ManyArgs = {
  updates: Array<User_Tenant_Updates>
}

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['oid']['input']>
  _gt?: InputMaybe<Scalars['oid']['input']>
  _gte?: InputMaybe<Scalars['oid']['input']>
  _in?: InputMaybe<Array<Scalars['oid']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['oid']['input']>
  _lte?: InputMaybe<Scalars['oid']['input']>
  _neq?: InputMaybe<Scalars['oid']['input']>
  _nin?: InputMaybe<Array<Scalars['oid']['input']>>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment" */
export type Payment = {
  __typename?: 'payment'
  amount: Scalars['Float']['output']
  capital_amount: Scalars['Float']['output']
  /** An object relationship */
  client: Client
  client_id: Scalars['uuid']['output']
  commission_amount: Scalars['Float']['output']
  commission_interest_arrears_amount: Scalars['Float']['output']
  created_at: Scalars['timestamptz']['output']
  current_balance_amount: Scalars['Float']['output']
  current_commission_interest_arrears_amount: Scalars['Float']['output']
  current_interest_arrears_amount: Scalars['Float']['output']
  days_late?: Maybe<Scalars['Int']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  expected_amount: Scalars['Float']['output']
  expected_capital_amount: Scalars['Float']['output']
  expected_commission_amount: Scalars['Float']['output']
  expected_interest_amount: Scalars['Float']['output']
  expected_payment_date: Scalars['date']['output']
  id: Scalars['uuid']['output']
  interest_amount: Scalars['Float']['output']
  interest_arrears_amount: Scalars['Float']['output']
  /** An object relationship */
  loan: Loan
  loan_id: Scalars['uuid']['output']
  /** An object relationship */
  location?: Maybe<Location>
  location_id?: Maybe<Scalars['uuid']['output']>
  metadata?: Maybe<Scalars['jsonb']['output']>
  new_balance_amount: Scalars['Float']['output']
  new_commission_interest_arrears_amount: Scalars['Float']['output']
  new_interest_arrears_amount: Scalars['Float']['output']
  new_payment_date: Scalars['date']['output']
  notes?: Maybe<Scalars['String']['output']>
  /** An object relationship */
  payment_method: Location_Payment_Method
  payment_method_id: Scalars['uuid']['output']
  payment_method_ref?: Maybe<Scalars['String']['output']>
  /** An object relationship */
  payment_type: Payment_Type
  penalty_amount?: Maybe<Scalars['Float']['output']>
  type: Payment_Type_Enum
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']['output']
}

/** columns and relationships of "payment" */
export type PaymentMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>
}

/** aggregated selection of "payment" */
export type Payment_Aggregate = {
  __typename?: 'payment_aggregate'
  aggregate?: Maybe<Payment_Aggregate_Fields>
  nodes: Array<Payment>
}

export type Payment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Payment_Aggregate_Bool_Exp_Count>
}

export type Payment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Payment_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<Payment_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "payment" */
export type Payment_Aggregate_Fields = {
  __typename?: 'payment_aggregate_fields'
  avg?: Maybe<Payment_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<Payment_Max_Fields>
  min?: Maybe<Payment_Min_Fields>
  stddev?: Maybe<Payment_Stddev_Fields>
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>
  sum?: Maybe<Payment_Sum_Fields>
  var_pop?: Maybe<Payment_Var_Pop_Fields>
  var_samp?: Maybe<Payment_Var_Samp_Fields>
  variance?: Maybe<Payment_Variance_Fields>
}

/** aggregate fields of "payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "payment" */
export type Payment_Aggregate_Order_By = {
  avg?: InputMaybe<Payment_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Payment_Max_Order_By>
  min?: InputMaybe<Payment_Min_Order_By>
  stddev?: InputMaybe<Payment_Stddev_Order_By>
  stddev_pop?: InputMaybe<Payment_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Payment_Stddev_Samp_Order_By>
  sum?: InputMaybe<Payment_Sum_Order_By>
  var_pop?: InputMaybe<Payment_Var_Pop_Order_By>
  var_samp?: InputMaybe<Payment_Var_Samp_Order_By>
  variance?: InputMaybe<Payment_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Payment_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>
}

/** input type for inserting array relation for remote table "payment" */
export type Payment_Arr_Rel_Insert_Input = {
  data: Array<Payment_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_On_Conflict>
}

/** aggregate avg on columns */
export type Payment_Avg_Fields = {
  __typename?: 'payment_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Float']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by avg() on columns of table "payment" */
export type Payment_Avg_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Bool_Exp>>
  _not?: InputMaybe<Payment_Bool_Exp>
  _or?: InputMaybe<Array<Payment_Bool_Exp>>
  amount?: InputMaybe<Float_Comparison_Exp>
  capital_amount?: InputMaybe<Float_Comparison_Exp>
  client?: InputMaybe<Client_Bool_Exp>
  client_id?: InputMaybe<Uuid_Comparison_Exp>
  commission_amount?: InputMaybe<Float_Comparison_Exp>
  commission_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  current_balance_amount?: InputMaybe<Float_Comparison_Exp>
  current_commission_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  current_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  days_late?: InputMaybe<Int_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  expected_amount?: InputMaybe<Float_Comparison_Exp>
  expected_capital_amount?: InputMaybe<Float_Comparison_Exp>
  expected_commission_amount?: InputMaybe<Float_Comparison_Exp>
  expected_interest_amount?: InputMaybe<Float_Comparison_Exp>
  expected_payment_date?: InputMaybe<Date_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  interest_amount?: InputMaybe<Float_Comparison_Exp>
  interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  loan?: InputMaybe<Loan_Bool_Exp>
  loan_id?: InputMaybe<Uuid_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  metadata?: InputMaybe<Jsonb_Comparison_Exp>
  new_balance_amount?: InputMaybe<Float_Comparison_Exp>
  new_commission_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  new_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  new_payment_date?: InputMaybe<Date_Comparison_Exp>
  notes?: InputMaybe<String_Comparison_Exp>
  payment_method?: InputMaybe<Location_Payment_Method_Bool_Exp>
  payment_method_id?: InputMaybe<Uuid_Comparison_Exp>
  payment_method_ref?: InputMaybe<String_Comparison_Exp>
  payment_type?: InputMaybe<Payment_Type_Bool_Exp>
  penalty_amount?: InputMaybe<Float_Comparison_Exp>
  type?: InputMaybe<Payment_Type_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentsPkey = 'payments_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Payment_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Payment_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Payment_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>
}

/** input type for incrementing numeric columns in table "payment" */
export type Payment_Inc_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  capital_amount?: InputMaybe<Scalars['Float']['input']>
  commission_amount?: InputMaybe<Scalars['Float']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  current_balance_amount?: InputMaybe<Scalars['Float']['input']>
  current_commission_interest_arrears_amount?: InputMaybe<
    Scalars['Float']['input']
  >
  current_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  days_late?: InputMaybe<Scalars['Int']['input']>
  expected_amount?: InputMaybe<Scalars['Float']['input']>
  expected_capital_amount?: InputMaybe<Scalars['Float']['input']>
  expected_commission_amount?: InputMaybe<Scalars['Float']['input']>
  expected_interest_amount?: InputMaybe<Scalars['Float']['input']>
  interest_amount?: InputMaybe<Scalars['Float']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_balance_amount?: InputMaybe<Scalars['Float']['input']>
  new_commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  penalty_amount?: InputMaybe<Scalars['Float']['input']>
}

/** input type for inserting data into table "payment" */
export type Payment_Insert_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  capital_amount?: InputMaybe<Scalars['Float']['input']>
  client?: InputMaybe<Client_Obj_Rel_Insert_Input>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  commission_amount?: InputMaybe<Scalars['Float']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  current_balance_amount?: InputMaybe<Scalars['Float']['input']>
  current_commission_interest_arrears_amount?: InputMaybe<
    Scalars['Float']['input']
  >
  current_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  days_late?: InputMaybe<Scalars['Int']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  expected_amount?: InputMaybe<Scalars['Float']['input']>
  expected_capital_amount?: InputMaybe<Scalars['Float']['input']>
  expected_commission_amount?: InputMaybe<Scalars['Float']['input']>
  expected_interest_amount?: InputMaybe<Scalars['Float']['input']>
  expected_payment_date?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  interest_amount?: InputMaybe<Scalars['Float']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  loan?: InputMaybe<Loan_Obj_Rel_Insert_Input>
  loan_id?: InputMaybe<Scalars['uuid']['input']>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  new_balance_amount?: InputMaybe<Scalars['Float']['input']>
  new_commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_payment_date?: InputMaybe<Scalars['date']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  payment_method?: InputMaybe<Location_Payment_Method_Obj_Rel_Insert_Input>
  payment_method_id?: InputMaybe<Scalars['uuid']['input']>
  payment_method_ref?: InputMaybe<Scalars['String']['input']>
  payment_type?: InputMaybe<Payment_Type_Obj_Rel_Insert_Input>
  penalty_amount?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Payment_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type Payment_Max_Fields = {
  __typename?: 'payment_max_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  client_id?: Maybe<Scalars['uuid']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Int']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  expected_payment_date?: Maybe<Scalars['date']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  loan_id?: Maybe<Scalars['uuid']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_payment_date?: Maybe<Scalars['date']['output']>
  notes?: Maybe<Scalars['String']['output']>
  payment_method_id?: Maybe<Scalars['uuid']['output']>
  payment_method_ref?: Maybe<Scalars['String']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "payment" */
export type Payment_Max_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  client_id?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  expected_payment_date?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  loan_id?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  new_payment_date?: InputMaybe<Order_By>
  notes?: InputMaybe<Order_By>
  payment_method_id?: InputMaybe<Order_By>
  payment_method_ref?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** columns and relationships of "payment_method" */
export type Payment_Method = {
  __typename?: 'payment_method'
  comment: Scalars['String']['output']
  /** An array relationship */
  location_payment_methods: Array<Location_Payment_Method>
  /** An aggregate relationship */
  location_payment_methods_aggregate: Location_Payment_Method_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "payment_method" */
export type Payment_MethodLocation_Payment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

/** columns and relationships of "payment_method" */
export type Payment_MethodLocation_Payment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

/** aggregated selection of "payment_method" */
export type Payment_Method_Aggregate = {
  __typename?: 'payment_method_aggregate'
  aggregate?: Maybe<Payment_Method_Aggregate_Fields>
  nodes: Array<Payment_Method>
}

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_Fields = {
  __typename?: 'payment_method_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Payment_Method_Max_Fields>
  min?: Maybe<Payment_Method_Min_Fields>
}

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Method_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "payment_method". All fields are combined with a logical 'AND'. */
export type Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Method_Bool_Exp>>
  _not?: InputMaybe<Payment_Method_Bool_Exp>
  _or?: InputMaybe<Array<Payment_Method_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  location_payment_methods?: InputMaybe<Location_Payment_Method_Bool_Exp>
  location_payment_methods_aggregate?: InputMaybe<Location_Payment_Method_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "payment_method" */
export enum Payment_Method_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentMethodTypePkey = 'payment_method_type_pkey'
}

export enum Payment_Method_Enum {
  /** CASH */
  Cash = 'CASH',
  /** WIRE_TRANSFER */
  WireTransfer = 'WIRE_TRANSFER'
}

/** Boolean expression to compare columns of type "payment_method_enum". All fields are combined with logical 'AND'. */
export type Payment_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Method_Enum>
  _in?: InputMaybe<Array<Payment_Method_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Payment_Method_Enum>
  _nin?: InputMaybe<Array<Payment_Method_Enum>>
}

/** input type for inserting data into table "payment_method" */
export type Payment_Method_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  location_payment_methods?: InputMaybe<Location_Payment_Method_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Payment_Method_Max_Fields = {
  __typename?: 'payment_method_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Payment_Method_Min_Fields = {
  __typename?: 'payment_method_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "payment_method" */
export type Payment_Method_Mutation_Response = {
  __typename?: 'payment_method_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method>
}

/** input type for inserting object relation for remote table "payment_method" */
export type Payment_Method_Obj_Rel_Insert_Input = {
  data: Payment_Method_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>
}

/** on_conflict condition type for table "payment_method" */
export type Payment_Method_On_Conflict = {
  constraint: Payment_Method_Constraint
  update_columns?: Array<Payment_Method_Update_Column>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

/** Ordering options when selecting data from "payment_method". */
export type Payment_Method_Order_By = {
  comment?: InputMaybe<Order_By>
  location_payment_methods_aggregate?: InputMaybe<Location_Payment_Method_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: payment_method */
export type Payment_Method_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "payment_method" */
export enum Payment_Method_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_method" */
export type Payment_Method_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "payment_method" */
export type Payment_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Method_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Payment_Method_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "payment_method" */
export enum Payment_Method_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Payment_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Set_Input>
  /** filter the rows which have to be updated */
  where: Payment_Method_Bool_Exp
}

/** aggregate min on columns */
export type Payment_Min_Fields = {
  __typename?: 'payment_min_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  client_id?: Maybe<Scalars['uuid']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  created_at?: Maybe<Scalars['timestamptz']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Int']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  expected_payment_date?: Maybe<Scalars['date']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  loan_id?: Maybe<Scalars['uuid']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_payment_date?: Maybe<Scalars['date']['output']>
  notes?: Maybe<Scalars['String']['output']>
  payment_method_id?: Maybe<Scalars['uuid']['output']>
  payment_method_ref?: Maybe<Scalars['String']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "payment" */
export type Payment_Min_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  client_id?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  expected_payment_date?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  loan_id?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  new_payment_date?: InputMaybe<Order_By>
  notes?: InputMaybe<Order_By>
  payment_method_id?: InputMaybe<Order_By>
  payment_method_ref?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "payment" */
export type Payment_Mutation_Response = {
  __typename?: 'payment_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Payment>
}

/** on_conflict condition type for table "payment" */
export type Payment_On_Conflict = {
  constraint: Payment_Constraint
  update_columns?: Array<Payment_Update_Column>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** Ordering options when selecting data from "payment". */
export type Payment_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  client?: InputMaybe<Client_Order_By>
  client_id?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  expected_payment_date?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  loan?: InputMaybe<Loan_Order_By>
  loan_id?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  metadata?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  new_payment_date?: InputMaybe<Order_By>
  notes?: InputMaybe<Order_By>
  payment_method?: InputMaybe<Location_Payment_Method_Order_By>
  payment_method_id?: InputMaybe<Order_By>
  payment_method_ref?: InputMaybe<Order_By>
  payment_type?: InputMaybe<Payment_Type_Order_By>
  penalty_amount?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: payment */
export type Payment_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Payment_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>
}

/** select columns of table "payment" */
export enum Payment_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CapitalAmount = 'capital_amount',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommissionAmount = 'commission_amount',
  /** column name */
  CommissionInterestArrearsAmount = 'commission_interest_arrears_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentBalanceAmount = 'current_balance_amount',
  /** column name */
  CurrentCommissionInterestArrearsAmount = 'current_commission_interest_arrears_amount',
  /** column name */
  CurrentInterestArrearsAmount = 'current_interest_arrears_amount',
  /** column name */
  DaysLate = 'days_late',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExpectedAmount = 'expected_amount',
  /** column name */
  ExpectedCapitalAmount = 'expected_capital_amount',
  /** column name */
  ExpectedCommissionAmount = 'expected_commission_amount',
  /** column name */
  ExpectedInterestAmount = 'expected_interest_amount',
  /** column name */
  ExpectedPaymentDate = 'expected_payment_date',
  /** column name */
  Id = 'id',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  InterestArrearsAmount = 'interest_arrears_amount',
  /** column name */
  LoanId = 'loan_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewBalanceAmount = 'new_balance_amount',
  /** column name */
  NewCommissionInterestArrearsAmount = 'new_commission_interest_arrears_amount',
  /** column name */
  NewInterestArrearsAmount = 'new_interest_arrears_amount',
  /** column name */
  NewPaymentDate = 'new_payment_date',
  /** column name */
  Notes = 'notes',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentMethodRef = 'payment_method_ref',
  /** column name */
  PenaltyAmount = 'penalty_amount',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "payment" */
export type Payment_Set_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  capital_amount?: InputMaybe<Scalars['Float']['input']>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  commission_amount?: InputMaybe<Scalars['Float']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  current_balance_amount?: InputMaybe<Scalars['Float']['input']>
  current_commission_interest_arrears_amount?: InputMaybe<
    Scalars['Float']['input']
  >
  current_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  days_late?: InputMaybe<Scalars['Int']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  expected_amount?: InputMaybe<Scalars['Float']['input']>
  expected_capital_amount?: InputMaybe<Scalars['Float']['input']>
  expected_commission_amount?: InputMaybe<Scalars['Float']['input']>
  expected_interest_amount?: InputMaybe<Scalars['Float']['input']>
  expected_payment_date?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  interest_amount?: InputMaybe<Scalars['Float']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  loan_id?: InputMaybe<Scalars['uuid']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  new_balance_amount?: InputMaybe<Scalars['Float']['input']>
  new_commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_payment_date?: InputMaybe<Scalars['date']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  payment_method_id?: InputMaybe<Scalars['uuid']['input']>
  payment_method_ref?: InputMaybe<Scalars['String']['input']>
  penalty_amount?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Payment_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate stddev on columns */
export type Payment_Stddev_Fields = {
  __typename?: 'payment_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Float']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev() on columns of table "payment" */
export type Payment_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Payment_Stddev_Pop_Fields = {
  __typename?: 'payment_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Float']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_pop() on columns of table "payment" */
export type Payment_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Payment_Stddev_Samp_Fields = {
  __typename?: 'payment_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Float']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by stddev_samp() on columns of table "payment" */
export type Payment_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "payment" */
export type Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Payment_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  capital_amount?: InputMaybe<Scalars['Float']['input']>
  client_id?: InputMaybe<Scalars['uuid']['input']>
  commission_amount?: InputMaybe<Scalars['Float']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  current_balance_amount?: InputMaybe<Scalars['Float']['input']>
  current_commission_interest_arrears_amount?: InputMaybe<
    Scalars['Float']['input']
  >
  current_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  days_late?: InputMaybe<Scalars['Int']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  expected_amount?: InputMaybe<Scalars['Float']['input']>
  expected_capital_amount?: InputMaybe<Scalars['Float']['input']>
  expected_commission_amount?: InputMaybe<Scalars['Float']['input']>
  expected_interest_amount?: InputMaybe<Scalars['Float']['input']>
  expected_payment_date?: InputMaybe<Scalars['date']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  interest_amount?: InputMaybe<Scalars['Float']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  loan_id?: InputMaybe<Scalars['uuid']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  metadata?: InputMaybe<Scalars['jsonb']['input']>
  new_balance_amount?: InputMaybe<Scalars['Float']['input']>
  new_commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  new_payment_date?: InputMaybe<Scalars['date']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  payment_method_id?: InputMaybe<Scalars['uuid']['input']>
  payment_method_ref?: InputMaybe<Scalars['String']['input']>
  penalty_amount?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<Payment_Type_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type Payment_Sum_Fields = {
  __typename?: 'payment_sum_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Int']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by sum() on columns of table "payment" */
export type Payment_Sum_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** columns and relationships of "payment_type" */
export type Payment_Type = {
  __typename?: 'payment_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  payments: Array<Payment>
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "payment_type" */
export type Payment_TypePaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "payment_type" */
export type Payment_TypePayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** aggregated selection of "payment_type" */
export type Payment_Type_Aggregate = {
  __typename?: 'payment_type_aggregate'
  aggregate?: Maybe<Payment_Type_Aggregate_Fields>
  nodes: Array<Payment_Type>
}

/** aggregate fields of "payment_type" */
export type Payment_Type_Aggregate_Fields = {
  __typename?: 'payment_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Payment_Type_Max_Fields>
  min?: Maybe<Payment_Type_Min_Fields>
}

/** aggregate fields of "payment_type" */
export type Payment_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "payment_type". All fields are combined with a logical 'AND'. */
export type Payment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Type_Bool_Exp>>
  _not?: InputMaybe<Payment_Type_Bool_Exp>
  _or?: InputMaybe<Array<Payment_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  payments?: InputMaybe<Payment_Bool_Exp>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "payment_type" */
export enum Payment_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentTypePkey = 'payment_type_pkey'
}

export enum Payment_Type_Enum {
  /** ARREARS_PAYMENT */
  ArrearsPayment = 'ARREARS_PAYMENT',
  /** NORMAL */
  Normal = 'NORMAL'
}

/** Boolean expression to compare columns of type "payment_type_enum". All fields are combined with logical 'AND'. */
export type Payment_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Type_Enum>
  _in?: InputMaybe<Array<Payment_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Payment_Type_Enum>
  _nin?: InputMaybe<Array<Payment_Type_Enum>>
}

/** input type for inserting data into table "payment_type" */
export type Payment_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Payment_Type_Max_Fields = {
  __typename?: 'payment_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Payment_Type_Min_Fields = {
  __typename?: 'payment_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "payment_type" */
export type Payment_Type_Mutation_Response = {
  __typename?: 'payment_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Type>
}

/** input type for inserting object relation for remote table "payment_type" */
export type Payment_Type_Obj_Rel_Insert_Input = {
  data: Payment_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Type_On_Conflict>
}

/** on_conflict condition type for table "payment_type" */
export type Payment_Type_On_Conflict = {
  constraint: Payment_Type_Constraint
  update_columns?: Array<Payment_Type_Update_Column>
  where?: InputMaybe<Payment_Type_Bool_Exp>
}

/** Ordering options when selecting data from "payment_type". */
export type Payment_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: payment_type */
export type Payment_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "payment_type" */
export enum Payment_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_type" */
export type Payment_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "payment_type" */
export type Payment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Payment_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "payment_type" */
export enum Payment_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Payment_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Payment_Type_Bool_Exp
}

/** update columns of table "payment" */
export enum Payment_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CapitalAmount = 'capital_amount',
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CommissionAmount = 'commission_amount',
  /** column name */
  CommissionInterestArrearsAmount = 'commission_interest_arrears_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentBalanceAmount = 'current_balance_amount',
  /** column name */
  CurrentCommissionInterestArrearsAmount = 'current_commission_interest_arrears_amount',
  /** column name */
  CurrentInterestArrearsAmount = 'current_interest_arrears_amount',
  /** column name */
  DaysLate = 'days_late',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExpectedAmount = 'expected_amount',
  /** column name */
  ExpectedCapitalAmount = 'expected_capital_amount',
  /** column name */
  ExpectedCommissionAmount = 'expected_commission_amount',
  /** column name */
  ExpectedInterestAmount = 'expected_interest_amount',
  /** column name */
  ExpectedPaymentDate = 'expected_payment_date',
  /** column name */
  Id = 'id',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  InterestArrearsAmount = 'interest_arrears_amount',
  /** column name */
  LoanId = 'loan_id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewBalanceAmount = 'new_balance_amount',
  /** column name */
  NewCommissionInterestArrearsAmount = 'new_commission_interest_arrears_amount',
  /** column name */
  NewInterestArrearsAmount = 'new_interest_arrears_amount',
  /** column name */
  NewPaymentDate = 'new_payment_date',
  /** column name */
  Notes = 'notes',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PaymentMethodRef = 'payment_method_ref',
  /** column name */
  PenaltyAmount = 'penalty_amount',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Payment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Payment_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Payment_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Payment_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Payment_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Payment_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Set_Input>
  /** filter the rows which have to be updated */
  where: Payment_Bool_Exp
}

/** aggregate var_pop on columns */
export type Payment_Var_Pop_Fields = {
  __typename?: 'payment_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Float']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_pop() on columns of table "payment" */
export type Payment_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Payment_Var_Samp_Fields = {
  __typename?: 'payment_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Float']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by var_samp() on columns of table "payment" */
export type Payment_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Payment_Variance_Fields = {
  __typename?: 'payment_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_balance_amount?: Maybe<Scalars['Float']['output']>
  current_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  current_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  days_late?: Maybe<Scalars['Float']['output']>
  expected_amount?: Maybe<Scalars['Float']['output']>
  expected_capital_amount?: Maybe<Scalars['Float']['output']>
  expected_commission_amount?: Maybe<Scalars['Float']['output']>
  expected_interest_amount?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_balance_amount?: Maybe<Scalars['Float']['output']>
  new_commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  new_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** order by variance() on columns of table "payment" */
export type Payment_Variance_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_balance_amount?: InputMaybe<Order_By>
  current_commission_interest_arrears_amount?: InputMaybe<Order_By>
  current_interest_arrears_amount?: InputMaybe<Order_By>
  days_late?: InputMaybe<Order_By>
  expected_amount?: InputMaybe<Order_By>
  expected_capital_amount?: InputMaybe<Order_By>
  expected_commission_amount?: InputMaybe<Order_By>
  expected_interest_amount?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  new_balance_amount?: InputMaybe<Order_By>
  new_commission_interest_arrears_amount?: InputMaybe<Order_By>
  new_interest_arrears_amount?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
}

/** columns and relationships of "phone_number_type" */
export type Phone_Number_Type = {
  __typename?: 'phone_number_type'
  /** An array relationship */
  client_phone_numbers: Array<Client_Phone_Number>
  /** An aggregate relationship */
  client_phone_numbers_aggregate: Client_Phone_Number_Aggregate
  comment: Scalars['String']['output']
  value: Scalars['String']['output']
}

/** columns and relationships of "phone_number_type" */
export type Phone_Number_TypeClient_Phone_NumbersArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

/** columns and relationships of "phone_number_type" */
export type Phone_Number_TypeClient_Phone_Numbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

/** aggregated selection of "phone_number_type" */
export type Phone_Number_Type_Aggregate = {
  __typename?: 'phone_number_type_aggregate'
  aggregate?: Maybe<Phone_Number_Type_Aggregate_Fields>
  nodes: Array<Phone_Number_Type>
}

/** aggregate fields of "phone_number_type" */
export type Phone_Number_Type_Aggregate_Fields = {
  __typename?: 'phone_number_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Phone_Number_Type_Max_Fields>
  min?: Maybe<Phone_Number_Type_Min_Fields>
}

/** aggregate fields of "phone_number_type" */
export type Phone_Number_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Phone_Number_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "phone_number_type". All fields are combined with a logical 'AND'. */
export type Phone_Number_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Phone_Number_Type_Bool_Exp>>
  _not?: InputMaybe<Phone_Number_Type_Bool_Exp>
  _or?: InputMaybe<Array<Phone_Number_Type_Bool_Exp>>
  client_phone_numbers?: InputMaybe<Client_Phone_Number_Bool_Exp>
  client_phone_numbers_aggregate?: InputMaybe<Client_Phone_Number_Aggregate_Bool_Exp>
  comment?: InputMaybe<String_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "phone_number_type" */
export enum Phone_Number_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  PhoneNumberTypePkey = 'phone_number_type_pkey'
}

export enum Phone_Number_Type_Enum {
  /** CONTACT */
  Contact = 'CONTACT',
  /** HOME */
  Home = 'HOME',
  /** MOBILE_PHONE */
  MobilePhone = 'MOBILE_PHONE',
  /** WORK */
  Work = 'WORK'
}

/** Boolean expression to compare columns of type "phone_number_type_enum". All fields are combined with logical 'AND'. */
export type Phone_Number_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Phone_Number_Type_Enum>
  _in?: InputMaybe<Array<Phone_Number_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Phone_Number_Type_Enum>
  _nin?: InputMaybe<Array<Phone_Number_Type_Enum>>
}

/** input type for inserting data into table "phone_number_type" */
export type Phone_Number_Type_Insert_Input = {
  client_phone_numbers?: InputMaybe<Client_Phone_Number_Arr_Rel_Insert_Input>
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Phone_Number_Type_Max_Fields = {
  __typename?: 'phone_number_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Phone_Number_Type_Min_Fields = {
  __typename?: 'phone_number_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "phone_number_type" */
export type Phone_Number_Type_Mutation_Response = {
  __typename?: 'phone_number_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Phone_Number_Type>
}

/** input type for inserting object relation for remote table "phone_number_type" */
export type Phone_Number_Type_Obj_Rel_Insert_Input = {
  data: Phone_Number_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Phone_Number_Type_On_Conflict>
}

/** on_conflict condition type for table "phone_number_type" */
export type Phone_Number_Type_On_Conflict = {
  constraint: Phone_Number_Type_Constraint
  update_columns?: Array<Phone_Number_Type_Update_Column>
  where?: InputMaybe<Phone_Number_Type_Bool_Exp>
}

/** Ordering options when selecting data from "phone_number_type". */
export type Phone_Number_Type_Order_By = {
  client_phone_numbers_aggregate?: InputMaybe<Client_Phone_Number_Aggregate_Order_By>
  comment?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: phone_number_type */
export type Phone_Number_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "phone_number_type" */
export enum Phone_Number_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "phone_number_type" */
export type Phone_Number_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "phone_number_type" */
export type Phone_Number_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Phone_Number_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Phone_Number_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "phone_number_type" */
export enum Phone_Number_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Phone_Number_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Phone_Number_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Phone_Number_Type_Bool_Exp
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "address_type" */
  address_type: Array<Address_Type>
  /** fetch aggregated fields from the table: "address_type" */
  address_type_aggregate: Address_Type_Aggregate
  /** fetch data from the table: "address_type" using primary key columns */
  address_type_by_pk?: Maybe<Address_Type>
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>
  /** fetch data from the table: "client" */
  client: Array<Client>
  /** fetch data from the table: "client_address" */
  client_address: Array<Client_Address>
  /** fetch aggregated fields from the table: "client_address" */
  client_address_aggregate: Client_Address_Aggregate
  /** fetch data from the table: "client_address" using primary key columns */
  client_address_by_pk?: Maybe<Client_Address>
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>
  /** fetch data from the table: "client_file" */
  client_file: Array<Client_File>
  /** fetch aggregated fields from the table: "client_file" */
  client_file_aggregate: Client_File_Aggregate
  /** fetch data from the table: "client_file" using primary key columns */
  client_file_by_pk?: Maybe<Client_File>
  /** fetch data from the table: "client_file_type" */
  client_file_type: Array<Client_File_Type>
  /** fetch aggregated fields from the table: "client_file_type" */
  client_file_type_aggregate: Client_File_Type_Aggregate
  /** fetch data from the table: "client_file_type" using primary key columns */
  client_file_type_by_pk?: Maybe<Client_File_Type>
  /** fetch data from the table: "client_phone_number" */
  client_phone_number: Array<Client_Phone_Number>
  /** fetch aggregated fields from the table: "client_phone_number" */
  client_phone_number_aggregate: Client_Phone_Number_Aggregate
  /** fetch data from the table: "client_phone_number" using primary key columns */
  client_phone_number_by_pk?: Maybe<Client_Phone_Number>
  /** fetch data from the table: "currency" */
  currency: Array<Currency>
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>
  /** fetch data from the table: "document_template" */
  document_template: Array<Document_Template>
  /** fetch aggregated fields from the table: "document_template" */
  document_template_aggregate: Document_Template_Aggregate
  /** fetch data from the table: "document_template" using primary key columns */
  document_template_by_pk?: Maybe<Document_Template>
  /** fetch data from the table: "document_template_type" */
  document_template_type: Array<Document_Template_Type>
  /** fetch aggregated fields from the table: "document_template_type" */
  document_template_type_aggregate: Document_Template_Type_Aggregate
  /** fetch data from the table: "document_template_type" using primary key columns */
  document_template_type_by_pk?: Maybe<Document_Template_Type>
  /** fetch data from the table: "loan" */
  loan: Array<Loan>
  /** fetch aggregated fields from the table: "loan" */
  loan_aggregate: Loan_Aggregate
  /** fetch data from the table: "loan" using primary key columns */
  loan_by_pk?: Maybe<Loan>
  /** fetch data from the table: "loan_periodicity" */
  loan_periodicity: Array<Loan_Periodicity>
  /** fetch aggregated fields from the table: "loan_periodicity" */
  loan_periodicity_aggregate: Loan_Periodicity_Aggregate
  /** fetch data from the table: "loan_periodicity" using primary key columns */
  loan_periodicity_by_pk?: Maybe<Loan_Periodicity>
  /** fetch data from the table: "loan_status" */
  loan_status: Array<Loan_Status>
  /** fetch aggregated fields from the table: "loan_status" */
  loan_status_aggregate: Loan_Status_Aggregate
  /** fetch data from the table: "loan_status" using primary key columns */
  loan_status_by_pk?: Maybe<Loan_Status>
  /** fetch data from the table: "loan_type" */
  loan_type: Array<Loan_Type>
  /** fetch aggregated fields from the table: "loan_type" */
  loan_type_aggregate: Loan_Type_Aggregate
  /** fetch data from the table: "loan_type" using primary key columns */
  loan_type_by_pk?: Maybe<Loan_Type>
  /** fetch data from the table: "location" */
  location: Array<Location>
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>
  /** fetch data from the table: "location_payment_method" */
  location_payment_method: Array<Location_Payment_Method>
  /** fetch aggregated fields from the table: "location_payment_method" */
  location_payment_method_aggregate: Location_Payment_Method_Aggregate
  /** fetch data from the table: "location_payment_method" using primary key columns */
  location_payment_method_by_pk?: Maybe<Location_Payment_Method>
  /** fetch data from the table: "payment" */
  payment: Array<Payment>
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>
  /** fetch data from the table: "payment_type" */
  payment_type: Array<Payment_Type>
  /** fetch aggregated fields from the table: "payment_type" */
  payment_type_aggregate: Payment_Type_Aggregate
  /** fetch data from the table: "payment_type" using primary key columns */
  payment_type_by_pk?: Maybe<Payment_Type>
  /** fetch data from the table: "phone_number_type" */
  phone_number_type: Array<Phone_Number_Type>
  /** fetch aggregated fields from the table: "phone_number_type" */
  phone_number_type_aggregate: Phone_Number_Type_Aggregate
  /** fetch data from the table: "phone_number_type" using primary key columns */
  phone_number_type_by_pk?: Maybe<Phone_Number_Type>
  /** fetch data from the table: "subscription_type" */
  subscription_type: Array<Subscription_Type>
  /** fetch aggregated fields from the table: "subscription_type" */
  subscription_type_aggregate: Subscription_Type_Aggregate
  /** fetch data from the table: "subscription_type" using primary key columns */
  subscription_type_by_pk?: Maybe<Subscription_Type>
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_loan_aggregation" */
  user_loan_aggregation: Array<User_Loan_Aggregation>
  /** fetch aggregated fields from the table: "user_loan_aggregation" */
  user_loan_aggregation_aggregate: User_Loan_Aggregation_Aggregate
  /** fetch data from the table: "user_location" */
  user_location: Array<User_Location>
  /** fetch aggregated fields from the table: "user_location" */
  user_location_aggregate: User_Location_Aggregate
  /** fetch data from the table: "user_location" using primary key columns */
  user_location_by_pk?: Maybe<User_Location>
  /** fetch data from the table: "user_payment_aggregation" */
  user_payment_aggregation: Array<User_Payment_Aggregation>
  /** fetch aggregated fields from the table: "user_payment_aggregation" */
  user_payment_aggregation_aggregate: User_Payment_Aggregation_Aggregate
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>
  /** fetch data from the table: "user_tenant" */
  user_tenant: Array<User_Tenant>
  /** fetch aggregated fields from the table: "user_tenant" */
  user_tenant_aggregate: User_Tenant_Aggregate
  /** fetch data from the table: "user_tenant" using primary key columns */
  user_tenant_by_pk?: Maybe<User_Tenant>
}

export type Query_RootAddress_TypeArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Address_Type_Order_By>>
  where?: InputMaybe<Address_Type_Bool_Exp>
}

export type Query_RootAddress_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Address_Type_Order_By>>
  where?: InputMaybe<Address_Type_Bool_Exp>
}

export type Query_RootAddress_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
}

export type Query_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
}

export type Query_RootAudit_Logged_Actions_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Order_By>>
  where?: InputMaybe<Client_Bool_Exp>
}

export type Query_RootClient_AddressArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

export type Query_RootClient_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

export type Query_RootClient_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Order_By>>
  where?: InputMaybe<Client_Bool_Exp>
}

export type Query_RootClient_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootClient_FileArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

export type Query_RootClient_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

export type Query_RootClient_File_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootClient_File_TypeArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Type_Order_By>>
  where?: InputMaybe<Client_File_Type_Bool_Exp>
}

export type Query_RootClient_File_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Type_Order_By>>
  where?: InputMaybe<Client_File_Type_Bool_Exp>
}

export type Query_RootClient_File_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootClient_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

export type Query_RootClient_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

export type Query_RootClient_Phone_Number_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Currency_Order_By>>
  where?: InputMaybe<Currency_Bool_Exp>
}

export type Query_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Currency_Order_By>>
  where?: InputMaybe<Currency_Bool_Exp>
}

export type Query_RootCurrency_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootDocument_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

export type Query_RootDocument_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

export type Query_RootDocument_Template_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootDocument_Template_TypeArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Type_Order_By>>
  where?: InputMaybe<Document_Template_Type_Bool_Exp>
}

export type Query_RootDocument_Template_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Type_Order_By>>
  where?: InputMaybe<Document_Template_Type_Bool_Exp>
}

export type Query_RootDocument_Template_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootLoanArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

export type Query_RootLoan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

export type Query_RootLoan_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootLoan_PeriodicityArgs = {
  distinct_on?: InputMaybe<Array<Loan_Periodicity_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Periodicity_Order_By>>
  where?: InputMaybe<Loan_Periodicity_Bool_Exp>
}

export type Query_RootLoan_Periodicity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Periodicity_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Periodicity_Order_By>>
  where?: InputMaybe<Loan_Periodicity_Bool_Exp>
}

export type Query_RootLoan_Periodicity_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootLoan_StatusArgs = {
  distinct_on?: InputMaybe<Array<Loan_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Status_Order_By>>
  where?: InputMaybe<Loan_Status_Bool_Exp>
}

export type Query_RootLoan_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Status_Order_By>>
  where?: InputMaybe<Loan_Status_Bool_Exp>
}

export type Query_RootLoan_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootLoan_TypeArgs = {
  distinct_on?: InputMaybe<Array<Loan_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Type_Order_By>>
  where?: InputMaybe<Loan_Type_Bool_Exp>
}

export type Query_RootLoan_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Type_Order_By>>
  where?: InputMaybe<Loan_Type_Bool_Exp>
}

export type Query_RootLoan_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Query_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Query_RootLocation_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootLocation_Payment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

export type Query_RootLocation_Payment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

export type Query_RootLocation_Payment_Method_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

export type Query_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

export type Query_RootPayment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Query_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Query_RootPayment_Method_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootPayment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>
  where?: InputMaybe<Payment_Type_Bool_Exp>
}

export type Query_RootPayment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>
  where?: InputMaybe<Payment_Type_Bool_Exp>
}

export type Query_RootPayment_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootPhone_Number_TypeArgs = {
  distinct_on?: InputMaybe<Array<Phone_Number_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Phone_Number_Type_Order_By>>
  where?: InputMaybe<Phone_Number_Type_Bool_Exp>
}

export type Query_RootPhone_Number_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phone_Number_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Phone_Number_Type_Order_By>>
  where?: InputMaybe<Phone_Number_Type_Bool_Exp>
}

export type Query_RootPhone_Number_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootSubscription_TypeArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subscription_Type_Order_By>>
  where?: InputMaybe<Subscription_Type_Bool_Exp>
}

export type Query_RootSubscription_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subscription_Type_Order_By>>
  where?: InputMaybe<Subscription_Type_Bool_Exp>
}

export type Query_RootSubscription_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Query_RootTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Query_RootTenant_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Query_RootUser_Loan_AggregationArgs = {
  distinct_on?: InputMaybe<Array<User_Loan_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Loan_Aggregation_Order_By>>
  where?: InputMaybe<User_Loan_Aggregation_Bool_Exp>
}

export type Query_RootUser_Loan_Aggregation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Loan_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Loan_Aggregation_Order_By>>
  where?: InputMaybe<User_Loan_Aggregation_Bool_Exp>
}

export type Query_RootUser_LocationArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Query_RootUser_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Query_RootUser_Location_By_PkArgs = {
  location_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

export type Query_RootUser_Payment_AggregationArgs = {
  distinct_on?: InputMaybe<Array<User_Payment_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Payment_Aggregation_Order_By>>
  where?: InputMaybe<User_Payment_Aggregation_Bool_Exp>
}

export type Query_RootUser_Payment_Aggregation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Payment_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Payment_Aggregation_Order_By>>
  where?: InputMaybe<User_Payment_Aggregation_Bool_Exp>
}

export type Query_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Query_RootUser_Role_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootUser_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Status_Order_By>>
  where?: InputMaybe<User_Status_Bool_Exp>
}

export type Query_RootUser_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Status_Order_By>>
  where?: InputMaybe<User_Status_Bool_Exp>
}

export type Query_RootUser_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Query_RootUser_TenantArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

export type Query_RootUser_Tenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

export type Query_RootUser_Tenant_By_PkArgs = {
  tenant_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float']['input']
  from: Scalars['geography']['input']
  use_spheroid?: InputMaybe<Scalars['Boolean']['input']>
}

export type St_D_Within_Input = {
  distance: Scalars['Float']['input']
  from: Scalars['geometry']['input']
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "address_type" */
  address_type: Array<Address_Type>
  /** fetch aggregated fields from the table: "address_type" */
  address_type_aggregate: Address_Type_Aggregate
  /** fetch data from the table: "address_type" using primary key columns */
  address_type_by_pk?: Maybe<Address_Type>
  /** fetch data from the table in a streaming manner: "address_type" */
  address_type_stream: Array<Address_Type>
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>
  /** fetch data from the table in a streaming manner: "audit.logged_actions" */
  audit_logged_actions_stream: Array<Audit_Logged_Actions>
  /** fetch data from the table: "client" */
  client: Array<Client>
  /** fetch data from the table: "client_address" */
  client_address: Array<Client_Address>
  /** fetch aggregated fields from the table: "client_address" */
  client_address_aggregate: Client_Address_Aggregate
  /** fetch data from the table: "client_address" using primary key columns */
  client_address_by_pk?: Maybe<Client_Address>
  /** fetch data from the table in a streaming manner: "client_address" */
  client_address_stream: Array<Client_Address>
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>
  /** fetch data from the table: "client_file" */
  client_file: Array<Client_File>
  /** fetch aggregated fields from the table: "client_file" */
  client_file_aggregate: Client_File_Aggregate
  /** fetch data from the table: "client_file" using primary key columns */
  client_file_by_pk?: Maybe<Client_File>
  /** fetch data from the table in a streaming manner: "client_file" */
  client_file_stream: Array<Client_File>
  /** fetch data from the table: "client_file_type" */
  client_file_type: Array<Client_File_Type>
  /** fetch aggregated fields from the table: "client_file_type" */
  client_file_type_aggregate: Client_File_Type_Aggregate
  /** fetch data from the table: "client_file_type" using primary key columns */
  client_file_type_by_pk?: Maybe<Client_File_Type>
  /** fetch data from the table in a streaming manner: "client_file_type" */
  client_file_type_stream: Array<Client_File_Type>
  /** fetch data from the table: "client_phone_number" */
  client_phone_number: Array<Client_Phone_Number>
  /** fetch aggregated fields from the table: "client_phone_number" */
  client_phone_number_aggregate: Client_Phone_Number_Aggregate
  /** fetch data from the table: "client_phone_number" using primary key columns */
  client_phone_number_by_pk?: Maybe<Client_Phone_Number>
  /** fetch data from the table in a streaming manner: "client_phone_number" */
  client_phone_number_stream: Array<Client_Phone_Number>
  /** fetch data from the table in a streaming manner: "client" */
  client_stream: Array<Client>
  /** fetch data from the table: "currency" */
  currency: Array<Currency>
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: Currency_Aggregate
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<Currency>
  /** fetch data from the table in a streaming manner: "currency" */
  currency_stream: Array<Currency>
  /** fetch data from the table: "document_template" */
  document_template: Array<Document_Template>
  /** fetch aggregated fields from the table: "document_template" */
  document_template_aggregate: Document_Template_Aggregate
  /** fetch data from the table: "document_template" using primary key columns */
  document_template_by_pk?: Maybe<Document_Template>
  /** fetch data from the table in a streaming manner: "document_template" */
  document_template_stream: Array<Document_Template>
  /** fetch data from the table: "document_template_type" */
  document_template_type: Array<Document_Template_Type>
  /** fetch aggregated fields from the table: "document_template_type" */
  document_template_type_aggregate: Document_Template_Type_Aggregate
  /** fetch data from the table: "document_template_type" using primary key columns */
  document_template_type_by_pk?: Maybe<Document_Template_Type>
  /** fetch data from the table in a streaming manner: "document_template_type" */
  document_template_type_stream: Array<Document_Template_Type>
  /** fetch data from the table: "loan" */
  loan: Array<Loan>
  /** fetch aggregated fields from the table: "loan" */
  loan_aggregate: Loan_Aggregate
  /** fetch data from the table: "loan" using primary key columns */
  loan_by_pk?: Maybe<Loan>
  /** fetch data from the table: "loan_periodicity" */
  loan_periodicity: Array<Loan_Periodicity>
  /** fetch aggregated fields from the table: "loan_periodicity" */
  loan_periodicity_aggregate: Loan_Periodicity_Aggregate
  /** fetch data from the table: "loan_periodicity" using primary key columns */
  loan_periodicity_by_pk?: Maybe<Loan_Periodicity>
  /** fetch data from the table in a streaming manner: "loan_periodicity" */
  loan_periodicity_stream: Array<Loan_Periodicity>
  /** fetch data from the table: "loan_status" */
  loan_status: Array<Loan_Status>
  /** fetch aggregated fields from the table: "loan_status" */
  loan_status_aggregate: Loan_Status_Aggregate
  /** fetch data from the table: "loan_status" using primary key columns */
  loan_status_by_pk?: Maybe<Loan_Status>
  /** fetch data from the table in a streaming manner: "loan_status" */
  loan_status_stream: Array<Loan_Status>
  /** fetch data from the table in a streaming manner: "loan" */
  loan_stream: Array<Loan>
  /** fetch data from the table: "loan_type" */
  loan_type: Array<Loan_Type>
  /** fetch aggregated fields from the table: "loan_type" */
  loan_type_aggregate: Loan_Type_Aggregate
  /** fetch data from the table: "loan_type" using primary key columns */
  loan_type_by_pk?: Maybe<Loan_Type>
  /** fetch data from the table in a streaming manner: "loan_type" */
  loan_type_stream: Array<Loan_Type>
  /** fetch data from the table: "location" */
  location: Array<Location>
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>
  /** fetch data from the table: "location_payment_method" */
  location_payment_method: Array<Location_Payment_Method>
  /** fetch aggregated fields from the table: "location_payment_method" */
  location_payment_method_aggregate: Location_Payment_Method_Aggregate
  /** fetch data from the table: "location_payment_method" using primary key columns */
  location_payment_method_by_pk?: Maybe<Location_Payment_Method>
  /** fetch data from the table in a streaming manner: "location_payment_method" */
  location_payment_method_stream: Array<Location_Payment_Method>
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>
  /** fetch data from the table: "payment" */
  payment: Array<Payment>
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>
  /** fetch data from the table in a streaming manner: "payment_method" */
  payment_method_stream: Array<Payment_Method>
  /** fetch data from the table in a streaming manner: "payment" */
  payment_stream: Array<Payment>
  /** fetch data from the table: "payment_type" */
  payment_type: Array<Payment_Type>
  /** fetch aggregated fields from the table: "payment_type" */
  payment_type_aggregate: Payment_Type_Aggregate
  /** fetch data from the table: "payment_type" using primary key columns */
  payment_type_by_pk?: Maybe<Payment_Type>
  /** fetch data from the table in a streaming manner: "payment_type" */
  payment_type_stream: Array<Payment_Type>
  /** fetch data from the table: "phone_number_type" */
  phone_number_type: Array<Phone_Number_Type>
  /** fetch aggregated fields from the table: "phone_number_type" */
  phone_number_type_aggregate: Phone_Number_Type_Aggregate
  /** fetch data from the table: "phone_number_type" using primary key columns */
  phone_number_type_by_pk?: Maybe<Phone_Number_Type>
  /** fetch data from the table in a streaming manner: "phone_number_type" */
  phone_number_type_stream: Array<Phone_Number_Type>
  /** fetch data from the table: "subscription_type" */
  subscription_type: Array<Subscription_Type>
  /** fetch aggregated fields from the table: "subscription_type" */
  subscription_type_aggregate: Subscription_Type_Aggregate
  /** fetch data from the table: "subscription_type" using primary key columns */
  subscription_type_by_pk?: Maybe<Subscription_Type>
  /** fetch data from the table in a streaming manner: "subscription_type" */
  subscription_type_stream: Array<Subscription_Type>
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>
  /** fetch data from the table in a streaming manner: "tenant" */
  tenant_stream: Array<Tenant>
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_loan_aggregation" */
  user_loan_aggregation: Array<User_Loan_Aggregation>
  /** fetch aggregated fields from the table: "user_loan_aggregation" */
  user_loan_aggregation_aggregate: User_Loan_Aggregation_Aggregate
  /** fetch data from the table in a streaming manner: "user_loan_aggregation" */
  user_loan_aggregation_stream: Array<User_Loan_Aggregation>
  /** fetch data from the table: "user_location" */
  user_location: Array<User_Location>
  /** fetch aggregated fields from the table: "user_location" */
  user_location_aggregate: User_Location_Aggregate
  /** fetch data from the table: "user_location" using primary key columns */
  user_location_by_pk?: Maybe<User_Location>
  /** fetch data from the table in a streaming manner: "user_location" */
  user_location_stream: Array<User_Location>
  /** fetch data from the table: "user_payment_aggregation" */
  user_payment_aggregation: Array<User_Payment_Aggregation>
  /** fetch aggregated fields from the table: "user_payment_aggregation" */
  user_payment_aggregation_aggregate: User_Payment_Aggregation_Aggregate
  /** fetch data from the table in a streaming manner: "user_payment_aggregation" */
  user_payment_aggregation_stream: Array<User_Payment_Aggregation>
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<User_Role>
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>
  /** fetch data from the table in a streaming manner: "user_status" */
  user_status_stream: Array<User_Status>
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>
  /** fetch data from the table: "user_tenant" */
  user_tenant: Array<User_Tenant>
  /** fetch aggregated fields from the table: "user_tenant" */
  user_tenant_aggregate: User_Tenant_Aggregate
  /** fetch data from the table: "user_tenant" using primary key columns */
  user_tenant_by_pk?: Maybe<User_Tenant>
  /** fetch data from the table in a streaming manner: "user_tenant" */
  user_tenant_stream: Array<User_Tenant>
}

export type Subscription_RootAddress_TypeArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Address_Type_Order_By>>
  where?: InputMaybe<Address_Type_Bool_Exp>
}

export type Subscription_RootAddress_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Address_Type_Order_By>>
  where?: InputMaybe<Address_Type_Bool_Exp>
}

export type Subscription_RootAddress_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootAddress_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Address_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Address_Type_Bool_Exp>
}

export type Subscription_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
}

export type Subscription_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
}

export type Subscription_RootAudit_Logged_Actions_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootAudit_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Audit_Logged_Actions_Stream_Cursor_Input>>
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
}

export type Subscription_RootClientArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Order_By>>
  where?: InputMaybe<Client_Bool_Exp>
}

export type Subscription_RootClient_AddressArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

export type Subscription_RootClient_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Address_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Address_Order_By>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

export type Subscription_RootClient_Address_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootClient_Address_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Client_Address_Stream_Cursor_Input>>
  where?: InputMaybe<Client_Address_Bool_Exp>
}

export type Subscription_RootClient_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Order_By>>
  where?: InputMaybe<Client_Bool_Exp>
}

export type Subscription_RootClient_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootClient_FileArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

export type Subscription_RootClient_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Order_By>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

export type Subscription_RootClient_File_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootClient_File_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Client_File_Stream_Cursor_Input>>
  where?: InputMaybe<Client_File_Bool_Exp>
}

export type Subscription_RootClient_File_TypeArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Type_Order_By>>
  where?: InputMaybe<Client_File_Type_Bool_Exp>
}

export type Subscription_RootClient_File_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_File_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_File_Type_Order_By>>
  where?: InputMaybe<Client_File_Type_Bool_Exp>
}

export type Subscription_RootClient_File_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootClient_File_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Client_File_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Client_File_Type_Bool_Exp>
}

export type Subscription_RootClient_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

export type Subscription_RootClient_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Phone_Number_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Client_Phone_Number_Order_By>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

export type Subscription_RootClient_Phone_Number_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootClient_Phone_Number_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Client_Phone_Number_Stream_Cursor_Input>>
  where?: InputMaybe<Client_Phone_Number_Bool_Exp>
}

export type Subscription_RootClient_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Client_Stream_Cursor_Input>>
  where?: InputMaybe<Client_Bool_Exp>
}

export type Subscription_RootCurrencyArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Currency_Order_By>>
  where?: InputMaybe<Currency_Bool_Exp>
}

export type Subscription_RootCurrency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Currency_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Currency_Order_By>>
  where?: InputMaybe<Currency_Bool_Exp>
}

export type Subscription_RootCurrency_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootCurrency_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Currency_Stream_Cursor_Input>>
  where?: InputMaybe<Currency_Bool_Exp>
}

export type Subscription_RootDocument_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

export type Subscription_RootDocument_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Order_By>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

export type Subscription_RootDocument_Template_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootDocument_Template_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Document_Template_Stream_Cursor_Input>>
  where?: InputMaybe<Document_Template_Bool_Exp>
}

export type Subscription_RootDocument_Template_TypeArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Type_Order_By>>
  where?: InputMaybe<Document_Template_Type_Bool_Exp>
}

export type Subscription_RootDocument_Template_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Template_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Document_Template_Type_Order_By>>
  where?: InputMaybe<Document_Template_Type_Bool_Exp>
}

export type Subscription_RootDocument_Template_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootDocument_Template_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Document_Template_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Document_Template_Type_Bool_Exp>
}

export type Subscription_RootLoanArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

export type Subscription_RootLoan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

export type Subscription_RootLoan_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootLoan_PeriodicityArgs = {
  distinct_on?: InputMaybe<Array<Loan_Periodicity_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Periodicity_Order_By>>
  where?: InputMaybe<Loan_Periodicity_Bool_Exp>
}

export type Subscription_RootLoan_Periodicity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Periodicity_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Periodicity_Order_By>>
  where?: InputMaybe<Loan_Periodicity_Bool_Exp>
}

export type Subscription_RootLoan_Periodicity_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootLoan_Periodicity_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Loan_Periodicity_Stream_Cursor_Input>>
  where?: InputMaybe<Loan_Periodicity_Bool_Exp>
}

export type Subscription_RootLoan_StatusArgs = {
  distinct_on?: InputMaybe<Array<Loan_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Status_Order_By>>
  where?: InputMaybe<Loan_Status_Bool_Exp>
}

export type Subscription_RootLoan_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Status_Order_By>>
  where?: InputMaybe<Loan_Status_Bool_Exp>
}

export type Subscription_RootLoan_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootLoan_Status_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Loan_Status_Stream_Cursor_Input>>
  where?: InputMaybe<Loan_Status_Bool_Exp>
}

export type Subscription_RootLoan_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Loan_Stream_Cursor_Input>>
  where?: InputMaybe<Loan_Bool_Exp>
}

export type Subscription_RootLoan_TypeArgs = {
  distinct_on?: InputMaybe<Array<Loan_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Type_Order_By>>
  where?: InputMaybe<Loan_Type_Bool_Exp>
}

export type Subscription_RootLoan_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Type_Order_By>>
  where?: InputMaybe<Loan_Type_Bool_Exp>
}

export type Subscription_RootLoan_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootLoan_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Loan_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Loan_Type_Bool_Exp>
}

export type Subscription_RootLocationArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Subscription_RootLocation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Subscription_RootLocation_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootLocation_Payment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

export type Subscription_RootLocation_Payment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Payment_Method_Order_By>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

export type Subscription_RootLocation_Payment_Method_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootLocation_Payment_Method_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Location_Payment_Method_Stream_Cursor_Input>>
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}

export type Subscription_RootLocation_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Location_Stream_Cursor_Input>>
  where?: InputMaybe<Location_Bool_Exp>
}

export type Subscription_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Subscription_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Subscription_RootPayment_Method_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootPayment_Method_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Payment_Method_Stream_Cursor_Input>>
  where?: InputMaybe<Payment_Method_Bool_Exp>
}

export type Subscription_RootPayment_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Payment_Stream_Cursor_Input>>
  where?: InputMaybe<Payment_Bool_Exp>
}

export type Subscription_RootPayment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>
  where?: InputMaybe<Payment_Type_Bool_Exp>
}

export type Subscription_RootPayment_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Type_Order_By>>
  where?: InputMaybe<Payment_Type_Bool_Exp>
}

export type Subscription_RootPayment_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootPayment_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Payment_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Payment_Type_Bool_Exp>
}

export type Subscription_RootPhone_Number_TypeArgs = {
  distinct_on?: InputMaybe<Array<Phone_Number_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Phone_Number_Type_Order_By>>
  where?: InputMaybe<Phone_Number_Type_Bool_Exp>
}

export type Subscription_RootPhone_Number_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Phone_Number_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Phone_Number_Type_Order_By>>
  where?: InputMaybe<Phone_Number_Type_Bool_Exp>
}

export type Subscription_RootPhone_Number_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootPhone_Number_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Phone_Number_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Phone_Number_Type_Bool_Exp>
}

export type Subscription_RootSubscription_TypeArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subscription_Type_Order_By>>
  where?: InputMaybe<Subscription_Type_Bool_Exp>
}

export type Subscription_RootSubscription_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Type_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Subscription_Type_Order_By>>
  where?: InputMaybe<Subscription_Type_Bool_Exp>
}

export type Subscription_RootSubscription_Type_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootSubscription_Type_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Subscription_Type_Stream_Cursor_Input>>
  where?: InputMaybe<Subscription_Type_Bool_Exp>
}

export type Subscription_RootTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Subscription_RootTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Tenant_Order_By>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Subscription_RootTenant_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootTenant_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<Tenant_Stream_Cursor_Input>>
  where?: InputMaybe<Tenant_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Loan_AggregationArgs = {
  distinct_on?: InputMaybe<Array<User_Loan_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Loan_Aggregation_Order_By>>
  where?: InputMaybe<User_Loan_Aggregation_Bool_Exp>
}

export type Subscription_RootUser_Loan_Aggregation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Loan_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Loan_Aggregation_Order_By>>
  where?: InputMaybe<User_Loan_Aggregation_Bool_Exp>
}

export type Subscription_RootUser_Loan_Aggregation_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Loan_Aggregation_Stream_Cursor_Input>>
  where?: InputMaybe<User_Loan_Aggregation_Bool_Exp>
}

export type Subscription_RootUser_LocationArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Subscription_RootUser_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Subscription_RootUser_Location_By_PkArgs = {
  location_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Location_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Location_Stream_Cursor_Input>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

export type Subscription_RootUser_Payment_AggregationArgs = {
  distinct_on?: InputMaybe<Array<User_Payment_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Payment_Aggregation_Order_By>>
  where?: InputMaybe<User_Payment_Aggregation_Bool_Exp>
}

export type Subscription_RootUser_Payment_Aggregation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Payment_Aggregation_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Payment_Aggregation_Order_By>>
  where?: InputMaybe<User_Payment_Aggregation_Bool_Exp>
}

export type Subscription_RootUser_Payment_Aggregation_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Payment_Aggregation_Stream_Cursor_Input>>
  where?: InputMaybe<User_Payment_Aggregation_Bool_Exp>
}

export type Subscription_RootUser_RoleArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Role_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Role_Order_By>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_Role_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootUser_Role_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Role_Stream_Cursor_Input>>
  where?: InputMaybe<User_Role_Bool_Exp>
}

export type Subscription_RootUser_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Status_Order_By>>
  where?: InputMaybe<User_Status_Bool_Exp>
}

export type Subscription_RootUser_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Status_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Status_Order_By>>
  where?: InputMaybe<User_Status_Bool_Exp>
}

export type Subscription_RootUser_Status_By_PkArgs = {
  value: Scalars['String']['input']
}

export type Subscription_RootUser_Status_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Status_Stream_Cursor_Input>>
  where?: InputMaybe<User_Status_Bool_Exp>
}

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>
  where?: InputMaybe<User_Bool_Exp>
}

export type Subscription_RootUser_TenantArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

export type Subscription_RootUser_Tenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

export type Subscription_RootUser_Tenant_By_PkArgs = {
  tenant_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

export type Subscription_RootUser_Tenant_StreamArgs = {
  batch_size: Scalars['Int']['input']
  cursor: Array<InputMaybe<User_Tenant_Stream_Cursor_Input>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

/** columns and relationships of "subscription_type" */
export type Subscription_Type = {
  __typename?: 'subscription_type'
  comment: Scalars['String']['output']
  /** An array relationship */
  locations: Array<Location>
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "subscription_type" */
export type Subscription_TypeLocationsArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

/** columns and relationships of "subscription_type" */
export type Subscription_TypeLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

/** aggregated selection of "subscription_type" */
export type Subscription_Type_Aggregate = {
  __typename?: 'subscription_type_aggregate'
  aggregate?: Maybe<Subscription_Type_Aggregate_Fields>
  nodes: Array<Subscription_Type>
}

/** aggregate fields of "subscription_type" */
export type Subscription_Type_Aggregate_Fields = {
  __typename?: 'subscription_type_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Subscription_Type_Max_Fields>
  min?: Maybe<Subscription_Type_Min_Fields>
}

/** aggregate fields of "subscription_type" */
export type Subscription_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Type_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "subscription_type". All fields are combined with a logical 'AND'. */
export type Subscription_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Type_Bool_Exp>>
  _not?: InputMaybe<Subscription_Type_Bool_Exp>
  _or?: InputMaybe<Array<Subscription_Type_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  locations?: InputMaybe<Location_Bool_Exp>
  locations_aggregate?: InputMaybe<Location_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "subscription_type" */
export enum Subscription_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  SubscriptioTypePkey = 'subscriptio_type_pkey'
}

export enum Subscription_Type_Enum {
  /** ANNUAL */
  Annual = 'ANNUAL',
  /** FREE */
  Free = 'FREE',
  /** MONTHLY */
  Monthly = 'MONTHLY'
}

/** Boolean expression to compare columns of type "subscription_type_enum". All fields are combined with logical 'AND'. */
export type Subscription_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Subscription_Type_Enum>
  _in?: InputMaybe<Array<Subscription_Type_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<Subscription_Type_Enum>
  _nin?: InputMaybe<Array<Subscription_Type_Enum>>
}

/** input type for inserting data into table "subscription_type" */
export type Subscription_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  locations?: InputMaybe<Location_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type Subscription_Type_Max_Fields = {
  __typename?: 'subscription_type_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type Subscription_Type_Min_Fields = {
  __typename?: 'subscription_type_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "subscription_type" */
export type Subscription_Type_Mutation_Response = {
  __typename?: 'subscription_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Type>
}

/** input type for inserting object relation for remote table "subscription_type" */
export type Subscription_Type_Obj_Rel_Insert_Input = {
  data: Subscription_Type_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Type_On_Conflict>
}

/** on_conflict condition type for table "subscription_type" */
export type Subscription_Type_On_Conflict = {
  constraint: Subscription_Type_Constraint
  update_columns?: Array<Subscription_Type_Update_Column>
  where?: InputMaybe<Subscription_Type_Bool_Exp>
}

/** Ordering options when selecting data from "subscription_type". */
export type Subscription_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  locations_aggregate?: InputMaybe<Location_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: subscription_type */
export type Subscription_Type_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "subscription_type" */
export enum Subscription_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "subscription_type" */
export type Subscription_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "subscription_type" */
export type Subscription_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Subscription_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "subscription_type" */
export enum Subscription_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Subscription_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Subscription_Type_Bool_Exp
}

/** columns and relationships of "tenant" */
export type Tenant = {
  __typename?: 'tenant'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id: Scalars['uuid']['output']
  /** An array relationship */
  locations: Array<Location>
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate
  name: Scalars['String']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An array relationship */
  user_tenants: Array<User_Tenant>
  /** An aggregate relationship */
  user_tenants_aggregate: User_Tenant_Aggregate
}

/** columns and relationships of "tenant" */
export type TenantLocationsArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

/** columns and relationships of "tenant" */
export type TenantLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Location_Order_By>>
  where?: InputMaybe<Location_Bool_Exp>
}

/** columns and relationships of "tenant" */
export type TenantUser_TenantsArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

/** columns and relationships of "tenant" */
export type TenantUser_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

/** aggregated selection of "tenant" */
export type Tenant_Aggregate = {
  __typename?: 'tenant_aggregate'
  aggregate?: Maybe<Tenant_Aggregate_Fields>
  nodes: Array<Tenant>
}

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_Fields = {
  __typename?: 'tenant_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<Tenant_Max_Fields>
  min?: Maybe<Tenant_Min_Fields>
}

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "tenant". All fields are combined with a logical 'AND'. */
export type Tenant_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Bool_Exp>>
  _not?: InputMaybe<Tenant_Bool_Exp>
  _or?: InputMaybe<Array<Tenant_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  locations?: InputMaybe<Location_Bool_Exp>
  locations_aggregate?: InputMaybe<Location_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_tenants?: InputMaybe<User_Tenant_Bool_Exp>
  user_tenants_aggregate?: InputMaybe<User_Tenant_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "tenant" */
export enum Tenant_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantPkey = 'tenant_pkey'
}

/** input type for inserting data into table "tenant" */
export type Tenant_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  locations?: InputMaybe<Location_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_tenants?: InputMaybe<User_Tenant_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Tenant_Max_Fields = {
  __typename?: 'tenant_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** aggregate min on columns */
export type Tenant_Min_Fields = {
  __typename?: 'tenant_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  name?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** response of any mutation on the table "tenant" */
export type Tenant_Mutation_Response = {
  __typename?: 'tenant_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<Tenant>
}

/** input type for inserting object relation for remote table "tenant" */
export type Tenant_Obj_Rel_Insert_Input = {
  data: Tenant_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_On_Conflict>
}

/** on_conflict condition type for table "tenant" */
export type Tenant_On_Conflict = {
  constraint: Tenant_Constraint
  update_columns?: Array<Tenant_Update_Column>
  where?: InputMaybe<Tenant_Bool_Exp>
}

/** Ordering options when selecting data from "tenant". */
export type Tenant_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  locations_aggregate?: InputMaybe<Location_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_tenants_aggregate?: InputMaybe<User_Tenant_Aggregate_Order_By>
}

/** primary key columns input for table: tenant */
export type Tenant_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** select columns of table "tenant" */
export enum Tenant_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tenant" */
export type Tenant_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** Streaming cursor of the table "tenant" */
export type Tenant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Tenant_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** update columns of table "tenant" */
export enum Tenant_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tenant_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Set_Input>
  /** filter the rows which have to be updated */
  where: Tenant_Bool_Exp
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>
  _gt?: InputMaybe<Scalars['timestamptz']['input']>
  _gte?: InputMaybe<Scalars['timestamptz']['input']>
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['timestamptz']['input']>
  _lte?: InputMaybe<Scalars['timestamptz']['input']>
  _neq?: InputMaybe<Scalars['timestamptz']['input']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  firebase_id: Scalars['String']['output']
  first_name?: Maybe<Scalars['String']['output']>
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']['output']>
  id: Scalars['uuid']['output']
  id_number?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  /** An array relationship */
  loans: Array<Loan>
  /** An aggregate relationship */
  loans_aggregate: Loan_Aggregate
  /** An array relationship */
  payments: Array<Payment>
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate
  phone_number?: Maybe<Scalars['String']['output']>
  role: User_Role_Enum
  status?: Maybe<User_Status_Enum>
  updated_at: Scalars['timestamptz']['output']
  /** An array relationship */
  user_locations: Array<User_Location>
  /** An aggregate relationship */
  user_locations_aggregate: User_Location_Aggregate
  /** An object relationship */
  user_role: User_Role
  /** An object relationship */
  user_status?: Maybe<User_Status>
  /** An array relationship */
  user_tenants: Array<User_Tenant>
  /** An aggregate relationship */
  user_tenants_aggregate: User_Tenant_Aggregate
}

/** columns and relationships of "user" */
export type UserLoansArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserLoans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Loan_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Loan_Order_By>>
  where?: InputMaybe<Loan_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserPaymentsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserPayments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<Payment_Order_By>>
  where?: InputMaybe<Payment_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_LocationsArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Location_Order_By>>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_TenantsArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tenant_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Tenant_Order_By>>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

export type User_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>
}

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Max_Order_By>
  min?: InputMaybe<User_Min_Order_By>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>
  _not?: InputMaybe<User_Bool_Exp>
  _or?: InputMaybe<Array<User_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  firebase_id?: InputMaybe<String_Comparison_Exp>
  first_name?: InputMaybe<String_Comparison_Exp>
  full_name?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  id_number?: InputMaybe<String_Comparison_Exp>
  last_name?: InputMaybe<String_Comparison_Exp>
  loans?: InputMaybe<Loan_Bool_Exp>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Bool_Exp>
  payments?: InputMaybe<Payment_Bool_Exp>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Bool_Exp>
  phone_number?: InputMaybe<String_Comparison_Exp>
  role?: InputMaybe<User_Role_Enum_Comparison_Exp>
  status?: InputMaybe<User_Status_Enum_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_locations?: InputMaybe<User_Location_Bool_Exp>
  user_locations_aggregate?: InputMaybe<User_Location_Aggregate_Bool_Exp>
  user_role?: InputMaybe<User_Role_Bool_Exp>
  user_status?: InputMaybe<User_Status_Bool_Exp>
  user_tenants?: InputMaybe<User_Tenant_Bool_Exp>
  user_tenants_aggregate?: InputMaybe<User_Tenant_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "firebase_id" */
  UserFirebaseIdKey = 'user_firebase_id_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  firebase_id?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  loans?: InputMaybe<Loan_Arr_Rel_Insert_Input>
  payments?: InputMaybe<Payment_Arr_Rel_Insert_Input>
  phone_number?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<User_Role_Enum>
  status?: InputMaybe<User_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_locations?: InputMaybe<User_Location_Arr_Rel_Insert_Input>
  user_role?: InputMaybe<User_Role_Obj_Rel_Insert_Input>
  user_status?: InputMaybe<User_Status_Obj_Rel_Insert_Input>
  user_tenants?: InputMaybe<User_Tenant_Arr_Rel_Insert_Input>
}

/** columns and relationships of "user_loan_aggregation" */
export type User_Loan_Aggregation = {
  __typename?: 'user_loan_aggregation'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['bigint']['output']>
  /** An object relationship */
  location?: Maybe<Location>
  location_id?: Maybe<Scalars['uuid']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['bigint']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['bigint']['output']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** aggregated selection of "user_loan_aggregation" */
export type User_Loan_Aggregation_Aggregate = {
  __typename?: 'user_loan_aggregation_aggregate'
  aggregate?: Maybe<User_Loan_Aggregation_Aggregate_Fields>
  nodes: Array<User_Loan_Aggregation>
}

/** aggregate fields of "user_loan_aggregation" */
export type User_Loan_Aggregation_Aggregate_Fields = {
  __typename?: 'user_loan_aggregation_aggregate_fields'
  avg?: Maybe<User_Loan_Aggregation_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<User_Loan_Aggregation_Max_Fields>
  min?: Maybe<User_Loan_Aggregation_Min_Fields>
  stddev?: Maybe<User_Loan_Aggregation_Stddev_Fields>
  stddev_pop?: Maybe<User_Loan_Aggregation_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Loan_Aggregation_Stddev_Samp_Fields>
  sum?: Maybe<User_Loan_Aggregation_Sum_Fields>
  var_pop?: Maybe<User_Loan_Aggregation_Var_Pop_Fields>
  var_samp?: Maybe<User_Loan_Aggregation_Var_Samp_Fields>
  variance?: Maybe<User_Loan_Aggregation_Variance_Fields>
}

/** aggregate fields of "user_loan_aggregation" */
export type User_Loan_Aggregation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Loan_Aggregation_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type User_Loan_Aggregation_Avg_Fields = {
  __typename?: 'user_loan_aggregation_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['Float']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['Float']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "user_loan_aggregation". All fields are combined with a logical 'AND'. */
export type User_Loan_Aggregation_Bool_Exp = {
  _and?: InputMaybe<Array<User_Loan_Aggregation_Bool_Exp>>
  _not?: InputMaybe<User_Loan_Aggregation_Bool_Exp>
  _or?: InputMaybe<Array<User_Loan_Aggregation_Bool_Exp>>
  amount?: InputMaybe<Float_Comparison_Exp>
  balance?: InputMaybe<Float_Comparison_Exp>
  commission_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  count?: InputMaybe<Bigint_Comparison_Exp>
  interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  late_amount?: InputMaybe<Float_Comparison_Exp>
  late_balance?: InputMaybe<Float_Comparison_Exp>
  late_count?: InputMaybe<Bigint_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  today_amount?: InputMaybe<Float_Comparison_Exp>
  today_balance?: InputMaybe<Float_Comparison_Exp>
  today_count?: InputMaybe<Bigint_Comparison_Exp>
  up_to_date_amount?: InputMaybe<Float_Comparison_Exp>
  up_to_date_balance?: InputMaybe<Float_Comparison_Exp>
  up_to_date_count?: InputMaybe<Bigint_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** aggregate max on columns */
export type User_Loan_Aggregation_Max_Fields = {
  __typename?: 'user_loan_aggregation_max_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['bigint']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['bigint']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['bigint']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** aggregate min on columns */
export type User_Loan_Aggregation_Min_Fields = {
  __typename?: 'user_loan_aggregation_min_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['bigint']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['bigint']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['bigint']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** Ordering options when selecting data from "user_loan_aggregation". */
export type User_Loan_Aggregation_Order_By = {
  amount?: InputMaybe<Order_By>
  balance?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  count?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  late_amount?: InputMaybe<Order_By>
  late_balance?: InputMaybe<Order_By>
  late_count?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  today_amount?: InputMaybe<Order_By>
  today_balance?: InputMaybe<Order_By>
  today_count?: InputMaybe<Order_By>
  up_to_date_amount?: InputMaybe<Order_By>
  up_to_date_balance?: InputMaybe<Order_By>
  up_to_date_count?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** select columns of table "user_loan_aggregation" */
export enum User_Loan_Aggregation_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Balance = 'balance',
  /** column name */
  CommissionInterestArrearsAmount = 'commission_interest_arrears_amount',
  /** column name */
  Count = 'count',
  /** column name */
  InterestArrearsAmount = 'interest_arrears_amount',
  /** column name */
  LateAmount = 'late_amount',
  /** column name */
  LateBalance = 'late_balance',
  /** column name */
  LateCount = 'late_count',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  TodayAmount = 'today_amount',
  /** column name */
  TodayBalance = 'today_balance',
  /** column name */
  TodayCount = 'today_count',
  /** column name */
  UpToDateAmount = 'up_to_date_amount',
  /** column name */
  UpToDateBalance = 'up_to_date_balance',
  /** column name */
  UpToDateCount = 'up_to_date_count',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type User_Loan_Aggregation_Stddev_Fields = {
  __typename?: 'user_loan_aggregation_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['Float']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['Float']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type User_Loan_Aggregation_Stddev_Pop_Fields = {
  __typename?: 'user_loan_aggregation_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['Float']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['Float']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type User_Loan_Aggregation_Stddev_Samp_Fields = {
  __typename?: 'user_loan_aggregation_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['Float']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['Float']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "user_loan_aggregation" */
export type User_Loan_Aggregation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Loan_Aggregation_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Loan_Aggregation_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  balance?: InputMaybe<Scalars['Float']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  count?: InputMaybe<Scalars['bigint']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  late_amount?: InputMaybe<Scalars['Float']['input']>
  late_balance?: InputMaybe<Scalars['Float']['input']>
  late_count?: InputMaybe<Scalars['bigint']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  today_amount?: InputMaybe<Scalars['Float']['input']>
  today_balance?: InputMaybe<Scalars['Float']['input']>
  today_count?: InputMaybe<Scalars['bigint']['input']>
  up_to_date_amount?: InputMaybe<Scalars['Float']['input']>
  up_to_date_balance?: InputMaybe<Scalars['Float']['input']>
  up_to_date_count?: InputMaybe<Scalars['bigint']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type User_Loan_Aggregation_Sum_Fields = {
  __typename?: 'user_loan_aggregation_sum_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['bigint']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['bigint']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['bigint']['output']>
}

/** aggregate var_pop on columns */
export type User_Loan_Aggregation_Var_Pop_Fields = {
  __typename?: 'user_loan_aggregation_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['Float']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['Float']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type User_Loan_Aggregation_Var_Samp_Fields = {
  __typename?: 'user_loan_aggregation_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['Float']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['Float']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type User_Loan_Aggregation_Variance_Fields = {
  __typename?: 'user_loan_aggregation_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  balance?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  late_amount?: Maybe<Scalars['Float']['output']>
  late_balance?: Maybe<Scalars['Float']['output']>
  late_count?: Maybe<Scalars['Float']['output']>
  today_amount?: Maybe<Scalars['Float']['output']>
  today_balance?: Maybe<Scalars['Float']['output']>
  today_count?: Maybe<Scalars['Float']['output']>
  up_to_date_amount?: Maybe<Scalars['Float']['output']>
  up_to_date_balance?: Maybe<Scalars['Float']['output']>
  up_to_date_count?: Maybe<Scalars['Float']['output']>
}

/** columns and relationships of "user_location" */
export type User_Location = {
  __typename?: 'user_location'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  location: Location
  location_id: Scalars['uuid']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']['output']
}

/** aggregated selection of "user_location" */
export type User_Location_Aggregate = {
  __typename?: 'user_location_aggregate'
  aggregate?: Maybe<User_Location_Aggregate_Fields>
  nodes: Array<User_Location>
}

export type User_Location_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Location_Aggregate_Bool_Exp_Count>
}

export type User_Location_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Location_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_location" */
export type User_Location_Aggregate_Fields = {
  __typename?: 'user_location_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Location_Max_Fields>
  min?: Maybe<User_Location_Min_Fields>
}

/** aggregate fields of "user_location" */
export type User_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Location_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user_location" */
export type User_Location_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Location_Max_Order_By>
  min?: InputMaybe<User_Location_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_location" */
export type User_Location_Arr_Rel_Insert_Input = {
  data: Array<User_Location_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Location_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_location". All fields are combined with a logical 'AND'. */
export type User_Location_Bool_Exp = {
  _and?: InputMaybe<Array<User_Location_Bool_Exp>>
  _not?: InputMaybe<User_Location_Bool_Exp>
  _or?: InputMaybe<Array<User_Location_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_location" */
export enum User_Location_Constraint {
  /** unique or primary key constraint on columns "user_id", "location_id" */
  UserLocationPkey = 'user_location_pkey'
}

/** input type for inserting data into table "user_location" */
export type User_Location_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  location?: InputMaybe<Location_Obj_Rel_Insert_Input>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type User_Location_Max_Fields = {
  __typename?: 'user_location_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "user_location" */
export type User_Location_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Location_Min_Fields = {
  __typename?: 'user_location_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "user_location" */
export type User_Location_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  location_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_location" */
export type User_Location_Mutation_Response = {
  __typename?: 'user_location_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Location>
}

/** on_conflict condition type for table "user_location" */
export type User_Location_On_Conflict = {
  constraint: User_Location_Constraint
  update_columns?: Array<User_Location_Update_Column>
  where?: InputMaybe<User_Location_Bool_Exp>
}

/** Ordering options when selecting data from "user_location". */
export type User_Location_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_location */
export type User_Location_Pk_Columns_Input = {
  location_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

/** select columns of table "user_location" */
export enum User_Location_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_location" */
export type User_Location_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** Streaming cursor of the table "user_location" */
export type User_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Location_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Location_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** update columns of table "user_location" */
export enum User_Location_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Location_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Location_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Location_Bool_Exp
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  firebase_id?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  phone_number?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  phone_number?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  firebase_id?: Maybe<Scalars['String']['output']>
  first_name?: Maybe<Scalars['String']['output']>
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['uuid']['output']>
  id_number?: Maybe<Scalars['String']['output']>
  last_name?: Maybe<Scalars['String']['output']>
  phone_number?: Maybe<Scalars['String']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  phone_number?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>
}

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: InputMaybe<User_Bool_Exp>
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  firebase_id?: InputMaybe<Order_By>
  first_name?: InputMaybe<Order_By>
  full_name?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  id_number?: InputMaybe<Order_By>
  last_name?: InputMaybe<Order_By>
  loans_aggregate?: InputMaybe<Loan_Aggregate_Order_By>
  payments_aggregate?: InputMaybe<Payment_Aggregate_Order_By>
  phone_number?: InputMaybe<Order_By>
  role?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_locations_aggregate?: InputMaybe<User_Location_Aggregate_Order_By>
  user_role?: InputMaybe<User_Role_Order_By>
  user_status?: InputMaybe<User_Status_Order_By>
  user_tenants_aggregate?: InputMaybe<User_Tenant_Aggregate_Order_By>
}

/** columns and relationships of "user_payment_aggregation" */
export type User_Payment_Aggregation = {
  __typename?: 'user_payment_aggregation'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  /** An object relationship */
  location?: Maybe<Location>
  location_id?: Maybe<Scalars['uuid']['output']>
  payment_date?: Maybe<Scalars['timestamptz']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** aggregated selection of "user_payment_aggregation" */
export type User_Payment_Aggregation_Aggregate = {
  __typename?: 'user_payment_aggregation_aggregate'
  aggregate?: Maybe<User_Payment_Aggregation_Aggregate_Fields>
  nodes: Array<User_Payment_Aggregation>
}

/** aggregate fields of "user_payment_aggregation" */
export type User_Payment_Aggregation_Aggregate_Fields = {
  __typename?: 'user_payment_aggregation_aggregate_fields'
  avg?: Maybe<User_Payment_Aggregation_Avg_Fields>
  count: Scalars['Int']['output']
  max?: Maybe<User_Payment_Aggregation_Max_Fields>
  min?: Maybe<User_Payment_Aggregation_Min_Fields>
  stddev?: Maybe<User_Payment_Aggregation_Stddev_Fields>
  stddev_pop?: Maybe<User_Payment_Aggregation_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Payment_Aggregation_Stddev_Samp_Fields>
  sum?: Maybe<User_Payment_Aggregation_Sum_Fields>
  var_pop?: Maybe<User_Payment_Aggregation_Var_Pop_Fields>
  var_samp?: Maybe<User_Payment_Aggregation_Var_Samp_Fields>
  variance?: Maybe<User_Payment_Aggregation_Variance_Fields>
}

/** aggregate fields of "user_payment_aggregation" */
export type User_Payment_Aggregation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Payment_Aggregation_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** aggregate avg on columns */
export type User_Payment_Aggregation_Avg_Fields = {
  __typename?: 'user_payment_aggregation_avg_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** Boolean expression to filter rows from the table "user_payment_aggregation". All fields are combined with a logical 'AND'. */
export type User_Payment_Aggregation_Bool_Exp = {
  _and?: InputMaybe<Array<User_Payment_Aggregation_Bool_Exp>>
  _not?: InputMaybe<User_Payment_Aggregation_Bool_Exp>
  _or?: InputMaybe<Array<User_Payment_Aggregation_Bool_Exp>>
  amount?: InputMaybe<Float_Comparison_Exp>
  capital_amount?: InputMaybe<Float_Comparison_Exp>
  commission_amount?: InputMaybe<Float_Comparison_Exp>
  commission_interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  count?: InputMaybe<Bigint_Comparison_Exp>
  interest_amount?: InputMaybe<Float_Comparison_Exp>
  interest_arrears_amount?: InputMaybe<Float_Comparison_Exp>
  location?: InputMaybe<Location_Bool_Exp>
  location_id?: InputMaybe<Uuid_Comparison_Exp>
  payment_date?: InputMaybe<Timestamptz_Comparison_Exp>
  penalty_amount?: InputMaybe<Float_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** aggregate max on columns */
export type User_Payment_Aggregation_Max_Fields = {
  __typename?: 'user_payment_aggregation_max_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  payment_date?: Maybe<Scalars['timestamptz']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** aggregate min on columns */
export type User_Payment_Aggregation_Min_Fields = {
  __typename?: 'user_payment_aggregation_min_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  location_id?: Maybe<Scalars['uuid']['output']>
  payment_date?: Maybe<Scalars['timestamptz']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** Ordering options when selecting data from "user_payment_aggregation". */
export type User_Payment_Aggregation_Order_By = {
  amount?: InputMaybe<Order_By>
  capital_amount?: InputMaybe<Order_By>
  commission_amount?: InputMaybe<Order_By>
  commission_interest_arrears_amount?: InputMaybe<Order_By>
  count?: InputMaybe<Order_By>
  interest_amount?: InputMaybe<Order_By>
  interest_arrears_amount?: InputMaybe<Order_By>
  location?: InputMaybe<Location_Order_By>
  location_id?: InputMaybe<Order_By>
  payment_date?: InputMaybe<Order_By>
  penalty_amount?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** select columns of table "user_payment_aggregation" */
export enum User_Payment_Aggregation_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CapitalAmount = 'capital_amount',
  /** column name */
  CommissionAmount = 'commission_amount',
  /** column name */
  CommissionInterestArrearsAmount = 'commission_interest_arrears_amount',
  /** column name */
  Count = 'count',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  InterestArrearsAmount = 'interest_arrears_amount',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  PaymentDate = 'payment_date',
  /** column name */
  PenaltyAmount = 'penalty_amount',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type User_Payment_Aggregation_Stddev_Fields = {
  __typename?: 'user_payment_aggregation_stddev_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_pop on columns */
export type User_Payment_Aggregation_Stddev_Pop_Fields = {
  __typename?: 'user_payment_aggregation_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** aggregate stddev_samp on columns */
export type User_Payment_Aggregation_Stddev_Samp_Fields = {
  __typename?: 'user_payment_aggregation_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** Streaming cursor of the table "user_payment_aggregation" */
export type User_Payment_Aggregation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Payment_Aggregation_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Payment_Aggregation_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Float']['input']>
  capital_amount?: InputMaybe<Scalars['Float']['input']>
  commission_amount?: InputMaybe<Scalars['Float']['input']>
  commission_interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  count?: InputMaybe<Scalars['bigint']['input']>
  interest_amount?: InputMaybe<Scalars['Float']['input']>
  interest_arrears_amount?: InputMaybe<Scalars['Float']['input']>
  location_id?: InputMaybe<Scalars['uuid']['input']>
  payment_date?: InputMaybe<Scalars['timestamptz']['input']>
  penalty_amount?: InputMaybe<Scalars['Float']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate sum on columns */
export type User_Payment_Aggregation_Sum_Fields = {
  __typename?: 'user_payment_aggregation_sum_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['bigint']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_pop on columns */
export type User_Payment_Aggregation_Var_Pop_Fields = {
  __typename?: 'user_payment_aggregation_var_pop_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** aggregate var_samp on columns */
export type User_Payment_Aggregation_Var_Samp_Fields = {
  __typename?: 'user_payment_aggregation_var_samp_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** aggregate variance on columns */
export type User_Payment_Aggregation_Variance_Fields = {
  __typename?: 'user_payment_aggregation_variance_fields'
  amount?: Maybe<Scalars['Float']['output']>
  capital_amount?: Maybe<Scalars['Float']['output']>
  commission_amount?: Maybe<Scalars['Float']['output']>
  commission_interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  count?: Maybe<Scalars['Float']['output']>
  interest_amount?: Maybe<Scalars['Float']['output']>
  interest_arrears_amount?: Maybe<Scalars['Float']['output']>
  penalty_amount?: Maybe<Scalars['Float']['output']>
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid']['input']
}

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role'
  comment: Scalars['String']['output']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "user_role" */
export type User_RoleUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "user_role" */
export type User_RoleUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate'
  aggregate?: Maybe<User_Role_Aggregate_Fields>
  nodes: Array<User_Role>
}

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Role_Max_Fields>
  min?: Maybe<User_Role_Min_Fields>
}

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Role_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: InputMaybe<Array<User_Role_Bool_Exp>>
  _not?: InputMaybe<User_Role_Bool_Exp>
  _or?: InputMaybe<Array<User_Role_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserRolePkey = 'user_role_pkey'
}

export enum User_Role_Enum {
  /** ANONYMOUS */
  Anonymous = 'ANONYMOUS',
  /** COLLECTOR */
  Collector = 'COLLECTOR',
  /** LOCATION_ADMIN */
  LocationAdmin = 'LOCATION_ADMIN',
  /** SUPERVISOR */
  Supervisor = 'SUPERVISOR',
  /** SUPER_ADMIN */
  SuperAdmin = 'SUPER_ADMIN',
  /** TENANT_ADMIN */
  TenantAdmin = 'TENANT_ADMIN'
}

/** Boolean expression to compare columns of type "user_role_enum". All fields are combined with logical 'AND'. */
export type User_Role_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Role_Enum>
  _in?: InputMaybe<Array<User_Role_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<User_Role_Enum>
  _nin?: InputMaybe<Array<User_Role_Enum>>
}

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>
}

/** input type for inserting object relation for remote table "user_role" */
export type User_Role_Obj_Rel_Insert_Input = {
  data: User_Role_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Role_On_Conflict>
}

/** on_conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint
  update_columns?: Array<User_Role_Update_Column>
  where?: InputMaybe<User_Role_Bool_Exp>
}

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  comment?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "user_role" */
export type User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Role_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Role_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Role_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Role_Bool_Exp
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  firebase_id?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  phone_number?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<User_Role_Enum>
  status?: InputMaybe<User_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "user_status" */
export type User_Status = {
  __typename?: 'user_status'
  comment: Scalars['String']['output']
  /** An array relationship */
  users: Array<User>
  /** An aggregate relationship */
  users_aggregate: User_Aggregate
  value: Scalars['String']['output']
}

/** columns and relationships of "user_status" */
export type User_StatusUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** columns and relationships of "user_status" */
export type User_StatusUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order_by?: InputMaybe<Array<User_Order_By>>
  where?: InputMaybe<User_Bool_Exp>
}

/** aggregated selection of "user_status" */
export type User_Status_Aggregate = {
  __typename?: 'user_status_aggregate'
  aggregate?: Maybe<User_Status_Aggregate_Fields>
  nodes: Array<User_Status>
}

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_Fields = {
  __typename?: 'user_status_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Status_Max_Fields>
  min?: Maybe<User_Status_Min_Fields>
}

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Status_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** Boolean expression to filter rows from the table "user_status". All fields are combined with a logical 'AND'. */
export type User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<User_Status_Bool_Exp>>
  _not?: InputMaybe<User_Status_Bool_Exp>
  _or?: InputMaybe<Array<User_Status_Bool_Exp>>
  comment?: InputMaybe<String_Comparison_Exp>
  users?: InputMaybe<User_Bool_Exp>
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_status" */
export enum User_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserStatusPkey = 'user_status_pkey'
}

export enum User_Status_Enum {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** BLOCKED */
  Blocked = 'BLOCKED'
}

/** Boolean expression to compare columns of type "user_status_enum". All fields are combined with logical 'AND'. */
export type User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Status_Enum>
  _in?: InputMaybe<Array<User_Status_Enum>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _neq?: InputMaybe<User_Status_Enum>
  _nin?: InputMaybe<Array<User_Status_Enum>>
}

/** input type for inserting data into table "user_status" */
export type User_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  users?: InputMaybe<User_Arr_Rel_Insert_Input>
  value?: InputMaybe<Scalars['String']['input']>
}

/** aggregate max on columns */
export type User_Status_Max_Fields = {
  __typename?: 'user_status_max_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** aggregate min on columns */
export type User_Status_Min_Fields = {
  __typename?: 'user_status_min_fields'
  comment?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

/** response of any mutation on the table "user_status" */
export type User_Status_Mutation_Response = {
  __typename?: 'user_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Status>
}

/** input type for inserting object relation for remote table "user_status" */
export type User_Status_Obj_Rel_Insert_Input = {
  data: User_Status_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<User_Status_On_Conflict>
}

/** on_conflict condition type for table "user_status" */
export type User_Status_On_Conflict = {
  constraint: User_Status_Constraint
  update_columns?: Array<User_Status_Update_Column>
  where?: InputMaybe<User_Status_Bool_Exp>
}

/** Ordering options when selecting data from "user_status". */
export type User_Status_Order_By = {
  comment?: InputMaybe<Order_By>
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_status */
export type User_Status_Pk_Columns_Input = {
  value: Scalars['String']['input']
}

/** select columns of table "user_status" */
export enum User_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_status" */
export type User_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** Streaming cursor of the table "user_status" */
export type User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

/** update columns of table "user_status" */
export enum User_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Status_Bool_Exp
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  firebase_id?: InputMaybe<Scalars['String']['input']>
  first_name?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['uuid']['input']>
  id_number?: InputMaybe<Scalars['String']['input']>
  last_name?: InputMaybe<Scalars['String']['input']>
  phone_number?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<User_Role_Enum>
  status?: InputMaybe<User_Status_Enum>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
}

/** columns and relationships of "user_tenant" */
export type User_Tenant = {
  __typename?: 'user_tenant'
  created_at: Scalars['timestamptz']['output']
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  /** An object relationship */
  tenant: Tenant
  tenant_id: Scalars['uuid']['output']
  updated_at: Scalars['timestamptz']['output']
  /** An object relationship */
  user: User
  user_id: Scalars['uuid']['output']
}

/** aggregated selection of "user_tenant" */
export type User_Tenant_Aggregate = {
  __typename?: 'user_tenant_aggregate'
  aggregate?: Maybe<User_Tenant_Aggregate_Fields>
  nodes: Array<User_Tenant>
}

export type User_Tenant_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Tenant_Aggregate_Bool_Exp_Count>
}

export type User_Tenant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Tenant_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
  filter?: InputMaybe<User_Tenant_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_tenant" */
export type User_Tenant_Aggregate_Fields = {
  __typename?: 'user_tenant_aggregate_fields'
  count: Scalars['Int']['output']
  max?: Maybe<User_Tenant_Max_Fields>
  min?: Maybe<User_Tenant_Min_Fields>
}

/** aggregate fields of "user_tenant" */
export type User_Tenant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Tenant_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']['input']>
}

/** order by aggregate values of table "user_tenant" */
export type User_Tenant_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Tenant_Max_Order_By>
  min?: InputMaybe<User_Tenant_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_tenant" */
export type User_Tenant_Arr_Rel_Insert_Input = {
  data: Array<User_Tenant_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Tenant_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_tenant". All fields are combined with a logical 'AND'. */
export type User_Tenant_Bool_Exp = {
  _and?: InputMaybe<Array<User_Tenant_Bool_Exp>>
  _not?: InputMaybe<User_Tenant_Bool_Exp>
  _or?: InputMaybe<Array<User_Tenant_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>
  tenant?: InputMaybe<Tenant_Bool_Exp>
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user?: InputMaybe<User_Bool_Exp>
  user_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_tenant" */
export enum User_Tenant_Constraint {
  /** unique or primary key constraint on columns "user_id", "tenant_id" */
  UserTenantPkey = 'user_tenant_pkey'
}

/** input type for inserting data into table "user_tenant" */
export type User_Tenant_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>
  tenant_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user?: InputMaybe<User_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** aggregate max on columns */
export type User_Tenant_Max_Fields = {
  __typename?: 'user_tenant_max_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  tenant_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by max() on columns of table "user_tenant" */
export type User_Tenant_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Tenant_Min_Fields = {
  __typename?: 'user_tenant_min_fields'
  created_at?: Maybe<Scalars['timestamptz']['output']>
  deleted_at?: Maybe<Scalars['timestamptz']['output']>
  tenant_id?: Maybe<Scalars['uuid']['output']>
  updated_at?: Maybe<Scalars['timestamptz']['output']>
  user_id?: Maybe<Scalars['uuid']['output']>
}

/** order by min() on columns of table "user_tenant" */
export type User_Tenant_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_tenant" */
export type User_Tenant_Mutation_Response = {
  __typename?: 'user_tenant_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output']
  /** data from the rows affected by the mutation */
  returning: Array<User_Tenant>
}

/** on_conflict condition type for table "user_tenant" */
export type User_Tenant_On_Conflict = {
  constraint: User_Tenant_Constraint
  update_columns?: Array<User_Tenant_Update_Column>
  where?: InputMaybe<User_Tenant_Bool_Exp>
}

/** Ordering options when selecting data from "user_tenant". */
export type User_Tenant_Order_By = {
  created_at?: InputMaybe<Order_By>
  deleted_at?: InputMaybe<Order_By>
  tenant?: InputMaybe<Tenant_Order_By>
  tenant_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user?: InputMaybe<User_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_tenant */
export type User_Tenant_Pk_Columns_Input = {
  tenant_id: Scalars['uuid']['input']
  user_id: Scalars['uuid']['input']
}

/** select columns of table "user_tenant" */
export enum User_Tenant_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_tenant" */
export type User_Tenant_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  tenant_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** Streaming cursor of the table "user_tenant" */
export type User_Tenant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Tenant_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Tenant_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>
  tenant_id?: InputMaybe<Scalars['uuid']['input']>
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>
  user_id?: InputMaybe<Scalars['uuid']['input']>
}

/** update columns of table "user_tenant" */
export enum User_Tenant_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Tenant_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Tenant_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Tenant_Bool_Exp
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Role = 'role',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Bool_Exp
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>
  _gt?: InputMaybe<Scalars['uuid']['input']>
  _gte?: InputMaybe<Scalars['uuid']['input']>
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>
  _is_null?: InputMaybe<Scalars['Boolean']['input']>
  _lt?: InputMaybe<Scalars['uuid']['input']>
  _lte?: InputMaybe<Scalars['uuid']['input']>
  _neq?: InputMaybe<Scalars['uuid']['input']>
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>
}

export type PayLoanMutationVariables = Exact<{
  input: PayLoanInput
}>

export type PayLoanMutation = {
  __typename?: 'mutation_root'
  payLoan?: { __typename?: 'PayLoanOutput'; status: string } | null
}

export type LinkUserMutationVariables = Exact<{
  input: LinkUserInput
}>

export type LinkUserMutation = {
  __typename?: 'mutation_root'
  linkUser?: { __typename?: 'LinkUserOutput'; status: string } | null
}

export type UpdateUserActionMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserActionMutation = {
  __typename?: 'mutation_root'
  updateUser?: { __typename?: 'UpdateUserOutput'; status: string } | null
}

export type AuditLoggedActionFragmentFragment = {
  __typename?: 'audit_logged_actions'
  id: any
  event_id: any
  table_name: string
  action: string
  user_id?: any | null
  transaction_id?: any | null
  row_data?: any | null
  row_id?: any | null
  changed_fields?: any | null
  action_tstamp_tx: any
  user?: { __typename?: 'user'; id: any; full_name?: string | null } | null
}

export type GetAuditLoggedActionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    | Array<Audit_Logged_Actions_Select_Column>
    | Audit_Logged_Actions_Select_Column
  >
  order_by?: InputMaybe<
    Array<Audit_Logged_Actions_Order_By> | Audit_Logged_Actions_Order_By
  >
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>
}>

export type GetAuditLoggedActionsQuery = {
  __typename?: 'query_root'
  auditLoggedActionsAggregate: {
    __typename?: 'audit_logged_actions_aggregate'
    aggregate?: {
      __typename?: 'audit_logged_actions_aggregate_fields'
      totalCount: number
    } | null
  }
  auditLoggedActions: Array<{
    __typename?: 'audit_logged_actions'
    id: any
    event_id: any
    table_name: string
    action: string
    user_id?: any | null
    transaction_id?: any | null
    row_data?: any | null
    row_id?: any | null
    changed_fields?: any | null
    action_tstamp_tx: any
    user?: { __typename?: 'user'; id: any; full_name?: string | null } | null
  }>
}

export type GetAuditLoggedActionQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetAuditLoggedActionQuery = {
  __typename?: 'query_root'
  auditLoggedAction?: {
    __typename?: 'audit_logged_actions'
    id: any
    event_id: any
    table_name: string
    action: string
    user_id?: any | null
    transaction_id?: any | null
    row_data?: any | null
    row_id?: any | null
    changed_fields?: any | null
    action_tstamp_tx: any
    user?: { __typename?: 'user'; id: any; full_name?: string | null } | null
  } | null
}

export type GetChartDictionariesQueryVariables = Exact<{ [key: string]: never }>

export type GetChartDictionariesQuery = {
  __typename?: 'query_root'
  users: Array<{
    __typename?: 'user'
    id: any
    full_name?: string | null
    id_number?: string | null
  }>
  locations: Array<{
    __typename?: 'location'
    id: any
    name: string
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
  }>
}

export type GetChartLocationsQueryVariables = Exact<{ [key: string]: never }>

export type GetChartLocationsQuery = {
  __typename?: 'query_root'
  locations: Array<{
    __typename?: 'location'
    id: any
    name: string
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
  }>
}

export type GetChartsSummaryDataQueryVariables = Exact<{
  clientWhere?: InputMaybe<Client_Bool_Exp>
  loanWhere?: InputMaybe<Loan_Bool_Exp>
  upToDateLoanWhere?: InputMaybe<Loan_Bool_Exp>
  todayLoanWhere?: InputMaybe<Loan_Bool_Exp>
  lateLoanWhere?: InputMaybe<Loan_Bool_Exp>
  paymentWhere?: InputMaybe<Payment_Bool_Exp>
  todayPaymentWhere?: InputMaybe<Payment_Bool_Exp>
  weeklyPaymentWhere?: InputMaybe<Payment_Bool_Exp>
  monthlyPaymentWhere?: InputMaybe<Payment_Bool_Exp>
}>

export type GetChartsSummaryDataQuery = {
  __typename?: 'query_root'
  clientsAggregate: {
    __typename?: 'client_aggregate'
    aggregate?: {
      __typename?: 'client_aggregate_fields'
      totalCount: number
    } | null
  }
  loansAggregate: {
    __typename?: 'loan_aggregate'
    aggregate?: {
      __typename?: 'loan_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'loan_sum_fields'
        amount?: number | null
        balance?: number | null
      } | null
    } | null
  }
  upToDateLoansAggregate: {
    __typename?: 'loan_aggregate'
    aggregate?: {
      __typename?: 'loan_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'loan_sum_fields'
        amount?: number | null
        balance?: number | null
      } | null
    } | null
  }
  todayLoansAggregate: {
    __typename?: 'loan_aggregate'
    aggregate?: {
      __typename?: 'loan_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'loan_sum_fields'
        amount?: number | null
        balance?: number | null
      } | null
    } | null
  }
  lateLoansAggregate: {
    __typename?: 'loan_aggregate'
    aggregate?: {
      __typename?: 'loan_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'loan_sum_fields'
        amount?: number | null
        balance?: number | null
      } | null
    } | null
  }
  paymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        amount?: number | null
        penalty_amount?: number | null
      } | null
    } | null
  }
  todayPaymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        amount?: number | null
        penalty_amount?: number | null
      } | null
    } | null
  }
  weeklyPaymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        amount?: number | null
        penalty_amount?: number | null
      } | null
    } | null
  }
  monthlyPaymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        amount?: number | null
        penalty_amount?: number | null
      } | null
    } | null
  }
}

export type ClientPhoneNumberFragmentFragment = {
  __typename?: 'client_phone_number'
  id: any
  value: string
  description?: string | null
  type: Phone_Number_Type_Enum
  created_at: any
  updated_at: any
}

export type ClientAddressFragmentFragment = {
  __typename?: 'client_address'
  id: any
  value: string
  geolocation?: any | null
  description?: string | null
  type: Address_Type_Enum
  photo_url?: string | null
  updated_at: any
  created_at: any
}

export type ClientFileFragmentFragment = {
  __typename?: 'client_file'
  id: any
  value: string
  type: Client_File_Type_Enum
  description?: string | null
  created_at: any
  updated_at: any
}

export type ClientFragmentFragment = {
  __typename?: 'client'
  id: any
  first_name: string
  last_name: string
  full_name?: string | null
  email: string
  id_number: string
  profession: string
  workplace: string
  created_at: any
  updated_at: any
  deleted_at?: any | null
  location_id: any
  location: { __typename?: 'location'; id: any; name: string }
  addresses: Array<{
    __typename?: 'client_address'
    id: any
    value: string
    geolocation?: any | null
    description?: string | null
    type: Address_Type_Enum
    photo_url?: string | null
    updated_at: any
    created_at: any
  }>
  phone_numbers: Array<{
    __typename?: 'client_phone_number'
    id: any
    value: string
    description?: string | null
    type: Phone_Number_Type_Enum
    created_at: any
    updated_at: any
  }>
  files: Array<{
    __typename?: 'client_file'
    id: any
    value: string
    type: Client_File_Type_Enum
    description?: string | null
    created_at: any
    updated_at: any
  }>
}

export type BaseClientFragmentFragment = {
  __typename?: 'client'
  id: any
  full_name?: string | null
  id_number: string
}

export type GetClientsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Client_Select_Column> | Client_Select_Column>
  order_by?: InputMaybe<Array<Client_Order_By> | Client_Order_By>
  where?: InputMaybe<Client_Bool_Exp>
}>

export type GetClientsQuery = {
  __typename?: 'query_root'
  clientsAggregate: {
    __typename?: 'client_aggregate'
    aggregate?: {
      __typename?: 'client_aggregate_fields'
      totalCount: number
    } | null
  }
  clients: Array<{
    __typename?: 'client'
    id: any
    first_name: string
    last_name: string
    full_name?: string | null
    email: string
    id_number: string
    profession: string
    workplace: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
    addresses: Array<{
      __typename?: 'client_address'
      id: any
      value: string
      geolocation?: any | null
      description?: string | null
      type: Address_Type_Enum
      photo_url?: string | null
      updated_at: any
      created_at: any
    }>
    phone_numbers: Array<{
      __typename?: 'client_phone_number'
      id: any
      value: string
      description?: string | null
      type: Phone_Number_Type_Enum
      created_at: any
      updated_at: any
    }>
    files: Array<{
      __typename?: 'client_file'
      id: any
      value: string
      type: Client_File_Type_Enum
      description?: string | null
      created_at: any
      updated_at: any
    }>
  }>
}

export type GetClientQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetClientQuery = {
  __typename?: 'query_root'
  client?: {
    __typename?: 'client'
    id: any
    first_name: string
    last_name: string
    full_name?: string | null
    email: string
    id_number: string
    profession: string
    workplace: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
    addresses: Array<{
      __typename?: 'client_address'
      id: any
      value: string
      geolocation?: any | null
      description?: string | null
      type: Address_Type_Enum
      photo_url?: string | null
      updated_at: any
      created_at: any
    }>
    phone_numbers: Array<{
      __typename?: 'client_phone_number'
      id: any
      value: string
      description?: string | null
      type: Phone_Number_Type_Enum
      created_at: any
      updated_at: any
    }>
    files: Array<{
      __typename?: 'client_file'
      id: any
      value: string
      type: Client_File_Type_Enum
      description?: string | null
      created_at: any
      updated_at: any
    }>
  } | null
}

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Client_Set_Input
}>

export type UpdateClientMutation = {
  __typename?: 'mutation_root'
  client?: {
    __typename?: 'client'
    id: any
    first_name: string
    last_name: string
    full_name?: string | null
    email: string
    id_number: string
    profession: string
    workplace: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
    addresses: Array<{
      __typename?: 'client_address'
      id: any
      value: string
      geolocation?: any | null
      description?: string | null
      type: Address_Type_Enum
      photo_url?: string | null
      updated_at: any
      created_at: any
    }>
    phone_numbers: Array<{
      __typename?: 'client_phone_number'
      id: any
      value: string
      description?: string | null
      type: Phone_Number_Type_Enum
      created_at: any
      updated_at: any
    }>
    files: Array<{
      __typename?: 'client_file'
      id: any
      value: string
      type: Client_File_Type_Enum
      description?: string | null
      created_at: any
      updated_at: any
    }>
  } | null
}

export type InsertClientMutationVariables = Exact<{
  object: Client_Insert_Input
}>

export type InsertClientMutation = {
  __typename?: 'mutation_root'
  client?: {
    __typename?: 'client'
    id: any
    first_name: string
    last_name: string
    full_name?: string | null
    email: string
    id_number: string
    profession: string
    workplace: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
    addresses: Array<{
      __typename?: 'client_address'
      id: any
      value: string
      geolocation?: any | null
      description?: string | null
      type: Address_Type_Enum
      photo_url?: string | null
      updated_at: any
      created_at: any
    }>
    phone_numbers: Array<{
      __typename?: 'client_phone_number'
      id: any
      value: string
      description?: string | null
      type: Phone_Number_Type_Enum
      created_at: any
      updated_at: any
    }>
    files: Array<{
      __typename?: 'client_file'
      id: any
      value: string
      type: Client_File_Type_Enum
      description?: string | null
      created_at: any
      updated_at: any
    }>
  } | null
}

export type GetClientDictionariesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetClientDictionariesQuery = {
  __typename?: 'query_root'
  locations: Array<{ __typename?: 'location'; id: any; name: string }>
}

export type GetBaseClientsQueryVariables = Exact<{
  order_by?: InputMaybe<Array<Client_Order_By> | Client_Order_By>
  where?: InputMaybe<Client_Bool_Exp>
}>

export type GetBaseClientsQuery = {
  __typename?: 'query_root'
  clients: Array<{
    __typename?: 'client'
    id: any
    full_name?: string | null
    id_number: string
  }>
}

export type UpsertClientMutationVariables = Exact<{
  object: Client_Insert_Input
  on_conflict: Client_On_Conflict
}>

export type UpsertClientMutation = {
  __typename?: 'mutation_root'
  client?: {
    __typename?: 'client'
    id: any
    first_name: string
    last_name: string
    full_name?: string | null
    email: string
    id_number: string
    profession: string
    workplace: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
    addresses: Array<{
      __typename?: 'client_address'
      id: any
      value: string
      geolocation?: any | null
      description?: string | null
      type: Address_Type_Enum
      photo_url?: string | null
      updated_at: any
      created_at: any
    }>
    phone_numbers: Array<{
      __typename?: 'client_phone_number'
      id: any
      value: string
      description?: string | null
      type: Phone_Number_Type_Enum
      created_at: any
      updated_at: any
    }>
    files: Array<{
      __typename?: 'client_file'
      id: any
      value: string
      type: Client_File_Type_Enum
      description?: string | null
      created_at: any
      updated_at: any
    }>
  } | null
}

export type DocumentTemplateFragmentFragment = {
  __typename?: 'document_template'
  id: any
  name: string
  value: string
  type: Document_Template_Type_Enum
  is_active: boolean
  location_id: any
  created_at: any
  updated_at: any
  deleted_at?: any | null
  location: {
    __typename?: 'location'
    id: any
    name: string
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
  }
}

export type GetDocumentTemplatesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    Array<Document_Template_Select_Column> | Document_Template_Select_Column
  >
  order_by?: InputMaybe<
    Array<Document_Template_Order_By> | Document_Template_Order_By
  >
  where?: InputMaybe<Document_Template_Bool_Exp>
}>

export type GetDocumentTemplatesQuery = {
  __typename?: 'query_root'
  documentTemplatesAggregate: {
    __typename?: 'document_template_aggregate'
    aggregate?: {
      __typename?: 'document_template_aggregate_fields'
      totalCount: number
    } | null
  }
  documentTemplates: Array<{
    __typename?: 'document_template'
    id: any
    name: string
    value: string
    type: Document_Template_Type_Enum
    is_active: boolean
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: {
      __typename?: 'location'
      id: any
      name: string
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
    }
  }>
}

export type GetDocumentTemplateQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetDocumentTemplateQuery = {
  __typename?: 'query_root'
  documentTemplate?: {
    __typename?: 'document_template'
    id: any
    name: string
    value: string
    type: Document_Template_Type_Enum
    is_active: boolean
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: {
      __typename?: 'location'
      id: any
      name: string
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
    }
  } | null
}

export type UpdateDocumentTemplateMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Document_Template_Set_Input
}>

export type UpdateDocumentTemplateMutation = {
  __typename?: 'mutation_root'
  documentTemplate?: {
    __typename?: 'document_template'
    id: any
    name: string
    value: string
    type: Document_Template_Type_Enum
    is_active: boolean
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: {
      __typename?: 'location'
      id: any
      name: string
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
    }
  } | null
}

export type InsertDocumentTemplateMutationVariables = Exact<{
  object: Document_Template_Insert_Input
}>

export type InsertDocumentTemplateMutation = {
  __typename?: 'mutation_root'
  documentTemplate?: {
    __typename?: 'document_template'
    id: any
    name: string
    value: string
    type: Document_Template_Type_Enum
    is_active: boolean
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: {
      __typename?: 'location'
      id: any
      name: string
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
    }
  } | null
}

export type UpsertDocumentTemplateMutationVariables = Exact<{
  object: Document_Template_Insert_Input
  on_conflict: Document_Template_On_Conflict
}>

export type UpsertDocumentTemplateMutation = {
  __typename?: 'mutation_root'
  documentTemplate?: {
    __typename?: 'document_template'
    id: any
    name: string
    value: string
    type: Document_Template_Type_Enum
    is_active: boolean
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: {
      __typename?: 'location'
      id: any
      name: string
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
    }
  } | null
}

export type GetDocumentTemplateDictionariesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDocumentTemplateDictionariesQuery = {
  __typename?: 'query_root'
  locations: Array<{ __typename?: 'location'; id: any; name: string }>
}

export type BaseLoanFragmentFragment = {
  __typename?: 'loan'
  id: any
  amount: number
  currency: Currency_Enum
  type: Loan_Type_Enum
  interest_percentage: number
  commission_percentage?: number | null
  periodicity: Loan_Periodicity_Enum
  created_at: any
  client: {
    __typename?: 'client'
    id: any
    full_name?: string | null
    id_number: string
  }
  user?: {
    __typename?: 'user'
    id: any
    full_name?: string | null
    id_number?: string | null
  } | null
}

export type LoanFragmentFragment = {
  __typename?: 'loan'
  id: any
  client_id: any
  amount: number
  type: Loan_Type_Enum
  balance: number
  payment_date: any
  interest_arrears_amount?: number | null
  commission_interest_arrears_amount?: number | null
  interest_percentage: number
  capital_percentage: number
  commission_percentage?: number | null
  status: Loan_Status_Enum
  periodicity: Loan_Periodicity_Enum
  periodicity_metadata?: any | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
  location_id: any
  user_id?: any | null
  currency: Currency_Enum
  client: {
    __typename?: 'client'
    id: any
    first_name: string
    last_name: string
    full_name?: string | null
    email: string
    id_number: string
    profession: string
    workplace: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
    addresses: Array<{
      __typename?: 'client_address'
      id: any
      value: string
      geolocation?: any | null
      description?: string | null
      type: Address_Type_Enum
      photo_url?: string | null
      updated_at: any
      created_at: any
    }>
    phone_numbers: Array<{
      __typename?: 'client_phone_number'
      id: any
      value: string
      description?: string | null
      type: Phone_Number_Type_Enum
      created_at: any
      updated_at: any
    }>
    files: Array<{
      __typename?: 'client_file'
      id: any
      value: string
      type: Client_File_Type_Enum
      description?: string | null
      created_at: any
      updated_at: any
    }>
  }
  location: {
    __typename?: 'location'
    id: any
    name: string
    geolocation?: any | null
    phone_number: string
    email: string
    id_number: string
    description: string
    address: string
    tenant_id: any
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
    minimum_payment_percentage?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    payment_methods: Array<{
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }>
    tenant: {
      __typename?: 'tenant'
      id: any
      name: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }
  }
  user?: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  } | null
}

export type GetLoansQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Loan_Select_Column> | Loan_Select_Column>
  order_by?: InputMaybe<Array<Loan_Order_By> | Loan_Order_By>
  where?: InputMaybe<Loan_Bool_Exp>
}>

export type GetLoansQuery = {
  __typename?: 'query_root'
  loansAggregate: {
    __typename?: 'loan_aggregate'
    aggregate?: {
      __typename?: 'loan_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'loan_sum_fields'
        amount?: number | null
        balance?: number | null
        interest_arrears_amount?: number | null
        commission_interest_arrears_amount?: number | null
      } | null
    } | null
  }
  loans: Array<{
    __typename?: 'loan'
    id: any
    client_id: any
    amount: number
    type: Loan_Type_Enum
    balance: number
    payment_date: any
    interest_arrears_amount?: number | null
    commission_interest_arrears_amount?: number | null
    interest_percentage: number
    capital_percentage: number
    commission_percentage?: number | null
    status: Loan_Status_Enum
    periodicity: Loan_Periodicity_Enum
    periodicity_metadata?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    location_id: any
    user_id?: any | null
    currency: Currency_Enum
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    location: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    }
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
  }>
}

export type GetBaseLoansQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Loan_Select_Column> | Loan_Select_Column>
  order_by?: InputMaybe<Array<Loan_Order_By> | Loan_Order_By>
  where?: InputMaybe<Loan_Bool_Exp>
}>

export type GetBaseLoansQuery = {
  __typename?: 'query_root'
  loansAggregate: {
    __typename?: 'loan_aggregate'
    aggregate?: {
      __typename?: 'loan_aggregate_fields'
      totalCount: number
      sum?: { __typename?: 'loan_sum_fields'; amount?: number | null } | null
    } | null
  }
  loans: Array<{
    __typename?: 'loan'
    id: any
    amount: number
    currency: Currency_Enum
    type: Loan_Type_Enum
    interest_percentage: number
    commission_percentage?: number | null
    periodicity: Loan_Periodicity_Enum
    created_at: any
    client: {
      __typename?: 'client'
      id: any
      full_name?: string | null
      id_number: string
    }
    user?: {
      __typename?: 'user'
      id: any
      full_name?: string | null
      id_number?: string | null
    } | null
  }>
}

export type GetLoanQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetLoanQuery = {
  __typename?: 'query_root'
  loan?: {
    __typename?: 'loan'
    id: any
    client_id: any
    amount: number
    type: Loan_Type_Enum
    balance: number
    payment_date: any
    interest_arrears_amount?: number | null
    commission_interest_arrears_amount?: number | null
    interest_percentage: number
    capital_percentage: number
    commission_percentage?: number | null
    status: Loan_Status_Enum
    periodicity: Loan_Periodicity_Enum
    periodicity_metadata?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    location_id: any
    user_id?: any | null
    currency: Currency_Enum
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    location: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    }
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
  } | null
}

export type UpdateLoanMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Loan_Set_Input
}>

export type UpdateLoanMutation = {
  __typename?: 'mutation_root'
  loan?: {
    __typename?: 'loan'
    id: any
    client_id: any
    amount: number
    type: Loan_Type_Enum
    balance: number
    payment_date: any
    interest_arrears_amount?: number | null
    commission_interest_arrears_amount?: number | null
    interest_percentage: number
    capital_percentage: number
    commission_percentage?: number | null
    status: Loan_Status_Enum
    periodicity: Loan_Periodicity_Enum
    periodicity_metadata?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    location_id: any
    user_id?: any | null
    currency: Currency_Enum
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    location: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    }
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
  } | null
}

export type InsertLoanMutationVariables = Exact<{
  object: Loan_Insert_Input
}>

export type InsertLoanMutation = {
  __typename?: 'mutation_root'
  loan?: {
    __typename?: 'loan'
    id: any
    client_id: any
    amount: number
    type: Loan_Type_Enum
    balance: number
    payment_date: any
    interest_arrears_amount?: number | null
    commission_interest_arrears_amount?: number | null
    interest_percentage: number
    capital_percentage: number
    commission_percentage?: number | null
    status: Loan_Status_Enum
    periodicity: Loan_Periodicity_Enum
    periodicity_metadata?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    location_id: any
    user_id?: any | null
    currency: Currency_Enum
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    location: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    }
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
  } | null
}

export type UpsertLoanMutationVariables = Exact<{
  object: Loan_Insert_Input
  on_conflict: Loan_On_Conflict
}>

export type UpsertLoanMutation = {
  __typename?: 'mutation_root'
  loan?: {
    __typename?: 'loan'
    id: any
    client_id: any
    amount: number
    type: Loan_Type_Enum
    balance: number
    payment_date: any
    interest_arrears_amount?: number | null
    commission_interest_arrears_amount?: number | null
    interest_percentage: number
    capital_percentage: number
    commission_percentage?: number | null
    status: Loan_Status_Enum
    periodicity: Loan_Periodicity_Enum
    periodicity_metadata?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    location_id: any
    user_id?: any | null
    currency: Currency_Enum
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    location: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    }
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
  } | null
}

export type GetLoanDictionariesQueryVariables = Exact<{ [key: string]: never }>

export type GetLoanDictionariesQuery = {
  __typename?: 'query_root'
  locations: Array<{ __typename?: 'location'; id: any; name: string }>
}

export type GetLoanDictionariesByLocationIdQueryVariables = Exact<{
  locationId: Scalars['uuid']['input']
}>

export type GetLoanDictionariesByLocationIdQuery = {
  __typename?: 'query_root'
  users: Array<{
    __typename?: 'user'
    id: any
    full_name?: string | null
    id_number?: string | null
  }>
  clients: Array<{
    __typename?: 'client'
    id: any
    full_name?: string | null
    id_number: string
  }>
}

export type GetLoansAggregateQueryVariables = Exact<{
  where?: InputMaybe<Loan_Bool_Exp>
}>

export type GetLoansAggregateQuery = {
  __typename?: 'query_root'
  loansAggregate: {
    __typename?: 'loan_aggregate'
    aggregate?: {
      __typename?: 'loan_aggregate_fields'
      totalCount: number
      sum?: { __typename?: 'loan_sum_fields'; amount?: number | null } | null
    } | null
  }
}

export type LocationFragmentFragment = {
  __typename?: 'location'
  id: any
  name: string
  geolocation?: any | null
  phone_number: string
  email: string
  id_number: string
  description: string
  address: string
  tenant_id: any
  subscription_type?: Subscription_Type_Enum | null
  next_subscription_payment_date?: any | null
  minimum_payment_percentage?: number | null
  created_at: any
  updated_at: any
  deleted_at?: any | null
  payment_methods: Array<{
    __typename?: 'location_payment_method'
    id: any
    name: string
    value: string
    method: Payment_Method_Enum
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: { __typename?: 'location'; id: any; name: string }
  }>
  tenant: {
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
  }
}

export type BaseLocationFragmentFragment = {
  __typename?: 'location'
  id: any
  name: string
  subscription_type?: Subscription_Type_Enum | null
  next_subscription_payment_date?: any | null
}

export type GetLocationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    Array<Location_Select_Column> | Location_Select_Column
  >
  order_by?: InputMaybe<Array<Location_Order_By> | Location_Order_By>
  where?: InputMaybe<Location_Bool_Exp>
}>

export type GetLocationsQuery = {
  __typename?: 'query_root'
  locationsAggregate: {
    __typename?: 'location_aggregate'
    aggregate?: {
      __typename?: 'location_aggregate_fields'
      totalCount: number
    } | null
  }
  locations: Array<{
    __typename?: 'location'
    id: any
    name: string
    geolocation?: any | null
    phone_number: string
    email: string
    id_number: string
    description: string
    address: string
    tenant_id: any
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
    minimum_payment_percentage?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    payment_methods: Array<{
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }>
    tenant: {
      __typename?: 'tenant'
      id: any
      name: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }
  }>
}

export type GetLocationQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetLocationQuery = {
  __typename?: 'query_root'
  location?: {
    __typename?: 'location'
    id: any
    name: string
    geolocation?: any | null
    phone_number: string
    email: string
    id_number: string
    description: string
    address: string
    tenant_id: any
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
    minimum_payment_percentage?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    payment_methods: Array<{
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }>
    tenant: {
      __typename?: 'tenant'
      id: any
      name: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }
  } | null
}

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Location_Set_Input
}>

export type UpdateLocationMutation = {
  __typename?: 'mutation_root'
  location?: {
    __typename?: 'location'
    id: any
    name: string
    geolocation?: any | null
    phone_number: string
    email: string
    id_number: string
    description: string
    address: string
    tenant_id: any
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
    minimum_payment_percentage?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    payment_methods: Array<{
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }>
    tenant: {
      __typename?: 'tenant'
      id: any
      name: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }
  } | null
}

export type InsertLocationMutationVariables = Exact<{
  object: Location_Insert_Input
}>

export type InsertLocationMutation = {
  __typename?: 'mutation_root'
  location?: {
    __typename?: 'location'
    id: any
    name: string
    geolocation?: any | null
    phone_number: string
    email: string
    id_number: string
    description: string
    address: string
    tenant_id: any
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
    minimum_payment_percentage?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    payment_methods: Array<{
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }>
    tenant: {
      __typename?: 'tenant'
      id: any
      name: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }
  } | null
}

export type LocationPaymentMethodFragmentFragment = {
  __typename?: 'location_payment_method'
  id: any
  name: string
  value: string
  method: Payment_Method_Enum
  location_id: any
  created_at: any
  updated_at: any
  deleted_at?: any | null
  location: { __typename?: 'location'; id: any; name: string }
}

export type GetLocationPaymentMethodsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<
    | Array<Location_Payment_Method_Select_Column>
    | Location_Payment_Method_Select_Column
  >
  order_by?: InputMaybe<
    Array<Location_Payment_Method_Order_By> | Location_Payment_Method_Order_By
  >
  where?: InputMaybe<Location_Payment_Method_Bool_Exp>
}>

export type GetLocationPaymentMethodsQuery = {
  __typename?: 'query_root'
  locationPaymentMethodsAggregate: {
    __typename?: 'location_payment_method_aggregate'
    aggregate?: {
      __typename?: 'location_payment_method_aggregate_fields'
      totalCount: number
    } | null
  }
  locationPaymentMethods: Array<{
    __typename?: 'location_payment_method'
    id: any
    name: string
    value: string
    method: Payment_Method_Enum
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: { __typename?: 'location'; id: any; name: string }
  }>
}

export type GetLocationPaymentMethodQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetLocationPaymentMethodQuery = {
  __typename?: 'query_root'
  locationPaymentMethod?: {
    __typename?: 'location_payment_method'
    id: any
    name: string
    value: string
    method: Payment_Method_Enum
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: { __typename?: 'location'; id: any; name: string }
  } | null
}

export type UpdateLocationPaymentMethodMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Location_Payment_Method_Set_Input
}>

export type UpdateLocationPaymentMethodMutation = {
  __typename?: 'mutation_root'
  locationPaymentMethod?: {
    __typename?: 'location_payment_method'
    id: any
    name: string
    value: string
    method: Payment_Method_Enum
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: { __typename?: 'location'; id: any; name: string }
  } | null
}

export type InsertLocationPaymentMethodMutationVariables = Exact<{
  object: Location_Payment_Method_Insert_Input
}>

export type InsertLocationPaymentMethodMutation = {
  __typename?: 'mutation_root'
  locationPaymentMethod?: {
    __typename?: 'location_payment_method'
    id: any
    name: string
    value: string
    method: Payment_Method_Enum
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: { __typename?: 'location'; id: any; name: string }
  } | null
}

export type UpsertLocationPaymentMethodMutationVariables = Exact<{
  object: Location_Payment_Method_Insert_Input
  on_conflict: Location_Payment_Method_On_Conflict
}>

export type UpsertLocationPaymentMethodMutation = {
  __typename?: 'mutation_root'
  locationPaymentMethod?: {
    __typename?: 'location_payment_method'
    id: any
    name: string
    value: string
    method: Payment_Method_Enum
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: { __typename?: 'location'; id: any; name: string }
  } | null
}

export type GetLocationPaymentMethodDictionariesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetLocationPaymentMethodDictionariesQuery = {
  __typename?: 'query_root'
  locations: Array<{ __typename?: 'location'; id: any; name: string }>
}

export type PaymentFragmentFragment = {
  __typename?: 'payment'
  id: any
  capital_amount: number
  current_balance_amount: number
  current_interest_arrears_amount: number
  current_commission_interest_arrears_amount: number
  expected_amount: number
  interest_amount: number
  amount: number
  new_balance_amount: number
  new_interest_arrears_amount: number
  new_commission_interest_arrears_amount: number
  notes?: string | null
  created_at: any
  deleted_at?: any | null
  expected_payment_date: any
  new_payment_date: any
  updated_at: any
  user_id: any
  client_id: any
  loan_id: any
  payment_method_id: any
  payment_method_ref?: string | null
  days_late?: number | null
  penalty_amount?: number | null
  commission_amount: number
  expected_commission_amount: number
  expected_capital_amount: number
  expected_interest_amount: number
  interest_arrears_amount: number
  commission_interest_arrears_amount: number
  type: Payment_Type_Enum
  user: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  }
  client: {
    __typename?: 'client'
    id: any
    first_name: string
    last_name: string
    full_name?: string | null
    email: string
    id_number: string
    profession: string
    workplace: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location_id: any
    location: { __typename?: 'location'; id: any; name: string }
    addresses: Array<{
      __typename?: 'client_address'
      id: any
      value: string
      geolocation?: any | null
      description?: string | null
      type: Address_Type_Enum
      photo_url?: string | null
      updated_at: any
      created_at: any
    }>
    phone_numbers: Array<{
      __typename?: 'client_phone_number'
      id: any
      value: string
      description?: string | null
      type: Phone_Number_Type_Enum
      created_at: any
      updated_at: any
    }>
    files: Array<{
      __typename?: 'client_file'
      id: any
      value: string
      type: Client_File_Type_Enum
      description?: string | null
      created_at: any
      updated_at: any
    }>
  }
  loan: {
    __typename?: 'loan'
    id: any
    client_id: any
    amount: number
    type: Loan_Type_Enum
    balance: number
    payment_date: any
    interest_arrears_amount?: number | null
    commission_interest_arrears_amount?: number | null
    interest_percentage: number
    capital_percentage: number
    commission_percentage?: number | null
    status: Loan_Status_Enum
    periodicity: Loan_Periodicity_Enum
    periodicity_metadata?: any | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    location_id: any
    user_id?: any | null
    currency: Currency_Enum
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    location: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    }
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
  }
  payment_method: {
    __typename?: 'location_payment_method'
    id: any
    name: string
    value: string
    method: Payment_Method_Enum
    location_id: any
    created_at: any
    updated_at: any
    deleted_at?: any | null
    location: { __typename?: 'location'; id: any; name: string }
  }
}

export type BasePaymentFragmentFragment = {
  __typename?: 'payment'
  id: any
  amount: number
  penalty_amount?: number | null
  created_at: any
  client: {
    __typename?: 'client'
    id: any
    full_name?: string | null
    id_number: string
  }
  user: {
    __typename?: 'user'
    id: any
    full_name?: string | null
    id_number?: string | null
  }
  loan: {
    __typename?: 'loan'
    id: any
    currency: Currency_Enum
    location: { __typename?: 'location'; id: any; name: string }
  }
}

export type GetPaymentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Payment_Select_Column> | Payment_Select_Column>
  order_by?: InputMaybe<Array<Payment_Order_By> | Payment_Order_By>
  where?: InputMaybe<Payment_Bool_Exp>
}>

export type GetPaymentsQuery = {
  __typename?: 'query_root'
  paymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        capital_amount?: number | null
        current_balance_amount?: number | null
        current_interest_arrears_amount?: number | null
        current_commission_interest_arrears_amount?: number | null
        expected_amount?: number | null
        interest_amount?: number | null
        amount?: number | null
        new_balance_amount?: number | null
        new_interest_arrears_amount?: number | null
        new_commission_interest_arrears_amount?: number | null
        penalty_amount?: number | null
        commission_amount?: number | null
        expected_commission_amount?: number | null
        expected_capital_amount?: number | null
        expected_interest_amount?: number | null
        interest_arrears_amount?: number | null
        commission_interest_arrears_amount?: number | null
      } | null
    } | null
  }
  payments: Array<{
    __typename?: 'payment'
    id: any
    capital_amount: number
    current_balance_amount: number
    current_interest_arrears_amount: number
    current_commission_interest_arrears_amount: number
    expected_amount: number
    interest_amount: number
    amount: number
    new_balance_amount: number
    new_interest_arrears_amount: number
    new_commission_interest_arrears_amount: number
    notes?: string | null
    created_at: any
    deleted_at?: any | null
    expected_payment_date: any
    new_payment_date: any
    updated_at: any
    user_id: any
    client_id: any
    loan_id: any
    payment_method_id: any
    payment_method_ref?: string | null
    days_late?: number | null
    penalty_amount?: number | null
    commission_amount: number
    expected_commission_amount: number
    expected_capital_amount: number
    expected_interest_amount: number
    interest_arrears_amount: number
    commission_interest_arrears_amount: number
    type: Payment_Type_Enum
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    }
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    loan: {
      __typename?: 'loan'
      id: any
      client_id: any
      amount: number
      type: Loan_Type_Enum
      balance: number
      payment_date: any
      interest_arrears_amount?: number | null
      commission_interest_arrears_amount?: number | null
      interest_percentage: number
      capital_percentage: number
      commission_percentage?: number | null
      status: Loan_Status_Enum
      periodicity: Loan_Periodicity_Enum
      periodicity_metadata?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      location_id: any
      user_id?: any | null
      currency: Currency_Enum
      client: {
        __typename?: 'client'
        id: any
        first_name: string
        last_name: string
        full_name?: string | null
        email: string
        id_number: string
        profession: string
        workplace: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location_id: any
        location: { __typename?: 'location'; id: any; name: string }
        addresses: Array<{
          __typename?: 'client_address'
          id: any
          value: string
          geolocation?: any | null
          description?: string | null
          type: Address_Type_Enum
          photo_url?: string | null
          updated_at: any
          created_at: any
        }>
        phone_numbers: Array<{
          __typename?: 'client_phone_number'
          id: any
          value: string
          description?: string | null
          type: Phone_Number_Type_Enum
          created_at: any
          updated_at: any
        }>
        files: Array<{
          __typename?: 'client_file'
          id: any
          value: string
          type: Client_File_Type_Enum
          description?: string | null
          created_at: any
          updated_at: any
        }>
      }
      location: {
        __typename?: 'location'
        id: any
        name: string
        geolocation?: any | null
        phone_number: string
        email: string
        id_number: string
        description: string
        address: string
        tenant_id: any
        subscription_type?: Subscription_Type_Enum | null
        next_subscription_payment_date?: any | null
        minimum_payment_percentage?: number | null
        created_at: any
        updated_at: any
        deleted_at?: any | null
        payment_methods: Array<{
          __typename?: 'location_payment_method'
          id: any
          name: string
          value: string
          method: Payment_Method_Enum
          location_id: any
          created_at: any
          updated_at: any
          deleted_at?: any | null
          location: { __typename?: 'location'; id: any; name: string }
        }>
        tenant: {
          __typename?: 'tenant'
          id: any
          name: string
          created_at: any
          updated_at: any
          deleted_at?: any | null
        }
      }
      user?: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        id_number?: string | null
        status?: User_Status_Enum | null
        phone_number?: string | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        user_locations: Array<{
          __typename?: 'user_location'
          location_id: any
        }>
      } | null
    }
    payment_method: {
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }
  }>
}

export type GetBasePaymentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Payment_Select_Column> | Payment_Select_Column>
  order_by?: InputMaybe<Array<Payment_Order_By> | Payment_Order_By>
  where?: InputMaybe<Payment_Bool_Exp>
}>

export type GetBasePaymentsQuery = {
  __typename?: 'query_root'
  paymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        amount?: number | null
        penalty_amount?: number | null
      } | null
    } | null
  }
  payments: Array<{
    __typename?: 'payment'
    id: any
    amount: number
    penalty_amount?: number | null
    created_at: any
    client: {
      __typename?: 'client'
      id: any
      full_name?: string | null
      id_number: string
    }
    user: {
      __typename?: 'user'
      id: any
      full_name?: string | null
      id_number?: string | null
    }
    loan: {
      __typename?: 'loan'
      id: any
      currency: Currency_Enum
      location: { __typename?: 'location'; id: any; name: string }
    }
  }>
}

export type GetPaymentQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetPaymentQuery = {
  __typename?: 'query_root'
  payment?: {
    __typename?: 'payment'
    id: any
    capital_amount: number
    current_balance_amount: number
    current_interest_arrears_amount: number
    current_commission_interest_arrears_amount: number
    expected_amount: number
    interest_amount: number
    amount: number
    new_balance_amount: number
    new_interest_arrears_amount: number
    new_commission_interest_arrears_amount: number
    notes?: string | null
    created_at: any
    deleted_at?: any | null
    expected_payment_date: any
    new_payment_date: any
    updated_at: any
    user_id: any
    client_id: any
    loan_id: any
    payment_method_id: any
    payment_method_ref?: string | null
    days_late?: number | null
    penalty_amount?: number | null
    commission_amount: number
    expected_commission_amount: number
    expected_capital_amount: number
    expected_interest_amount: number
    interest_arrears_amount: number
    commission_interest_arrears_amount: number
    type: Payment_Type_Enum
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    }
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    loan: {
      __typename?: 'loan'
      id: any
      client_id: any
      amount: number
      type: Loan_Type_Enum
      balance: number
      payment_date: any
      interest_arrears_amount?: number | null
      commission_interest_arrears_amount?: number | null
      interest_percentage: number
      capital_percentage: number
      commission_percentage?: number | null
      status: Loan_Status_Enum
      periodicity: Loan_Periodicity_Enum
      periodicity_metadata?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      location_id: any
      user_id?: any | null
      currency: Currency_Enum
      client: {
        __typename?: 'client'
        id: any
        first_name: string
        last_name: string
        full_name?: string | null
        email: string
        id_number: string
        profession: string
        workplace: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location_id: any
        location: { __typename?: 'location'; id: any; name: string }
        addresses: Array<{
          __typename?: 'client_address'
          id: any
          value: string
          geolocation?: any | null
          description?: string | null
          type: Address_Type_Enum
          photo_url?: string | null
          updated_at: any
          created_at: any
        }>
        phone_numbers: Array<{
          __typename?: 'client_phone_number'
          id: any
          value: string
          description?: string | null
          type: Phone_Number_Type_Enum
          created_at: any
          updated_at: any
        }>
        files: Array<{
          __typename?: 'client_file'
          id: any
          value: string
          type: Client_File_Type_Enum
          description?: string | null
          created_at: any
          updated_at: any
        }>
      }
      location: {
        __typename?: 'location'
        id: any
        name: string
        geolocation?: any | null
        phone_number: string
        email: string
        id_number: string
        description: string
        address: string
        tenant_id: any
        subscription_type?: Subscription_Type_Enum | null
        next_subscription_payment_date?: any | null
        minimum_payment_percentage?: number | null
        created_at: any
        updated_at: any
        deleted_at?: any | null
        payment_methods: Array<{
          __typename?: 'location_payment_method'
          id: any
          name: string
          value: string
          method: Payment_Method_Enum
          location_id: any
          created_at: any
          updated_at: any
          deleted_at?: any | null
          location: { __typename?: 'location'; id: any; name: string }
        }>
        tenant: {
          __typename?: 'tenant'
          id: any
          name: string
          created_at: any
          updated_at: any
          deleted_at?: any | null
        }
      }
      user?: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        id_number?: string | null
        status?: User_Status_Enum | null
        phone_number?: string | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        user_locations: Array<{
          __typename?: 'user_location'
          location_id: any
        }>
      } | null
    }
    payment_method: {
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }
  } | null
}

export type UpdatePaymentMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Payment_Set_Input
}>

export type UpdatePaymentMutation = {
  __typename?: 'mutation_root'
  payment?: {
    __typename?: 'payment'
    id: any
    capital_amount: number
    current_balance_amount: number
    current_interest_arrears_amount: number
    current_commission_interest_arrears_amount: number
    expected_amount: number
    interest_amount: number
    amount: number
    new_balance_amount: number
    new_interest_arrears_amount: number
    new_commission_interest_arrears_amount: number
    notes?: string | null
    created_at: any
    deleted_at?: any | null
    expected_payment_date: any
    new_payment_date: any
    updated_at: any
    user_id: any
    client_id: any
    loan_id: any
    payment_method_id: any
    payment_method_ref?: string | null
    days_late?: number | null
    penalty_amount?: number | null
    commission_amount: number
    expected_commission_amount: number
    expected_capital_amount: number
    expected_interest_amount: number
    interest_arrears_amount: number
    commission_interest_arrears_amount: number
    type: Payment_Type_Enum
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    }
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    loan: {
      __typename?: 'loan'
      id: any
      client_id: any
      amount: number
      type: Loan_Type_Enum
      balance: number
      payment_date: any
      interest_arrears_amount?: number | null
      commission_interest_arrears_amount?: number | null
      interest_percentage: number
      capital_percentage: number
      commission_percentage?: number | null
      status: Loan_Status_Enum
      periodicity: Loan_Periodicity_Enum
      periodicity_metadata?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      location_id: any
      user_id?: any | null
      currency: Currency_Enum
      client: {
        __typename?: 'client'
        id: any
        first_name: string
        last_name: string
        full_name?: string | null
        email: string
        id_number: string
        profession: string
        workplace: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location_id: any
        location: { __typename?: 'location'; id: any; name: string }
        addresses: Array<{
          __typename?: 'client_address'
          id: any
          value: string
          geolocation?: any | null
          description?: string | null
          type: Address_Type_Enum
          photo_url?: string | null
          updated_at: any
          created_at: any
        }>
        phone_numbers: Array<{
          __typename?: 'client_phone_number'
          id: any
          value: string
          description?: string | null
          type: Phone_Number_Type_Enum
          created_at: any
          updated_at: any
        }>
        files: Array<{
          __typename?: 'client_file'
          id: any
          value: string
          type: Client_File_Type_Enum
          description?: string | null
          created_at: any
          updated_at: any
        }>
      }
      location: {
        __typename?: 'location'
        id: any
        name: string
        geolocation?: any | null
        phone_number: string
        email: string
        id_number: string
        description: string
        address: string
        tenant_id: any
        subscription_type?: Subscription_Type_Enum | null
        next_subscription_payment_date?: any | null
        minimum_payment_percentage?: number | null
        created_at: any
        updated_at: any
        deleted_at?: any | null
        payment_methods: Array<{
          __typename?: 'location_payment_method'
          id: any
          name: string
          value: string
          method: Payment_Method_Enum
          location_id: any
          created_at: any
          updated_at: any
          deleted_at?: any | null
          location: { __typename?: 'location'; id: any; name: string }
        }>
        tenant: {
          __typename?: 'tenant'
          id: any
          name: string
          created_at: any
          updated_at: any
          deleted_at?: any | null
        }
      }
      user?: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        id_number?: string | null
        status?: User_Status_Enum | null
        phone_number?: string | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        user_locations: Array<{
          __typename?: 'user_location'
          location_id: any
        }>
      } | null
    }
    payment_method: {
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }
  } | null
}

export type InsertPaymentMutationVariables = Exact<{
  object: Payment_Insert_Input
}>

export type InsertPaymentMutation = {
  __typename?: 'mutation_root'
  payment?: {
    __typename?: 'payment'
    id: any
    capital_amount: number
    current_balance_amount: number
    current_interest_arrears_amount: number
    current_commission_interest_arrears_amount: number
    expected_amount: number
    interest_amount: number
    amount: number
    new_balance_amount: number
    new_interest_arrears_amount: number
    new_commission_interest_arrears_amount: number
    notes?: string | null
    created_at: any
    deleted_at?: any | null
    expected_payment_date: any
    new_payment_date: any
    updated_at: any
    user_id: any
    client_id: any
    loan_id: any
    payment_method_id: any
    payment_method_ref?: string | null
    days_late?: number | null
    penalty_amount?: number | null
    commission_amount: number
    expected_commission_amount: number
    expected_capital_amount: number
    expected_interest_amount: number
    interest_arrears_amount: number
    commission_interest_arrears_amount: number
    type: Payment_Type_Enum
    user: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    }
    client: {
      __typename?: 'client'
      id: any
      first_name: string
      last_name: string
      full_name?: string | null
      email: string
      id_number: string
      profession: string
      workplace: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location_id: any
      location: { __typename?: 'location'; id: any; name: string }
      addresses: Array<{
        __typename?: 'client_address'
        id: any
        value: string
        geolocation?: any | null
        description?: string | null
        type: Address_Type_Enum
        photo_url?: string | null
        updated_at: any
        created_at: any
      }>
      phone_numbers: Array<{
        __typename?: 'client_phone_number'
        id: any
        value: string
        description?: string | null
        type: Phone_Number_Type_Enum
        created_at: any
        updated_at: any
      }>
      files: Array<{
        __typename?: 'client_file'
        id: any
        value: string
        type: Client_File_Type_Enum
        description?: string | null
        created_at: any
        updated_at: any
      }>
    }
    loan: {
      __typename?: 'loan'
      id: any
      client_id: any
      amount: number
      type: Loan_Type_Enum
      balance: number
      payment_date: any
      interest_arrears_amount?: number | null
      commission_interest_arrears_amount?: number | null
      interest_percentage: number
      capital_percentage: number
      commission_percentage?: number | null
      status: Loan_Status_Enum
      periodicity: Loan_Periodicity_Enum
      periodicity_metadata?: any | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      location_id: any
      user_id?: any | null
      currency: Currency_Enum
      client: {
        __typename?: 'client'
        id: any
        first_name: string
        last_name: string
        full_name?: string | null
        email: string
        id_number: string
        profession: string
        workplace: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location_id: any
        location: { __typename?: 'location'; id: any; name: string }
        addresses: Array<{
          __typename?: 'client_address'
          id: any
          value: string
          geolocation?: any | null
          description?: string | null
          type: Address_Type_Enum
          photo_url?: string | null
          updated_at: any
          created_at: any
        }>
        phone_numbers: Array<{
          __typename?: 'client_phone_number'
          id: any
          value: string
          description?: string | null
          type: Phone_Number_Type_Enum
          created_at: any
          updated_at: any
        }>
        files: Array<{
          __typename?: 'client_file'
          id: any
          value: string
          type: Client_File_Type_Enum
          description?: string | null
          created_at: any
          updated_at: any
        }>
      }
      location: {
        __typename?: 'location'
        id: any
        name: string
        geolocation?: any | null
        phone_number: string
        email: string
        id_number: string
        description: string
        address: string
        tenant_id: any
        subscription_type?: Subscription_Type_Enum | null
        next_subscription_payment_date?: any | null
        minimum_payment_percentage?: number | null
        created_at: any
        updated_at: any
        deleted_at?: any | null
        payment_methods: Array<{
          __typename?: 'location_payment_method'
          id: any
          name: string
          value: string
          method: Payment_Method_Enum
          location_id: any
          created_at: any
          updated_at: any
          deleted_at?: any | null
          location: { __typename?: 'location'; id: any; name: string }
        }>
        tenant: {
          __typename?: 'tenant'
          id: any
          name: string
          created_at: any
          updated_at: any
          deleted_at?: any | null
        }
      }
      user?: {
        __typename?: 'user'
        id: any
        firebase_id: string
        role: User_Role_Enum
        first_name?: string | null
        last_name?: string | null
        full_name?: string | null
        id_number?: string | null
        status?: User_Status_Enum | null
        phone_number?: string | null
        deleted_at?: any | null
        updated_at: any
        created_at: any
        user_locations: Array<{
          __typename?: 'user_location'
          location_id: any
        }>
      } | null
    }
    payment_method: {
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }
  } | null
}

export type GetPaymentsWithAggregateQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Payment_Select_Column> | Payment_Select_Column>
  order_by?: InputMaybe<Array<Payment_Order_By> | Payment_Order_By>
  where?: InputMaybe<Payment_Bool_Exp>
}>

export type GetPaymentsWithAggregateQuery = {
  __typename?: 'query_root'
  paymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        capital_amount?: number | null
        interest_amount?: number | null
        amount?: number | null
        penalty_amount?: number | null
        commission_amount?: number | null
        interest_arrears_amount?: number | null
        commission_interest_arrears_amount?: number | null
      } | null
    } | null
  }
  payments: Array<{
    __typename?: 'payment'
    id: any
    amount: number
    penalty_amount?: number | null
    created_at: any
    client: {
      __typename?: 'client'
      id: any
      full_name?: string | null
      id_number: string
    }
    user: {
      __typename?: 'user'
      id: any
      full_name?: string | null
      id_number?: string | null
    }
    loan: {
      __typename?: 'loan'
      id: any
      currency: Currency_Enum
      location: { __typename?: 'location'; id: any; name: string }
    }
  }>
}

export type GetPaymentsAggregateQueryVariables = Exact<{
  where?: InputMaybe<Payment_Bool_Exp>
}>

export type GetPaymentsAggregateQuery = {
  __typename?: 'query_root'
  paymentsAggregate: {
    __typename?: 'payment_aggregate'
    aggregate?: {
      __typename?: 'payment_aggregate_fields'
      totalCount: number
      sum?: {
        __typename?: 'payment_sum_fields'
        capital_amount?: number | null
        interest_amount?: number | null
        amount?: number | null
        penalty_amount?: number | null
        commission_amount?: number | null
        interest_arrears_amount?: number | null
        commission_interest_arrears_amount?: number | null
      } | null
    } | null
  }
}

export type GetPaymentsDictionariesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPaymentsDictionariesQuery = {
  __typename?: 'query_root'
  locations: Array<{ __typename?: 'location'; id: any; name: string }>
  users: Array<{ __typename?: 'user'; id: any; full_name?: string | null }>
  clients: Array<{ __typename?: 'client'; id: any; full_name?: string | null }>
}

export type TenantFragmentFragment = {
  __typename?: 'tenant'
  id: any
  name: string
  created_at: any
  updated_at: any
  deleted_at?: any | null
}

export type GetTenantsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<Tenant_Select_Column> | Tenant_Select_Column>
  order_by?: InputMaybe<Array<Tenant_Order_By> | Tenant_Order_By>
  where?: InputMaybe<Tenant_Bool_Exp>
}>

export type GetTenantsQuery = {
  __typename?: 'query_root'
  tenantsAggregate: {
    __typename?: 'tenant_aggregate'
    aggregate?: {
      __typename?: 'tenant_aggregate_fields'
      totalCount: number
    } | null
  }
  tenants: Array<{
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
  }>
}

export type GetTenantQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetTenantQuery = {
  __typename?: 'query_root'
  tenant?: {
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
  } | null
}

export type UpdateTenantMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: Tenant_Set_Input
}>

export type UpdateTenantMutation = {
  __typename?: 'mutation_root'
  tenant?: {
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
  } | null
}

export type InsertTenantMutationVariables = Exact<{
  object: Tenant_Insert_Input
}>

export type InsertTenantMutation = {
  __typename?: 'mutation_root'
  tenant?: {
    __typename?: 'tenant'
    id: any
    name: string
    created_at: any
    updated_at: any
    deleted_at?: any | null
  } | null
}

export type UserFragmentFragment = {
  __typename?: 'user'
  id: any
  firebase_id: string
  role: User_Role_Enum
  first_name?: string | null
  last_name?: string | null
  full_name?: string | null
  id_number?: string | null
  status?: User_Status_Enum | null
  phone_number?: string | null
  deleted_at?: any | null
  updated_at: any
  created_at: any
  user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
}

export type BaseUserFragmentFragment = {
  __typename?: 'user'
  id: any
  full_name?: string | null
  id_number?: string | null
}

export type GetUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  distinct_on?: InputMaybe<Array<User_Select_Column> | User_Select_Column>
  order_by?: InputMaybe<Array<User_Order_By> | User_Order_By>
  where?: InputMaybe<User_Bool_Exp>
}>

export type GetUsersQuery = {
  __typename?: 'query_root'
  usersAggregate: {
    __typename?: 'user_aggregate'
    aggregate?: {
      __typename?: 'user_aggregate_fields'
      totalCount: number
    } | null
  }
  users: Array<{
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  }>
}

export type GetUserByFirebaseIdQueryVariables = Exact<{
  firebaseId?: InputMaybe<Scalars['String']['input']>
}>

export type GetUserByFirebaseIdQuery = {
  __typename?: 'query_root'
  user: Array<{
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  }>
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid']['input']
}>

export type GetUserQuery = {
  __typename?: 'query_root'
  user?: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  } | null
}

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid']['input']
  set: User_Set_Input
}>

export type UpdateUserMutation = {
  __typename?: 'mutation_root'
  user?: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  } | null
}

export type GetBaseUsersQueryVariables = Exact<{
  order_by?: InputMaybe<Array<User_Order_By> | User_Order_By>
  where?: InputMaybe<User_Bool_Exp>
}>

export type GetBaseUsersQuery = {
  __typename?: 'query_root'
  users: Array<{
    __typename?: 'user'
    id: any
    full_name?: string | null
    id_number?: string | null
  }>
}

export type GetUserDictionariesQueryVariables = Exact<{ [key: string]: never }>

export type GetUserDictionariesQuery = {
  __typename?: 'query_root'
  locations: Array<{ __typename?: 'location'; id: any; name: string }>
}

export type UserPaymentAggregationFragmentFragment = {
  __typename?: 'user_payment_aggregation'
  user_id?: any | null
  amount?: number | null
  interest_amount?: number | null
  capital_amount?: number | null
  commission_amount?: number | null
  penalty_amount?: number | null
  commission_interest_arrears_amount?: number | null
  interest_arrears_amount?: number | null
  payment_date?: any | null
  count?: any | null
  location_id?: any | null
  user?: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  } | null
  location?: {
    __typename?: 'location'
    id: any
    name: string
    geolocation?: any | null
    phone_number: string
    email: string
    id_number: string
    description: string
    address: string
    tenant_id: any
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
    minimum_payment_percentage?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    payment_methods: Array<{
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }>
    tenant: {
      __typename?: 'tenant'
      id: any
      name: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }
  } | null
}

export type UserLoanAggregationFragmentFragment = {
  __typename?: 'user_loan_aggregation'
  location_id?: any | null
  user_id?: any | null
  amount?: number | null
  today_amount?: number | null
  late_amount?: number | null
  up_to_date_amount?: number | null
  balance?: number | null
  today_balance?: number | null
  late_balance?: number | null
  up_to_date_balance?: number | null
  count?: any | null
  today_count?: any | null
  late_count?: any | null
  up_to_date_count?: any | null
  interest_arrears_amount?: number | null
  commission_interest_arrears_amount?: number | null
  user?: {
    __typename?: 'user'
    id: any
    firebase_id: string
    role: User_Role_Enum
    first_name?: string | null
    last_name?: string | null
    full_name?: string | null
    id_number?: string | null
    status?: User_Status_Enum | null
    phone_number?: string | null
    deleted_at?: any | null
    updated_at: any
    created_at: any
    user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
  } | null
  location?: {
    __typename?: 'location'
    id: any
    name: string
    geolocation?: any | null
    phone_number: string
    email: string
    id_number: string
    description: string
    address: string
    tenant_id: any
    subscription_type?: Subscription_Type_Enum | null
    next_subscription_payment_date?: any | null
    minimum_payment_percentage?: number | null
    created_at: any
    updated_at: any
    deleted_at?: any | null
    payment_methods: Array<{
      __typename?: 'location_payment_method'
      id: any
      name: string
      value: string
      method: Payment_Method_Enum
      location_id: any
      created_at: any
      updated_at: any
      deleted_at?: any | null
      location: { __typename?: 'location'; id: any; name: string }
    }>
    tenant: {
      __typename?: 'tenant'
      id: any
      name: string
      created_at: any
      updated_at: any
      deleted_at?: any | null
    }
  } | null
}

export type GetUserPaymentAggregationsQueryVariables = Exact<{
  where?: InputMaybe<User_Payment_Aggregation_Bool_Exp>
  order_by?: InputMaybe<
    Array<User_Payment_Aggregation_Order_By> | User_Payment_Aggregation_Order_By
  >
}>

export type GetUserPaymentAggregationsQuery = {
  __typename?: 'query_root'
  userPaymentAggregations: Array<{
    __typename?: 'user_payment_aggregation'
    user_id?: any | null
    amount?: number | null
    interest_amount?: number | null
    capital_amount?: number | null
    commission_amount?: number | null
    penalty_amount?: number | null
    commission_interest_arrears_amount?: number | null
    interest_arrears_amount?: number | null
    payment_date?: any | null
    count?: any | null
    location_id?: any | null
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
    location?: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    } | null
  }>
}

export type GetUserLoanAggregationsQueryVariables = Exact<{
  where?: InputMaybe<User_Loan_Aggregation_Bool_Exp>
  order_by?: InputMaybe<
    Array<User_Loan_Aggregation_Order_By> | User_Loan_Aggregation_Order_By
  >
}>

export type GetUserLoanAggregationsQuery = {
  __typename?: 'query_root'
  userLoanAggregations: Array<{
    __typename?: 'user_loan_aggregation'
    location_id?: any | null
    user_id?: any | null
    amount?: number | null
    today_amount?: number | null
    late_amount?: number | null
    up_to_date_amount?: number | null
    balance?: number | null
    today_balance?: number | null
    late_balance?: number | null
    up_to_date_balance?: number | null
    count?: any | null
    today_count?: any | null
    late_count?: any | null
    up_to_date_count?: any | null
    interest_arrears_amount?: number | null
    commission_interest_arrears_amount?: number | null
    user?: {
      __typename?: 'user'
      id: any
      firebase_id: string
      role: User_Role_Enum
      first_name?: string | null
      last_name?: string | null
      full_name?: string | null
      id_number?: string | null
      status?: User_Status_Enum | null
      phone_number?: string | null
      deleted_at?: any | null
      updated_at: any
      created_at: any
      user_locations: Array<{ __typename?: 'user_location'; location_id: any }>
    } | null
    location?: {
      __typename?: 'location'
      id: any
      name: string
      geolocation?: any | null
      phone_number: string
      email: string
      id_number: string
      description: string
      address: string
      tenant_id: any
      subscription_type?: Subscription_Type_Enum | null
      next_subscription_payment_date?: any | null
      minimum_payment_percentage?: number | null
      created_at: any
      updated_at: any
      deleted_at?: any | null
      payment_methods: Array<{
        __typename?: 'location_payment_method'
        id: any
        name: string
        value: string
        method: Payment_Method_Enum
        location_id: any
        created_at: any
        updated_at: any
        deleted_at?: any | null
        location: { __typename?: 'location'; id: any; name: string }
      }>
      tenant: {
        __typename?: 'tenant'
        id: any
        name: string
        created_at: any
        updated_at: any
        deleted_at?: any | null
      }
    } | null
  }>
}

export const AuditLoggedActionFragmentFragmentDoc = gql`
  fragment AuditLoggedActionFragment on audit_logged_actions {
    id
    event_id
    table_name
    action
    user_id
    transaction_id
    row_data
    row_id
    changed_fields
    action_tstamp_tx
    user {
      id
      full_name
    }
  }
`
export const BaseLocationFragmentFragmentDoc = gql`
  fragment BaseLocationFragment on location {
    id
    name
    subscription_type
    next_subscription_payment_date
  }
`
export const DocumentTemplateFragmentFragmentDoc = gql`
  fragment DocumentTemplateFragment on document_template {
    id
    name
    value
    type
    is_active
    location_id
    location {
      ...BaseLocationFragment
    }
    created_at
    updated_at
    deleted_at
  }
  ${BaseLocationFragmentFragmentDoc}
`
export const BaseClientFragmentFragmentDoc = gql`
  fragment BaseClientFragment on client {
    id
    full_name
    id_number
  }
`
export const BaseUserFragmentFragmentDoc = gql`
  fragment BaseUserFragment on user {
    id
    full_name
    id_number
  }
`
export const BaseLoanFragmentFragmentDoc = gql`
  fragment BaseLoanFragment on loan {
    id
    amount
    currency
    type
    interest_percentage
    commission_percentage
    periodicity
    created_at
    client {
      ...BaseClientFragment
    }
    user {
      ...BaseUserFragment
    }
  }
  ${BaseClientFragmentFragmentDoc}
  ${BaseUserFragmentFragmentDoc}
`
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on user {
    id
    firebase_id
    role
    first_name
    last_name
    full_name
    id_number
    status
    phone_number
    user_locations(where: { deleted_at: { _is_null: true } }) {
      location_id
    }
    deleted_at
    updated_at
    created_at
  }
`
export const ClientAddressFragmentFragmentDoc = gql`
  fragment ClientAddressFragment on client_address {
    id
    value
    geolocation
    description
    type
    photo_url
    updated_at
    created_at
  }
`
export const ClientPhoneNumberFragmentFragmentDoc = gql`
  fragment ClientPhoneNumberFragment on client_phone_number {
    id
    value
    description
    type
    created_at
    updated_at
  }
`
export const ClientFileFragmentFragmentDoc = gql`
  fragment ClientFileFragment on client_file {
    id
    value
    type
    description
    created_at
    updated_at
  }
`
export const ClientFragmentFragmentDoc = gql`
  fragment ClientFragment on client {
    id
    first_name
    last_name
    full_name
    email
    id_number
    profession
    workplace
    created_at
    updated_at
    deleted_at
    location_id
    location {
      id
      name
    }
    addresses(where: { deleted_at: { _is_null: true } }) {
      ...ClientAddressFragment
    }
    phone_numbers(where: { deleted_at: { _is_null: true } }) {
      ...ClientPhoneNumberFragment
    }
    files(where: { deleted_at: { _is_null: true } }) {
      ...ClientFileFragment
    }
  }
  ${ClientAddressFragmentFragmentDoc}
  ${ClientPhoneNumberFragmentFragmentDoc}
  ${ClientFileFragmentFragmentDoc}
`
export const LocationPaymentMethodFragmentFragmentDoc = gql`
  fragment LocationPaymentMethodFragment on location_payment_method {
    id
    name
    value
    method
    location_id
    location {
      id
      name
    }
    created_at
    updated_at
    deleted_at
  }
`
export const TenantFragmentFragmentDoc = gql`
  fragment TenantFragment on tenant {
    id
    name
    created_at
    updated_at
    deleted_at
  }
`
export const LocationFragmentFragmentDoc = gql`
  fragment LocationFragment on location {
    id
    name
    geolocation
    phone_number
    email
    id_number
    description
    address
    tenant_id
    subscription_type
    next_subscription_payment_date
    minimum_payment_percentage
    created_at
    updated_at
    deleted_at
    payment_methods(where: { deleted_at: { _is_null: true } }) {
      ...LocationPaymentMethodFragment
    }
    tenant {
      ...TenantFragment
    }
  }
  ${LocationPaymentMethodFragmentFragmentDoc}
  ${TenantFragmentFragmentDoc}
`
export const LoanFragmentFragmentDoc = gql`
  fragment LoanFragment on loan {
    id
    client_id
    amount
    type
    balance
    payment_date
    interest_arrears_amount
    commission_interest_arrears_amount
    interest_percentage
    capital_percentage
    commission_percentage
    status
    periodicity
    periodicity_metadata
    deleted_at
    updated_at
    created_at
    location_id
    user_id
    currency
    client {
      ...ClientFragment
    }
    location {
      ...LocationFragment
    }
    user {
      ...UserFragment
    }
  }
  ${ClientFragmentFragmentDoc}
  ${LocationFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
`
export const PaymentFragmentFragmentDoc = gql`
  fragment PaymentFragment on payment {
    id
    capital_amount
    current_balance_amount
    current_interest_arrears_amount
    current_commission_interest_arrears_amount
    expected_amount
    interest_amount
    amount
    new_balance_amount
    new_interest_arrears_amount
    new_commission_interest_arrears_amount
    notes
    created_at
    deleted_at
    expected_payment_date
    new_payment_date
    updated_at
    user_id
    client_id
    loan_id
    payment_method_id
    payment_method_ref
    days_late
    penalty_amount
    commission_amount
    expected_commission_amount
    expected_capital_amount
    expected_interest_amount
    interest_arrears_amount
    commission_interest_arrears_amount
    type
    user {
      ...UserFragment
    }
    client {
      ...ClientFragment
    }
    loan {
      ...LoanFragment
    }
    payment_method {
      ...LocationPaymentMethodFragment
    }
  }
  ${UserFragmentFragmentDoc}
  ${ClientFragmentFragmentDoc}
  ${LoanFragmentFragmentDoc}
  ${LocationPaymentMethodFragmentFragmentDoc}
`
export const BasePaymentFragmentFragmentDoc = gql`
  fragment BasePaymentFragment on payment {
    id
    amount
    penalty_amount
    created_at
    client {
      ...BaseClientFragment
    }
    user {
      ...BaseUserFragment
    }
    loan {
      id
      currency
      location {
        id
        name
      }
    }
  }
  ${BaseClientFragmentFragmentDoc}
  ${BaseUserFragmentFragmentDoc}
`
export const UserPaymentAggregationFragmentFragmentDoc = gql`
  fragment UserPaymentAggregationFragment on user_payment_aggregation {
    user_id
    amount
    interest_amount
    capital_amount
    commission_amount
    penalty_amount
    commission_interest_arrears_amount
    interest_arrears_amount
    payment_date
    count
    location_id
    user {
      ...UserFragment
    }
    location {
      ...LocationFragment
    }
  }
  ${UserFragmentFragmentDoc}
  ${LocationFragmentFragmentDoc}
`
export const UserLoanAggregationFragmentFragmentDoc = gql`
  fragment UserLoanAggregationFragment on user_loan_aggregation {
    location_id
    user_id
    amount
    today_amount
    late_amount
    up_to_date_amount
    balance
    today_balance
    late_balance
    up_to_date_balance
    count
    today_count
    late_count
    up_to_date_count
    interest_arrears_amount
    commission_interest_arrears_amount
    user {
      ...UserFragment
    }
    location {
      ...LocationFragment
    }
  }
  ${UserFragmentFragmentDoc}
  ${LocationFragmentFragmentDoc}
`
export const PayLoanDocument = gql`
  mutation payLoan($input: PayLoanInput!) {
    payLoan(input: $input) {
      status
    }
  }
`
export type PayLoanMutationFn = Apollo.MutationFunction<
  PayLoanMutation,
  PayLoanMutationVariables
>

/**
 * __usePayLoanMutation__
 *
 * To run a mutation, you first call `usePayLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payLoanMutation, { data, loading, error }] = usePayLoanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayLoanMutation,
    PayLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PayLoanMutation, PayLoanMutationVariables>(
    PayLoanDocument,
    options
  )
}
export type PayLoanMutationHookResult = ReturnType<typeof usePayLoanMutation>
export type PayLoanMutationResult = Apollo.MutationResult<PayLoanMutation>
export type PayLoanMutationOptions = Apollo.BaseMutationOptions<
  PayLoanMutation,
  PayLoanMutationVariables
>
export const LinkUserDocument = gql`
  mutation linkUser($input: LinkUserInput!) {
    linkUser(input: $input) {
      status
    }
  }
`
export type LinkUserMutationFn = Apollo.MutationFunction<
  LinkUserMutation,
  LinkUserMutationVariables
>

/**
 * __useLinkUserMutation__
 *
 * To run a mutation, you first call `useLinkUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkUserMutation, { data, loading, error }] = useLinkUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkUserMutation,
    LinkUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LinkUserMutation, LinkUserMutationVariables>(
    LinkUserDocument,
    options
  )
}
export type LinkUserMutationHookResult = ReturnType<typeof useLinkUserMutation>
export type LinkUserMutationResult = Apollo.MutationResult<LinkUserMutation>
export type LinkUserMutationOptions = Apollo.BaseMutationOptions<
  LinkUserMutation,
  LinkUserMutationVariables
>
export const UpdateUserActionDocument = gql`
  mutation updateUserAction($input: UpdateUserInput!) {
    updateUser(input: $input) {
      status
    }
  }
`
export type UpdateUserActionMutationFn = Apollo.MutationFunction<
  UpdateUserActionMutation,
  UpdateUserActionMutationVariables
>

/**
 * __useUpdateUserActionMutation__
 *
 * To run a mutation, you first call `useUpdateUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserActionMutation, { data, loading, error }] = useUpdateUserActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserActionMutation,
    UpdateUserActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserActionMutation,
    UpdateUserActionMutationVariables
  >(UpdateUserActionDocument, options)
}
export type UpdateUserActionMutationHookResult = ReturnType<
  typeof useUpdateUserActionMutation
>
export type UpdateUserActionMutationResult =
  Apollo.MutationResult<UpdateUserActionMutation>
export type UpdateUserActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserActionMutation,
  UpdateUserActionMutationVariables
>
export const GetAuditLoggedActionsDocument = gql`
  query getAuditLoggedActions(
    $limit: Int
    $offset: Int
    $distinct_on: [audit_logged_actions_select_column!]
    $order_by: [audit_logged_actions_order_by!]
    $where: audit_logged_actions_bool_exp
  ) {
    auditLoggedActionsAggregate: audit_logged_actions_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    auditLoggedActions: audit_logged_actions(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...AuditLoggedActionFragment
    }
  }
  ${AuditLoggedActionFragmentFragmentDoc}
`

/**
 * __useGetAuditLoggedActionsQuery__
 *
 * To run a query within a React component, call `useGetAuditLoggedActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLoggedActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLoggedActionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAuditLoggedActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAuditLoggedActionsQuery,
    GetAuditLoggedActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAuditLoggedActionsQuery,
    GetAuditLoggedActionsQueryVariables
  >(GetAuditLoggedActionsDocument, options)
}
export function useGetAuditLoggedActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuditLoggedActionsQuery,
    GetAuditLoggedActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAuditLoggedActionsQuery,
    GetAuditLoggedActionsQueryVariables
  >(GetAuditLoggedActionsDocument, options)
}
export type GetAuditLoggedActionsQueryHookResult = ReturnType<
  typeof useGetAuditLoggedActionsQuery
>
export type GetAuditLoggedActionsLazyQueryHookResult = ReturnType<
  typeof useGetAuditLoggedActionsLazyQuery
>
export type GetAuditLoggedActionsQueryResult = Apollo.QueryResult<
  GetAuditLoggedActionsQuery,
  GetAuditLoggedActionsQueryVariables
>
export const GetAuditLoggedActionDocument = gql`
  query getAuditLoggedAction($id: uuid!) {
    auditLoggedAction: audit_logged_actions_by_pk(id: $id) {
      ...AuditLoggedActionFragment
    }
  }
  ${AuditLoggedActionFragmentFragmentDoc}
`

/**
 * __useGetAuditLoggedActionQuery__
 *
 * To run a query within a React component, call `useGetAuditLoggedActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLoggedActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLoggedActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuditLoggedActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuditLoggedActionQuery,
    GetAuditLoggedActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetAuditLoggedActionQuery,
    GetAuditLoggedActionQueryVariables
  >(GetAuditLoggedActionDocument, options)
}
export function useGetAuditLoggedActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuditLoggedActionQuery,
    GetAuditLoggedActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetAuditLoggedActionQuery,
    GetAuditLoggedActionQueryVariables
  >(GetAuditLoggedActionDocument, options)
}
export type GetAuditLoggedActionQueryHookResult = ReturnType<
  typeof useGetAuditLoggedActionQuery
>
export type GetAuditLoggedActionLazyQueryHookResult = ReturnType<
  typeof useGetAuditLoggedActionLazyQuery
>
export type GetAuditLoggedActionQueryResult = Apollo.QueryResult<
  GetAuditLoggedActionQuery,
  GetAuditLoggedActionQueryVariables
>
export const GetChartDictionariesDocument = gql`
  query getChartDictionaries {
    users: user {
      ...BaseUserFragment
    }
    locations: location {
      ...BaseLocationFragment
    }
  }
  ${BaseUserFragmentFragmentDoc}
  ${BaseLocationFragmentFragmentDoc}
`

/**
 * __useGetChartDictionariesQuery__
 *
 * To run a query within a React component, call `useGetChartDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChartDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChartDictionariesQuery,
    GetChartDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetChartDictionariesQuery,
    GetChartDictionariesQueryVariables
  >(GetChartDictionariesDocument, options)
}
export function useGetChartDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChartDictionariesQuery,
    GetChartDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetChartDictionariesQuery,
    GetChartDictionariesQueryVariables
  >(GetChartDictionariesDocument, options)
}
export type GetChartDictionariesQueryHookResult = ReturnType<
  typeof useGetChartDictionariesQuery
>
export type GetChartDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetChartDictionariesLazyQuery
>
export type GetChartDictionariesQueryResult = Apollo.QueryResult<
  GetChartDictionariesQuery,
  GetChartDictionariesQueryVariables
>
export const GetChartLocationsDocument = gql`
  query getChartLocations {
    locations: location {
      ...BaseLocationFragment
    }
  }
  ${BaseLocationFragmentFragmentDoc}
`

/**
 * __useGetChartLocationsQuery__
 *
 * To run a query within a React component, call `useGetChartLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChartLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChartLocationsQuery,
    GetChartLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetChartLocationsQuery,
    GetChartLocationsQueryVariables
  >(GetChartLocationsDocument, options)
}
export function useGetChartLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChartLocationsQuery,
    GetChartLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetChartLocationsQuery,
    GetChartLocationsQueryVariables
  >(GetChartLocationsDocument, options)
}
export type GetChartLocationsQueryHookResult = ReturnType<
  typeof useGetChartLocationsQuery
>
export type GetChartLocationsLazyQueryHookResult = ReturnType<
  typeof useGetChartLocationsLazyQuery
>
export type GetChartLocationsQueryResult = Apollo.QueryResult<
  GetChartLocationsQuery,
  GetChartLocationsQueryVariables
>
export const GetChartsSummaryDataDocument = gql`
  query getChartsSummaryData(
    $clientWhere: client_bool_exp
    $loanWhere: loan_bool_exp
    $upToDateLoanWhere: loan_bool_exp
    $todayLoanWhere: loan_bool_exp
    $lateLoanWhere: loan_bool_exp
    $paymentWhere: payment_bool_exp
    $todayPaymentWhere: payment_bool_exp
    $weeklyPaymentWhere: payment_bool_exp
    $monthlyPaymentWhere: payment_bool_exp
  ) {
    clientsAggregate: client_aggregate(where: $clientWhere) {
      aggregate {
        totalCount: count
      }
    }
    loansAggregate: loan_aggregate(where: $loanWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          balance
        }
      }
    }
    upToDateLoansAggregate: loan_aggregate(where: $upToDateLoanWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          balance
        }
      }
    }
    todayLoansAggregate: loan_aggregate(where: $todayLoanWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          balance
        }
      }
    }
    lateLoansAggregate: loan_aggregate(where: $lateLoanWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          balance
        }
      }
    }
    paymentsAggregate: payment_aggregate(where: $paymentWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          penalty_amount
        }
      }
    }
    todayPaymentsAggregate: payment_aggregate(where: $todayPaymentWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          penalty_amount
        }
      }
    }
    weeklyPaymentsAggregate: payment_aggregate(where: $weeklyPaymentWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          penalty_amount
        }
      }
    }
    monthlyPaymentsAggregate: payment_aggregate(where: $monthlyPaymentWhere) {
      aggregate {
        totalCount: count
        sum {
          amount
          penalty_amount
        }
      }
    }
  }
`

/**
 * __useGetChartsSummaryDataQuery__
 *
 * To run a query within a React component, call `useGetChartsSummaryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChartsSummaryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartsSummaryDataQuery({
 *   variables: {
 *      clientWhere: // value for 'clientWhere'
 *      loanWhere: // value for 'loanWhere'
 *      upToDateLoanWhere: // value for 'upToDateLoanWhere'
 *      todayLoanWhere: // value for 'todayLoanWhere'
 *      lateLoanWhere: // value for 'lateLoanWhere'
 *      paymentWhere: // value for 'paymentWhere'
 *      todayPaymentWhere: // value for 'todayPaymentWhere'
 *      weeklyPaymentWhere: // value for 'weeklyPaymentWhere'
 *      monthlyPaymentWhere: // value for 'monthlyPaymentWhere'
 *   },
 * });
 */
export function useGetChartsSummaryDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChartsSummaryDataQuery,
    GetChartsSummaryDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetChartsSummaryDataQuery,
    GetChartsSummaryDataQueryVariables
  >(GetChartsSummaryDataDocument, options)
}
export function useGetChartsSummaryDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChartsSummaryDataQuery,
    GetChartsSummaryDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetChartsSummaryDataQuery,
    GetChartsSummaryDataQueryVariables
  >(GetChartsSummaryDataDocument, options)
}
export type GetChartsSummaryDataQueryHookResult = ReturnType<
  typeof useGetChartsSummaryDataQuery
>
export type GetChartsSummaryDataLazyQueryHookResult = ReturnType<
  typeof useGetChartsSummaryDataLazyQuery
>
export type GetChartsSummaryDataQueryResult = Apollo.QueryResult<
  GetChartsSummaryDataQuery,
  GetChartsSummaryDataQueryVariables
>
export const GetClientsDocument = gql`
  query getClients(
    $limit: Int
    $offset: Int
    $distinct_on: [client_select_column!]
    $order_by: [client_order_by!]
    $where: client_bool_exp
  ) {
    clientsAggregate: client_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    clients: client(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...ClientFragment
    }
  }
  ${ClientFragmentFragmentDoc}
`

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options
  )
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options
  )
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>
export type GetClientsLazyQueryHookResult = ReturnType<
  typeof useGetClientsLazyQuery
>
export type GetClientsQueryResult = Apollo.QueryResult<
  GetClientsQuery,
  GetClientsQueryVariables
>
export const GetClientDocument = gql`
  query getClient($id: uuid!) {
    client: client_by_pk(id: $id) {
      ...ClientFragment
    }
  }
  ${ClientFragmentFragmentDoc}
`

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientQuery(
  baseOptions: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options
  )
}
export function useGetClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options
  )
}
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>
export type GetClientLazyQueryHookResult = ReturnType<
  typeof useGetClientLazyQuery
>
export type GetClientQueryResult = Apollo.QueryResult<
  GetClientQuery,
  GetClientQueryVariables
>
export const UpdateClientDocument = gql`
  mutation updateClient($id: uuid!, $set: client_set_input!) {
    client: update_client_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...ClientFragment
    }
  }
  ${ClientFragmentFragmentDoc}
`
export type UpdateClientMutationFn = Apollo.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UpdateClientDocument, options)
}
export type UpdateClientMutationHookResult = ReturnType<
  typeof useUpdateClientMutation
>
export type UpdateClientMutationResult =
  Apollo.MutationResult<UpdateClientMutation>
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>
export const InsertClientDocument = gql`
  mutation insertClient($object: client_insert_input!) {
    client: insert_client_one(object: $object) {
      ...ClientFragment
    }
  }
  ${ClientFragmentFragmentDoc}
`
export type InsertClientMutationFn = Apollo.MutationFunction<
  InsertClientMutation,
  InsertClientMutationVariables
>

/**
 * __useInsertClientMutation__
 *
 * To run a mutation, you first call `useInsertClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientMutation, { data, loading, error }] = useInsertClientMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientMutation,
    InsertClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertClientMutation,
    InsertClientMutationVariables
  >(InsertClientDocument, options)
}
export type InsertClientMutationHookResult = ReturnType<
  typeof useInsertClientMutation
>
export type InsertClientMutationResult =
  Apollo.MutationResult<InsertClientMutation>
export type InsertClientMutationOptions = Apollo.BaseMutationOptions<
  InsertClientMutation,
  InsertClientMutationVariables
>
export const GetClientDictionariesDocument = gql`
  query getClientDictionaries {
    locations: location(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`

/**
 * __useGetClientDictionariesQuery__
 *
 * To run a query within a React component, call `useGetClientDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientDictionariesQuery,
    GetClientDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetClientDictionariesQuery,
    GetClientDictionariesQueryVariables
  >(GetClientDictionariesDocument, options)
}
export function useGetClientDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientDictionariesQuery,
    GetClientDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetClientDictionariesQuery,
    GetClientDictionariesQueryVariables
  >(GetClientDictionariesDocument, options)
}
export type GetClientDictionariesQueryHookResult = ReturnType<
  typeof useGetClientDictionariesQuery
>
export type GetClientDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetClientDictionariesLazyQuery
>
export type GetClientDictionariesQueryResult = Apollo.QueryResult<
  GetClientDictionariesQuery,
  GetClientDictionariesQueryVariables
>
export const GetBaseClientsDocument = gql`
  query getBaseClients($order_by: [client_order_by!], $where: client_bool_exp) {
    clients: client(order_by: $order_by, where: $where) {
      ...BaseClientFragment
    }
  }
  ${BaseClientFragmentFragmentDoc}
`

/**
 * __useGetBaseClientsQuery__
 *
 * To run a query within a React component, call `useGetBaseClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseClientsQuery({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBaseClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseClientsQuery,
    GetBaseClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBaseClientsQuery, GetBaseClientsQueryVariables>(
    GetBaseClientsDocument,
    options
  )
}
export function useGetBaseClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseClientsQuery,
    GetBaseClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBaseClientsQuery, GetBaseClientsQueryVariables>(
    GetBaseClientsDocument,
    options
  )
}
export type GetBaseClientsQueryHookResult = ReturnType<
  typeof useGetBaseClientsQuery
>
export type GetBaseClientsLazyQueryHookResult = ReturnType<
  typeof useGetBaseClientsLazyQuery
>
export type GetBaseClientsQueryResult = Apollo.QueryResult<
  GetBaseClientsQuery,
  GetBaseClientsQueryVariables
>
export const UpsertClientDocument = gql`
  mutation upsertClient(
    $object: client_insert_input!
    $on_conflict: client_on_conflict!
  ) {
    client: insert_client_one(object: $object, on_conflict: $on_conflict) {
      ...ClientFragment
    }
  }
  ${ClientFragmentFragmentDoc}
`
export type UpsertClientMutationFn = Apollo.MutationFunction<
  UpsertClientMutation,
  UpsertClientMutationVariables
>

/**
 * __useUpsertClientMutation__
 *
 * To run a mutation, you first call `useUpsertClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertClientMutation, { data, loading, error }] = useUpsertClientMutation({
 *   variables: {
 *      object: // value for 'object'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertClientMutation,
    UpsertClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertClientMutation,
    UpsertClientMutationVariables
  >(UpsertClientDocument, options)
}
export type UpsertClientMutationHookResult = ReturnType<
  typeof useUpsertClientMutation
>
export type UpsertClientMutationResult =
  Apollo.MutationResult<UpsertClientMutation>
export type UpsertClientMutationOptions = Apollo.BaseMutationOptions<
  UpsertClientMutation,
  UpsertClientMutationVariables
>
export const GetDocumentTemplatesDocument = gql`
  query getDocumentTemplates(
    $limit: Int
    $offset: Int
    $distinct_on: [document_template_select_column!]
    $order_by: [document_template_order_by!]
    $where: document_template_bool_exp
  ) {
    documentTemplatesAggregate: document_template_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    documentTemplates: document_template(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...DocumentTemplateFragment
    }
  }
  ${DocumentTemplateFragmentFragmentDoc}
`

/**
 * __useGetDocumentTemplatesQuery__
 *
 * To run a query within a React component, call `useGetDocumentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTemplatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDocumentTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDocumentTemplatesQuery,
    GetDocumentTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDocumentTemplatesQuery,
    GetDocumentTemplatesQueryVariables
  >(GetDocumentTemplatesDocument, options)
}
export function useGetDocumentTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentTemplatesQuery,
    GetDocumentTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDocumentTemplatesQuery,
    GetDocumentTemplatesQueryVariables
  >(GetDocumentTemplatesDocument, options)
}
export type GetDocumentTemplatesQueryHookResult = ReturnType<
  typeof useGetDocumentTemplatesQuery
>
export type GetDocumentTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetDocumentTemplatesLazyQuery
>
export type GetDocumentTemplatesQueryResult = Apollo.QueryResult<
  GetDocumentTemplatesQuery,
  GetDocumentTemplatesQueryVariables
>
export const GetDocumentTemplateDocument = gql`
  query getDocumentTemplate($id: uuid!) {
    documentTemplate: document_template_by_pk(id: $id) {
      ...DocumentTemplateFragment
    }
  }
  ${DocumentTemplateFragmentFragmentDoc}
`

/**
 * __useGetDocumentTemplateQuery__
 *
 * To run a query within a React component, call `useGetDocumentTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocumentTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocumentTemplateQuery,
    GetDocumentTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDocumentTemplateQuery,
    GetDocumentTemplateQueryVariables
  >(GetDocumentTemplateDocument, options)
}
export function useGetDocumentTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentTemplateQuery,
    GetDocumentTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDocumentTemplateQuery,
    GetDocumentTemplateQueryVariables
  >(GetDocumentTemplateDocument, options)
}
export type GetDocumentTemplateQueryHookResult = ReturnType<
  typeof useGetDocumentTemplateQuery
>
export type GetDocumentTemplateLazyQueryHookResult = ReturnType<
  typeof useGetDocumentTemplateLazyQuery
>
export type GetDocumentTemplateQueryResult = Apollo.QueryResult<
  GetDocumentTemplateQuery,
  GetDocumentTemplateQueryVariables
>
export const UpdateDocumentTemplateDocument = gql`
  mutation updateDocumentTemplate(
    $id: uuid!
    $set: document_template_set_input!
  ) {
    documentTemplate: update_document_template_by_pk(
      pk_columns: { id: $id }
      _set: $set
    ) {
      ...DocumentTemplateFragment
    }
  }
  ${DocumentTemplateFragmentFragmentDoc}
`
export type UpdateDocumentTemplateMutationFn = Apollo.MutationFunction<
  UpdateDocumentTemplateMutation,
  UpdateDocumentTemplateMutationVariables
>

/**
 * __useUpdateDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentTemplateMutation, { data, loading, error }] = useUpdateDocumentTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentTemplateMutation,
    UpdateDocumentTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentTemplateMutation,
    UpdateDocumentTemplateMutationVariables
  >(UpdateDocumentTemplateDocument, options)
}
export type UpdateDocumentTemplateMutationHookResult = ReturnType<
  typeof useUpdateDocumentTemplateMutation
>
export type UpdateDocumentTemplateMutationResult =
  Apollo.MutationResult<UpdateDocumentTemplateMutation>
export type UpdateDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentTemplateMutation,
  UpdateDocumentTemplateMutationVariables
>
export const InsertDocumentTemplateDocument = gql`
  mutation insertDocumentTemplate($object: document_template_insert_input!) {
    documentTemplate: insert_document_template_one(object: $object) {
      ...DocumentTemplateFragment
    }
  }
  ${DocumentTemplateFragmentFragmentDoc}
`
export type InsertDocumentTemplateMutationFn = Apollo.MutationFunction<
  InsertDocumentTemplateMutation,
  InsertDocumentTemplateMutationVariables
>

/**
 * __useInsertDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useInsertDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDocumentTemplateMutation, { data, loading, error }] = useInsertDocumentTemplateMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertDocumentTemplateMutation,
    InsertDocumentTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertDocumentTemplateMutation,
    InsertDocumentTemplateMutationVariables
  >(InsertDocumentTemplateDocument, options)
}
export type InsertDocumentTemplateMutationHookResult = ReturnType<
  typeof useInsertDocumentTemplateMutation
>
export type InsertDocumentTemplateMutationResult =
  Apollo.MutationResult<InsertDocumentTemplateMutation>
export type InsertDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  InsertDocumentTemplateMutation,
  InsertDocumentTemplateMutationVariables
>
export const UpsertDocumentTemplateDocument = gql`
  mutation upsertDocumentTemplate(
    $object: document_template_insert_input!
    $on_conflict: document_template_on_conflict!
  ) {
    documentTemplate: insert_document_template_one(
      object: $object
      on_conflict: $on_conflict
    ) {
      ...DocumentTemplateFragment
    }
  }
  ${DocumentTemplateFragmentFragmentDoc}
`
export type UpsertDocumentTemplateMutationFn = Apollo.MutationFunction<
  UpsertDocumentTemplateMutation,
  UpsertDocumentTemplateMutationVariables
>

/**
 * __useUpsertDocumentTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertDocumentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDocumentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDocumentTemplateMutation, { data, loading, error }] = useUpsertDocumentTemplateMutation({
 *   variables: {
 *      object: // value for 'object'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertDocumentTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertDocumentTemplateMutation,
    UpsertDocumentTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertDocumentTemplateMutation,
    UpsertDocumentTemplateMutationVariables
  >(UpsertDocumentTemplateDocument, options)
}
export type UpsertDocumentTemplateMutationHookResult = ReturnType<
  typeof useUpsertDocumentTemplateMutation
>
export type UpsertDocumentTemplateMutationResult =
  Apollo.MutationResult<UpsertDocumentTemplateMutation>
export type UpsertDocumentTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpsertDocumentTemplateMutation,
  UpsertDocumentTemplateMutationVariables
>
export const GetDocumentTemplateDictionariesDocument = gql`
  query getDocumentTemplateDictionaries {
    locations: location(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`

/**
 * __useGetDocumentTemplateDictionariesQuery__
 *
 * To run a query within a React component, call `useGetDocumentTemplateDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentTemplateDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentTemplateDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentTemplateDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDocumentTemplateDictionariesQuery,
    GetDocumentTemplateDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDocumentTemplateDictionariesQuery,
    GetDocumentTemplateDictionariesQueryVariables
  >(GetDocumentTemplateDictionariesDocument, options)
}
export function useGetDocumentTemplateDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocumentTemplateDictionariesQuery,
    GetDocumentTemplateDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDocumentTemplateDictionariesQuery,
    GetDocumentTemplateDictionariesQueryVariables
  >(GetDocumentTemplateDictionariesDocument, options)
}
export type GetDocumentTemplateDictionariesQueryHookResult = ReturnType<
  typeof useGetDocumentTemplateDictionariesQuery
>
export type GetDocumentTemplateDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetDocumentTemplateDictionariesLazyQuery
>
export type GetDocumentTemplateDictionariesQueryResult = Apollo.QueryResult<
  GetDocumentTemplateDictionariesQuery,
  GetDocumentTemplateDictionariesQueryVariables
>
export const GetLoansDocument = gql`
  query getLoans(
    $limit: Int
    $offset: Int
    $distinct_on: [loan_select_column!]
    $order_by: [loan_order_by!]
    $where: loan_bool_exp
  ) {
    loansAggregate: loan_aggregate(where: $where) {
      aggregate {
        totalCount: count
        sum {
          amount
          balance
          interest_arrears_amount
          commission_interest_arrears_amount
        }
      }
    }
    loans: loan(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...LoanFragment
    }
  }
  ${LoanFragmentFragmentDoc}
`

/**
 * __useGetLoansQuery__
 *
 * To run a query within a React component, call `useGetLoansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoansQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLoansQuery, GetLoansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLoansQuery, GetLoansQueryVariables>(
    GetLoansDocument,
    options
  )
}
export function useGetLoansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoansQuery,
    GetLoansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLoansQuery, GetLoansQueryVariables>(
    GetLoansDocument,
    options
  )
}
export type GetLoansQueryHookResult = ReturnType<typeof useGetLoansQuery>
export type GetLoansLazyQueryHookResult = ReturnType<
  typeof useGetLoansLazyQuery
>
export type GetLoansQueryResult = Apollo.QueryResult<
  GetLoansQuery,
  GetLoansQueryVariables
>
export const GetBaseLoansDocument = gql`
  query getBaseLoans(
    $limit: Int
    $offset: Int
    $distinct_on: [loan_select_column!]
    $order_by: [loan_order_by!]
    $where: loan_bool_exp
  ) {
    loansAggregate: loan_aggregate(where: $where) {
      aggregate {
        totalCount: count
        sum {
          amount
        }
      }
    }
    loans: loan(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...BaseLoanFragment
    }
  }
  ${BaseLoanFragmentFragmentDoc}
`

/**
 * __useGetBaseLoansQuery__
 *
 * To run a query within a React component, call `useGetBaseLoansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseLoansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseLoansQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBaseLoansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseLoansQuery,
    GetBaseLoansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBaseLoansQuery, GetBaseLoansQueryVariables>(
    GetBaseLoansDocument,
    options
  )
}
export function useGetBaseLoansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseLoansQuery,
    GetBaseLoansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBaseLoansQuery, GetBaseLoansQueryVariables>(
    GetBaseLoansDocument,
    options
  )
}
export type GetBaseLoansQueryHookResult = ReturnType<
  typeof useGetBaseLoansQuery
>
export type GetBaseLoansLazyQueryHookResult = ReturnType<
  typeof useGetBaseLoansLazyQuery
>
export type GetBaseLoansQueryResult = Apollo.QueryResult<
  GetBaseLoansQuery,
  GetBaseLoansQueryVariables
>
export const GetLoanDocument = gql`
  query getLoan($id: uuid!) {
    loan: loan_by_pk(id: $id) {
      ...LoanFragment
    }
  }
  ${LoanFragmentFragmentDoc}
`

/**
 * __useGetLoanQuery__
 *
 * To run a query within a React component, call `useGetLoanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLoanQuery(
  baseOptions: Apollo.QueryHookOptions<GetLoanQuery, GetLoanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLoanQuery, GetLoanQueryVariables>(
    GetLoanDocument,
    options
  )
}
export function useGetLoanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLoanQuery, GetLoanQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLoanQuery, GetLoanQueryVariables>(
    GetLoanDocument,
    options
  )
}
export type GetLoanQueryHookResult = ReturnType<typeof useGetLoanQuery>
export type GetLoanLazyQueryHookResult = ReturnType<typeof useGetLoanLazyQuery>
export type GetLoanQueryResult = Apollo.QueryResult<
  GetLoanQuery,
  GetLoanQueryVariables
>
export const UpdateLoanDocument = gql`
  mutation updateLoan($id: uuid!, $set: loan_set_input!) {
    loan: update_loan_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...LoanFragment
    }
  }
  ${LoanFragmentFragmentDoc}
`
export type UpdateLoanMutationFn = Apollo.MutationFunction<
  UpdateLoanMutation,
  UpdateLoanMutationVariables
>

/**
 * __useUpdateLoanMutation__
 *
 * To run a mutation, you first call `useUpdateLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoanMutation, { data, loading, error }] = useUpdateLoanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLoanMutation,
    UpdateLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateLoanMutation, UpdateLoanMutationVariables>(
    UpdateLoanDocument,
    options
  )
}
export type UpdateLoanMutationHookResult = ReturnType<
  typeof useUpdateLoanMutation
>
export type UpdateLoanMutationResult = Apollo.MutationResult<UpdateLoanMutation>
export type UpdateLoanMutationOptions = Apollo.BaseMutationOptions<
  UpdateLoanMutation,
  UpdateLoanMutationVariables
>
export const InsertLoanDocument = gql`
  mutation insertLoan($object: loan_insert_input!) {
    loan: insert_loan_one(object: $object) {
      ...LoanFragment
    }
  }
  ${LoanFragmentFragmentDoc}
`
export type InsertLoanMutationFn = Apollo.MutationFunction<
  InsertLoanMutation,
  InsertLoanMutationVariables
>

/**
 * __useInsertLoanMutation__
 *
 * To run a mutation, you first call `useInsertLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLoanMutation, { data, loading, error }] = useInsertLoanMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertLoanMutation,
    InsertLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<InsertLoanMutation, InsertLoanMutationVariables>(
    InsertLoanDocument,
    options
  )
}
export type InsertLoanMutationHookResult = ReturnType<
  typeof useInsertLoanMutation
>
export type InsertLoanMutationResult = Apollo.MutationResult<InsertLoanMutation>
export type InsertLoanMutationOptions = Apollo.BaseMutationOptions<
  InsertLoanMutation,
  InsertLoanMutationVariables
>
export const UpsertLoanDocument = gql`
  mutation upsertLoan(
    $object: loan_insert_input!
    $on_conflict: loan_on_conflict!
  ) {
    loan: insert_loan_one(object: $object, on_conflict: $on_conflict) {
      ...LoanFragment
    }
  }
  ${LoanFragmentFragmentDoc}
`
export type UpsertLoanMutationFn = Apollo.MutationFunction<
  UpsertLoanMutation,
  UpsertLoanMutationVariables
>

/**
 * __useUpsertLoanMutation__
 *
 * To run a mutation, you first call `useUpsertLoanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLoanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLoanMutation, { data, loading, error }] = useUpsertLoanMutation({
 *   variables: {
 *      object: // value for 'object'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertLoanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertLoanMutation,
    UpsertLoanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertLoanMutation, UpsertLoanMutationVariables>(
    UpsertLoanDocument,
    options
  )
}
export type UpsertLoanMutationHookResult = ReturnType<
  typeof useUpsertLoanMutation
>
export type UpsertLoanMutationResult = Apollo.MutationResult<UpsertLoanMutation>
export type UpsertLoanMutationOptions = Apollo.BaseMutationOptions<
  UpsertLoanMutation,
  UpsertLoanMutationVariables
>
export const GetLoanDictionariesDocument = gql`
  query getLoanDictionaries {
    locations: location(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`

/**
 * __useGetLoanDictionariesQuery__
 *
 * To run a query within a React component, call `useGetLoanDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoanDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoanDictionariesQuery,
    GetLoanDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLoanDictionariesQuery,
    GetLoanDictionariesQueryVariables
  >(GetLoanDictionariesDocument, options)
}
export function useGetLoanDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanDictionariesQuery,
    GetLoanDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLoanDictionariesQuery,
    GetLoanDictionariesQueryVariables
  >(GetLoanDictionariesDocument, options)
}
export type GetLoanDictionariesQueryHookResult = ReturnType<
  typeof useGetLoanDictionariesQuery
>
export type GetLoanDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetLoanDictionariesLazyQuery
>
export type GetLoanDictionariesQueryResult = Apollo.QueryResult<
  GetLoanDictionariesQuery,
  GetLoanDictionariesQueryVariables
>
export const GetLoanDictionariesByLocationIdDocument = gql`
  query getLoanDictionariesByLocationId($locationId: uuid!) {
    users: user(
      where: {
        _or: [
          { deleted_at: { _is_null: true }, role: { _in: [TENANT_ADMIN] } }
          {
            deleted_at: { _is_null: true }
            user_locations: { location_id: { _eq: $locationId } }
          }
        ]
      }
    ) {
      ...BaseUserFragment
    }
    clients: client(
      where: {
        deleted_at: { _is_null: true }
        location_id: { _eq: $locationId }
      }
    ) {
      ...BaseClientFragment
    }
  }
  ${BaseUserFragmentFragmentDoc}
  ${BaseClientFragmentFragmentDoc}
`

/**
 * __useGetLoanDictionariesByLocationIdQuery__
 *
 * To run a query within a React component, call `useGetLoanDictionariesByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoanDictionariesByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoanDictionariesByLocationIdQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLoanDictionariesByLocationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLoanDictionariesByLocationIdQuery,
    GetLoanDictionariesByLocationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLoanDictionariesByLocationIdQuery,
    GetLoanDictionariesByLocationIdQueryVariables
  >(GetLoanDictionariesByLocationIdDocument, options)
}
export function useGetLoanDictionariesByLocationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoanDictionariesByLocationIdQuery,
    GetLoanDictionariesByLocationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLoanDictionariesByLocationIdQuery,
    GetLoanDictionariesByLocationIdQueryVariables
  >(GetLoanDictionariesByLocationIdDocument, options)
}
export type GetLoanDictionariesByLocationIdQueryHookResult = ReturnType<
  typeof useGetLoanDictionariesByLocationIdQuery
>
export type GetLoanDictionariesByLocationIdLazyQueryHookResult = ReturnType<
  typeof useGetLoanDictionariesByLocationIdLazyQuery
>
export type GetLoanDictionariesByLocationIdQueryResult = Apollo.QueryResult<
  GetLoanDictionariesByLocationIdQuery,
  GetLoanDictionariesByLocationIdQueryVariables
>
export const GetLoansAggregateDocument = gql`
  query getLoansAggregate($where: loan_bool_exp) {
    loansAggregate: loan_aggregate(where: $where) {
      aggregate {
        totalCount: count
        sum {
          amount
        }
      }
    }
  }
`

/**
 * __useGetLoansAggregateQuery__
 *
 * To run a query within a React component, call `useGetLoansAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoansAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoansAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLoansAggregateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLoansAggregateQuery,
    GetLoansAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLoansAggregateQuery,
    GetLoansAggregateQueryVariables
  >(GetLoansAggregateDocument, options)
}
export function useGetLoansAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLoansAggregateQuery,
    GetLoansAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLoansAggregateQuery,
    GetLoansAggregateQueryVariables
  >(GetLoansAggregateDocument, options)
}
export type GetLoansAggregateQueryHookResult = ReturnType<
  typeof useGetLoansAggregateQuery
>
export type GetLoansAggregateLazyQueryHookResult = ReturnType<
  typeof useGetLoansAggregateLazyQuery
>
export type GetLoansAggregateQueryResult = Apollo.QueryResult<
  GetLoansAggregateQuery,
  GetLoansAggregateQueryVariables
>
export const GetLocationsDocument = gql`
  query getLocations(
    $limit: Int
    $offset: Int
    $distinct_on: [location_select_column!]
    $order_by: [location_order_by!]
    $where: location_bool_exp
  ) {
    locationsAggregate: location_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    locations: location(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  )
}
export function useGetLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  )
}
export type GetLocationsQueryHookResult = ReturnType<
  typeof useGetLocationsQuery
>
export type GetLocationsLazyQueryHookResult = ReturnType<
  typeof useGetLocationsLazyQuery
>
export type GetLocationsQueryResult = Apollo.QueryResult<
  GetLocationsQuery,
  GetLocationsQueryVariables
>
export const GetLocationDocument = gql`
  query getLocation($id: uuid!) {
    location: location_by_pk(id: $id) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  )
}
export function useGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  )
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>
export type GetLocationLazyQueryHookResult = ReturnType<
  typeof useGetLocationLazyQuery
>
export type GetLocationQueryResult = Apollo.QueryResult<
  GetLocationQuery,
  GetLocationQueryVariables
>
export const UpdateLocationDocument = gql`
  mutation updateLocation($id: uuid!, $set: location_set_input!) {
    location: update_location_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >(UpdateLocationDocument, options)
}
export type UpdateLocationMutationHookResult = ReturnType<
  typeof useUpdateLocationMutation
>
export type UpdateLocationMutationResult =
  Apollo.MutationResult<UpdateLocationMutation>
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>
export const InsertLocationDocument = gql`
  mutation insertLocation($object: location_insert_input!) {
    location: insert_location_one(object: $object) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`
export type InsertLocationMutationFn = Apollo.MutationFunction<
  InsertLocationMutation,
  InsertLocationMutationVariables
>

/**
 * __useInsertLocationMutation__
 *
 * To run a mutation, you first call `useInsertLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationMutation, { data, loading, error }] = useInsertLocationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertLocationMutation,
    InsertLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertLocationMutation,
    InsertLocationMutationVariables
  >(InsertLocationDocument, options)
}
export type InsertLocationMutationHookResult = ReturnType<
  typeof useInsertLocationMutation
>
export type InsertLocationMutationResult =
  Apollo.MutationResult<InsertLocationMutation>
export type InsertLocationMutationOptions = Apollo.BaseMutationOptions<
  InsertLocationMutation,
  InsertLocationMutationVariables
>
export const GetLocationPaymentMethodsDocument = gql`
  query getLocationPaymentMethods(
    $limit: Int
    $offset: Int
    $distinct_on: [location_payment_method_select_column!]
    $order_by: [location_payment_method_order_by!]
    $where: location_payment_method_bool_exp
  ) {
    locationPaymentMethodsAggregate: location_payment_method_aggregate(
      where: $where
    ) {
      aggregate {
        totalCount: count
      }
    }
    locationPaymentMethods: location_payment_method(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...LocationPaymentMethodFragment
    }
  }
  ${LocationPaymentMethodFragmentFragmentDoc}
`

/**
 * __useGetLocationPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetLocationPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationPaymentMethodsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetLocationPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationPaymentMethodsQuery,
    GetLocationPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLocationPaymentMethodsQuery,
    GetLocationPaymentMethodsQueryVariables
  >(GetLocationPaymentMethodsDocument, options)
}
export function useGetLocationPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationPaymentMethodsQuery,
    GetLocationPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLocationPaymentMethodsQuery,
    GetLocationPaymentMethodsQueryVariables
  >(GetLocationPaymentMethodsDocument, options)
}
export type GetLocationPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetLocationPaymentMethodsQuery
>
export type GetLocationPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetLocationPaymentMethodsLazyQuery
>
export type GetLocationPaymentMethodsQueryResult = Apollo.QueryResult<
  GetLocationPaymentMethodsQuery,
  GetLocationPaymentMethodsQueryVariables
>
export const GetLocationPaymentMethodDocument = gql`
  query getLocationPaymentMethod($id: uuid!) {
    locationPaymentMethod: location_payment_method_by_pk(id: $id) {
      ...LocationPaymentMethodFragment
    }
  }
  ${LocationPaymentMethodFragmentFragmentDoc}
`

/**
 * __useGetLocationPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetLocationPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationPaymentMethodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationPaymentMethodQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocationPaymentMethodQuery,
    GetLocationPaymentMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLocationPaymentMethodQuery,
    GetLocationPaymentMethodQueryVariables
  >(GetLocationPaymentMethodDocument, options)
}
export function useGetLocationPaymentMethodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationPaymentMethodQuery,
    GetLocationPaymentMethodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLocationPaymentMethodQuery,
    GetLocationPaymentMethodQueryVariables
  >(GetLocationPaymentMethodDocument, options)
}
export type GetLocationPaymentMethodQueryHookResult = ReturnType<
  typeof useGetLocationPaymentMethodQuery
>
export type GetLocationPaymentMethodLazyQueryHookResult = ReturnType<
  typeof useGetLocationPaymentMethodLazyQuery
>
export type GetLocationPaymentMethodQueryResult = Apollo.QueryResult<
  GetLocationPaymentMethodQuery,
  GetLocationPaymentMethodQueryVariables
>
export const UpdateLocationPaymentMethodDocument = gql`
  mutation updateLocationPaymentMethod(
    $id: uuid!
    $set: location_payment_method_set_input!
  ) {
    locationPaymentMethod: update_location_payment_method_by_pk(
      pk_columns: { id: $id }
      _set: $set
    ) {
      ...LocationPaymentMethodFragment
    }
  }
  ${LocationPaymentMethodFragmentFragmentDoc}
`
export type UpdateLocationPaymentMethodMutationFn = Apollo.MutationFunction<
  UpdateLocationPaymentMethodMutation,
  UpdateLocationPaymentMethodMutationVariables
>

/**
 * __useUpdateLocationPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateLocationPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationPaymentMethodMutation, { data, loading, error }] = useUpdateLocationPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateLocationPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationPaymentMethodMutation,
    UpdateLocationPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLocationPaymentMethodMutation,
    UpdateLocationPaymentMethodMutationVariables
  >(UpdateLocationPaymentMethodDocument, options)
}
export type UpdateLocationPaymentMethodMutationHookResult = ReturnType<
  typeof useUpdateLocationPaymentMethodMutation
>
export type UpdateLocationPaymentMethodMutationResult =
  Apollo.MutationResult<UpdateLocationPaymentMethodMutation>
export type UpdateLocationPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLocationPaymentMethodMutation,
    UpdateLocationPaymentMethodMutationVariables
  >
export const InsertLocationPaymentMethodDocument = gql`
  mutation insertLocationPaymentMethod(
    $object: location_payment_method_insert_input!
  ) {
    locationPaymentMethod: insert_location_payment_method_one(object: $object) {
      ...LocationPaymentMethodFragment
    }
  }
  ${LocationPaymentMethodFragmentFragmentDoc}
`
export type InsertLocationPaymentMethodMutationFn = Apollo.MutationFunction<
  InsertLocationPaymentMethodMutation,
  InsertLocationPaymentMethodMutationVariables
>

/**
 * __useInsertLocationPaymentMethodMutation__
 *
 * To run a mutation, you first call `useInsertLocationPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLocationPaymentMethodMutation, { data, loading, error }] = useInsertLocationPaymentMethodMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLocationPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertLocationPaymentMethodMutation,
    InsertLocationPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertLocationPaymentMethodMutation,
    InsertLocationPaymentMethodMutationVariables
  >(InsertLocationPaymentMethodDocument, options)
}
export type InsertLocationPaymentMethodMutationHookResult = ReturnType<
  typeof useInsertLocationPaymentMethodMutation
>
export type InsertLocationPaymentMethodMutationResult =
  Apollo.MutationResult<InsertLocationPaymentMethodMutation>
export type InsertLocationPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    InsertLocationPaymentMethodMutation,
    InsertLocationPaymentMethodMutationVariables
  >
export const UpsertLocationPaymentMethodDocument = gql`
  mutation upsertLocationPaymentMethod(
    $object: location_payment_method_insert_input!
    $on_conflict: location_payment_method_on_conflict!
  ) {
    locationPaymentMethod: insert_location_payment_method_one(
      object: $object
      on_conflict: $on_conflict
    ) {
      ...LocationPaymentMethodFragment
    }
  }
  ${LocationPaymentMethodFragmentFragmentDoc}
`
export type UpsertLocationPaymentMethodMutationFn = Apollo.MutationFunction<
  UpsertLocationPaymentMethodMutation,
  UpsertLocationPaymentMethodMutationVariables
>

/**
 * __useUpsertLocationPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpsertLocationPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLocationPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLocationPaymentMethodMutation, { data, loading, error }] = useUpsertLocationPaymentMethodMutation({
 *   variables: {
 *      object: // value for 'object'
 *      on_conflict: // value for 'on_conflict'
 *   },
 * });
 */
export function useUpsertLocationPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertLocationPaymentMethodMutation,
    UpsertLocationPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertLocationPaymentMethodMutation,
    UpsertLocationPaymentMethodMutationVariables
  >(UpsertLocationPaymentMethodDocument, options)
}
export type UpsertLocationPaymentMethodMutationHookResult = ReturnType<
  typeof useUpsertLocationPaymentMethodMutation
>
export type UpsertLocationPaymentMethodMutationResult =
  Apollo.MutationResult<UpsertLocationPaymentMethodMutation>
export type UpsertLocationPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertLocationPaymentMethodMutation,
    UpsertLocationPaymentMethodMutationVariables
  >
export const GetLocationPaymentMethodDictionariesDocument = gql`
  query getLocationPaymentMethodDictionaries {
    locations: location(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`

/**
 * __useGetLocationPaymentMethodDictionariesQuery__
 *
 * To run a query within a React component, call `useGetLocationPaymentMethodDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationPaymentMethodDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationPaymentMethodDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationPaymentMethodDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationPaymentMethodDictionariesQuery,
    GetLocationPaymentMethodDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetLocationPaymentMethodDictionariesQuery,
    GetLocationPaymentMethodDictionariesQueryVariables
  >(GetLocationPaymentMethodDictionariesDocument, options)
}
export function useGetLocationPaymentMethodDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationPaymentMethodDictionariesQuery,
    GetLocationPaymentMethodDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetLocationPaymentMethodDictionariesQuery,
    GetLocationPaymentMethodDictionariesQueryVariables
  >(GetLocationPaymentMethodDictionariesDocument, options)
}
export type GetLocationPaymentMethodDictionariesQueryHookResult = ReturnType<
  typeof useGetLocationPaymentMethodDictionariesQuery
>
export type GetLocationPaymentMethodDictionariesLazyQueryHookResult =
  ReturnType<typeof useGetLocationPaymentMethodDictionariesLazyQuery>
export type GetLocationPaymentMethodDictionariesQueryResult =
  Apollo.QueryResult<
    GetLocationPaymentMethodDictionariesQuery,
    GetLocationPaymentMethodDictionariesQueryVariables
  >
export const GetPaymentsDocument = gql`
  query getPayments(
    $limit: Int
    $offset: Int
    $distinct_on: [payment_select_column!]
    $order_by: [payment_order_by!]
    $where: payment_bool_exp
  ) {
    paymentsAggregate: payment_aggregate(where: $where) {
      aggregate {
        totalCount: count
        sum {
          capital_amount
          current_balance_amount
          current_interest_arrears_amount
          current_commission_interest_arrears_amount
          expected_amount
          interest_amount
          amount
          new_balance_amount
          new_interest_arrears_amount
          new_commission_interest_arrears_amount
          penalty_amount
          commission_amount
          expected_commission_amount
          expected_capital_amount
          expected_interest_amount
          interest_arrears_amount
          commission_interest_arrears_amount
        }
      }
    }
    payments: payment(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...PaymentFragment
    }
  }
  ${PaymentFragmentFragmentDoc}
`

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentsQuery,
    GetPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(
    GetPaymentsDocument,
    options
  )
}
export function useGetPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentsQuery,
    GetPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(
    GetPaymentsDocument,
    options
  )
}
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>
export type GetPaymentsLazyQueryHookResult = ReturnType<
  typeof useGetPaymentsLazyQuery
>
export type GetPaymentsQueryResult = Apollo.QueryResult<
  GetPaymentsQuery,
  GetPaymentsQueryVariables
>
export const GetBasePaymentsDocument = gql`
  query getBasePayments(
    $limit: Int
    $offset: Int
    $distinct_on: [payment_select_column!]
    $order_by: [payment_order_by!]
    $where: payment_bool_exp
  ) {
    paymentsAggregate: payment_aggregate(where: $where) {
      aggregate {
        totalCount: count
        sum {
          amount
          penalty_amount
        }
      }
    }
    payments: payment(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...BasePaymentFragment
    }
  }
  ${BasePaymentFragmentFragmentDoc}
`

/**
 * __useGetBasePaymentsQuery__
 *
 * To run a query within a React component, call `useGetBasePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasePaymentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBasePaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBasePaymentsQuery,
    GetBasePaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBasePaymentsQuery, GetBasePaymentsQueryVariables>(
    GetBasePaymentsDocument,
    options
  )
}
export function useGetBasePaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasePaymentsQuery,
    GetBasePaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBasePaymentsQuery,
    GetBasePaymentsQueryVariables
  >(GetBasePaymentsDocument, options)
}
export type GetBasePaymentsQueryHookResult = ReturnType<
  typeof useGetBasePaymentsQuery
>
export type GetBasePaymentsLazyQueryHookResult = ReturnType<
  typeof useGetBasePaymentsLazyQuery
>
export type GetBasePaymentsQueryResult = Apollo.QueryResult<
  GetBasePaymentsQuery,
  GetBasePaymentsQueryVariables
>
export const GetPaymentDocument = gql`
  query getPayment($id: uuid!) {
    payment: payment_by_pk(id: $id) {
      ...PaymentFragment
    }
  }
  ${PaymentFragmentFragmentDoc}
`

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentQuery,
    GetPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(
    GetPaymentDocument,
    options
  )
}
export function useGetPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentQuery,
    GetPaymentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(
    GetPaymentDocument,
    options
  )
}
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>
export type GetPaymentLazyQueryHookResult = ReturnType<
  typeof useGetPaymentLazyQuery
>
export type GetPaymentQueryResult = Apollo.QueryResult<
  GetPaymentQuery,
  GetPaymentQueryVariables
>
export const UpdatePaymentDocument = gql`
  mutation updatePayment($id: uuid!, $set: payment_set_input!) {
    payment: update_payment_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...PaymentFragment
    }
  }
  ${PaymentFragmentFragmentDoc}
`
export type UpdatePaymentMutationFn = Apollo.MutationFunction<
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables
>

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMutation,
    UpdatePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePaymentMutation,
    UpdatePaymentMutationVariables
  >(UpdatePaymentDocument, options)
}
export type UpdatePaymentMutationHookResult = ReturnType<
  typeof useUpdatePaymentMutation
>
export type UpdatePaymentMutationResult =
  Apollo.MutationResult<UpdatePaymentMutation>
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables
>
export const InsertPaymentDocument = gql`
  mutation insertPayment($object: payment_insert_input!) {
    payment: insert_payment_one(object: $object) {
      ...PaymentFragment
    }
  }
  ${PaymentFragmentFragmentDoc}
`
export type InsertPaymentMutationFn = Apollo.MutationFunction<
  InsertPaymentMutation,
  InsertPaymentMutationVariables
>

/**
 * __useInsertPaymentMutation__
 *
 * To run a mutation, you first call `useInsertPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPaymentMutation, { data, loading, error }] = useInsertPaymentMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertPaymentMutation,
    InsertPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertPaymentMutation,
    InsertPaymentMutationVariables
  >(InsertPaymentDocument, options)
}
export type InsertPaymentMutationHookResult = ReturnType<
  typeof useInsertPaymentMutation
>
export type InsertPaymentMutationResult =
  Apollo.MutationResult<InsertPaymentMutation>
export type InsertPaymentMutationOptions = Apollo.BaseMutationOptions<
  InsertPaymentMutation,
  InsertPaymentMutationVariables
>
export const GetPaymentsWithAggregateDocument = gql`
  query getPaymentsWithAggregate(
    $limit: Int
    $offset: Int
    $distinct_on: [payment_select_column!]
    $order_by: [payment_order_by!]
    $where: payment_bool_exp
  ) {
    paymentsAggregate: payment_aggregate(where: $where) {
      aggregate {
        totalCount: count
        sum {
          capital_amount
          interest_amount
          amount
          penalty_amount
          commission_amount
          interest_arrears_amount
          commission_interest_arrears_amount
        }
      }
    }
    payments: payment(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...BasePaymentFragment
    }
  }
  ${BasePaymentFragmentFragmentDoc}
`

/**
 * __useGetPaymentsWithAggregateQuery__
 *
 * To run a query within a React component, call `useGetPaymentsWithAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsWithAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsWithAggregateQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPaymentsWithAggregateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentsWithAggregateQuery,
    GetPaymentsWithAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPaymentsWithAggregateQuery,
    GetPaymentsWithAggregateQueryVariables
  >(GetPaymentsWithAggregateDocument, options)
}
export function useGetPaymentsWithAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentsWithAggregateQuery,
    GetPaymentsWithAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPaymentsWithAggregateQuery,
    GetPaymentsWithAggregateQueryVariables
  >(GetPaymentsWithAggregateDocument, options)
}
export type GetPaymentsWithAggregateQueryHookResult = ReturnType<
  typeof useGetPaymentsWithAggregateQuery
>
export type GetPaymentsWithAggregateLazyQueryHookResult = ReturnType<
  typeof useGetPaymentsWithAggregateLazyQuery
>
export type GetPaymentsWithAggregateQueryResult = Apollo.QueryResult<
  GetPaymentsWithAggregateQuery,
  GetPaymentsWithAggregateQueryVariables
>
export const GetPaymentsAggregateDocument = gql`
  query getPaymentsAggregate($where: payment_bool_exp) {
    paymentsAggregate: payment_aggregate(where: $where) {
      aggregate {
        totalCount: count
        sum {
          capital_amount
          interest_amount
          amount
          penalty_amount
          commission_amount
          interest_arrears_amount
          commission_interest_arrears_amount
        }
      }
    }
  }
`

/**
 * __useGetPaymentsAggregateQuery__
 *
 * To run a query within a React component, call `useGetPaymentsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsAggregateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPaymentsAggregateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentsAggregateQuery,
    GetPaymentsAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPaymentsAggregateQuery,
    GetPaymentsAggregateQueryVariables
  >(GetPaymentsAggregateDocument, options)
}
export function useGetPaymentsAggregateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentsAggregateQuery,
    GetPaymentsAggregateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPaymentsAggregateQuery,
    GetPaymentsAggregateQueryVariables
  >(GetPaymentsAggregateDocument, options)
}
export type GetPaymentsAggregateQueryHookResult = ReturnType<
  typeof useGetPaymentsAggregateQuery
>
export type GetPaymentsAggregateLazyQueryHookResult = ReturnType<
  typeof useGetPaymentsAggregateLazyQuery
>
export type GetPaymentsAggregateQueryResult = Apollo.QueryResult<
  GetPaymentsAggregateQuery,
  GetPaymentsAggregateQueryVariables
>
export const GetPaymentsDictionariesDocument = gql`
  query getPaymentsDictionaries {
    locations: location(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
    users: user(where: { deleted_at: { _is_null: true } }) {
      id
      full_name
    }
    clients: client(where: { deleted_at: { _is_null: true } }) {
      id
      full_name
    }
  }
`

/**
 * __useGetPaymentsDictionariesQuery__
 *
 * To run a query within a React component, call `useGetPaymentsDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentsDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPaymentsDictionariesQuery,
    GetPaymentsDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPaymentsDictionariesQuery,
    GetPaymentsDictionariesQueryVariables
  >(GetPaymentsDictionariesDocument, options)
}
export function useGetPaymentsDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentsDictionariesQuery,
    GetPaymentsDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPaymentsDictionariesQuery,
    GetPaymentsDictionariesQueryVariables
  >(GetPaymentsDictionariesDocument, options)
}
export type GetPaymentsDictionariesQueryHookResult = ReturnType<
  typeof useGetPaymentsDictionariesQuery
>
export type GetPaymentsDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetPaymentsDictionariesLazyQuery
>
export type GetPaymentsDictionariesQueryResult = Apollo.QueryResult<
  GetPaymentsDictionariesQuery,
  GetPaymentsDictionariesQueryVariables
>
export const GetTenantsDocument = gql`
  query getTenants(
    $limit: Int
    $offset: Int
    $distinct_on: [tenant_select_column!]
    $order_by: [tenant_order_by!]
    $where: tenant_bool_exp
  ) {
    tenantsAggregate: tenant_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    tenants: tenant(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTenantsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTenantsQuery,
    GetTenantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options
  )
}
export function useGetTenantsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantsQuery,
    GetTenantsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(
    GetTenantsDocument,
    options
  )
}
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>
export type GetTenantsLazyQueryHookResult = ReturnType<
  typeof useGetTenantsLazyQuery
>
export type GetTenantsQueryResult = Apollo.QueryResult<
  GetTenantsQuery,
  GetTenantsQueryVariables
>
export const GetTenantDocument = gql`
  query getTenant($id: uuid!) {
    tenant: tenant_by_pk(id: $id) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantQuery(
  baseOptions: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(
    GetTenantDocument,
    options
  )
}
export function useGetTenantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantQuery,
    GetTenantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(
    GetTenantDocument,
    options
  )
}
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>
export type GetTenantLazyQueryHookResult = ReturnType<
  typeof useGetTenantLazyQuery
>
export type GetTenantQueryResult = Apollo.QueryResult<
  GetTenantQuery,
  GetTenantQueryVariables
>
export const UpdateTenantDocument = gql`
  mutation updateTenant($id: uuid!, $set: tenant_set_input!) {
    tenant: update_tenant_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`
export type UpdateTenantMutationFn = Apollo.MutationFunction<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >(UpdateTenantDocument, options)
}
export type UpdateTenantMutationHookResult = ReturnType<
  typeof useUpdateTenantMutation
>
export type UpdateTenantMutationResult =
  Apollo.MutationResult<UpdateTenantMutation>
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>
export const InsertTenantDocument = gql`
  mutation insertTenant($object: tenant_insert_input!) {
    tenant: insert_tenant_one(object: $object) {
      ...TenantFragment
    }
  }
  ${TenantFragmentFragmentDoc}
`
export type InsertTenantMutationFn = Apollo.MutationFunction<
  InsertTenantMutation,
  InsertTenantMutationVariables
>

/**
 * __useInsertTenantMutation__
 *
 * To run a mutation, you first call `useInsertTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTenantMutation, { data, loading, error }] = useInsertTenantMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertTenantMutation,
    InsertTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InsertTenantMutation,
    InsertTenantMutationVariables
  >(InsertTenantDocument, options)
}
export type InsertTenantMutationHookResult = ReturnType<
  typeof useInsertTenantMutation
>
export type InsertTenantMutationResult =
  Apollo.MutationResult<InsertTenantMutation>
export type InsertTenantMutationOptions = Apollo.BaseMutationOptions<
  InsertTenantMutation,
  InsertTenantMutationVariables
>
export const GetUsersDocument = gql`
  query getUsers(
    $limit: Int
    $offset: Int
    $distinct_on: [user_select_column!]
    $order_by: [user_order_by!]
    $where: user_bool_exp
  ) {
    usersAggregate: user_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    users: user(
      limit: $limit
      offset: $offset
      distinct_on: $distinct_on
      order_by: $order_by
      where: $where
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      distinct_on: // value for 'distinct_on'
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  )
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  )
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>
export const GetUserByFirebaseIdDocument = gql`
  query getUserByFirebaseId($firebaseId: String) {
    user(where: { firebase_id: { _eq: $firebaseId } }) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUserByFirebaseIdQuery__
 *
 * To run a query within a React component, call `useGetUserByFirebaseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByFirebaseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByFirebaseIdQuery({
 *   variables: {
 *      firebaseId: // value for 'firebaseId'
 *   },
 * });
 */
export function useGetUserByFirebaseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export function useGetUserByFirebaseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserByFirebaseIdQuery,
    GetUserByFirebaseIdQueryVariables
  >(GetUserByFirebaseIdDocument, options)
}
export type GetUserByFirebaseIdQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdQuery
>
export type GetUserByFirebaseIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByFirebaseIdLazyQuery
>
export type GetUserByFirebaseIdQueryResult = Apollo.QueryResult<
  GetUserByFirebaseIdQuery,
  GetUserByFirebaseIdQueryVariables
>
export const GetUserDocument = gql`
  query getUser($id: uuid!) {
    user: user_by_pk(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($id: uuid!, $set: user_set_input!) {
    user: update_user_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const GetBaseUsersDocument = gql`
  query getBaseUsers($order_by: [user_order_by!], $where: user_bool_exp) {
    users: user(order_by: $order_by, where: $where) {
      ...BaseUserFragment
    }
  }
  ${BaseUserFragmentFragmentDoc}
`

/**
 * __useGetBaseUsersQuery__
 *
 * To run a query within a React component, call `useGetBaseUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseUsersQuery({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBaseUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseUsersQuery,
    GetBaseUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBaseUsersQuery, GetBaseUsersQueryVariables>(
    GetBaseUsersDocument,
    options
  )
}
export function useGetBaseUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseUsersQuery,
    GetBaseUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBaseUsersQuery, GetBaseUsersQueryVariables>(
    GetBaseUsersDocument,
    options
  )
}
export type GetBaseUsersQueryHookResult = ReturnType<
  typeof useGetBaseUsersQuery
>
export type GetBaseUsersLazyQueryHookResult = ReturnType<
  typeof useGetBaseUsersLazyQuery
>
export type GetBaseUsersQueryResult = Apollo.QueryResult<
  GetBaseUsersQuery,
  GetBaseUsersQueryVariables
>
export const GetUserDictionariesDocument = gql`
  query getUserDictionaries {
    locations: location(where: { deleted_at: { _is_null: true } }) {
      id
      name
    }
  }
`

/**
 * __useGetUserDictionariesQuery__
 *
 * To run a query within a React component, call `useGetUserDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDictionariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserDictionariesQuery,
    GetUserDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserDictionariesQuery,
    GetUserDictionariesQueryVariables
  >(GetUserDictionariesDocument, options)
}
export function useGetUserDictionariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserDictionariesQuery,
    GetUserDictionariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserDictionariesQuery,
    GetUserDictionariesQueryVariables
  >(GetUserDictionariesDocument, options)
}
export type GetUserDictionariesQueryHookResult = ReturnType<
  typeof useGetUserDictionariesQuery
>
export type GetUserDictionariesLazyQueryHookResult = ReturnType<
  typeof useGetUserDictionariesLazyQuery
>
export type GetUserDictionariesQueryResult = Apollo.QueryResult<
  GetUserDictionariesQuery,
  GetUserDictionariesQueryVariables
>
export const GetUserPaymentAggregationsDocument = gql`
  query getUserPaymentAggregations(
    $where: user_payment_aggregation_bool_exp
    $order_by: [user_payment_aggregation_order_by!]
  ) {
    userPaymentAggregations: user_payment_aggregation(
      where: $where
      order_by: $order_by
    ) {
      ...UserPaymentAggregationFragment
    }
  }
  ${UserPaymentAggregationFragmentFragmentDoc}
`

/**
 * __useGetUserPaymentAggregationsQuery__
 *
 * To run a query within a React component, call `useGetUserPaymentAggregationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPaymentAggregationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPaymentAggregationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetUserPaymentAggregationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPaymentAggregationsQuery,
    GetUserPaymentAggregationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserPaymentAggregationsQuery,
    GetUserPaymentAggregationsQueryVariables
  >(GetUserPaymentAggregationsDocument, options)
}
export function useGetUserPaymentAggregationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPaymentAggregationsQuery,
    GetUserPaymentAggregationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserPaymentAggregationsQuery,
    GetUserPaymentAggregationsQueryVariables
  >(GetUserPaymentAggregationsDocument, options)
}
export type GetUserPaymentAggregationsQueryHookResult = ReturnType<
  typeof useGetUserPaymentAggregationsQuery
>
export type GetUserPaymentAggregationsLazyQueryHookResult = ReturnType<
  typeof useGetUserPaymentAggregationsLazyQuery
>
export type GetUserPaymentAggregationsQueryResult = Apollo.QueryResult<
  GetUserPaymentAggregationsQuery,
  GetUserPaymentAggregationsQueryVariables
>
export const GetUserLoanAggregationsDocument = gql`
  query getUserLoanAggregations(
    $where: user_loan_aggregation_bool_exp
    $order_by: [user_loan_aggregation_order_by!]
  ) {
    userLoanAggregations: user_loan_aggregation(
      where: $where
      order_by: $order_by
    ) {
      ...UserLoanAggregationFragment
    }
  }
  ${UserLoanAggregationFragmentFragmentDoc}
`

/**
 * __useGetUserLoanAggregationsQuery__
 *
 * To run a query within a React component, call `useGetUserLoanAggregationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLoanAggregationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLoanAggregationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetUserLoanAggregationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserLoanAggregationsQuery,
    GetUserLoanAggregationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserLoanAggregationsQuery,
    GetUserLoanAggregationsQueryVariables
  >(GetUserLoanAggregationsDocument, options)
}
export function useGetUserLoanAggregationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserLoanAggregationsQuery,
    GetUserLoanAggregationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserLoanAggregationsQuery,
    GetUserLoanAggregationsQueryVariables
  >(GetUserLoanAggregationsDocument, options)
}
export type GetUserLoanAggregationsQueryHookResult = ReturnType<
  typeof useGetUserLoanAggregationsQuery
>
export type GetUserLoanAggregationsLazyQueryHookResult = ReturnType<
  typeof useGetUserLoanAggregationsLazyQuery
>
export type GetUserLoanAggregationsQueryResult = Apollo.QueryResult<
  GetUserLoanAggregationsQuery,
  GetUserLoanAggregationsQueryVariables
>
