import React, { useState } from 'react'
import { Box, styled, BoxProps } from '@mui/material'

import Header, { DRAWER_WIDTH, DrawerHeader } from './Header'
import Main from '../Shared/Main'
import Footer from './Footer'

type AdminLayoutProps = {
  children: JSX.Element
}

interface ContainerProps extends BoxProps {
  drawerOpen?: boolean
}

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'drawerOpen'
})<ContainerProps>(({ theme, drawerOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  minHeight: '100vh',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - calc(${theme.spacing(8)} + 1px))`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(drawerOpen && {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }
}))

const AdminLayout: React.FC<AdminLayoutProps> = ({
  children
}: AdminLayoutProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <Box display='flex'>
      <Header drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Container drawerOpen={drawerOpen}>
        <Main>
          <DrawerHeader />
          {children}
        </Main>
        <Footer />
      </Container>
    </Box>
  )
}

export default AdminLayout
