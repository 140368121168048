import {
  addWeeks,
  addMonths,
  differenceInCalendarDays,
  format,
  parseISO
} from 'date-fns'
import { GridCellParams } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'

import {
  Currency_Enum,
  Loan_Periodicity_Enum,
  Loan_Type_Enum,
  LoanFragmentFragment,
  Payment_Type_Enum,
  PaymentFragmentFragment
} from 'api/generated'
import { formatCurrency, FormatCurrencyProps } from '../currency'
import { AGGREGATION_ROW_ID } from 'config/constants'

export type GetPaymentPreviewMetadataProps = {
  amount: number
  type: Loan_Type_Enum
  currency: Currency_Enum
  periodicity: Loan_Periodicity_Enum
  interestPercentage: number
  capitalPercentage: number
  commissionPercentage: number
  paymentDate: Date
  penaltyFee?: number
}

export type Amount = {
  value: number
  formattedValue: string
}

export type DateFormatted = {
  value: Date
  formattedValue: string
}

export type GetPaymentPreviewMetadataResponse =
  GetPaymentPreviewMetadataProps & {
    paymentAmount: Amount
    interestAmount: Amount
    capitalAmount: Amount
    commissionAmount: Amount
    interestArrearsAmount: Amount
    newBalance: Amount
    balance: Amount
  }

export const getAmountAndFormattedAmount = ({
  amount = 0,
  ...rest
}: FormatCurrencyProps): Amount => ({
  value: Math.round(amount || 0),
  formattedValue: formatCurrency({
    amount,
    ...rest
  })
})

export const getDateAndFormattedDate = (date = new Date()): DateFormatted => ({
  value: date,
  formattedValue: format(date, 'dd/MM/yyyy')
})

export const getPaymentAmount = ({
  interestAmount = 0,
  capitalAmount = 0,
  commissionAmount = 0,
  interestArrearsAmount = 0,
  commissionInterestArrearsAmount = 0
}: {
  interestAmount?: number
  capitalAmount?: number
  commissionAmount?: number
  interestArrearsAmount?: number
  commissionInterestArrearsAmount?: number
}): number => {
  return (
    interestAmount +
    capitalAmount +
    commissionAmount +
    interestArrearsAmount +
    commissionInterestArrearsAmount
  )
}

export type GetPeriodicityWeekTimesAndNextPaymentDateResponse = {
  periodicityWeekTimes: number
  newPaymentDate: Date
  daysLate: number
}

export const getPeriodicityWeekTimesAndNextPaymentDate = (
  periodicity: Loan_Periodicity_Enum,
  paymentDate: string
): GetPeriodicityWeekTimesAndNextPaymentDateResponse => {
  const date = parseISO(paymentDate)
  const daysLate = differenceInCalendarDays(new Date(), date)
  const daysLateValue = daysLate > 0 ? daysLate : 0

  switch (periodicity) {
    case Loan_Periodicity_Enum.Weekly: {
      const newPaymentDate = addWeeks(date, 1)

      return {
        periodicityWeekTimes: 1,
        newPaymentDate,
        daysLate: daysLateValue
      }
    }

    case Loan_Periodicity_Enum.Biweekly: {
      const newPaymentDate = addWeeks(date, 2)

      return {
        periodicityWeekTimes: 2,
        newPaymentDate,
        daysLate: daysLateValue
      }
    }

    case Loan_Periodicity_Enum.Monthly: {
      const newPaymentDate = addMonths(date, 1)

      return {
        periodicityWeekTimes: 4,
        newPaymentDate,
        daysLate: daysLateValue
      }
    }

    default:
      return {
        periodicityWeekTimes: 1,
        newPaymentDate: date,
        daysLate: 0
      }
  }
}

export const getPeriodicityWeekTimes = (
  periodicity: Loan_Periodicity_Enum
): number => {
  switch (periodicity) {
    case Loan_Periodicity_Enum.Weekly:
      return 1

    case Loan_Periodicity_Enum.Biweekly:
      return 2

    case Loan_Periodicity_Enum.Monthly:
      return 4

    default:
      return 1
  }
}

export const getAmountPercentage = (amount: number, percentage = 0): number => {
  return Math.round(amount * (percentage / 100))
}

export const getPaymentPreviewFixedAndLoweringMetadata = ({
  ...rest
}: GetPaymentPreviewMetadataProps): GetPaymentPreviewMetadataResponse => {
  const {
    amount,
    interestPercentage,
    commissionPercentage,
    capitalPercentage,
    periodicity,
    penaltyFee,
    type,
    currency
  } = rest
  const weeklyInterestAmount = getAmountPercentage(amount, interestPercentage)
  const weeklyCapitalAmount = getAmountPercentage(amount, capitalPercentage)
  const weeklyCommissionAmount = getAmountPercentage(
    amount,
    commissionPercentage
  )
  const periodicityWeekTimes = getPeriodicityWeekTimes(periodicity)
  const interestAmount = Math.round(weeklyInterestAmount * periodicityWeekTimes)
  const capitalAmount = Math.round(weeklyCapitalAmount * periodicityWeekTimes)
  const commissionAmount = Math.round(
    weeklyCommissionAmount * periodicityWeekTimes
  )
  const interestArrearsAmount = penaltyFee || 0

  const paymentAmount = getPaymentAmount({
    interestAmount,
    commissionAmount,
    capitalAmount,
    interestArrearsAmount
  })
  const newBalance =
    type === Loan_Type_Enum.Fixed ? amount : amount - capitalAmount

  return {
    balance: getAmountAndFormattedAmount({ amount: amount, currency }),
    interestAmount: getAmountAndFormattedAmount({
      amount: interestAmount,
      currency
    }),
    commissionAmount: getAmountAndFormattedAmount({
      amount: commissionAmount,
      currency
    }),
    capitalAmount: getAmountAndFormattedAmount({
      amount: capitalAmount,
      currency
    }),
    interestArrearsAmount: getAmountAndFormattedAmount({
      amount: interestArrearsAmount,
      currency
    }),
    paymentAmount: getAmountAndFormattedAmount({
      amount: paymentAmount,
      currency
    }),
    newBalance: getAmountAndFormattedAmount({
      amount: newBalance,
      currency
    }),
    ...rest
  }
}

export const getPaymentPreviewMetadata = ({
  ...rest
}: GetPaymentPreviewMetadataProps): GetPaymentPreviewMetadataResponse | null => {
  switch (rest?.type) {
    case Loan_Type_Enum.Fixed:
    case Loan_Type_Enum.Lowering:
    case Loan_Type_Enum.FixedCapital:
      return getPaymentPreviewFixedAndLoweringMetadata(rest)

    default:
      return null
  }
}

export type GetPaymentMetadataResponse = {
  maximumPaymentAmount: Amount
  maximumArrearsPaymentAmount: Amount
  paymentAmount: Amount
  expectedPaymentAmount: Amount
  interestAmount: Amount
  capitalAmount: Amount
  interestArrearsAmount: Amount
  commissionInterestArrearsAmount: Amount
  commissionAmount: Amount
  newBalanceAmount: Amount
  newInterestArrearsAmount: Amount
  newCommissionInterestArrearsAmount: Amount
  expectedInterestArrearsAmount: Amount
  expectedCommissionInterestArrearsAmount: Amount
  currentPaymentAmount: Amount
  expectedInterestAmount: Amount
  expectedCapitalAmount: Amount
  expectedCommissionAmount: Amount
  newPaymentDate: DateFormatted
  daysLate: number
  minimumPaymentAmount: Amount
}

export type GetPaymentMetadataResponseNumber = {
  paymentAmount: number
  expectedPaymentAmount: number
  interestAmount: number
  capitalAmount: number
  interestArrearsAmount: number
  commissionInterestArrearsAmount: number
  commissionAmount: number
  newBalanceAmount: number
  newInterestArrearsAmount: number
  newCommissionInterestArrearsAmount: number
  expectedInterestArrearsAmount: number
  expectedCommissionInterestArrearsAmount: number
  currentPaymentAmount: number
  expectedInterestAmount: number
  expectedCapitalAmount: number
  expectedCommissionAmount: number
  maximumPaymentAmount: number
  maximumArrearsPaymentAmount: number
  newPaymentDate: Date
  daysLate: number
  minimumPaymentAmount: number
}

export const getMaximumPaymentAmount = ({
  interestAmount = 0,
  commissionAmount = 0,
  interestArrearsAmount = 0,
  commissionInterestArrearsAmount = 0,
  balance = 0
}: {
  interestAmount?: number
  commissionAmount?: number
  interestArrearsAmount?: number
  commissionInterestArrearsAmount?: number
  balance?: number
  currency: string
}): number =>
  interestAmount +
  commissionAmount +
  interestArrearsAmount +
  commissionInterestArrearsAmount +
  balance

export const getMaximumArrearsPaymentAmount = ({
  interestArrearsAmount = 0,
  commissionInterestArrearsAmount = 0
}: {
  interestArrearsAmount?: number
  commissionInterestArrearsAmount?: number
}): number => interestArrearsAmount + commissionInterestArrearsAmount

export const getPaymentFixedAndLoweringMetadataResponse = (
  {
    paymentAmount,
    expectedPaymentAmount,
    interestAmount,
    capitalAmount,
    interestArrearsAmount,
    commissionInterestArrearsAmount,
    commissionAmount,
    newBalanceAmount,
    newInterestArrearsAmount,
    newCommissionInterestArrearsAmount,
    expectedInterestArrearsAmount,
    expectedCommissionInterestArrearsAmount,
    currentPaymentAmount,
    expectedInterestAmount,
    expectedCapitalAmount,
    expectedCommissionAmount,
    maximumPaymentAmount,
    maximumArrearsPaymentAmount,
    newPaymentDate,
    daysLate,
    minimumPaymentAmount
  }: GetPaymentMetadataResponseNumber,
  currency: string
): GetPaymentMetadataResponse => ({
  newPaymentDate: getDateAndFormattedDate(newPaymentDate),
  daysLate,
  maximumPaymentAmount: getAmountAndFormattedAmount({
    amount: maximumPaymentAmount,
    currency
  }),
  minimumPaymentAmount: getAmountAndFormattedAmount({
    amount: minimumPaymentAmount,
    currency
  }),
  maximumArrearsPaymentAmount: getAmountAndFormattedAmount({
    amount: maximumArrearsPaymentAmount,
    currency
  }),
  paymentAmount: getAmountAndFormattedAmount({
    amount: paymentAmount,
    currency
  }),
  expectedPaymentAmount: getAmountAndFormattedAmount({
    amount: expectedPaymentAmount,
    currency
  }),
  interestAmount: getAmountAndFormattedAmount({
    amount: interestAmount,
    currency
  }),
  capitalAmount: getAmountAndFormattedAmount({
    amount: capitalAmount,
    currency
  }),
  interestArrearsAmount: getAmountAndFormattedAmount({
    amount: interestArrearsAmount,
    currency
  }),
  commissionInterestArrearsAmount: getAmountAndFormattedAmount({
    amount: commissionInterestArrearsAmount,
    currency
  }),
  commissionAmount: getAmountAndFormattedAmount({
    amount: commissionAmount,
    currency
  }),
  newBalanceAmount: getAmountAndFormattedAmount({
    amount: newBalanceAmount,
    currency
  }),
  newInterestArrearsAmount: getAmountAndFormattedAmount({
    amount: newInterestArrearsAmount,
    currency
  }),
  newCommissionInterestArrearsAmount: getAmountAndFormattedAmount({
    amount: newCommissionInterestArrearsAmount,
    currency
  }),
  expectedInterestArrearsAmount: getAmountAndFormattedAmount({
    amount: expectedInterestArrearsAmount,
    currency
  }),
  expectedCommissionInterestArrearsAmount: getAmountAndFormattedAmount({
    amount: expectedCommissionInterestArrearsAmount,
    currency
  }),
  currentPaymentAmount: getAmountAndFormattedAmount({
    amount: currentPaymentAmount,
    currency
  }),
  expectedInterestAmount: getAmountAndFormattedAmount({
    amount: expectedInterestAmount,
    currency
  }),
  expectedCapitalAmount: getAmountAndFormattedAmount({
    amount: expectedCapitalAmount,
    currency
  }),
  expectedCommissionAmount: getAmountAndFormattedAmount({
    amount: expectedCommissionAmount,
    currency
  })
})

export const getPaymentFixedAndLoweringMetadata = (
  {
    amount,
    balance,
    type,
    interest_percentage,
    capital_percentage,
    commission_percentage,
    periodicity,
    interest_arrears_amount,
    commission_interest_arrears_amount,
    currency,
    payment_date,
    location
  }: LoanFragmentFragment,
  paymentAmount: number,
  paymentType: Payment_Type_Enum
): GetPaymentMetadataResponse => {
  const expectedInterestArrearsAmount = interest_arrears_amount || 0
  const expectedCommissionInterestArrearsAmount =
    commission_interest_arrears_amount || 0
  let currentPaymentAmount = paymentAmount
  let newInterestArrearsAmount = expectedInterestArrearsAmount
  let newCommissionInterestArrearsAmount =
    expectedCommissionInterestArrearsAmount
  let newBalanceAmount = balance
  let interestAmount = 0
  let capitalAmount = 0
  let commissionAmount = 0
  let interestArrearsAmount = 0
  let commissionInterestArrearsAmount = 0
  const minimumPaymentPercentage = location?.minimum_payment_percentage || 0
  const amountToEvaluate = type === Loan_Type_Enum.Fixed ? balance : amount
  const expectedWeeklyInterestAmount = getAmountPercentage(
    amountToEvaluate,
    interest_percentage
  )
  const expectedWeeklyCapitalAmount = getAmountPercentage(
    amountToEvaluate,
    capital_percentage
  )
  const expectedWeeklyCommissionAmount = getAmountPercentage(
    amountToEvaluate,
    commission_percentage || 0
  )
  const { periodicityWeekTimes, newPaymentDate, daysLate } =
    getPeriodicityWeekTimesAndNextPaymentDate(periodicity, payment_date)
  const expectedInterestAmount = Math.round(
    expectedWeeklyInterestAmount * periodicityWeekTimes
  )
  const expectedCapitalAmount = Math.round(
    expectedWeeklyCapitalAmount * periodicityWeekTimes
  )
  const expectedCommissionAmount = Math.round(
    expectedWeeklyCommissionAmount * periodicityWeekTimes
  )

  const expectedPaymentAmount = getPaymentAmount({
    interestAmount: expectedInterestAmount,
    commissionAmount: expectedCommissionAmount,
    capitalAmount: expectedCapitalAmount,
    interestArrearsAmount: expectedInterestArrearsAmount,
    commissionInterestArrearsAmount: expectedCommissionInterestArrearsAmount
  })
  const maximumPaymentAmount = getMaximumPaymentAmount({
    interestAmount: expectedInterestAmount,
    commissionAmount: expectedCommissionAmount,
    interestArrearsAmount: expectedInterestArrearsAmount,
    commissionInterestArrearsAmount: expectedCommissionInterestArrearsAmount,
    balance,
    currency
  })
  const maximumArrearsPaymentAmount = getMaximumArrearsPaymentAmount({
    interestArrearsAmount: expectedInterestArrearsAmount,
    commissionInterestArrearsAmount: expectedCommissionInterestArrearsAmount
  })
  const minimumPaymentAmount =
    paymentType === Payment_Type_Enum.Normal
      ? getAmountPercentage(expectedInterestAmount, minimumPaymentPercentage)
      : 0

  if (paymentType === Payment_Type_Enum.ArrearsPayment) {
    if (expectedInterestArrearsAmount > 0) {
      const isInterestArrearsAmountLowerOrEqualThanExpected =
        currentPaymentAmount <= expectedInterestArrearsAmount

      if (isInterestArrearsAmountLowerOrEqualThanExpected) {
        interestArrearsAmount = currentPaymentAmount
        newInterestArrearsAmount =
          newInterestArrearsAmount - interestArrearsAmount
        currentPaymentAmount = 0

        return getPaymentFixedAndLoweringMetadataResponse(
          {
            paymentAmount,
            expectedPaymentAmount,
            interestAmount,
            capitalAmount,
            interestArrearsAmount,
            commissionInterestArrearsAmount,
            commissionAmount,
            newBalanceAmount,
            newInterestArrearsAmount,
            newCommissionInterestArrearsAmount,
            expectedInterestArrearsAmount,
            expectedCommissionInterestArrearsAmount,
            currentPaymentAmount,
            expectedInterestAmount,
            expectedCapitalAmount,
            expectedCommissionAmount,
            maximumPaymentAmount,
            maximumArrearsPaymentAmount,
            newPaymentDate,
            daysLate,
            minimumPaymentAmount
          },
          currency
        )
      } else {
        newInterestArrearsAmount = 0
        currentPaymentAmount =
          currentPaymentAmount - expectedInterestArrearsAmount
        interestArrearsAmount = expectedInterestArrearsAmount
      }
    }

    if (expectedCommissionInterestArrearsAmount > 0) {
      const isCommissionInterestArrearsAmountLowerOrEqualThanExpected =
        currentPaymentAmount <= expectedCommissionInterestArrearsAmount

      if (isCommissionInterestArrearsAmountLowerOrEqualThanExpected) {
        commissionInterestArrearsAmount = currentPaymentAmount
        newCommissionInterestArrearsAmount =
          newCommissionInterestArrearsAmount - commissionInterestArrearsAmount
        currentPaymentAmount = 0
      } else {
        newCommissionInterestArrearsAmount = 0
        currentPaymentAmount =
          currentPaymentAmount - expectedCommissionInterestArrearsAmount
        commissionInterestArrearsAmount =
          expectedCommissionInterestArrearsAmount
      }
    }

    return getPaymentFixedAndLoweringMetadataResponse(
      {
        paymentAmount,
        expectedPaymentAmount,
        interestAmount,
        capitalAmount,
        interestArrearsAmount,
        commissionInterestArrearsAmount,
        commissionAmount,
        newBalanceAmount,
        newInterestArrearsAmount,
        newCommissionInterestArrearsAmount,
        expectedInterestArrearsAmount,
        expectedCommissionInterestArrearsAmount,
        currentPaymentAmount,
        expectedInterestAmount,
        expectedCapitalAmount,
        expectedCommissionAmount,
        maximumPaymentAmount,
        maximumArrearsPaymentAmount,
        newPaymentDate,
        daysLate,
        minimumPaymentAmount
      },
      currency
    )
  }

  const isInterestAmountLowerOrEqualThanExpected =
    currentPaymentAmount <= expectedInterestAmount

  if (isInterestAmountLowerOrEqualThanExpected) {
    interestAmount = currentPaymentAmount
    const currentInterestArrearsAmount = expectedInterestAmount - interestAmount
    newInterestArrearsAmount =
      newInterestArrearsAmount + currentInterestArrearsAmount
    newCommissionInterestArrearsAmount =
      newCommissionInterestArrearsAmount + expectedCommissionAmount

    currentPaymentAmount = 0

    return getPaymentFixedAndLoweringMetadataResponse(
      {
        paymentAmount,
        expectedPaymentAmount,
        interestAmount,
        capitalAmount,
        interestArrearsAmount,
        commissionInterestArrearsAmount,
        commissionAmount,
        newBalanceAmount,
        newInterestArrearsAmount,
        newCommissionInterestArrearsAmount,
        expectedInterestArrearsAmount,
        expectedCommissionInterestArrearsAmount,
        currentPaymentAmount,
        expectedInterestAmount,
        expectedCapitalAmount,
        expectedCommissionAmount,
        maximumPaymentAmount,
        maximumArrearsPaymentAmount,
        newPaymentDate,
        daysLate,
        minimumPaymentAmount
      },
      currency
    )
  }

  interestAmount = expectedInterestAmount
  currentPaymentAmount = currentPaymentAmount - interestAmount

  const isCommissionAmountLowerOrEqualThanExpected =
    currentPaymentAmount <= expectedCommissionAmount

  if (isCommissionAmountLowerOrEqualThanExpected) {
    commissionAmount = currentPaymentAmount
    const currentCommissionInterestArrearsAmount =
      expectedCommissionAmount - commissionAmount
    newCommissionInterestArrearsAmount =
      newCommissionInterestArrearsAmount +
      currentCommissionInterestArrearsAmount
    currentPaymentAmount = 0

    return getPaymentFixedAndLoweringMetadataResponse(
      {
        paymentAmount,
        expectedPaymentAmount,
        interestAmount,
        capitalAmount,
        interestArrearsAmount,
        commissionInterestArrearsAmount,
        commissionAmount,
        newBalanceAmount,
        newInterestArrearsAmount,
        newCommissionInterestArrearsAmount,
        expectedInterestArrearsAmount,
        expectedCommissionInterestArrearsAmount,
        currentPaymentAmount,
        expectedInterestAmount,
        expectedCapitalAmount,
        expectedCommissionAmount,
        maximumPaymentAmount,
        maximumArrearsPaymentAmount,
        newPaymentDate,
        daysLate,
        minimumPaymentAmount
      },
      currency
    )
  }

  commissionAmount = expectedCommissionAmount
  currentPaymentAmount = currentPaymentAmount - commissionAmount

  if (expectedInterestArrearsAmount > 0) {
    const isInterestArrearsAmountLowerOrEqualThanExpected =
      currentPaymentAmount <= expectedInterestArrearsAmount

    if (isInterestArrearsAmountLowerOrEqualThanExpected) {
      interestArrearsAmount = currentPaymentAmount
      newInterestArrearsAmount =
        newInterestArrearsAmount - interestArrearsAmount
      currentPaymentAmount = 0

      return getPaymentFixedAndLoweringMetadataResponse(
        {
          paymentAmount,
          expectedPaymentAmount,
          interestAmount,
          capitalAmount,
          interestArrearsAmount,
          commissionInterestArrearsAmount,
          commissionAmount,
          newBalanceAmount,
          newInterestArrearsAmount,
          newCommissionInterestArrearsAmount,
          expectedInterestArrearsAmount,
          expectedCommissionInterestArrearsAmount,
          currentPaymentAmount,
          expectedInterestAmount,
          expectedCapitalAmount,
          expectedCommissionAmount,
          maximumPaymentAmount,
          maximumArrearsPaymentAmount,
          newPaymentDate,
          daysLate,
          minimumPaymentAmount
        },
        currency
      )
    } else {
      newInterestArrearsAmount = 0
      currentPaymentAmount =
        currentPaymentAmount - expectedInterestArrearsAmount
      interestArrearsAmount = expectedInterestArrearsAmount
    }
  }

  if (expectedCommissionInterestArrearsAmount > 0) {
    const isCommissionInterestArrearsAmountLowerOrEqualThanExpected =
      currentPaymentAmount <= expectedCommissionInterestArrearsAmount

    if (isCommissionInterestArrearsAmountLowerOrEqualThanExpected) {
      commissionInterestArrearsAmount = currentPaymentAmount
      newCommissionInterestArrearsAmount =
        newCommissionInterestArrearsAmount - commissionInterestArrearsAmount
      currentPaymentAmount = 0

      return getPaymentFixedAndLoweringMetadataResponse(
        {
          paymentAmount,
          expectedPaymentAmount,
          interestAmount,
          capitalAmount,
          interestArrearsAmount,
          commissionInterestArrearsAmount,
          commissionAmount,
          newBalanceAmount,
          newInterestArrearsAmount,
          newCommissionInterestArrearsAmount,
          expectedInterestArrearsAmount,
          expectedCommissionInterestArrearsAmount,
          currentPaymentAmount,
          expectedInterestAmount,
          expectedCapitalAmount,
          expectedCommissionAmount,
          maximumPaymentAmount,
          maximumArrearsPaymentAmount,
          newPaymentDate,
          daysLate,
          minimumPaymentAmount
        },
        currency
      )
    } else {
      newCommissionInterestArrearsAmount = 0
      currentPaymentAmount =
        currentPaymentAmount - expectedCommissionInterestArrearsAmount
      commissionInterestArrearsAmount = expectedCommissionInterestArrearsAmount
    }
  }

  capitalAmount = currentPaymentAmount
  newBalanceAmount = newBalanceAmount - capitalAmount

  return getPaymentFixedAndLoweringMetadataResponse(
    {
      paymentAmount,
      expectedPaymentAmount,
      interestAmount,
      capitalAmount,
      interestArrearsAmount,
      commissionInterestArrearsAmount,
      commissionAmount,
      newBalanceAmount,
      newInterestArrearsAmount,
      newCommissionInterestArrearsAmount,
      expectedInterestArrearsAmount,
      expectedCommissionInterestArrearsAmount,
      currentPaymentAmount,
      expectedInterestAmount,
      expectedCapitalAmount,
      expectedCommissionAmount,
      maximumPaymentAmount,
      maximumArrearsPaymentAmount,
      newPaymentDate,
      daysLate,
      minimumPaymentAmount
    },
    currency
  )
}

export const getPaymentMetadata = (
  loan: LoanFragmentFragment,
  paymentAmount: number,
  paymentType: Payment_Type_Enum
): GetPaymentMetadataResponse | null => {
  switch (loan?.type) {
    case Loan_Type_Enum.Fixed:
    case Loan_Type_Enum.Lowering:
    case Loan_Type_Enum.FixedCapital:
      return getPaymentFixedAndLoweringMetadata(
        loan,
        paymentAmount,
        paymentType
      )

    default:
      return null
  }
}

export const getComparePaymentAmountClassName = (
  id: string,
  amount = 0,
  expectedAmount = 0,
  expectedAmountShouldBeMore = true
): string => {
  return typeof amount !== 'number' || id === AGGREGATION_ROW_ID
    ? ''
    : amount === expectedAmount
    ? 'info'
    : expectedAmountShouldBeMore
    ? amount > expectedAmount
      ? 'success'
      : 'error'
    : amount > expectedAmount
    ? 'error'
    : 'success'
}

export const getPaymentAmountClassName = (
  id: string,
  amount: number,
  expectedAmountShouldBeMore = true
): string => {
  return typeof amount !== 'number' || id === AGGREGATION_ROW_ID
    ? ''
    : expectedAmountShouldBeMore
    ? amount > 0
      ? 'success'
      : 'info'
    : amount > 0
    ? 'error'
    : 'info'
}

export const getPaymentCellClassName = (params: GridCellParams): string => {
  const value = params?.value as number
  const row = params?.row as PaymentFragmentFragment
  const id = row?.id

  switch (params.field) {
    case 'amount':
      return getComparePaymentAmountClassName(id, value, row?.expected_amount)

    case 'interest_amount':
      return getComparePaymentAmountClassName(
        id,
        value,
        row?.expected_interest_amount
      )

    case 'commission_amount':
      return getComparePaymentAmountClassName(
        id,
        value,
        row?.expected_commission_amount
      )

    case 'capital_amount':
      return getComparePaymentAmountClassName(
        id,
        value,
        row?.expected_capital_amount
      )

    case 'new_balance_amount':
      return getComparePaymentAmountClassName(
        id,
        value,
        row?.current_balance_amount
      )

    case 'new_interest_arrears_amount':
      return getComparePaymentAmountClassName(
        id,
        value,
        row?.current_interest_arrears_amount,
        false
      )

    case 'new_commission_interest_arrears_amount':
      return getComparePaymentAmountClassName(
        id,
        value,
        row?.current_commission_interest_arrears_amount,
        false
      )

    case 'penalty_amount':
      return getPaymentAmountClassName(id, row?.penalty_amount || 0)

    case 'days_late':
      return getPaymentAmountClassName(id, row?.days_late || 0, false)

    default:
      return ''
  }
}

export const getPaymentDetailMetadata = (
  payment?: PaymentFragmentFragment | null,
  t?: TFunction
) => {
  if (!payment) {
    return []
  }

  const currency = payment?.loan?.currency

  return [
    {
      key: 'amount',
      bgColor: getComparePaymentAmountClassName(
        '',
        payment?.amount,
        payment?.expected_amount
      ),
      data: [
        {
          title: 'expected_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.expected_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.amount,
            currency
          })?.formattedValue
        }
      ]
    },
    {
      key: 'interest_amount',
      bgColor: getComparePaymentAmountClassName(
        '',
        payment?.interest_amount,
        payment?.expected_interest_amount
      ),
      data: [
        {
          title: 'expected_interest_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.expected_interest_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'interest_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.interest_amount,
            currency
          })?.formattedValue
        }
      ]
    },
    {
      key: 'commission_amount',
      bgColor: getComparePaymentAmountClassName(
        '',
        payment?.commission_amount,
        payment?.expected_commission_amount
      ),
      data: [
        {
          title: 'expected_commission_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.expected_commission_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'commission_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.commission_amount,
            currency
          })?.formattedValue
        }
      ]
    },
    {
      key: 'capital_amount',
      bgColor: getComparePaymentAmountClassName(
        '',
        payment?.capital_amount,
        payment?.expected_capital_amount
      ),
      data: [
        {
          title: 'expected_capital_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.expected_capital_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'capital_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.capital_amount,
            currency
          })?.formattedValue
        }
      ]
    },
    {
      key: 'new_balance_amount',
      bgColor: getComparePaymentAmountClassName(
        '',
        payment?.new_balance_amount,
        payment?.current_balance_amount
      ),
      data: [
        {
          title: 'current_balance_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.current_balance_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'new_balance_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.new_balance_amount,
            currency
          })?.formattedValue
        }
      ]
    },
    {
      key: 'new_interest_arrears_amount',
      bgColor: getComparePaymentAmountClassName(
        '',
        payment?.new_interest_arrears_amount,
        payment?.current_interest_arrears_amount,
        false
      ),
      data: [
        {
          title: 'current_interest_arrears_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.current_interest_arrears_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'interest_arrears_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.interest_arrears_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'new_interest_arrears_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.new_interest_arrears_amount,
            currency
          })?.formattedValue
        }
      ]
    },
    {
      key: 'new_commission_interest_arrears_amount',
      bgColor: getComparePaymentAmountClassName(
        '',
        payment?.new_commission_interest_arrears_amount,
        payment?.current_commission_interest_arrears_amount,
        false
      ),
      data: [
        {
          title: 'current_commission_interest_arrears_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.current_commission_interest_arrears_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'commission_interest_arrears_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.commission_interest_arrears_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'new_commission_interest_arrears_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.new_commission_interest_arrears_amount,
            currency
          })?.formattedValue
        }
      ]
    },
    {
      key: 'days_late',
      bgColor: getPaymentAmountClassName('', payment?.days_late || 0, false),
      data: [
        {
          title: 'expected_payment_date',
          value: payment?.expected_payment_date
            ? format(parseISO(payment?.expected_payment_date), 'dd/MM/yyyy')
            : ''
        },
        {
          title: 'new_payment_date',
          value: payment?.new_payment_date
            ? format(parseISO(payment?.new_payment_date), 'dd/MM/yyyy')
            : ''
        },
        {
          title: 'created_at',
          value: payment?.created_at
            ? format(parseISO(payment?.created_at), 'dd/MM/yyyy')
            : ''
        },
        {
          title: 'days_late',
          value: payment?.days_late || 0
        }
      ]
    },
    {
      key: 'penalty_amount',
      bgColor: getPaymentAmountClassName('', payment?.penalty_amount || 0),
      data: [
        {
          title: 'penalty_amount',
          value: getAmountAndFormattedAmount({
            amount: payment?.penalty_amount,
            currency
          })?.formattedValue
        },
        {
          title: 'paymentMethod',
          value: payment?.payment_method?.name
        },
        {
          title: 'type',
          value: t?.(payment?.type)
        }
      ]
    }
  ]
}

export default {
  getPaymentPreviewMetadata,
  getPaymentMetadata,
  getPaymentCellClassName,
  getPaymentDetailMetadata
}
