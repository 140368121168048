import React, { Fragment } from 'react'
import { Navigate } from 'react-router-dom'

import { User_Role_Enum } from 'api/generated'
import { useUser } from 'context/auth/user'
import { useAuth } from 'context/auth/auth'

type ProtectedRouteProps = {
  allowedRoles: User_Role_Enum[]
  inclusive?: boolean
  to?: string
  replace?: boolean
  children: JSX.Element | JSX.Element[]
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  allowedRoles,
  inclusive = true,
  to = '/',
  replace = true,
  children
}: ProtectedRouteProps) => {
  const { loadingDbUser } = useAuth()
  const { dbUser } = useUser()

  return loadingDbUser ? (
    <Fragment />
  ) : dbUser &&
    (inclusive
      ? allowedRoles?.includes(dbUser?.role)
      : !allowedRoles?.includes(dbUser?.role)) ? (
    <>{children}</>
  ) : (
    <Navigate to={to} replace={replace} />
  )
}

export default ProtectedRoute
