import React from 'react'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarProps
} from '@mui/x-data-grid-pro'

import MuiGridToolbarExport from '../MuiGridToolbarExport'

const MuiGridToolbar: React.FC<GridToolbarProps> = ({
  children
}: GridToolbarProps) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <MuiGridToolbarExport />
      {children}
    </GridToolbarContainer>
  )
}

export default MuiGridToolbar
