import React from 'react'
import { Box, BoxProps } from '@mui/material'

import LanguageSelect from 'components/LanguageSelect'
import ThemeSelect from 'components/ThemeSelect'

type FooterProps = BoxProps

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      component='footer'
      marginTop={2}
      {...props}
    >
      <LanguageSelect />

      <ThemeSelect />
    </Box>
  )
}

export default Footer
