import React from 'react'
import { Snackbar, SnackbarProps } from '@mui/material'
import { Alert, AlertProps } from '@mui/material'

type MuiSnackbarProps = {
  snackbarProps: SnackbarProps
  message?: string
  alertProps: AlertProps
}

const MuiSnackbar: React.FC<MuiSnackbarProps> = ({
  message,
  snackbarProps: { open, ...snackbarProps },
  alertProps
}: MuiSnackbarProps) => {
  return (
    <Snackbar open={open} {...snackbarProps}>
      <Alert elevation={6} variant='filled' {...alertProps}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default MuiSnackbar
