import React, { forwardRef } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'

type LinkProps = MuiLinkProps & RouterLinkProps

// eslint-disable-next-line react/display-name
const LinkRef: React.FC<LinkProps> = forwardRef<HTMLAnchorElement, LinkProps>(
  (props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <MuiLink component={RouterLink} ref={ref} underline='none' {...props} />
    )
  }
)

export default LinkRef
