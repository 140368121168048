import React, { Fragment } from 'react'

import { User_Role_Enum } from 'api/generated'
import { useUser } from 'context/auth/user'

type ProtectedComponentProps = {
  allowedRoles: User_Role_Enum[]
  inclusive?: boolean
  children: JSX.Element | JSX.Element[]
  isProtected?: boolean
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  allowedRoles,
  inclusive = true,
  children,
  isProtected = true
}: ProtectedComponentProps) => {
  const { dbUser } = useUser()

  return isProtected ? (
    dbUser &&
    (inclusive
      ? allowedRoles?.includes(dbUser?.role)
      : !allowedRoles?.includes(dbUser?.role)) ? (
      <>{children}</>
    ) : (
      <Fragment />
    )
  ) : (
    <>{children}</>
  )
}

export default ProtectedComponent
