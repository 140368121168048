import React from 'react'
import { ApolloError } from '@apollo/client'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Replay as ReplayIcon } from '@mui/icons-material'

import { MuiButton } from 'components'

type QueryErrorProps = {
  error: ApolloError | Error | undefined
  refetch?(): void
  loading?: boolean
}

const QueryError: React.FC<QueryErrorProps> = ({
  error,
  loading,
  refetch
}: QueryErrorProps) => {
  const { t } = useTranslation()

  return error ? (
    <>
      <Typography variant='h6' component='div'>
        <Box my={2}>{`${t('somethingWentWrong')} ${error}`}</Box>
      </Typography>

      {refetch ? (
        <MuiButton
          fullWidth={false}
          disabled={loading}
          onClick={refetch}
          startIcon={<ReplayIcon />}
        >
          {t('retry')}
        </MuiButton>
      ) : null}
    </>
  ) : null
}

export default QueryError
