import { GridValueFormatter } from '@mui/x-data-grid-pro'
import { intervalToDuration, Duration, format, parseISO } from 'date-fns'
import { TFunction } from 'i18next'

const formatDurationTime = (seconds: number, t: TFunction): string => {
  if (seconds === 0) {
    return `0 ${t('seg')}`
  }

  const durations = intervalToDuration({ start: 0, end: seconds * 1000 })
  let response = ''

  for (const duration in durations) {
    const time = durations[duration as keyof Duration]
    if (time) {
      response += `${time} ${t(duration)} `
    }
  }

  return response
}

export const dataGridDateFormatter: GridValueFormatter = (value: string) => {
  return value ? format(parseISO(value), 'dd/MM/yyyy') : ''
}

export const dataGridDateTimeFormatter: GridValueFormatter = (
  value: string
) => {
  return value ? format(new Date(value), 'dd/MM/yyyy HH:mm:ss') : ''
}

export const firstPaymentDaysOptions = Array.from(
  { length: 15 },
  (_, i) => i + 1
)
export const secondPaymentDaysOptions = Array.from(
  { length: 16 },
  (_, i) => i + 16
)

export default {
  formatDurationTime,
  dataGridDateFormatter,
  dataGridDateTimeFormatter,
  firstPaymentDaysOptions,
  secondPaymentDaysOptions
}
