import React from 'react'
import { AppBar, Toolbar, Box } from '@mui/material'

import MuiLink from '../../MuiLink'

const Header: React.FC = () => {
  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <Box
            display='flex'
            flex={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <MuiLink to={`/`}>
              <img src='/logo.png' alt='Credisaas logo' width={200} />
            </MuiLink>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default Header
