import React from 'react'
import { Box, BoxProps } from '@mui/material'

type MainProps = BoxProps

const Main: React.FC<MainProps> = (props: MainProps) => {
  return <Box component='main' p={2} {...props} />
}

export default Main
