import React from 'react'
import Zoom, { UncontrolledProps } from 'react-medium-image-zoom'

export type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  zoomProps?: UncontrolledProps
}

const Image: React.FC<ImageProps> = ({ zoomProps, ...props }) => {
  return (
    <Zoom {...zoomProps}>
      <img {...props} />
    </Zoom>
  )
}

export default Image
