export const numOrZero = (
  value: number | undefined | null | string
): number => {
  try {
    if (!value) {
      throw new Error(`No value provided: ${value}`)
    }
    const typeOfValue = typeof value

    if (!['number', 'string'].includes(typeOfValue)) {
      throw new Error(`Type of value not allowed: ${typeOfValue}`)
    }

    return Number(value)
  } catch {
    return 0
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sum = (values: any[]): number =>
  values?.reduce((acc, curr) => numOrZero(acc) + numOrZero(curr), 0)

export const getPercentage = (
  partialValue: number,
  totalValue: number
): number =>
  Math.round(
    ((100 * numOrZero(partialValue)) / numOrZero(totalValue) + Number.EPSILON) *
      100
  ) / 100

export default {
  numOrZero,
  sum,
  getPercentage
}
