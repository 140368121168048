import React, { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Divider,
  Skeleton,
  Typography,
  Box,
  ListItemButton,
  ListItem,
  List,
  TablePagination
} from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { formatDistanceToNow } from 'date-fns'
import { ApolloError } from '@apollo/client'

import { QueryError, MuiLink } from 'components'
import { GetBaseLoansQuery } from 'api/generated'
import { formatCurrency } from 'utils/currency'
import { useThemeType } from 'context/theme'
import { pageSizeOptions } from 'components/MuiDataGrid'
import { PaginationModelType } from 'utils/dataGrid'
import MuiDataGridNoRowsOverlay from '../MuiDataGridNoRowsOverlay'

export const TODAY_DATE_RANGE: DateRange<Date> = [new Date(), new Date()]

export type LoansListProps = {
  data?: GetBaseLoansQuery
  loading?: boolean
  error?: ApolloError
  refetch?(): void
  paginationModel: PaginationModelType
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModelType>>
}

const LoansList: React.FC<LoansListProps> = ({
  data,
  loading,
  error,
  refetch,
  paginationModel,
  setPaginationModel
}: LoansListProps) => {
  const { t } = useTranslation()
  const { dateFnsLocale } = useThemeType()
  const loans = data?.loans

  const handleRefetchQuery = useCallback(() => {
    refetch?.()
  }, [refetch])

  return (
    <>
      {error ? (
        <QueryError
          error={error}
          loading={loading}
          refetch={handleRefetchQuery}
        />
      ) : loading && !loans ? (
        <Skeleton variant='rectangular' width='100%' height='50vh' />
      ) : loans?.length ? (
        <>
          <List sx={{ height: '50vh', overflow: 'auto', width: '100%' }}>
            {loans?.map(loan => {
              return (
                <Fragment key={loan?.id}>
                  <ListItem
                    secondaryAction={<ChevronRightIcon />}
                    disablePadding
                  >
                    <ListItemButton
                      component={MuiLink}
                      to={`/loans/${loan?.id}`}
                    >
                      <Box flex={1} display='flex' flexDirection='column'>
                        <Typography variant='subtitle2'>
                          {loan?.client?.full_name}
                        </Typography>

                        <Typography variant='caption'>
                          {`${loan?.user?.full_name} / ${t(
                            loan?.periodicity
                          )} / ${t(loan?.type)}`}
                        </Typography>
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-end'
                      >
                        <Typography variant='subtitle2'>
                          {formatCurrency({
                            amount: loan?.amount || 0,
                            currency: loan?.currency
                          })}
                        </Typography>

                        <Typography variant='caption'>
                          {formatDistanceToNow(new Date(loan?.created_at), {
                            addSuffix: true,
                            locale: dateFnsLocale
                          })}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                  <Divider component='li' />
                </Fragment>
              )
            })}
          </List>
          <TablePagination
            component='div'
            count={data?.loansAggregate?.aggregate?.totalCount || 0}
            page={paginationModel?.page || 0}
            rowsPerPageOptions={pageSizeOptions}
            rowsPerPage={paginationModel?.pageSize || 10}
            onPageChange={(_event, newPage) => {
              setPaginationModel(paginationModel => ({
                ...paginationModel,
                page: newPage
              }))
            }}
            onRowsPerPageChange={event => {
              const newPageSize = Number(event?.target?.value)

              setPaginationModel({
                page: 0,
                pageSize: newPageSize
              })
            }}
          />
        </>
      ) : (
        <Box
          height='40vh'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <MuiDataGridNoRowsOverlay />
        </Box>
      )}
    </>
  )
}

export default LoansList
