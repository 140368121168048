import React, { Fragment, useCallback } from 'react'
import {
  Divider,
  Skeleton,
  Typography,
  Box,
  ListItemButton,
  ListItem,
  List,
  TablePagination
} from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { formatDistanceToNow } from 'date-fns'
import { ApolloError } from '@apollo/client'

import { QueryError, MuiLink, MuiDataGridNoRowsOverlay } from 'components'
import { GetPaymentsWithAggregateQuery } from 'api/generated'
import { formatCurrency } from 'utils/currency'
import { useThemeType } from 'context/theme'
import { pageSizeOptions } from 'components/MuiDataGrid'
import { PaginationModelType } from 'utils/dataGrid'

export const TODAY_DATE_RANGE: DateRange<Date> = [new Date(), new Date()]

export type PaymentsListProps = {
  data?: GetPaymentsWithAggregateQuery
  loading?: boolean
  error?: ApolloError
  refetch?(): void
  paginationModel: PaginationModelType
  setPaginationModel: React.Dispatch<React.SetStateAction<PaginationModelType>>
}

const PaymentsList: React.FC<PaymentsListProps> = ({
  data,
  loading,
  error,
  refetch,
  paginationModel,
  setPaginationModel
}: PaymentsListProps) => {
  const { dateFnsLocale } = useThemeType()
  const payments = data?.payments

  const handleRefetchQuery = useCallback(() => {
    refetch?.()
  }, [refetch])

  return (
    <>
      {error ? (
        <QueryError
          error={error}
          loading={loading}
          refetch={handleRefetchQuery}
        />
      ) : loading ? (
        <Skeleton variant='rectangular' width='100%' height='50vh' />
      ) : payments?.length ? (
        <>
          <List sx={{ height: '50vh', overflow: 'auto', width: '100%' }}>
            {payments?.map(payment => {
              return (
                <Fragment key={payment?.id}>
                  <ListItem
                    secondaryAction={<ChevronRightIcon />}
                    disablePadding
                  >
                    <ListItemButton
                      component={MuiLink}
                      to={`/payments/${payment?.id}`}
                    >
                      <Box flex={1} display='flex' flexDirection='column'>
                        <Typography variant='subtitle2'>
                          {payment?.client?.full_name}
                        </Typography>

                        <Typography variant='caption'>
                          {`${payment?.user?.full_name} / ${payment?.loan?.location?.name}`}
                        </Typography>
                      </Box>

                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-end'
                      >
                        <Typography variant='subtitle2'>
                          {formatCurrency({
                            amount:
                              (payment?.amount || 0) +
                              (payment?.penalty_amount || 0),
                            currency: payment?.loan?.currency
                          })}
                        </Typography>

                        <Typography variant='caption'>
                          {formatDistanceToNow(new Date(payment?.created_at), {
                            addSuffix: true,
                            locale: dateFnsLocale
                          })}
                        </Typography>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                  <Divider component='li' />
                </Fragment>
              )
            })}
          </List>

          <TablePagination
            component='div'
            count={data?.paymentsAggregate?.aggregate?.totalCount || 0}
            page={paginationModel?.page || 0}
            rowsPerPageOptions={pageSizeOptions}
            rowsPerPage={paginationModel?.pageSize || 10}
            onPageChange={(_event, newPage) => {
              setPaginationModel(paginationModel => ({
                ...paginationModel,
                page: newPage
              }))
            }}
            onRowsPerPageChange={event => {
              const newPageSize = Number(event?.target?.value)

              setPaginationModel({
                page: 0,
                pageSize: newPageSize
              })
            }}
          />
        </>
      ) : (
        <Box
          height='40vh'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <MuiDataGridNoRowsOverlay />
        </Box>
      )}
    </>
  )
}

export default PaymentsList
