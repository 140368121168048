import { GridFilterModel } from '@mui/x-data-grid-pro'

import { DELETED_FILTER_MODEL, EMPTY_FILTER_MODEL } from '../dataGrid'

export const ALL_PAYMENT_METHODS_FILTER = EMPTY_FILTER_MODEL
export const DELETED_PAYMENT_METHODS_FILTER = DELETED_FILTER_MODEL

export enum PAYMENT_METHOD_FILTERS_ENUM {
  ALL = 'ALL',
  DELETED = 'DELETED'
}

export type PaymentMethodFiltersTypeValues = {
  index: number
  title: string
  filter: GridFilterModel
}

export type PaymentMethodFiltersType = {
  [key in PAYMENT_METHOD_FILTERS_ENUM]: PaymentMethodFiltersTypeValues
}

export const PAYMENT_METHOD_FILTERS_OBJECT: PaymentMethodFiltersType = {
  [PAYMENT_METHOD_FILTERS_ENUM.ALL]: {
    index: 0,
    title: 'all',
    filter: ALL_PAYMENT_METHODS_FILTER
  },
  [PAYMENT_METHOD_FILTERS_ENUM.DELETED]: {
    index: 1,
    title: 'deleted',
    filter: DELETED_PAYMENT_METHODS_FILTER
  }
}

export const PAYMENT_METHOD_FILTERS_KEYS_ARRAY = Object.keys(
  PAYMENT_METHOD_FILTERS_OBJECT
) as PAYMENT_METHOD_FILTERS_ENUM[]

export const PAYMENT_METHOD_FILTERS_ARRAY: PaymentMethodFiltersTypeValues[] =
  PAYMENT_METHOD_FILTERS_KEYS_ARRAY?.map(
    filterKey => PAYMENT_METHOD_FILTERS_OBJECT?.[filterKey]
  )

export const getPaymentMethodFilterByIndex = (
  index: number
): PaymentMethodFiltersTypeValues | undefined =>
  PAYMENT_METHOD_FILTERS_ARRAY?.find(
    paymentFilter => paymentFilter?.index === index
  )

export const getInitialPaymentMethodFilter = () => {
  return PAYMENT_METHOD_FILTERS_OBJECT.ALL
}

export default {
  ALL_PAYMENT_METHODS_FILTER,
  DELETED_PAYMENT_METHODS_FILTER,
  PAYMENT_METHOD_FILTERS_OBJECT,
  PAYMENT_METHOD_FILTERS_KEYS_ARRAY,
  PAYMENT_METHOD_FILTERS_ARRAY,
  getPaymentMethodFilterByIndex,
  getInitialPaymentMethodFilter
}
