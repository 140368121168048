import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  Brightness4 as Brightness4Icon
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { useThemeType } from 'context/theme'

const ThemeSelect: React.FC = () => {
  const { toggleThemeType, isDarkTheme } = useThemeType()
  const { t } = useTranslation()

  return (
    <Tooltip title={t('toggleTheme')}>
      <IconButton onClick={toggleThemeType} color='inherit'>
        {isDarkTheme ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  )
}

export default ThemeSelect
