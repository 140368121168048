import React, { useState, useCallback } from 'react'
import { useMap } from '@vis.gl/react-google-maps'
import { DefaultTFuncReturn } from 'i18next'
import {
  Box,
  InputLabel,
  FormHelperText,
  Tooltip,
  IconButton,
  CircularProgress
} from '@mui/material'
import { MyLocation as MyLocationIcon } from '@mui/icons-material'

import MuiCard from '../MuiCard'
import GoogleMap, { DEFAULT_GOOGLE_MAP_ID, DEFAULT_ZOOM } from '../Map'
import MapMarker from '../MapMarker'

type MapFieldProps = {
  mapId?: string
  label?: string | DefaultTFuncReturn
  helperText?: string | DefaultTFuncReturn
  error?: boolean
  disabled?: boolean
  value?: google.maps.LatLngLiteral
  onClick?: (geolocation: google.maps.LatLngLiteral) => void
}

const MapField: React.FC<MapFieldProps> = ({
  mapId = DEFAULT_GOOGLE_MAP_ID,
  label,
  helperText,
  value,
  onClick,
  error = true,
  disabled = false
}: MapFieldProps) => {
  const map = useMap(mapId)
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false)
  const geolocationClient = navigator?.geolocation

  const handleShowCurrentLocation = useCallback(() => {
    if (geolocationClient) {
      setLoadingCurrentLocation(true)
      geolocationClient.getCurrentPosition(position => {
        const lat = position?.coords?.latitude
        const lng = position?.coords?.longitude
        const latLng = {
          lat,
          lng
        }

        map?.setCenter?.(latLng)
        map?.setZoom(DEFAULT_ZOOM)

        onClick?.(latLng)

        setLoadingCurrentLocation(false)
      })
    }
  }, [geolocationClient, map, onClick])

  return (
    <>
      <MuiCard>
        <Box
          position='relative'
          p={2}
          height={400}
          border={error ? 1 : undefined}
          borderColor={error ? 'error.main' : undefined}
        >
          {label ? (
            <Box mb={2}>
              <InputLabel>{label}</InputLabel>
            </Box>
          ) : null}

          <Box
            position='absolute'
            left={25}
            bottom={25}
            zIndex={1}
            bgcolor='common.white'
          >
            <Tooltip title='Mostrar ubicación'>
              <span>
                <IconButton
                  onClick={disabled ? undefined : handleShowCurrentLocation}
                  disabled={loadingCurrentLocation || disabled}
                >
                  {loadingCurrentLocation ? (
                    <CircularProgress size={20} sx={{ color: 'grey.700' }} />
                  ) : (
                    <MyLocationIcon
                      fontSize='small'
                      sx={{ color: 'grey.700' }}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </Box>

          <Box width='100%' height='90%'>
            <GoogleMap
              id={mapId}
              defaultCenter={value}
              onClick={event => {
                const latLng = event?.detail?.latLng
                if (latLng) {
                  onClick?.(latLng)
                }
              }}
            >
              <>{value ? <MapMarker position={value} /> : null}</>
            </GoogleMap>
          </Box>
        </Box>
      </MuiCard>

      {helperText ? (
        <FormHelperText error={error} variant='outlined' margin={undefined}>
          {helperText}
        </FormHelperText>
      ) : null}
    </>
  )
}

export default MapField
