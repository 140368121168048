import { GridFilterModel } from '@mui/x-data-grid-pro'

import { DELETED_FILTER_MODEL, EMPTY_FILTER_MODEL } from '../dataGrid'

export const ALL_CLIENTS_FILTER = EMPTY_FILTER_MODEL

export const DELETED_CLIENTS_FILTER = DELETED_FILTER_MODEL

export enum CLIENT_FILTERS_ENUM {
  ALL = 'ALL',
  DELETED = 'DELETED'
}

export type ClientFiltersTypeValues = {
  index: number
  title: string
  filter: GridFilterModel
  collectorMode: boolean
}

export type ClientFiltersType = {
  [key in CLIENT_FILTERS_ENUM]: ClientFiltersTypeValues
}

export const CLIENT_FILTERS_OBJECT: ClientFiltersType = {
  [CLIENT_FILTERS_ENUM.ALL]: {
    index: 3,
    title: 'all',
    filter: ALL_CLIENTS_FILTER,
    collectorMode: false
  },
  [CLIENT_FILTERS_ENUM.DELETED]: {
    index: 4,
    title: 'deleted',
    filter: DELETED_CLIENTS_FILTER,
    collectorMode: false
  }
}

export const CLIENT_FILTERS_KEYS_ARRAY = Object.keys(
  CLIENT_FILTERS_OBJECT
) as CLIENT_FILTERS_ENUM[]

export const COLLECTOR_MODE_CLIENT_FILTERS_KEYS_ARRAY = (
  Object.keys(CLIENT_FILTERS_OBJECT) as CLIENT_FILTERS_ENUM[]
)?.filter(filterKey => CLIENT_FILTERS_OBJECT?.[filterKey]?.collectorMode)

export const CLIENT_FILTERS_ARRAY: ClientFiltersTypeValues[] =
  CLIENT_FILTERS_KEYS_ARRAY?.map(
    filterKey => CLIENT_FILTERS_OBJECT?.[filterKey]
  )

export const getClientFilterByIndex = (
  index: number
): ClientFiltersTypeValues | undefined =>
  CLIENT_FILTERS_ARRAY?.find(clientFilter => clientFilter?.index === index)

export const getInitialClientFilter = () => {
  return CLIENT_FILTERS_OBJECT.ALL
}

export default {
  CLIENT_FILTERS_OBJECT,
  CLIENT_FILTERS_KEYS_ARRAY,
  CLIENT_FILTERS_ARRAY,
  getClientFilterByIndex,
  getInitialClientFilter
}
